// Routes to different components.. High order component nothing is rendered in this but this routes to all the components
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Login/Login';
import Error from '../shared/Error';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import OAuth2RedirectHandler from '../oauth2/OAuth2RedirectHandler';
import ResetPassword from '../ResetPassword/ResetPassword';
import LiveStream from '../LiveStream/LiveStream';
import ProducerEvent from '../ProducerEvent/ProducerEvent';
import PromoterPanel from '../PromoterPanel/PromoterPanel';
import Events from '../PromoterPanel/Events/Events';
import CreateEvent from '../PromoterPanel/Events/CreateEvent/CreateEvent';
import AllEvents from '../PromoterPanel/Events/AllEvents/AllEvents';
import BasicInfo from '../PromoterPanel/Events/CreateEvent/BasicInfo/BasicInfo';
import EventChoice from '../PromoterPanel/Events/CreateEvent/EventChoice/EventChoice';
import Details from '../PromoterPanel/Events/CreateEvent/Details/Details';
import Publish from '../PromoterPanel/Events/CreateEvent/Publish/Publish';
// import OnlineEvent from '../PromoterPanel/Events/CreateEvent/OnlineEvent/OnlineEvent';
import Streaming from '../PromoterPanel/Events/CreateEvent/Streaming/Streaming';
import Tickets from '../PromoterPanel/Events/CreateEvent/Tickets/Tickets';
import InPersonTickets from '../PromoterPanel/Events/CreateEvent/Tickets/InPersonTickets';
import { EVENT_STEPS } from '../constants';
import PreviewEvent from '../PromoterPanel/Events/CreateEvent/PreviewEvent/PreviewEvent';
import AccountSettings from '../AccountSettings/AccountSettings';
import EventDashboard from '../EventDashboard/EventDashboard';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import PrivacyPolicy from '../policies/PrivacyPolicy';
import TermsAndConditions from '../policies/TermsAndConditions';
import About from '../About/About';
import Dashboard from '../PromoterPanel/Dashboard/Dashboard';
import InPerson from '../PromoterPanel/Events/CreateEvent/InPerson/InPerson';

// Exporting default function named main.
/**
 * Main function component that handles the routing for the application.
 *
 * @return {JSX.Element} The main function component.
 */
export default function Main() {
  return (
    <div className="flex-grow-1">
      <Routes>
        <Route exact path="/" element={<Navigate to="login" replace />} />
        {/* <Route path='home' element={<Home/>} /> */}
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        {/* <Route path='create-event' element={<ProducerEvent/>} /> */}
        {/* <Route path='events' element={<EventDashboard/>}/> */}
        <Route path="events/:eventId" element={<PreviewEvent />} />
        <Route path="promoter-panel" element={<PromoterPanel />}>
          <Route path="home" element={<Dashboard />} />
          <Route path="events" element={<Events />}>
            <Route index element={<AllEvents />} />
            {/* Sequence starts from here */}
            {/* Doesn't necessarily have to follow this order, this is just a catelog */}
            {/* navigation is done inside components itself.. */}
            <Route path="create-event" element={<CreateEvent />}>
              {/* <Route index element={<InPersonTickets/>} /> */}
              <Route index element={<BasicInfo />} />
              {/* <Route index element={<Details/>} /> */}
              <Route
                path={`${EVENT_STEPS.BASIC_DETAILS.toLowerCase()}/:eventId`}
                element={<BasicInfo />}
              />
              <Route
                path={`${EVENT_STEPS.MAIN_DETAILS.toLowerCase()}/:eventId`}
                element={<Details />}
              />
              {/* <Route path='online-event' element={<OnlineEvent/>} /> */}
              <Route
                path={`${EVENT_STEPS.IN_PERSON_DETAILS.toLowerCase()}/:eventId`}
                element={<InPerson />}
              />
              <Route
                path={`${EVENT_STEPS.STREAMING_DETAILS.toLowerCase()}/:eventId`}
                element={<Streaming />}
              />
              {/* in person event choice step */}
              <Route
                path={`${EVENT_STEPS.EVENT_CHOICE.toLowerCase()}/:eventId`}
                element={<EventChoice />}
              />
              <Route
                path={`${EVENT_STEPS.TICKET_DETAILS.toLowerCase()}/:eventId`}
                element={<Tickets />}
              />
              {/* in person tickets choice step after details save and continue goes to event choice page.. and makes choice of inperson or streaming */}
              <Route
                path={`${EVENT_STEPS.IN_PERSON_TICKET_DETAILS.toLowerCase()}/:eventId`}
                element={<InPersonTickets />}
              />
              <Route
                path={`${EVENT_STEPS.FINAL_DETAILS.toLowerCase()}/:eventId`}
                element={<Publish />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="account-settings" element={<AccountSettings />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route
          path="privacy-policy"
          element={
            <div className="p-4">
              <PrivacyPolicy />
            </div>
          }
        ></Route>
        <Route
          path="terms-of-service"
          element={
            <div className="p-4">
              <TermsAndConditions />
            </div>
          }
        ></Route>
        <Route path="account-settings" element={<AccountSettings />}></Route>
        {/* component for live stream POC */}
        {/* <Route path='stream/:eventId' element={<LiveStream/>} />    */}
        <Route
          path="oauth2/redirect"
          element={<OAuth2RedirectHandler />}
        ></Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}
