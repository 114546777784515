import React, { useLayoutEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import {
  faBook,
  faBullhorn,
  faCalendarDay,
  faClipboardList,
  faListCheck,
  faQuestionCircle,
  faStairs,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// donot modify this object
/**
 * Enumerates the tabs for the admin panel.
 * @readonly
 * @enum {string}
 */
const ADMIN_TABS = Object.freeze({
  /**
   * The tab for managing users.
   */
  MANAGE_USERS: 'MANAGE-USERS',
  /**
   * The tab for managing events.
   */
  MANAGE_EVENTS: 'MANAGE-EVENTS',
  /**
   * The tab for managing patrons.
   */
  MANAGE_PATRONS: 'MANAGE-PATRONS',
  /**
   * The tab for managing orders.
   */
  MANAGE_ORDERS: 'MANAGE-ORDERS',
});

/**
 * The component for the admin panel.
 * @returns {JSX.Element} The rendered component.
 */
const Admin = () => {
  const location = useLocation();

  let path = location.pathname.split('/').pop(); // pathname will be admin/manage-users

  const [activeTab, setActiveTab] = useState(path);

  // useLayoutEffect(() => {
  //     document.body.classList.add('theme-light')

  //     return () => {
  //         document.body.classList.remove('theme-light')
  //     }
  // }, [])

  return (
    <div className="flex-grow-1">
      <Container fluid className="d-flex h-100">
        <Row className="flex-grow-1 flex-nowrap">
          <Col className="p-0 d-flex flex-grow-0">
            <Nav
              defaultActiveKey={activeTab.toUpperCase()}
              onSelect={(selectedTab) => setActiveTab(selectedTab)}
              className="flex-column vertical-nav-tabs acc-setup-nav-tabs"
            >
              <Nav.Link
                as={Link}
                to={ADMIN_TABS.MANAGE_USERS.toLowerCase()}
                eventKey={ADMIN_TABS.MANAGE_USERS}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faUsers} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">User Management</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={ADMIN_TABS.MANAGE_EVENTS.toLowerCase()}
                eventKey={ADMIN_TABS.MANAGE_EVENTS}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">Event Management</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={ADMIN_TABS.MANAGE_PATRONS.toLowerCase()}
                eventKey={ADMIN_TABS.MANAGE_PATRONS}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faBullhorn} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">
                  Patron Management
                </span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={ADMIN_TABS.MANAGE_ORDERS.toLowerCase()}
                eventKey={ADMIN_TABS.MANAGE_ORDERS}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faListCheck} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">Order Management</span>
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="flex-grow-1">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
