import React, { useLayoutEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Container, Row, Col, Nav, Button, Form } from 'react-bootstrap';
import {
  faChevronLeft,
  faCamera,
  faQuestionCircle,
  faStairs,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * This fucntional component renders the login information for the user to update password and email.
 *
 * @returns {JSX.Element} The login information component.
 */
const LoginInfo = () => {
  /**
   * Handles the form submission event.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <h2 className="fw-bold mt-3 mb-0">Login Information</h2>
      <span>Update your password and email information anytime.</span>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="10">
            <Row>
              <Col xs="6">
                <Form.Group controlId="email" className="mt-4 mb-3">
                  <Form.Label className="required">Email Address</Form.Label>
                  <Form.Control type="text" placeholder="name..." />
                </Form.Group>

                <Form.Group controlId="password" className="mb-3">
                  <Form.Label className="required">Password</Form.Label>
                  <Form.Control type="password" placeholder="email..." />
                </Form.Group>
              </Col>
              <Col xs="6" className="d-flex">
                <Button
                  variant="outlined"
                  className="border border-darkGray mt-auto mb-3 ms-auto"
                >
                  Edit Password
                </Button>
              </Col>
            </Row>

            {/* <hr className="my-4" />

						<h4 className="mt-4 fw-bold mb-3">Social Logins</h4>

						<Row>
							<Col className="d-flex">
								<img src="/google_icon_green.png" width="70" height="70" alt="Google logo"/>
								<div className="ms-3">
									<h4 className="mt-3 fw-bold mb-0">Google Account</h4>
									<span className="status text-darkGray">Disabled</span>
								</div>
								<div className="ms-auto">
									<Button variant='outlined'  className="border border-darkGray px-4">
										Enable
									</Button>
								</div>
								
							</Col>
						</Row> */}

            <hr className="my-4" />

            <Row>
              <Col className="d-flex">
                {/* <Button variant="outlined" 
									className="ms-auto me-3 border border-dark">
										Discard
								</Button> */}
                {/* <Button type="submit" variant="orange" 
									className="text-white px-5 ms-auto border border-orange">
									Save
								</Button> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default LoginInfo;
