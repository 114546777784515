import React, { useState, useRef } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  faCalendarDays,
  faCirclePlus,
  faDollarSign,
  faExternalLinkAlt,
  faPencil,
  faPercent,
  faTrashCan,
  faTriangleExclamation,
  faPlus,
  faTrash,
  faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Button,
  InputGroup,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Card,
  Modal,
} from 'react-bootstrap';
import { EVENT_STEPS, TICKET_LOCATION_TYPE, TICKET_TYPE } from '../../../../constants';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import moment from 'moment';
import { formatDate } from '../../../../utils/CommonUtils';
import validateEventForm from '../eventFormValidator';
import validateCouponDetails from './couponValidator';
import { createEvent } from '../../../../utils/APIUtils';
import ToastMessage from '../../../../shared/ToastMessage';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const BILL_FREQUENCY = Object.freeze({
  ONCE: 'ONCE',
  RECURRING: 'RECURRING',
});

const MAX_DESC_CHAR_COUNT = 1500;

const editorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'link',
    'undo',
    'redo',
    'numberedList',
    'bulletedList',
  ],
};

export const BILL_PERIOD = Object.freeze({
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
});

export const COUPON_TYPE = Object.freeze({
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
});

const InPersonTickets = () => {
  const [context, setContext] = useOutletContext();
  let { inPersonTicketDetailsDTO } = context;
  const [venueImage, setvenueImage] = useState();

  inPersonTicketDetailsDTO.ticketCategory = 'STD_PRICE';
  inPersonTicketDetailsDTO.eventTicketCouponsDtoList =
    inPersonTicketDetailsDTO.eventTicketCouponsDtoList || [];
  inPersonTicketDetailsDTO.inPersonTicketSectionDetailsDtoList =
    inPersonTicketDetailsDTO.inPersonTicketSectionDetailsDtoList || [];

  const [formState, setFormState] = useState(inPersonTicketDetailsDTO);
  const [isSaving, setIsSaving] = useState(false);
  const uploadFileRef = useRef(null);
  const [audienceMessage, setAudienceMessage] = useState('');
  const [sections, setSections] = useState([
    {
      sectionName: '',
      capacity: 0,
      inPersonTicketSectionTypeDetailsDtoList: [
        {
          ticketType: '',
          price: 0.0,
          serviceFees: 0,
          description: '',
          active: true,
        },
      ],
    },
  ]);

  if (
    inPersonTicketDetailsDTO.inPersonTicketSectionDetailsDtoList.length === 0
  ) {
    inPersonTicketDetailsDTO.inPersonTicketSectionDetailsDtoList = sections;
  }

  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  const [show, setShow] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [locationType, setLocationType] = useState(
    TICKET_LOCATION_TYPE.LIVESTREAM
  );
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateState = (state) =>
    setFormState((prevState) => {
      return { ...prevState, ...state };
    });

  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const isDatesSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getMinTime = () => {
    let sDate = formState.salesStartDate,
      eDate = formState.salesEndDate,
      sTime = formState.salesStartTime;
    let date =
      sTime &&
      sDate &&
      sTime &&
      isDatesSameDay(new Date(sDate), new Date(eDate))
        ? new Date(sTime)
        : setHours(setMinutes(new Date(), 0), 0);
    return date;
  };

  const getMaxTime = () => {
    let date = formState.salesEndTime
      ? new Date(formState.salesEndTime)
      : setHours(setMinutes(new Date(), 45), 23);
    return date;
  };

  const handleChange = (e) => {
    let state = {};
    const { name, id, value, checked, type } = e.target;
    if (type === 'radio') {
      state[name] = value === 'true' ? true : false;
    } else {
      state[id] = value;
    }
    updateState(state);
  };

  const handleChangeTicketType = (sectionIndex, ticketIndex, event) => {
    let { name, value, type } = event.target;
    let parsedValue;
    if (!isNaN(value)) {
      if (Number.isInteger(parseFloat(value))) {
        parsedValue = parseInt(value);
      } else {
        parsedValue = parseFloat(value);
      }
    } else if (
      value.toLowerCase() === 'true' ||
      value.toLowerCase() === 'false'
    ) {
      parsedValue = value.toLowerCase() === 'true';
    } else {
      parsedValue = value;
    }
    setSections((prevSections) => {
      if (
        prevSections[sectionIndex].inPersonTicketSectionTypeDetailsDtoList[
          ticketIndex
        ]['ticketType'] === '' &&
        ticketIndex === 0
      ) {
        prevSections[sectionIndex].inPersonTicketSectionTypeDetailsDtoList[
          ticketIndex
        ]['ticketType'] = 'Regular';
      }
      const updatedSections = [...prevSections];
      if (type === 'checkbox') {
        const isActive =
          !updatedSections[sectionIndex]
            .inPersonTicketSectionTypeDetailsDtoList[ticketIndex].active;
        updatedSections[sectionIndex].inPersonTicketSectionTypeDetailsDtoList[
          ticketIndex
        ].active = isActive;
      } else {
        updatedSections[sectionIndex].inPersonTicketSectionTypeDetailsDtoList[
          ticketIndex
        ] = {
          ...updatedSections[sectionIndex]
            .inPersonTicketSectionTypeDetailsDtoList[ticketIndex],
          [name]: parsedValue,
        };
      }

      const updatedFormState = {
        ...formState,
        inPersonTicketSectionDetailsDtoList: updatedSections,
      };

      updateState(updatedFormState);
      return updatedSections;
    });
  };

  const handleAddType = (sectionIndex) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[
        sectionIndex
      ].inPersonTicketSectionTypeDetailsDtoList.push({
        ticketType: '',
        price: 0.0,
        serviceFees: 0,
        description: '',
        active: true,
      });

      const updatedFormState = {
        ...formState,
        inPersonTicketSectionDetailsDtoList: updatedSections,
      };

      updateState(updatedFormState);
      return updatedSections;
    });
  };

  const handleDeleteType = (sectionIndex, ticketIndex) => {
    if (ticketIndex >= 1) {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        updatedSections[
          sectionIndex
        ].inPersonTicketSectionTypeDetailsDtoList.splice(ticketIndex, 1);

        const updatedFormState = {
          ...formState,
          inPersonTicketSectionDetailsDtoList: updatedSections,
        };

        updateState(updatedFormState);
        return updatedSections;
      });
    }
  };

  const handleAddSection = (e) => {
    e.preventDefault();
    let { name } = e.target;
    const isSectionNameDuplicate = sections.some(
      (section) => section.sectionName === name
    );
    let error = {};
    if (isSectionNameDuplicate) {
      error['eventTitle'] = 'The Section Name cannot be same';
    }

    const newSection = {
      sectionName: '',
      capacity: 0,
      inPersonTicketSectionTypeDetailsDtoList: [
        {
          ticketType: '',
          price: 0.0,
          serviceFees: 0,
          description: '',
          active: true,
        },
      ],
    };

    setSections((prevSections) => {
      const updatedSections = [...prevSections, newSection];

      const updatedFormState = {
        ...formState,
        inPersonTicketSectionDetailsDtoList: updatedSections,
      };

      updateState(updatedFormState);
      return updatedSections;
    });
  };

  const handleChangeSection = (sectionIndex, event) => {
    let { name, value } = event.target;
    const parsedValue = value.trim();

    let parsed;

    if (isNaN(parsedValue)) {
      parsed = parsedValue;
    } else {
      const floatVal = parseFloat(parsedValue);
      const intVal = parseInt(parsedValue, 10);

      if (!isNaN(floatVal) && !isNaN(intVal)) {
        if (floatVal === intVal) {
          parsed = intVal;
        } else {
          parsed = floatVal;
        }
      } else {
        if (parsedValue.toLowerCase() === 'true') {
          parsed = true;
        } else if (parsedValue.toLowerCase() === 'false') {
          parsed = false;
        } else {
          parsed = parsedValue;
        }
      }
    }

    setSections((prevSections) => {
      if (
        prevSections[sectionIndex]['sectionName'] === '' &&
        sectionIndex === 0
      ) {
        prevSections[sectionIndex]['sectionName'] = 'Main Section';
      }
      const updatedSections = prevSections.map((section, idx) => {
        if (idx === sectionIndex) {
          return {
            ...section,
            [name]: parsed,
          };
        }
        return section;
      });

      const updatedFormState = {
        ...formState,
        inPersonTicketSectionDetailsDtoList: updatedSections,
      };

      updateState(updatedFormState);
      return updatedSections;
    });
  };

  const handleDeleteSection = (index) => {
    if (index >= 1) {
      setSections((prevSections) => {
        const updatedSections = prevSections.filter(
          (_, sectionIndex) => sectionIndex !== index
        );

        const updatedFormState = {
          ...formState,
          inPersonTicketSectionDetailsDtoList: updatedSections,
        };

        updateState(updatedFormState);
        return updatedSections;
      });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setvenueImage(reader.result);
        updateState({ venueImage: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const validate = () => {
    let error = validateEventForm(formState, 'IN_PERSON_TICKET_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      setIsSaving(true);
      let data = JSON.parse(JSON.stringify(formState));
      data.salesStartTime = moment(formState.salesStartTime).format('hh:mm A');
      data.salesEndTime = moment(formState.salesEndTime).format('hh:mm A');
      createEvent('IN_PERSON_TICKET_DETAILS', data, context.eventId)
        .then((res) => {
          setContext({
            ...context,
            inPersonTicketDetailsDTO: {
              ...formState,
            },
          });
          setToast({ show: true, msg: 'Saved Successfully!', success: true });
          setIsSaving(false);
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.FINAL_DETAILS}/${context.eventId}`
          );
        })
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          setIsSaving(false);
        });
    }
  };

  const getNewCouponDTO = () => {
    return {
      couponType: '',
      couponDiscount: null,
      couponName: '',
      couponCode: '',
      includeTaxInPrice: false,
      validTillDate: '',
      limitToFirstTimeUsers: false,
      limitToSpecificUser: false,
      specificUserEmail: '',
      limitUsageCount: false,
      usageCount: null,
      limitToOneUsePerUser: false,
      couponId: '',
      isNewCoupon: true,
    };
  };

  const getMinEndDate = () => {
    if (
      formState.salesStartTime &&
      formState.salesEndTime &&
      formState.salesEndTime < formState.salesStartTime
    ) {
      return moment(formState.salesStartDate, 'YYYY-MM-DD')
        .add(1, 'd')
        .toDate();
    }
    return moment(formState.salesStartDate).toDate();
  };

  const getCoupon = (index) => {
    let coupon;
    if (index) {
      coupon = formState.eventTicketCouponsDtoList[index];
    } else {
      coupon = getNewCouponDTO();
    }
    setCurrentCoupon(coupon);
    setShowCoupon(true);
  };

  const validateCoupon = (coupon, couponList) => {
    let error = validateCouponDetails(coupon, couponList);
    setErrors(error);
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  };

  const createCoupon = (coupon) => {
    let ticketDTO = { ...formState };
    let isCouponValid = validateCoupon(
      coupon,
      ticketDTO.eventTicketCouponsDtoList
    );

    if (isCouponValid) {
      if (coupon.isNewCoupon) {
        coupon.isNewCoupon = false;
        ticketDTO.eventTicketCouponsDtoList.push(coupon);
      } else {
        let index = ticketDTO.eventTicketCouponsDtoList.findIndex(
          (c) => c.couponCode === coupon.couponCode
        );
        ticketDTO.eventTicketCouponsDtoList[index] = coupon;
      }

      setFormState(ticketDTO);
      setShowCoupon(false);
    }
  };

  const deleteCoupon = (index) => {
    let couponList = [...formState.eventTicketCouponsDtoList];
    couponList.splice(index, 1);
    setFormState({ ...formState, eventTicketCouponsDtoList: couponList });
  };

  const editCoupon = (index) => {
    let couponList = [...formState.eventTicketCouponsDtoList];
    let currentCoupon = couponList[index];
    setCurrentCoupon(currentCoupon);
    setShowCoupon(true);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <h2 className="fw-bold mb-4">Add tickets</h2>
            <h5 className="mb-2 fw-bold">Select the type of admission</h5>
            <ButtonToolbar aria-label="Location Type Button Group">
              <ButtonGroup size="lg" aria-label="First group">
                <Button
                  onClick={() => updateState({ ticketType: TICKET_TYPE.PAID })}
                  className={`px-5 btn-location-type ${
                    formState.ticketType === TICKET_TYPE.PAID ? 'active' : ''
                  }`}
                >
                  Paid
                </Button>
              </ButtonGroup>
              <ButtonGroup size="lg" className="mx-3" aria-label="Second group">
                <Button
                  onClick={() => updateState({ ticketType: TICKET_TYPE.FREE })}
                  className={`px-5 btn-location-type ${
                    formState.ticketType === TICKET_TYPE.FREE ? 'active' : ''
                  }`}
                >
                  Free
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            {errors && errors.ticketType && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.ticketType}
              </Form.Text>
            )}

            <hr />

            <Row className="mb-4">
              <Col xs={12} lg={8}>
                <h5 className="fw-bold mb-2">Tax Mode</h5>
                <Form.Group className="mb-3">
                  <Form.Check
                    label="Tax Free"
                    name="chargeTax"
                    type="radio"
                    id="taxRadioYes"
                    value={true}
                    checked={formState.chargeTax}
                    onChange={handleChange}
                  />
                  <div className="d-flex align-items-center">
                    <Form.Check
                      label="Tax with"
                      name="chargeTax"
                      type="radio"
                      id="taxRadioNo"
                      placeholder=""
                      value={false}
                      onChange={handleChange}
                      checked={!formState.chargeTax}
                    />
                    <Form.Control
                      disabled={formState.chargeTax}
                      className="mx-2"
                      style={{ width: '100px' }}
                      type="text"
                      value={formState.taxPercentage}
                      onChange={handleChange}
                    />{' '}
                    %
                    <Button variant="primary" className="text-white ms-2 px-3">
                      Apply
                    </Button>
                  </div>
                </Form.Group>
                {errors && errors.taxPercentage && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.taxPercentage}
                  </Form.Text>
                )}

                <hr />

                <h5 className="my-4 fw-bold">Manage Admission Types</h5>
                {formState.inPersonTicketSectionDetailsDtoList.map(
                  (section, sectionIndex) => (
                    <div key={sectionIndex}>
                      <div className="mt-3 border-2 border border-primary rounded-25px p-4">
                        <h5 className="d-flex fw-bold">
                          Section
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleDeleteSection(sectionIndex)}
                            className="trash-icon ms-auto"
                          />
                        </h5>

                        <Row className="my-3">
                          <Col xs={12} md={6}>
                            <Form.Group controlId="sectionName">
                              <Form.Label className="required">Name</Form.Label>

                              <Form.Control
                                name={'sectionName'}
                                value={section.sectionName}
                                onChange={(event) =>
                                  handleChangeSection(sectionIndex, event)
                                }
                                disabled={sectionIndex >= 1 ? false : true}
                                type="text"
                                placeholder={
                                  sectionIndex >= 1 ? 'Ex:VIP' : 'Main Section'
                                }
                              />
                              {errors &&
                                errors.sectionErrors &&
                                errors.sectionErrors[sectionIndex] && (
                                  <Form.Text className="text-danger">
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      className="me-2"
                                    />
                                    {
                                      errors.sectionErrors[sectionIndex]
                                        .sectionName
                                    }
                                  </Form.Text>
                                )}
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group controlId="capacity">
                              <Form.Label className="required">
                                Capacity
                              </Form.Label>
                              <Form.Control
                                name={'capacity'}
                                value={section.capacity}
                                onChange={(event) =>
                                  handleChangeSection(sectionIndex, event)
                                }
                                type="text"
                                placeholder="Ex:100"
                              />
                              {errors &&
                                errors.sectionErrors &&
                                errors.sectionErrors[sectionIndex] && (
                                  <Form.Text className="text-danger">
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      className="me-2"
                                    />
                                    {errors.sectionErrors[sectionIndex].capacity}
                                  </Form.Text>
                                )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr />
                        <h6 className="fw-bold">Ticket Types</h6>
                        <Row>
                          {formState.inPersonTicketSectionDetailsDtoList[
                            sectionIndex
                          ].inPersonTicketSectionTypeDetailsDtoList.map(
                            (type, ticketIndex) => (
                              <Card key={ticketIndex} className="mt-3">
                                <Card.Body>
                                  <Row className="my-3">
                                    <Col>
                                      <Form.Group controlId="ticketType">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                          name="ticketType"
                                          disabled={
                                            ticketIndex === 0 ? true : false
                                          }
                                          value={
                                            ticketIndex === 0
                                              ? 'Regular'
                                              : typeof type.ticketType !==
                                                  'undefined' &&
                                                typeof type.ticketType ===
                                                  'string'
                                              ? type.ticketType
                                              : ''
                                          }
                                          onChange={(event) =>
                                            handleChangeTicketType(
                                              sectionIndex,
                                              ticketIndex,
                                              event
                                            )
                                          }
                                          type="text"
                                          placeholder={
                                            ticketIndex >= 1
                                              ? 'Ex:Students'
                                              : 'Regular'
                                          }
                                          style={{ width: '12vw' }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col>
                                      <Form.Group controlId="price">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                          name="price"
                                          value={
                                            typeof type.price === 'number' &&
                                            !isNaN(type.price)
                                              ? type.price.toString()
                                              : ''
                                          }
                                          onChange={(event) =>
                                            handleChangeTicketType(
                                              sectionIndex,
                                              ticketIndex,
                                              event
                                            )
                                          }
                                          type="text"
                                          placeholder="Ex:100"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col>
                                      <Form.Group controlId="serviceFees">
                                        <Form.Label>Fee</Form.Label>
                                        <Form.Control
                                          name="serviceFees"
                                          value={
                                            typeof type.serviceFees ===
                                              'number' &&
                                            !isNaN(type.serviceFees)
                                              ? type.serviceFees.toString()
                                              : ''
                                          }
                                          onChange={(event) =>
                                            handleChangeTicketType(
                                              sectionIndex,
                                              ticketIndex,
                                              event
                                            )
                                          }
                                          type="text"
                                          placeholder="optional"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col>
                                      <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                          name="description"
                                          value={
                                            typeof type.description === 'string'
                                              ? type.description.toString()
                                              : ''
                                          }
                                          onChange={(event) =>
                                            handleChangeTicketType(
                                              sectionIndex,
                                              ticketIndex,
                                              event
                                            )
                                          }
                                          type="text"
                                          placeholder="optional"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col className="d-flex align-items-center pt-3">
                                      <Form.Switch
                                        id="active"
                                        label="Active"
                                        checked={
                                          formState
                                            .inPersonTicketSectionDetailsDtoList[
                                            sectionIndex
                                          ]
                                            .inPersonTicketSectionTypeDetailsDtoList[
                                            ticketIndex
                                          ].active
                                        }
                                        onChange={(e) =>
                                          handleChangeTicketType(
                                            sectionIndex,
                                            ticketIndex,
                                            e
                                          )
                                        }
                                        className="mt-3"
                                      />
                                    </Col>
                                    <Col>
                                      <span>Delete</span> <br />
                                      <FontAwesomeIcon
                                        size="2x"
                                        icon={faTrash}
                                        onClick={() =>
                                          handleDeleteType(
                                            sectionIndex,
                                            ticketIndex
                                          )
                                        }
                                        className="trash-icon mt-2"
                                      />
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            )
                          )}
                          <div className="d-flex align-items-center">
                            <Button
                              onClick={() => handleAddType(sectionIndex)}
                              className="border rounded-circle m-2 text-white py-0 px-2 rounded"
                            >
                              <FontAwesomeIcon size="sm" icon={faPlus} />
                            </Button>
                            <h6 className="fw-bold text-dark mb-0">
                              Add a new type
                            </h6>
                          </div>
                        </Row>
                      </div>
                    </div>
                  )
                )}

                <div className="p-3">
                  <div className="dashed-box-rounded d-flex align-items-center justify-content-center flex-column p-5 my-4">
                    <Button
                      onClick={handleAddSection}
                      variant="primary"
                      className="text-white btn-create-event mb-2"
                    >
                      Add a new section
                    </Button>
                    <h5>Create a new section capacity and pricing</h5>
                  </div>
                </div>

                <hr />

                <h4 className="fw-bold">Venue Layout (optional)</h4>

                <div className="p-3">
                  <div className="dashed-box-rounded d-flex align-items-center p-3">
                    <h5 className="mb-0">You can upload here</h5>
                    <form
                      id="form-file-upload"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        hidden
                        ref={uploadFileRef}
                        type="file"
                        id="input-file-upload"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleImageUpload}
                      />
                    </form>
                    <Button
                      onClick={() => uploadFileRef.current.click()}
                      variant="primary"
                      className="text-white ms-auto btn-create-event"
                    >
                      <FontAwesomeIcon icon={faCloudArrowUp} className="me-2" />
                      Upload File
                    </Button>
                  </div>
                </div>

                <h4 className="m-3 fw-bold">Preview </h4>
                {venueImage && (
                  <img
                    className="m-3"
                    src={venueImage}
                    alt="Venue Layout Preview"
                  />
                )}
              </Col>
              <Col xs={12} lg={5}>
                <hr className="my-4" />

                <h4 className="fw-bold mb-4">Ticket Sales Timeframe</h4>
                <div className="d-flex align-items-center mb-3">
                  <Form.Group className="flex-grow-1 me-3">
                    <Form.Label className="required">
                      Sales Start Date:
                    </Form.Label>
                    <DatePicker
                      minDate={new Date()}
                      maxDate={
                        formState.salesEndDate &&
                        moment(formState.salesEndDate).toDate()
                      }
                      className="form-control w-20"
                      selected={
                        formState.salesStartDate &&
                        moment(formState.salesStartDate).toDate()
                      }
                      onChange={(date) =>
                        updateState({ salesStartDate: formatDate(date) })
                      }
                    />

                    {errors && errors.salesStartDate && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.salesStartDate}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="flex-grow-1">
                    <Form.Label className="required">
                      Sales Start Time:
                    </Form.Label>
                    <DatePicker
                      className="form-control w-20"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={setHours(setMinutes(new Date(), 0), 0)}
                      maxTime={getMaxTime()}
                      selected={
                        formState.salesStartTime &&
                        new Date(formState.salesStartTime)
                      }
                      onChange={(date) =>
                        updateState({ salesStartTime: date.getTime() })
                      }
                    />

                    {errors && errors.salesStartTime && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.salesStartTime}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <Form.Group className="flex-grow-1 me-3">
                    <Form.Label className="required">
                      Sales End Date:
                    </Form.Label>
                    <DatePicker
                      minDate={getMinEndDate()}
                      className="form-control w-20"
                      selected={
                        formState.salesEndDate &&
                        moment(formState.salesEndDate).toDate()
                      }
                      onChange={(date) =>
                        updateState({ salesEndDate: formatDate(date) })
                      }
                    />

                    {errors && errors.salesEndDate && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.salesEndDate}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="flex-grow-1">
                    <Form.Label className="required">Sales End Time:</Form.Label>
                    <DatePicker
                      className="form-control w-20"
                      minTime={getMinTime()}
                      maxTime={setHours(setMinutes(new Date(), 45), 23)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      selected={
                        formState.salesEndTime &&
                        new Date(parseInt(formState.salesEndTime))
                      }
                      onChange={(date) =>
                        updateState({ salesEndTime: date.getTime() })
                      }
                    />

                    {errors && errors.salesEndTime && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.salesEndTime}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <Form.Group className="flex-grow-1 mb-3">
                  <Form.Label>Timezone:</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    value="(GMT-0400) Canada(Toronto) Time"
                  />
                  <Form.Text>
                    This is the timezone for the event set up in Step 1.
                  </Form.Text>
                </Form.Group>

                <Form.Check
                  type="checkbox"
                  name="showTicketDetailsAtCheckout"
                  label="Show ticket sale end dates and sate status at checkout"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      showTicketDetailsAtCheckout: e.target.checked,
                    })
                  }
                  checked={formState.showTicketDetailsAtCheckout}
                />

                <div className="d-flex align-items-center mt-3">
                  <Form.Check
                    type="checkbox"
                    name="showTicketDetailsAtCheckout"
                    label="Show timer (time that it takes to complete an order)"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        showTicketDetailsAtCheckout: e.target.checked,
                      })
                    }
                    checked={formState.showTicketDetailsAtCheckout}
                  />
                  <Form.Control
                    className="mx-2"
                    type="text"
                    style={{ width: '50px' }}
                  />
                  <span>Mins</span>
                </div>
              </Col>
            </Row>

            <hr className="my-4" />

            <h4 className="fw-bold">How did you hear us?</h4>
            <p>Select the answers or make your own</p>

            <div className="d-flex">
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Social Media</h6>}
                className="me-3"
              />
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Email</h6>}
                className="me-3"
              />
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Word of mouth</h6>}
                className="me-3"
              />
            </div>

            <div className="d-flex my-3">
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Search Engine</h6>}
                className="me-3"
              />
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Radio</h6>}
                className="me-3"
              />
              <Form.Switch
                id="socialmedia"
                label={<h6 className="fw-bold">Newsletter</h6>}
                className="me-3"
              />
            </div>

            <h5 className="fw-bold">Add your own...</h5>
            <Form.Control
              type="text"
              className="my-2 w-auto"
              placeholder="add your own..."
            />
            <div>
              <Button variant="primary" className="text-white px-4 fw-bold">
                Apply
              </Button>
            </div>

            <hr className="my-4" />

            <h4 className="fw-bold">Message for your audience</h4>
            <p>
              This note will be included in the email purchase confirmation sent
              to your audience.
            </p>

            <div
              className={`${
                audienceMessage.length > MAX_DESC_CHAR_COUNT
                  ? 'editor-error'
                  : ''
              }`}
            >
              <CKEditor
                id="description"
                className={`${
                  audienceMessage.length > MAX_DESC_CHAR_COUNT
                    ? 'editor-error'
                    : ''
                }`}
                editor={ClassicEditor}
                data={audienceMessage}
                config={editorConfiguration}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAudienceMessage(data);
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </div>

            <Form.Text
              className={`${
                audienceMessage.length > MAX_DESC_CHAR_COUNT
                  ? 'text-danger'
                  : ''
              }`}
            >
              {audienceMessage.length + ` / ${MAX_DESC_CHAR_COUNT} characters`}
            </Form.Text>
          </Col>
        </Row>

        <div className="d-flex my-4 pt-5">
          <Button
            as={Link}
            to={`/promoter-panel/events/create-event/${EVENT_STEPS.IN_PERSON_DETAILS}/${context.eventId}`}
            variant="outlined"
            className="ms-auto px-3 border border-dark btn-create-event"
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="text-white ms-3 px-4 btn-create-event"
          >
            {isSaving && (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Saving...</span>
              </>
            )}
            {!isSaving && <span>Save and Continue</span>}
          </Button>
        </div>
      </Form>

      <Modal
        size="lg"
        show={showCoupon}
        onHide={() => {
          setShowCoupon(false);
          setErrors(null);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0 px-5 py-4" closeButton>
          <Modal.Title className="fw-bold">
            {currentCoupon && currentCoupon.isNewCoupon
              ? 'New Coupon'
              : 'Edit Coupon'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <p>Select the type of coupon you want to offer</p>
          {currentCoupon && (
            <>
              <div className="my-4">
                <ButtonToolbar
                  id="locationTypeId"
                  aria-label="Location Type Button Group"
                >
                  <ButtonGroup size="lg" aria-label="First group">
                    <Button
                      className={`btn-hover-states  p-4 me-3 ${
                        currentCoupon.couponType === COUPON_TYPE.FIXED
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          couponType: COUPON_TYPE.FIXED,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="me-2"
                        size="2x"
                      />
                      <p>Fixed amount discount</p>
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup size="lg" aria-label="First group">
                    <Button
                      className={`btn-hover-states p-4 me-3 ${
                        currentCoupon.couponType === COUPON_TYPE.PERCENTAGE
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          couponType: COUPON_TYPE.PERCENTAGE,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPercent}
                        className="me-2"
                        size="2x"
                      />
                      <p>Percentage Discount</p>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                {errors && errors.couponType && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.couponType}
                  </Form.Text>
                )}
              </div>

              <div className="d-flex">
                <Form.Group controlId="couponName" className="mb-3 me-4">
                  <Form.Label className="required">Coupon Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCoupon.couponName}
                    onChange={(e) =>
                      setCurrentCoupon({
                        ...currentCoupon,
                        couponName: e.target.value,
                      })
                    }
                  />
                  {errors && errors.couponName && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.couponName}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="couponCode" className="mb-3">
                  <Form.Label className="required">Coupon code</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCoupon.couponCode}
                    onChange={(e) =>
                      setCurrentCoupon({
                        ...currentCoupon,
                        couponCode: e.target.value,
                      })
                    }
                  />
                  {errors && errors.couponCode && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.couponCode}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>

              <Form.Group controlId="couponDiscount" className="mb-3">
                <Form.Label className="required">Discount</Form.Label>

                {currentCoupon.couponType === COUPON_TYPE.FIXED && (
                  <div className="mb-3 d-flex w-25">
                    <InputGroup.Text className="me-2 bg-gray fw-bold">
                      CA $
                    </InputGroup.Text>
                    <InputGroup>
                      <Form.Control
                        value={currentCoupon.couponDiscount}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            couponDiscount: e.target.value,
                          })
                        }
                        aria-describedby="twitter"
                      />
                    </InputGroup>
                  </div>
                )}

                {currentCoupon.couponType === COUPON_TYPE.PERCENTAGE && (
                  <div className="mb-3 d-flex w-25">
                    <InputGroup>
                      <Form.Control
                        value={currentCoupon.couponDiscount}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            couponDiscount: e.target.value,
                          })
                        }
                        aria-describedby="twitter"
                      />
                      <InputGroup.Text className="me-2 bg-gray fw-bold">
                        %
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                )}

                {errors && errors.couponDiscount && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.couponDiscount}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="includeTaxInPrice" className="my-3">
                <Form.Check
                  checked={currentCoupon.includeTaxInPrice}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      includeTaxInPrice: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  name="couponTax"
                  label="Include Tax in price"
                />
              </Form.Group>

              <div className="d-flex align-items-center my-3 w-50">
                <Form.Group className="w-50 me-3">
                  <Form.Label>Valid Till:</Form.Label>
                  <div className="custom-calender-input">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <DatePicker
                      className="form-control w-20"
                      minDate={new Date()}
                      selected={
                        currentCoupon.validTillDate &&
                        moment(currentCoupon.validTillDate).toDate()
                      }
                      onChange={(date) =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          validTillDate: formatDate(date),
                        })
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <Form.Group>
                <Form.Label>Limit Uses</Form.Label>
                <Form.Check
                  checked={currentCoupon.limitToFirstTimeUsers}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToFirstTimeUsers: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  name="couponTax"
                  label="Eligible for first time users only"
                />

                <Form.Check
                  checked={currentCoupon.limitToSpecificUser}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToSpecificUser: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  label="Limit to specific customer"
                />

                {currentCoupon.limitToSpecificUser && (
                  <Form.Group className="w-50 ms-4 mb-4">
                    <span className="required d-flex">
                      <Form.Control
                        value={currentCoupon.specificUserEmail}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            specificUserEmail: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        aria-describedby="email id"
                        className="me-2"
                      />
                    </span>

                    {errors && errors.specificUserEmail && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.specificUserEmail}
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                <Form.Check
                  checked={currentCoupon.limitUsageCount}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitUsageCount: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  label="Limit the number of times this code can be redeemed"
                />

                {currentCoupon.limitUsageCount && (
                  <Form.Group className="w-25 ms-4 mb-4">
                    <Form.Control
                      value={currentCoupon.usageCount}
                      onChange={(e) =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          usageCount: e.target.value,
                        })
                      }
                      placeholder="Enter Count"
                      aria-describedby="email id"
                    />
                    {errors && errors.usageCount && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.usageCount}
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                <Form.Check
                  className="mb-3"
                  type="checkbox"
                  checked={currentCoupon.limitToOneUsePerUser}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToOneUsePerUser: e.target.checked,
                    })
                  }
                  label="Limit the one user per customer"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 p-5">
          <Button
            onClick={() => {
              setShowCoupon(false);
              setErrors(null);
            }}
            variant="outline-dark"
            className="px-4"
          >
            Cancel
          </Button>
          <Button
            onClick={() => createCoupon(currentCoupon)}
            variant="orange"
            className="text-white px-4"
          >
            Create Coupon
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <div className="bg-light p-5">
            <Row className="mb-3">
              <Col>Ticket Price</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Tax</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Service Fee</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Stripe Fee</Col>
              <Col>$0</Col>
            </Row>

            <hr />

            <Row className="mb-3">
              <Col>Cost to Patron</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Your Payout</Col>
              <Col>$0</Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-5">
          <Button variant="orange" className="text-white">
            Got it!
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default InPersonTickets;
