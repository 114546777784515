import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * React functional component 'Error' display a custom error page with status code and message.
 *
 * @param {Object} props - The props object.
 * @param {string} [props.code] - The error status code.
 * @param {string} [props.message] - The error message.
 * @return {JSX.Element} The JSX element to render.
 */
const Error = (props) => {
  return (
    <div className="homebanner  page-not-found pb-5">
      <div className="ms-auto mt-auto mb-5 pb-5 me-5">
        <h1 className="mb-4">
          {props.code || '404'} | {props.message || 'Page not Found'}
        </h1>
        <Button
          as={Link}
          to="/"
          variant="secondary"
          className="text-white"
          size="lg"
        >
          Back Home
        </Button>
      </div>
    </div>
  );
};

export default Error;
