import React from 'react';
import { Row, Col, Pagination as Pagination_ } from 'react-bootstrap';

/**
 * React functional component renders an html elements to manage the pagination based on props.
 *
 * @param {Object} props - The props.
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.handlePageChange - The function to handle the page change.
 */

const Pagination = (props) => {
  /**
   * Function to manage the page change.
   *
   * @param {number} pageNo - The page number to change to.
   * @return {void} This function does not return anything.
   */
  const handlePageChange = (pageNo) => {
    if (pageNo !== props.currentPage) props.handlePageChange(pageNo);
  };

  if (!props.totalPages) {
    return <></>;
  }

  return (
    <Row>
      <Col className="d-flex justify-content-between align-items-center">
        <span className="invisible ">
          Showing Page {props.currentPage + 1} of {props.totalPages}
        </span>
        <Pagination_ className="mb-0">
          <Pagination_.Prev
            onClick={() => handlePageChange(props.currentPage - 1)}
            disabled={props.currentPage == 0}
            className="page-item-prev"
          />
          {(() => {
            let items = [];
            for (let i = 0; i < props.totalPages; i++) {
              items.push(
                <Pagination_.Item
                  active={props.currentPage == i}
                  onClick={() => handlePageChange(i)}
                >
                  {i + 1}
                </Pagination_.Item>
              );
            }
            return items;
          })()}
          <Pagination_.Next
            onClick={() => handlePageChange(props.currentPage + 1)}
            disabled={props.currentPage == props.totalPages - 1}
            className="page-item-next"
          />
        </Pagination_>
        <div>
          <span className="d-none d-md-block">
            Showing Page {props.currentPage + 1} of {props.totalPages}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;
