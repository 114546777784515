import React, { useState, useEffect, useContext, createContext } from 'react';
import { LOGGED_IN_USER } from '../constants';

/**
 * Context object for managing authentication state.
 *
 * @type {React.Context<object>}
 */
const authContext = createContext();

/**
 * Provider component that wraps your app and makes auth object
 * ... available to any child component that calls useAuth().
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child elements.
 * @return {React.ReactElement} The auth context provider.
 */
/**
 * Provides the auth context to the entire app.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child elements.
 * @return {React.ReactElement} The auth context provider.
 */
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook for child components to get the auth object
 * ... and re-render when it changes.
 *
 * @return {Object} The auth object.
 */
export const useAuth = () => {
  return useContext(authContext);
};
/**
 * Returns the auth object that provides methods for authenticating
 * a user.
 *
 * @return {Object} The auth object.
 */
function useProvideAuth() {
  const [user, setUser] = useState(null);

  /**
   * Effect hook that subscribes to user on mount.
   * This effect will cause any component that utilizes this hook to
   * re-render with the latest auth object.
   *
   * @return {void} No return value
   */
  useEffect(() => {
    let user = localStorage.getItem(LOGGED_IN_USER);

    if (user) {
      user = JSON.parse(user);
    }

    setUser(user);
  }, []);

  /**
   * Logs out the user.
   *
   * @return {void} No return value
   */
  const logout = () => {
    setUser(null);
    localStorage.removeItem(LOGGED_IN_USER);
  };

  /**
   * Returns the auth object that provides methods for authenticating
   * a user.
   *
   * @return {Object} The auth object.
   */
  return { user, setUser, logout };
}
