/**
 * Import the Regular expression, pattern and constants to use in the current module
 */
import {
  INT_REGEX,
  COUPON_CODE_REGEX,
  EMAIL_REGEX,
} from '../../../../constants';

/**
 * Import the Coupon Type constant from the 'Tickets' module
 * @module Constants/Tickets
 * @constant {Object} COUPON_TYPE - Coupon type
 */
import { COUPON_TYPE } from './Tickets';

// couponType: '',
//         couponDiscount: null,
//         couponName: '',
//         couponCode: '',
//         includeTaxInPrice: false,
//         validTillDate: '',
//         limitToFirstTimeUsers: false,
//         limitToSpecificUser: false,
//         specificUserEmail: '',
//         limitUsageCount: false,
//         usageCount:null,
//         limitToOneUsePerUser: false,
//         couponId: ''

/**
 * Validates the detail of the Coupon entered by the user
 * @param {Object} coupon - The coupon object to be validated
 * @param {Array} couponList - The list of all the coupons
 * @return {Object} error - The error object containing the validation errors
 */
const validateCouponDetails = (coupon, couponList) => {
  let error = {};

  if (!coupon.couponType || coupon.couponType.trim().length == 0) {
    error['couponType'] = 'Please select coupon type';
  } else {
    if (
      !coupon.couponDiscount ||
      coupon.couponDiscount.trim().length == 0 ||
      INT_REGEX.test(coupon.couponDiscount) == false
    ) {
      error['couponDiscount'] = 'Please enter a valid discount value';
    } else if (
      coupon.couponType == COUPON_TYPE.PERCENTAGE &&
      (coupon.couponDiscount > 100 || coupon.couponDiscount < 1)
    ) {
      error['couponDiscount'] = 'Please provide a value from 1 to 100';
    }
  }

  if (!coupon.couponName || coupon.couponName?.trim().length == 0) {
    error['couponName'] = 'This field is required!';
  } else {
    if (couponList && couponList.length) {
      let count = couponList.filter(
        (c) => c.couponName == coupon.couponName
      ).length;
      if ((coupon.isNewCoupon && count) || count > 1) {
        error['couponName'] = 'Duplicate coupon name found!';
      }
    }
  }

  if (!coupon.couponCode || coupon.couponCode?.trim().length == 0) {
    error['couponCode'] = 'This field is required!';
  } else if (COUPON_CODE_REGEX.test(coupon.couponCode) == false) {
    error['couponCode'] =
      'Coupon code should be alphanumeric without any spaces';
  } else {
    if (couponList && couponList.length) {
      let count = couponList.filter(
        (c) => c.couponCode == coupon.couponCode
      ).length;
      if ((coupon.isNewCoupon && count) || count > 1) {
        error['couponCode'] = 'Duplicate coupon code found!';
      }
    }
  }

  if (
    coupon.limitToSpecificUser &&
    (!coupon.specificUserEmail ||
      coupon.specificUserEmail.trim().length == 0 ||
      !EMAIL_REGEX.test(String(coupon.specificUserEmail).toLowerCase()))
  ) {
    error['specificUserEmail'] = 'Please provide a valid email';
  }

  if (
    coupon.limitUsageCount &&
    (!coupon.usageCount ||
      coupon.usageCount.trim().length == 0 ||
      !INT_REGEX.test(String(coupon.usageCount).toLowerCase()))
  ) {
    error['usageCount'] = 'Please provide a valid number value';
  }

  return error;
};

export default validateCouponDetails;
