// shows loading screen when new events is getting created.
// Shows side event panel
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import EventSidePanel from './EventSidePanel/EventSidePanel';
import { getPromoterEventDetails } from '../../../utils/APIUtils';
import { EVENT_STEPS } from '../../../constants';
import { useCreateEvent } from './useCreateEvent';

const storageUtils = require('../../../utils/LocalStorageUtils');
// Main component
/**
 * Renders the CreateEvent component.
 *
 * @returns {JSX.Element} The rendered CreateEvent component.
 */
const CreateEvent = () => {
  const location = useLocation();
  const params = useParams();
  /**
   * Hook that provides the current state of the event, a function to update the event state,
   * a loading state, and an error state for the CreateEvent component.
   *
   * @returns {Object} An object containing the event state, a function to update the event state,
   * a loading state, and an error state.
   */
  const { eventState, setEventState, loading, error } = useCreateEvent(
    params.eventId
  );

  console.log('Event State', eventState);

  // TODO: move this to separate custom hook
  // const [formState, setFormState] = useState(() => {
  //         const initialState = getFormState();
  //         return initialState;
  // });

  // const [formState, setFormState] = React.useState({
  //     eventId: storageUtils.getItem('eventId'),
  //     basicEventDetailsDto: {},
  //     mainEventDetailsDto: {},
  //     streamingDetailsDto: {},
  //     ticketDetailsDto: {},
  //     finalEventDetailsDto:{}
  // });

  // useEffect(() => {
  //   console.log("use effect in CreateEvent.jsx called");
  //   console.log(formState.eventId);
  //   if(formState.eventId){
  //     getEventDetails(formState.eventId).then((res) => {
  //         console.log(res);
  //     })
  //     .catch((err) => {
  //         console.log(err);
  //     })
  //   }
  // }, [])

  // formStateJSON={
  //     "eventTitle": "string",  // required
  //     "organizer": "String",
  //     "type": "String", // required
  //     "genre": "String", // required
  //     "location": "String | predefined values - VENUE, LIVESTREAM OR HYBRID ", // required
  //     "eventDate": "String",  // required
  //     "eventStartTime": 1667746789, // required
  //     "eventEndTime": 1667746789, // required
  //     "eventTimezone": "String", // required
  //     "eventSummary": "String | Mandatory", // required
  //     "eventDescription": "String | Mandatory", // required
  //     "videoUrl": "String",
  //     "twitterUrl": "String",
  //     "facebookUrl": "String",
  //     "InstagramUrl": "String",
  //     "tikTokUrl": "String",
  //   “eventBanner”: FormData {
  //   eventBanner: File
  //   },
  //   “eventImages”: FormData {
  //   eventImage1: File,
  //   eventImage2: File,
  //       eventImage3: File // max 3 images only
  //   },
  //     "cameras": [
  //       {
  //         "artistName": "String", // required if camera added
  //         "cameraName": "String", // required if camera added
  //         "description": "String", // required if camera added
  //         "thumbnail":  "File" ,
  //          “isPrimary”: true
  //       },
  //       {
  //         "artistName": "String",
  //         "cameraName": "String",
  //         "description": "String",
  //         "thumbnail":  "File",
  //         “Primary”: false
  //       }
  //     ],
  //     "ticketType": "String | Values will be 'PAID' | 'FREE'",
  //     "ticketName": "String", // required
  //     "ticketQuantity": 500, // required
  //     "ticketPrice": 30, // required
  //     "salesStartDate": 1667746789, // required
  //     "salesEndDate": 1667746789, // required
  //     "salesStartTime": 1667746789, // required
  //     "salesEndTime": 1667746789, // required
  //     "showSalesStatus": false,
  //     "eventPublic": true, // required
  //     "publishNow": true , // required
  //     "publishStartDate": 1667746789, // required if publishNow is False
  //     "publishEndDate": 1667746789, // required if publishNow is False
  //     "termsAccepted": false // Mandatory
  //   }

  // useEffect(() => {
  //   console.log(location);
  // }, [location])

  /**
   * Shows loading animation if the component is still loading.
   */
  if (loading) {
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }
  // otherwise shows event side panel that tracks step in the event creation step
  if (!loading) {
    return (
      <Row className="h-100">
        {location.pathname !== '/promoter-panel/events/create-event' && (
          <Col
            xs={12}
            md={3}
            lg={2}
            className="px-0 h-md-100  side-panel-event-steps"
          >
            <EventSidePanel formState={eventState}></EventSidePanel>
          </Col>
        )}

        <Col
          className={`${
            location.pathname !== '/promoter-panel/events/create-event' &&
            'side-panel-event-content'
          }`}
          xs={12}
          md={9}
          lg={10}
        >
          <div className="px-3 mx-lg-5 px-lg-5 py-4">
            <Outlet context={[eventState, setEventState]} />
          </div>
        </Col>
      </Row>
    );
  }
};

export default CreateEvent;
