import React, { useLayoutEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Container, Row, Col, Nav, Button, Form } from 'react-bootstrap';
import {
  faChevronLeft,
  faClipboardList,
  faQuestionCircle,
  faCalendarDay,
  faBullhorn,
  faCircleMinus,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoginInfo from './LoginInfo/LoginInfo';
import ContactInfo from './ContactInfo/ContactInfo';
import CloseAccount from './CloseAccount/CloseAccount';
import MyOrders from './MyOrders/MyOrders';
import { faAddressBook, faUser } from '@fortawesome/free-regular-svg-icons';
import { useAuth } from '../hooks/useAuth';
import { USER_ROLES } from '../constants';

/**
 * Enumeration representing the types of tabs.
 *
 * @readonly
 * @enum {string}
 */
const TAB_TYPES = Object.freeze({
  /**
   * Contact information tab.
   */
  CONTACT_INFO: 'CONTACT_INFO',

  /**
   * Login information tab.
   */
  LOGIN_INFO: 'LOGIN_INFO',

  /**
   * Close account tab.
   */
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',

  /**
   * My orders tab.
   */
  MY_ORDERS: 'MY_ORDERS',

  /**
   * FAQ tab.
   */
  FAQ: 'FAQ',
});

// This function is used for the account settings
/**
 * Component for the account settings page.
 *
 * @return {JSX.Element} The rendered component.
 */
const AccountSettings = () => {
  const auth = useAuth();

  // useLayoutEffect(() => {
  //     document.body.classList.add('theme-light')

  //     return () => {
  //         document.body.classList.remove('theme-light')
  //     }
  // }, [])

  const [activeTab, setactiveTab] = useState(TAB_TYPES.CONTACT_INFO);

  /**
   * Handles the tab selection event.
   *
   * @param {string} eventKey - The key of the selected tab.
   */
  const handleSelect = (eventKey) => {
    console.log(eventKey);
    setactiveTab(eventKey);
  };

  return (
    <div className="h-100">
      <Container fluid className="d-flex h-100">
        <Row className="flex-grow-1 flex-nowrap">
          <Col className="p-0 d-flex flex-grow-0">
            <Nav
              onSelect={handleSelect}
              defaultActiveKey="CONTACT_INFO"
              className="flex-column vertical-nav-tabs acc-setup-nav-tabs"
            >
              <Nav.Link
                eventKey={TAB_TYPES.CONTACT_INFO}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Contact Information"
                  icon={faAddressBook}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Contact Information
                </span>
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_TYPES.LOGIN_INFO}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Login Information"
                  icon={faUser}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Login Information
                </span>
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_TYPES.CLOSE_ACCOUNT}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Close Your Account"
                  icon={faCircleMinus}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Close Your Account
                </span>
              </Nav.Link>
              {auth.user &&
                auth.user.roles.indexOf(USER_ROLES.ROLE_PROMOTER) == -1 && (
                  <Nav.Link
                    eventKey={TAB_TYPES.MY_ORDERS}
                    className="d-flex align-items-center"
                  >
                    <FontAwesomeIcon
                      title="My Orders"
                      icon={faList}
                      className="me-1 fs-sm-1"
                    />
                    <span className="ms-2 d-none d-md-block">Your Orders</span>
                  </Nav.Link>
                )}

              <Nav.Link
                eventKey={TAB_TYPES.FAQ}
                className="mt-auto d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="FAQ"
                  icon={faQuestionCircle}
                  className="me-1 fs-sm-1"
                  size="lg"
                />
                <span className="ms-2 d-none d-md-block">FAQ</span>
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="flex-grow-1">
            <div className="px-3 mx-lg-5 px-lg-5 py-4">
              {activeTab !== TAB_TYPES.MY_ORDERS && (
                <Button
                  as={Link}
                  to={
                    auth.user &&
                    auth.user.roles.indexOf(USER_ROLES.ROLE_PROMOTER) == -1
                      ? '/events'
                      : '/promoter-panel/events'
                  }
                  variant="link"
                  className="text-decoration-none px-0 mt-2"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                  Go Back to Dashboard
                </Button>
              )}

              {(() => {
                switch (activeTab) {
                  case TAB_TYPES.CONTACT_INFO:
                    return <ContactInfo />;

                  case TAB_TYPES.LOGIN_INFO:
                    return <LoginInfo />;

                  case TAB_TYPES.CLOSE_ACCOUNT:
                    return <CloseAccount />;

                  case TAB_TYPES.MY_ORDERS:
                    return <MyOrders />;

                  default:
                    break;
                }
              })()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountSettings;
