// Initial step of create event flow
// When creating a new event, this is the component that gets loaded
// Asks for basic details from promoter, Name of event, start date, etc.

import React, { useState, useEffect } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronDown,
  faExclamationCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Form,
  Row,
  Col,
  ButtonGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import validateEventForm from '../eventFormValidator';
import {
  getLocationTypes,
  createEvent,
  getCategoryDetails,
} from '../../../../utils/APIUtils';
import { setItem } from '../../../../utils/LocalStorageUtils';
import moment from 'moment';
import { EVENT_STEPS } from '../../../../constants';
import EventStartEndDate from '../../Shared/EventStartEndDate';

/**
 * Render the basic information component for creating an event.
 *
 * @return {JSX.Element} The rendered basic information component.
 */
const BasicInfo = () => {
  /**
   * Router navigation initiated.
   * @type {Function}
   */
  const navigate = useNavigate();

  /**
   * Outlet to pass state across component tree, without manually passing props.
   * @type {Array} The context array.
   * @property {Object} 0 - The context object.
   * @property {function} 1 - Function to set the context.
   */
  const [context, setContext] = useOutletContext();

  /**
   * DTO Object for basic event details, this component initiates this object and context passes throughout other components.
   * @type {Object} BasicEventDetailsDto
   */
  let { basicEventDetailsDto } = context;

  /**
   * Default values for basic event details, this component initiates this object and context passes throughout other components.
   * Not used anymore. This value is for streaming event. Now promoter has choice for either in-person or streaming and this value
   * gets updated according to choice. Kept for debugging and legacy purposes.
   *
   * @type {Object} BasicEventDetailsDto
   */
  basicEventDetailsDto.locationTypeId = '637487f24f2a940eca7b68d0'; // hardcoded

  /**
   * Errors state
   * @type {Object.<string, string|null>}
   */
  const [errors, setErrors] = useState({});

  /**
   * Entire form state, this state is used to set values in the form.
   * @type {Object} BasicEventDetailsDto
   */
  const [formState, setFormState] = useState(basicEventDetailsDto);

  /**
   * Saving state, for loading-like animation effect when post request is made to the server with all the values.
   * @type {boolean}
   */
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Single function to update state when any values change in form fields.
   * @param {Object} state - The state to update.
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      console.log({ ...prevState, ...state });
      return { ...prevState, ...state };
    });

  /**
   * State for initialized events.
   * @type {boolean}
   */
  const [initialized, setInitialized] = useState(false);

  /**
   * State for other events.
   * @type {boolean}
   */
  const [other, setOther] = useState(false);

  /**
   * State for other category events.
   * @type {boolean}
   */
  const [otherCategory, setOtherCategory] = useState(false);

  /**
   * State for category types.
   * @type {Array<Object>|null}
   */
  const [categoryTypes, setCategoryTypes] = useState(null);

  /**
   * State for location types which are set by retrieving information from server by making a get request.
   * @type {Array<Object>|null}
   */
  const [locationTypes, setLocationTypes] = useState(null);

  /**
   * List of special category ids.
   * @type {Array<string>}
   */
  const specialIds = [
    '655d47f74d92b1df1305393c',
    '655d49444d92b1df1305394f',
    '655d358b4d92b1df13053930',
    '655d49794d92b1df13053954',
    '655d47614d92b1df13053937',
    '655d48604d92b1df13053943',
    '655d48ff4d92b1df1305394c',
  ];

  /**
   * Effect hook for fetching category types, location types and initial state of event and setting it in state.
   *
   * @see https://reactjs.org/docs/hooks-effect.html
   */
  useEffect(() => {
    console.log('basic info called');

    if (!initialized) {
      Promise.all([getCategoryDetails(), getLocationTypes()])
        .then((values) => {
          setCategoryTypes(values[0].data);
          setLocationTypes(values[1].data);
          setInitialized(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [initialized]);

  /**
   * Validates the form to ensure all required fields are populated and all fields have correct type of values.
   *
   * @return {boolean} True if the form is valid, false otherwise.
   */
  const validate = () => {
    let error = validateEventForm(formState, 'BASIC_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };

  /**
   * This function gets called when any changes are made to any fields in form.
   *
   * @param {Event} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleChange = (event) => {
    setOther(false);
    let state = {};
    const { name, id, value, checked } = event.target;
    if (specialIds.includes(value)) {
      setOther(true);
    }
    if (id) {
      state[id] = value;
    }
    if (checked) {
      state[name] = checked;
    }
    updateState(state);
  };

  /**
   * This function gets called when the submit button is clicked.
   *
   * @param {Event} e - The event object.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    if (isValid) {
      setIsSaving(true);
      let data = JSON.parse(JSON.stringify(formState)); // clone obj and modify
      data.eventStartTime = moment(formState.eventStartTime).format('hh:mm A');
      data.eventEndTime = moment(formState.eventEndTime).format('hh:mm A');

      createEvent('BASIC_DETAILS', data)
        .then((res) => {
          setContext({
            ...context,
            basicEventDetailsDto: {
              ...formState,
            },
            eventId: res.data.eventId,
          });
          setItem('eventId', res.data.eventId);
          setIsSaving(false);
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.MAIN_DETAILS}/${res.data.eventId}`
          );
        })
        .catch((err) => {
          console.log(err);
          setIsSaving(false);
        });
    }
  };

  return (
    <div>
      <Button
        as={Link}
        to="/promoter-panel/home"
        variant="link"
        className="text-decoration-none px-0 mt-2"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
        Back to Events
      </Button>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="5">
            <h2 className="fw-bold my-3">Basic Info</h2>
            <Form.Group controlId="eventTitle" className="mb-3">
              <Form.Label className="required">Event Title</Form.Label>
              <Form.Control
                value={formState.eventTitle}
                onChange={handleChange}
                type="text"
                placeholder="Enter event title"
              />
              {errors && errors.eventTitle && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors.eventTitle}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="organizer" className="mb-3">
              <Form.Label>Organizer</Form.Label>
              <Form.Control
                value={formState.organizer}
                onChange={handleChange}
                type="text"
                placeholder="Enter Organizer"
              />
            </Form.Group>

            <div className="d-flex align-items-center mb-3">
              <Form.Group
                controlId="eventCategoryId"
                className="flex-grow-1 me-3"
              >
                <Form.Label>Category</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  value={formState.eventCategoryId}
                  className="mb-3"
                >
                  <option value="">Select</option>
                  {categoryTypes &&
                    categoryTypes.map((item, i) => (
                      <option
                        key={i}
                        disabled={item.status === 'INACTIVE'}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                controlId="eventSubCategoryId"
                className="flex-grow-1 me-3"
              >
                <Form.Select
                  onChange={handleChange}
                  value={formState.eventSubCategoryId}
                  className="mt-3"
                >
                  <option value="">Select</option>
                  {formState.eventCategoryId &&
                    categoryTypes
                      .find((item) => item.id === formState.eventCategoryId)
                      .eventSubCategoriesModelList.map((i) => (
                        <option
                          key={i}
                          disabled={i.status === 'INACTIVE'}
                          value={i.id}
                        >
                          {i.name}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
            </div>
            {other && (
              <Form.Group className="flex-grow-1 me-3">
                <Form.Control
                  value={formState.otherCategory}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter Other Category"
                  className="flex-grow-1 me-3"
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col className="mb-3">
            <h2 className="fw-bold my-3">Location</h2>
            <p>
              Help people to discover your event and let promoters know where to
              show up
            </p>

            <ButtonToolbar
              id="locationTypeId"
              aria-label="Location Type Button Group"
            >
              {locationTypes &&
                locationTypes.map((location, i) => (
                  <ButtonGroup key={i} size="lg" aria-label="First group">
                    <Button
                      onClick={() =>
                        updateState({ locationTypeId: location.id })
                      }
                      className={`px-4 me-3 btn-location-type ${
                        formState.locationTypeId === location.id ? 'active' : ''
                      } ${location.status === 'INACTIVE' ? 'disabled' : ''}`}
                      disabled={location.status === 'INACTIVE'}
                    >
                      {location.name}
                    </Button>
                  </ButtonGroup>
                ))}
            </ButtonToolbar>
            {errors && errors.locationTypeId && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.locationTypeId}
              </Form.Text>
            )}

            <p className="mt-3">
              Live stream events have unique pages where you can add links to
              livestreams and more - coming soon
            </p>
          </Col>
        </Row>
        <hr className="my-4" />
        <h2 className="fw-bold my-3">Date and Time</h2>
        <EventStartEndDate
          formState={formState}
          updateState={updateState}
          errors={errors}
          showTimezonefield
        />

        <Row className="my-5">
          <Col className="d-flex mt-4">
            <Button
              as={Link}
              to="/promoter-panel/home"
              variant="outlined"
              className="ms-auto me-3 border border-dark btn-create-event"
            >
              Discard
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="text-white px-3 border border-primary btn-create-event"
            >
              {isSaving && (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Saving...</span>
                </>
              )}
              {!isSaving && <span>Save and Continue</span>}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BasicInfo;
