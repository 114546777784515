import { ACCESS_TOKEN, API_BASE_URL, HEADERS } from '../constants';
import { getItem } from './LocalStorageUtils';
import { getFormDataObj } from './CommonUtils';
import axios from 'axios';
import qs from 'qs';

/**
 * Function to retrieve the authenication header including the bearer token.
 * @returns {object} Containing the Authorization header with Bearer token.
 */
const getAuthHeaders = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (token) token = JSON.parse(token);

  return {
    ...HEADERS,
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Function performs the login with provided data and captcha response.
 * @param {object} data The data to be posted to the server.
 * @param {string} captchaResponse The captcha response to be sent with the request.
 * @returns {Promise} The promise returned by axios.
 */
const login = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/authenticate', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to login a user with the provided data and captcha response.
 * @param {object} data The data to be posted to the server.
 * @param {string} captchaResponse The captcha response to be sent with the request.
 * @returns {Promise} The promise returned by axios.
 */
const login2 = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };

  return axios.post(`${API_BASE_URL}/login`, data, { headers: LOGIN_HEADERS });
};

// Function to register a new user with provided data and captcha response.
/**
 * @param {object} data The data to be posted to the server.
 * @param {string} captchaResponse The captcha response to be sent with the request.
 * @returns {Promise} The promise returned by axios.
 */
const register = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/register', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to verify the email address.
 * @param {string} code The verification code sent to the user's email.
 * @param {string} pendingAuthenticationToken The pending authentication token.
 * @returns {Promise} The promise returned by axios.
 */
const verifyemail = (code, pendingAuthenticationToken) => {
  return axios.get(
    API_BASE_URL +
      `/verifyemail?code=${code}&pendingAuthenticationToken=${pendingAuthenticationToken}`,
    { headers: HEADERS }
  );
};

// Function to send the reset password email.
/**
 * @param {object} data The data to be posted to the server.
 * @returns {Promise} The promise returned by axios.
 */
const sendResetPasswordEmail = (data) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': data.captchaResponse,
  };
  return axios.get(API_BASE_URL + `/forgot-password?email=${data.email}`, {
    headers: LOGIN_HEADERS,
  });
};

// DEPRECATED
// function to verify the token to reset the password.
// const verifyToken = (data) => {
//     return axios.post(API_BASE_URL + '/password/reset/token/verify', data, { headers: HEADERS })
// }

/**
 * Function to reset a new password.
 * @param {Object} data - The data to be posted to the server.
 * @param {string} captchaResponse - The captcha response.
 * @returns {Promise} The promise returned by axios.
 */
const resetNewPassword = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/reset-password', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to retrieve the list of event types.
 * @returns {Promise} The promise returned by axios.
 */
const getEventTypes = () => {
  return axios.get(API_BASE_URL + `/eventType/list`, { headers: HEADERS });
};

/**
 * Function to retrieve the list of event genres.
 * @returns {Promise} The promise returned by axios.
 */
const getEventGenres = () => {
  return axios.get(API_BASE_URL + `/genre/list`, { headers: HEADERS });
};

/**
 * Function to retrieve the location type.
 * @returns {Promise} The promise returned by axios.
 */
const getLocationTypes = () => {
  return axios.get(API_BASE_URL + `/locationtype/list`, { headers: HEADERS });
};

/**
 * Function is used to create event.
 * @param {string} step - The step of the event creation.
 * @param {Object} data - The data to be posted to the server.
 * @param {string} eventId - The ID of the event (optional).
 * @returns {Promise} The promise returned by axios.
 */
const createEvent = (step, data, eventId) => {
  console.log('######', data);
  let formData = getFormDataObj(data);
  formData.forEach((value, key) => {
    console.log('---', value, key);
  });
  // console.log('--------', formData)
  if (step == 'TICKET_DETAILS') {
    formData.set('showTicketDetailsAtCheckout', true); // hard coded for now, make sure to change it
  }
  return axios.post(
    API_BASE_URL +
      `/promoter/event/create/${step}${eventId ? '?eventId=' + eventId : ''}`,
    formData,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to retrieve the promotoe event details.
 * @param {string} eventId - The ID of the event.
 * @returns {Promise} The promise returned by axios.
 */
const getPromoterEventDetails = (eventId) => {
  return axios.get(API_BASE_URL + `/promoter/event/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to get the event detail based on event ID.
 * @param {string} eventId - The ID of the event.
 * @returns {Promise} The promise returned by axios.
 */
const getEventDetails = (eventId) => {
  return axios.get(API_BASE_URL + `/event/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to get the category detail of an event based on event ID.
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @returns {Promise} The promise returned by axios.
 */
const getCategoryDetails = (isloggedIn) => {
  return axios.get(API_BASE_URL + `/eventCategories/list`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to filter the event list based on data.
 * @param {Object} data - The data to filter the list.
 * @param {number} [page=1] - The page number.
 * @returns {Promise} The promise returned by axios.
 */
const eventListFilter = (data, page = 1) => {
  console.log('bbbbb', data);
  return axios.post(
    API_BASE_URL + `/event/list/filter?page=${page}&size=10`,
    data,
    { headers: HEADERS }
  );
};

/**
 * Function to retrieve promotor event list.
 * @param {number} [page] - The page number.
 * @param {string} [status] - The status of the event.
 * @returns {Promise} The promise returned by axios.
 */
const getPromoterEventList = (page, status) => {
  return axios.get(
    API_BASE_URL + `/promoter/event/list${page ? '?page=' + page : ''}`,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to retrieve event list.
 * @param {number} [page] - The page number.
 * @param {boolean} [isloggedIn] - Indicates if the user is logged in.
 * @returns {Promise} The promise returned by axios.
 */
const getEventList = (page, isloggedIn) => {
  return axios.get(API_BASE_URL + `/event/list${page ? '?page=' + page : ''}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to get list of customized event.
 * @param {boolean} [isloggedIn] - Indicates if the user is logged in.
 * @returns {Promise} The promise returned by axios.
 */
const getEventListCustomized = (isloggedIn) => {
  return axios.get(API_BASE_URL + `/event/list/customized`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve the details of camera based on event ID.
 * @param {string} eventId - The ID of the event.
 * @returns {Promise} The promise returned by axios.
 */
const getCameraDetails = (eventId) => {
  return axios.get(API_BASE_URL + `/promoter/event/cameras/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve user details.
 * @returns {Promise} The promise returned by axios.
 */
const getUserDetails = () => {
  return axios.get(API_BASE_URL + `/user/details`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to update the default stripe.
 * @param {boolean} [flagValue=false] - Indicates if the stripe should be set as default.
 * @returns {Promise} The promise returned by axios.
 */
const updateDefaultStripe = (flagValue = false) => {
  return axios.put(
    API_BASE_URL +
      `/user/details/update/stripe/defaultAccount?flagValue=${flagValue}`,
    {},
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to reset the stripe link.
 * @returns {Promise} The promise returned by axios.
 */
const resetStripeLink = () => {
  return axios.get(API_BASE_URL + `/reset-link`, { headers: getAuthHeaders() });
};

/**
 * Function to update the contact information of the user.
 * @param {Object} data - The data containing the contact information.
 * @returns {Promise} The promise returned by axios.
 */
const updateContactInfo = (data) => {
  console.log(getAuthHeaders());
  let formData = getFormDataObj(data);
  return axios.put(API_BASE_URL + `/user/details/update`, formData, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to create an channel.
 * @param {string} eventId - The ID of the event.
 * @returns {Promise} The promise returned by axios.
 */
const createChannel = (eventId) => {
  return axios.get(API_BASE_URL + `/promoter/event/create/channel/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve the details of promotor dashboard.
 * @returns {Promise} The promise returned by axios.
 */
const getPromoterDashboard = () => {
  return axios.get(API_BASE_URL + `/promoter/event/dashboard/details`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to make modification in the channel.
 * @param {string} action - The action to be made in the channel.
 * @param {string} eventId - The ID of the event.
 * @param {string} [cameraId] - The ID of the camera.
 * @returns {Promise} The promise returned by axios.
 */
const modifyChannel = (action, eventId, cameraId) => {
  return axios.post(
    API_BASE_URL +
      `/channels/event/${eventId}/action/${action}?operateAll=${
        cameraId ? false + '&cameraSetupIds=' + cameraId : true
      }`,
    {},
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to retrieve the methods to make a payment.
 * @returns {Promise} The promise returned by axios.
 */
const fetchPaymentMethods = () => {
  return axios.get(API_BASE_URL + `/getPaymentMethods`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to pay with already added payment method.
 * @param {Object} paymentBody - The payment body.
 * @returns {Promise} The promise returned by axios.
 */
const payWithExistingMethod = (paymentBody) => {
  return axios.post(API_BASE_URL + `/payment`, paymentBody, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to save the Billing information.
 * @param {string} eventId - The ID of the event.
 * @param {Object} billingDetails - The billing details.
 * @returns {Promise} The promise returned by axios.
 */
const saveBillingInfo = (eventId, billingDetails) => {
  return axios.post(
    API_BASE_URL + `/checkout/billingInfo/${eventId}`,
    billingDetails,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to validate the coupon.
 * @param {string} eventId - The ID of the event.
 * @param {string} couponCode - The coupon code.
 * @returns {Promise} The promise returned by axios.
 */
const validateCoupon = (eventId, couponCode) => {
  return axios.post(
    API_BASE_URL + `/coupon/validate/${eventId}?coupon=${couponCode}`,
    {},
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to retrieve the list of orders.
 * @returns {Promise} The promise returned by axios.
 */
const getOrderList = () => {
  return axios.get(API_BASE_URL + `/order/list`, { headers: getAuthHeaders() });
};

/**
 * Function to cancel order.
 * @param {Object} orderDetails - The order details.
 * @returns {Promise} The promise returned by axios.
 */
const cancelOrder = (orderDetails) => {
  return axios.post(API_BASE_URL + `/refund-customer`, orderDetails, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to refund the order by admin.
 * @param {Object} refundDTO - The refund details.
 * @returns {Promise} The promise returned by axios.
 */
const refundOrderAdmin = (refundDTO) => {
  return axios.post(API_BASE_URL + `/refund-admin`, refundDTO, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to get the fee detail.
 * @returns {Promise} The promise returned by axios.
 */
const getFeeDetails = () => {
  return axios.get(API_BASE_URL + `/order/fee/details`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to manage the users.
 * @param {string} userRole - The user role.
 * @param {number} page - The page number.
 * @param {string} query - The search query.
 * @returns {Promise} The promise returned by axios.
 */
const getManageUsers = (userRole, page, query) => {
  let queryStr = `${userRole ? `userRole=${userRole}&` : ''}${
    page ? `page=${page}&` : ''
  }${query ? `search=${query}` : ''}`;

  return axios.get(API_BASE_URL + `/super-admin/users?${queryStr}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to manage an event.
 * @param {string} status - The event status.
 * @param {number} page - The page number.
 * @param {string} search - The search query.
 * @returns {Promise} The promise returned by axios.
 */
const getManageEvents = (status, page, search) => {
  let queryStr = `${status ? `status=${status}&` : ''}${
    page ? `page=${page}&` : ''
  }${search ? `search=${search}` : ''}`;

  return axios.get(API_BASE_URL + `/super-admin/events?${queryStr}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to manage the orders.
 * @param {number} page - The page number.
 * @param {string} search - The search query.
 * @returns {Promise} The promise returned by axios.
 */
const getManageOrders = (page, search) => {
  let queryStr = `${page ? `page=${page}&` : ''}${
    search ? `search=${search}` : ''
  }`;
  return axios.get(API_BASE_URL + `/super-admin/orders?${queryStr}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve the patron order list.
 * @param {string} userId - The user ID.
 * @returns {Promise} The promise returned by axios.
 */
const getPatronOrderList = (userId) => {
  return axios.get(
    API_BASE_URL + `/super-admin/patron/orders/list?userId=${userId}`,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to update an event.
 * @param {string} eventId - The event ID.
 * @param {Object} eventDto - The event DTO.
 * @returns {Promise} The promise returned by axios.
 */
const updateEvent = (eventId, eventDto) => {
  return axios.post(
    API_BASE_URL + `/super-admin/event/update/${eventId}`,
    eventDto,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to update an event status.
 * @param {string} status - The event status.
 * @param {Object} eventDto - The event DTO.
 * @returns {Promise} The promise returned by axios.
 */
const updateEventStatus = (status, eventDto) => {
  return axios.post(
    API_BASE_URL + `/super-admin/event/status/change?status=${status}`,
    eventDto,
    { headers: getAuthHeaders() }
  );
};

/**
 * Function to update a status for user.
 * @param {string} userId - The user ID.
 * @param {string} status - The user status.
 * @returns {Promise} The promise returned by axios.
 */
const updateUserStatus = (userId, status) => {
  return axios.post(
    API_BASE_URL + `/super-admin/user/status/update/${userId}?status=${status}`,
    status,
    { headers: getAuthHeaders() }
  );
};

// Export all API Functions.
export {
  login,
  login2,
  register,
  verifyemail,
  sendResetPasswordEmail,
  // verifyToken,
  resetNewPassword,
  createEvent,
  getEventTypes,
  getEventGenres,
  getLocationTypes,
  getEventDetails,
  getPromoterEventDetails,
  getEventList,
  getPromoterEventList,
  getPromoterDashboard,
  getEventListCustomized,
  getCameraDetails,
  getUserDetails,
  updateDefaultStripe,
  resetStripeLink,
  updateContactInfo,
  createChannel,
  modifyChannel,
  fetchPaymentMethods,
  payWithExistingMethod,
  validateCoupon,
  saveBillingInfo,
  getOrderList,
  cancelOrder,
  refundOrderAdmin,
  getFeeDetails,
  getManageUsers, // admin access required
  getManageEvents, // admin access required
  getManageOrders, // admin access required
  getPatronOrderList, // admin access required
  updateEvent,
  updateEventStatus,
  updateUserStatus,
  getCategoryDetails, //Category Filtering
  eventListFilter, // Filtering events
};
