import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Button,
  Nav,
  Spinner,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { getEventDetails } from '../../../../utils/APIUtils';
import { useAsync } from '../../../../hooks/useAsync';
import moment from 'moment';

/**
 * Represents the Preview event component for the preview event page
 * @returns {JSX.Element} The PreviewEvent component
 */
const PreviewEvent = () => {
  // Hook for handling the asynchronous event
  const { data, loading, error, run } = useAsync(null);

  // State hook to set the Toast
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  // Extract the route parameter using the useParams() hook
  const params = useParams();

  // State hook to set the event DTO using the useState() hook
  const [eventDTO, setEventDTO] = useState(null);

  // useEffect() hook used to fetch the event detail and payment method
  useEffect(() => {
    run(() => getEventDetails(params.eventId))
      .then((res) => {
        setEventDTO(res.data);
        console.log('loading', loading);
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
  }, [params.eventId, run]);

  /**
   * Renders the loading state of the component if it is in the loading state.
   *
   * @return {JSX.Element} The loading state component
   */
  if (loading) {
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="h-100">
      <Container fluid className="bg-header">
        <Row>
          <Col xs="12">
            <div className="p-4 text-center">
              <h4>Preview</h4>
            </div>
          </Col>
        </Row>
        <Row className="bg-light">
          <Col xs="12">
            <div className="p-4 text-center">
              <Button
                to={'/promoter-panel/events'}
                as={Link}
                variant="link"
                className="text-dark"
              >
                <h5>Close Preview</h5>
              </Button>
            </div>
          </Col>
        </Row>

        {/* banner section starts */}
        <Row>
          <Col className="px-0">
            <div
              className="homebanner position-relative"
              style={{
                backgroundImage: `url(${eventDTO?.bannerThumbnailS3IdList[0].thumbnailUrl})`,
              }}
            >
              <div className="banner-overlay d-flex">
                <div
                  id="bannertext"
                  className="banner-text d-flex flex-grow-1 mt-auto p-5 mb-5 mx-4 text-white"
                >
                  <div>
                    <h1 className="text-uppercase fw-bold">
                      {eventDTO?.eventTitle}
                    </h1>

                    <div className="d-flex">
                      <Button
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faShareAlt}
                          className="me-2"
                          size="2x"
                        />
                        <h4 className="mb-0">Share</h4>
                      </Button>

                      <span className="h-100 py-3 mx-4 h4 mb-0"> | </span>

                      <Button
                        variant="link"
                        className="text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className="me-2"
                          size="2x"
                        />
                        <h4 className="mb-0">Add to my calendar</h4>
                      </Button>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <h2>
                      {moment(
                        `${eventDTO?.eventStartDate} ${eventDTO?.eventStartTime}`
                      ).format('ddd MMM DD, YYYY hh:mm A')}{' '}
                      {eventDTO?.timezone}
                    </h2>
                    {(() => {
                      const isStreamEvent = eventDTO?.locationTypeModel.type !== 'In Person';

                      return (
                        <Button
                          size="lg"
                          variant="primary"
                          className="text-white mt-3 px-4"
                        >
                          View Tickets
                          {eventDTO?.eventTicketDetailsModel?.price && (
                            <span>
                              {' '}
                              ${eventDTO.eventTicketDetailsModel.price} CAD
                            </span>
                          )}
                        </Button>
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* banner section ends */}
        <Row>
          <Col xs="12">
            <div className="p-5">
              <h4>About the Artist</h4>
              <p dangerouslySetInnerHTML={{ __html: eventDTO?.description }}></p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="px-4 d-flex align-items-center justify-content-center">
              {eventDTO?.eventThumbnailsList?.map((dto, index) => (
                <div className="px-2" key={index}>
                  <img
                    src={`${dto.thumbnailUrl}`}
                    style={{ maxHeight: '300px' }}
                    className="img-fluid border border-darkGray"
                    alt={`artist ${index + 1}`}
                  />
                </div>
              ))}
            </div>
            <div className="justify-content-center d-flex d-md-none">
              <Nav className="flex-row pt-4 justify-content-center">
                <Nav.Link href="#" className="px-1">
                  <img src="/twitter_icon.svg" alt="twitter logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/facebook.svg" alt="facebook logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/youtube.svg" alt="youtube logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/instagram.svg" alt="instagram logo" />
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="py-5">
          <Col lg={6} xs={12}>
            <div className="px-5">
              <h4>FOLLOW THE ARTIST. BE PART OF SOMETHING MORE</h4>
              <p>
                See what they’re up to off stage and on spare time. It’s like
                the ultimate free backstage pass to your favourite bands!{' '}
              </p>
              <p className="mt-4">
                Support your artists and follow them on social media.
              </p>

              <Nav className="flex-row pt-4">
                {eventDTO?.socialMediaLinksModel?.twitter && (
                  <Nav.Link
                    href={`https://www.twitter.com/${eventDTO.socialMediaLinksModel.twitter}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/twitter_icon.svg"
                      alt="twitter logo"
                    />
                  </Nav.Link>
                )}
                {eventDTO?.socialMediaLinksModel?.facebook && (
                  <Nav.Link
                    href={`https://www.facebook.com/${eventDTO.socialMediaLinksModel.facebook}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/facebook.svg"
                      alt="facebook logo"
                    />
                  </Nav.Link>
                )}

                {eventDTO?.socialMediaLinksModel?.instagram && (
                  <Nav.Link
                    href={`https://www.instagram.com/${eventDTO.socialMediaLinksModel.instagram}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/instagram.svg"
                      alt="instagram logo"
                    />
                  </Nav.Link>
                )}
              </Nav>
            </div>
          </Col>
          {eventDTO?.externalVideoUrl && (
            <Col lg={6} xs={12}>
              <div className="pe-5">
                <iframe
                  width="100%"
                  height="350"
                  src={eventDTO.externalVideoUrl}
                  title="Event Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PreviewEvent;
