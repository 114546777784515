import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  cancelOrder,
  getFeeDetails,
  getManageOrders,
  refundOrderAdmin,
} from '../../utils/APIUtils';
import { useAsync } from '../../hooks/useAsync';
import Pagination from '../../shared/Pagination';
import {
  RESTOCKING_FEE,
  GATEWAY_FEE,
  APPLICATION_FEE,
  SERVICE_FEE_PERCENT,
  TAX_PERCENTAGE,
} from '../../constants';
import moment from 'moment';
import ToastMessage from '../../shared/ToastMessage';

/**
 * This function component is responsible for rendering the html elements and handling the orders.
 *
 * It fetches the list of orders from the server and displays them in a table.
 * It also allows the admin to cancel an order and refund the order amount.
 *
 * @return {JSX.Element} The OrderManagement component.
 */
const OrderManagement = () => {
  // State hook to manage the selected order.
  /**
   * @desc State hook to manage the selected order.
   * @type {React.State<Object|null>}
   */
  const [selectedOrder, setSelectedOrder] = useState(null);

  /**
   * @desc Destructuring assigmnent to extract properties from the useAsync hook.
   * @type {Object}
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * @desc State hook to set the orders list.
   * @type {React.State<Array>}
   */
  const [ordersList, setOrdersList] = useState([]);

  /**
   * @desc State hook to manage the loading of the filtered results.
   * @type {React.State<boolean>}
   */
  const [loadingFilteredResults, setLoadingFilteredResults] = useState(false);

  /**
   * @desc State hook to set the search query.
   * @type {React.State<string>}
   */
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * @desc State hook to manage the current page.
   * @type {React.State<number>}
   */
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * @desc State hook to manage the total pages.
   * @type {React.State<number>}
   */
  const [totalPages, setTotalPages] = useState(0);

  /**
   * @desc State hook to manage the cancel order modal and show the detail for cancel orders.
   * @type {React.State<boolean>}
   */
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  /**
   * @desc State hook to set the reason for the refund.
   * @type {React.State<string>}
   */
  const [refundReason, setRefundReason] = useState('');

  /**
   * @desc State hook to set the full refund.
   * @type {React.State<boolean>}
   */
  const [fullRefund, setFullRefund] = useState(false);

  /**
   * @desc State hook to set the order refung ID.
   * @type {React.State<string>}
   */
  const [orderRefundId, setOrderRefundId] = useState('');

  /**
   * @desc State hook to set the fee details.
   * @type {React.State<Object|null>}
   */
  const [feeDetails, setFeeDetails] = useState(null);

  /**
   * @desc State hook to managethe loading of fee details.
   * @type {React.State<boolean>}
   */
  const [loadingFeeDetails, setLoadingFeeDetails] = useState(true);

  /**
   * @desc State hook to set the toast.
   * @type {React.State<Object>}
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * @desc Fetches the filtered list of orders based on the search query and page number.
   * @returns {void}
   */
  const getFilteredOrderList = () => {
    let query = searchQuery.trim(),
      pageNo = currentPage;

    setLoadingFilteredResults(true);

    getManageOrders(pageNo, query)
      .then((res) => {
        setOrdersList(res.data.ordersList);
        if (res.data.totalPages != totalPages) {
          setTotalPages(res.data.totalPages);
          setCurrentPage(0);
        }
        setLoadingFilteredResults(false);
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg, success: false });
        console.log(err);
        setLoadingFilteredResults(false);
      });
  };

  /**
   * This effect is used to debounce the search query.
   * When the search query or current page changes, it waits for 500ms and then calls getFilteredOrderList.
   * This is to prevent calling the API for every letter typed in the search box.
   * This effect also returns a cleanup function to clear the timeout.
   */
  useEffect(() => {
    const getData = setTimeout(() => {
      getFilteredOrderList();
    }, 500);

    return () => clearTimeout(getData);
  }, [searchQuery, currentPage]);

  /**
   * This effect is used to fetch the list of orders when the component mounts.
   * It calls getManageOrders and sets the orders list and total pages state.
   */
  useEffect(() => {
    run(() => getManageOrders()).then((res) => {
      setOrdersList(res.data.ordersList);
      setTotalPages(res.data.totalPages);
    });
  }, []);

  /**
   * This function is used to handle the page changes.
   * @param {number} page - The page number to navigate to.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  /**
   * This function is used to get the sub total price of a ticket for an event.
   * @param {Object} ticketDTO - The ticket details object.
   * @param {boolean} [ticketDTO.chargeTax] - Whether or not to charge tax.
   * @param {number} [ticketDTO.taxPercentage] - The tax percentage.
   * @param {number} [ticketDTO.ticketPrice] - The ticket price.
   * @param {string} [ticketDTO.ticketType] - The ticket type.
   * @returns {number} The sub total price of the ticket.
   */
  const getSubTotalPrice = (ticketDTO) => {
    const { chargeTax, taxPercentage, ticketPrice, ticketType } =
      ticketDTO || {};

    return (
      ticketPrice +
      (chargeTax
        ? (ticketPrice * taxPercentage) / 100
        : ticketPrice * TAX_PERCENTAGE) +
      ticketPrice * SERVICE_FEE_PERCENT
    );
  };

  /**
   * This function is used to get the total price.
   *
   * @return {number} The total price.
   */
  const getTotalPrice = () => {
    let subtotal = getSubTotalPrice(selectedOrder.ticketDetails);
    let discount = selectedOrder.couponDiscount || 0;

    return subtotal - discount;
  };

  /**
   * This function is used to get the refund amount.
   *
   * @param {Object} order - The order object.
   * @param {Object} feeDetails - The fee details object.
   * @return {number} The refund amount.
   */
  const getRefundAmount = (order, feeDetails) => {
    if (!order || !feeDetails) {
      return 0;
    }

    const { restockingFees, applicationFees, stripeFees } = feeDetails,
      total = order.totalAmount / 100;

    // let refund = getTotalPrice() - RESTOCKING_FEE - GATEWAY_FEE - APPLICATION_FEE;
    let refund =
      total -
      (restockingFees % total) -
      (applicationFees % total) -
      (stripeFees % total);

    if (refund < 0) {
      refund = 0;
    }

    return refund;
  };

  /**
   * This function is used to handle the cancel order.
   *
   * @return {void}
   */
  const handleCancelOrder = () => {
    let orderCancelDTO = {
      eventId: selectedOrder.eventId,
      orderId: selectedOrder.orderId,
      refundReason: refundReason,
      fullRefund: fullRefund,
    };

    refundOrderAdmin(orderCancelDTO)
      .then((res) => {
        console.log(res.data);
        setOrderRefundId(res.data);
        setToast({
          show: true,
          msg: 'Refund initiated successfully',
          success: true,
        });
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
        console.log(err);
      });
  };

  /**
   * This function is used to handle the "open cancel order" modal.
   *
   * @return {void}
   */
  const handleOpenCancelOrderModal = () => {
    setShowCancelOrderModal(true);

    if (!feeDetails) {
      getFeeDetails()
        .then((res) => {
          console.log(res.data);
          setLoadingFeeDetails(false);
          setFeeDetails(res.data);
        })
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          setLoadingFeeDetails(false);

          /**
           * This block of code logs the error to the console.
           *
           * @type {Error}
           */
          console.log('ERR', err);
        });
    }
  };

  /**
   * Renders a loading spinner if loading is true.
   *
   * @return {JSX.Element|null} The loading spinner or null if loading is false.
   */
  if (loading) {
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="m-4 px-4">
      {!selectedOrder && (
        <div>
          <h2 className="fw-bold">Order Management</h2>
          <p className="mb-0">View and manage orders.</p>
          <div className="d-flex my-4">
            <Form.Group className="ms-auto">
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Form.Group>
          </div>

          <Row className="bg-primary text-white mt-3">
            <Col xs={1} className="p-2 text-center">
              Order ID
            </Col>
            <Col className="p-2">Event Name</Col>
            <Col className="p-2">Promoter Name / Email</Col>
            <Col className="p-2">Patron Name / Email</Col>
            <Col xs="1" className="p-2 text-center">
              Service Fees
            </Col>
            <Col xs="1" className="p-2 text-center">
              Promoter Fees
            </Col>
            <Col xs="1" className="p-2 text-center">
              Total Amount
            </Col>
            <Col className="p-2 text-center">Last Update</Col>
            <Col xs="1" className="p-2 text-center">
              Payment Status
            </Col>
          </Row>
          {loadingFilteredResults && (
            <div className="p-5 d-flex align-items-center justify-content-center">
              <h6 className="me-2">Loading...</h6>
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          {!loadingFilteredResults && ordersList.length == 0 && (
            <h5 className="text-center py-4">No Results Found!</h5>
          )}

          {!loadingFilteredResults &&
            ordersList.length &&
            ordersList.map((order, i) => {
              return (
                <Row
                  key={i}
                  className="bg-header mb-2 border border-gray align-items-center"
                >
                  <Col xs={1} className="p-2 text-center">
                    <Button
                      onClick={() => setSelectedOrder(order)}
                      className="p-0 text-secondary"
                      variant="link"
                    >
                      {order.uniqueGeneratedOrderId}
                    </Button>
                  </Col>
                  <Col className="p-2">{order.eventName}</Col>
                  <Col className="p-2">
                    {order.promoterFirstName} {order.promoterLastName} / <br />{' '}
                    {order.promoterEmail}
                  </Col>
                  <Col className="p-2">
                    {order.patronFirstName} {order.patronLastName} / <br />{' '}
                    {order.patronEmail}
                  </Col>
                  <Col xs="1" className="p-2 text-center">
                    ${order.serviceFees / 100}
                  </Col>
                  <Col xs="1" className="p-2 text-center">
                    ${order.promoterFees / 100}
                  </Col>
                  <Col xs="1" className="p-2 text-center">
                    ${order.totalAmount / 100}
                  </Col>
                  <Col className="p-2 text-center">
                    {moment(order.lastUpdate).format('MMM DD, YYYY hh:mm A')}
                  </Col>
                  <Col xs="1" className="p-2">
                    {order.paymentStatus}
                  </Col>
                </Row>
              );
            })}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}

      {selectedOrder && (
        <div className="mb-5">
          <Button
            onClick={() => setSelectedOrder(null)}
            variant="link"
            className="text-decoration-none px-0 mt-2 text-secondary"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            View All Orders
          </Button>

          <h3 className="fw-bold my-3">
            Order # {selectedOrder.uniqueGeneratedOrderId}
            <span
              className={
                selectedOrder.paymentStatus == 'CONFIRMED'
                  ? 'text-success'
                  : selectedOrder.paymentStatus == 'PROCESSING'
                  ? 'text-secondary'
                  : 'text-danger'
              }
            >
              {' '}
              ( {selectedOrder.paymentStatus} )
            </span>
          </h3>
          <h5 className="mb-3">
            <label className="fw-bold me-2">Promoter Name: </label>
            {selectedOrder.promoterFirstName} {selectedOrder.promoterLastName}
          </h5>
          <h5 className="mb-3">
            <label className="fw-bold me-2">Promoter Email: </label>
            {selectedOrder.promoterEmail}
          </h5>
          <h5 className="mb-3">
            <label className="fw-bold me-2"> Event name: </label>
            {selectedOrder.eventName}
          </h5>
          <h5 className="mb-4">
            <label className="fw-bold me-2"> Event Type: </label>
            Live Stream
          </h5>

          <hr />

          <Row className="my-4">
            <Col>
              <div>
                <p>Email</p>
                <p>{selectedOrder.orderBillingInfoDto.email}</p>
              </div>
            </Col>
            <Col>
              <div>
                <p>Phone</p>
                <p>-</p>
              </div>
            </Col>

            <Col>
              <div>
                <p>Billing Address</p>
                <p>
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .addressLine1
                  }
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .addressLine2
                  }
                  ,&nbsp;
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .city
                  }
                  &nbsp;
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .province
                  }
                  ,&nbsp;
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .country
                  }
                  , &nbsp;
                  {
                    selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                      .postalCode
                  }
                  ,
                </p>
              </div>
            </Col>
          </Row>

          <div className="border p-3 mb-4">
            <h5 className="fw-bold mb-3">Payment Details</h5>
            <div className="d-flex">
              <div className="pe-5 me-5">
                <p>
                  Credit Card: XXXX-XXXX-XXXX-{selectedOrder.cardLastFourDigits}
                </p>
                <p>Card Type: {selectedOrder.cardBrand}</p>
              </div>
              <div>
                <p>Expire Date: -</p>
                <p>
                  Transaction Date:{' '}
                  {moment(selectedOrder.transactionDate).format('DD-MMM-YYYY')}
                </p>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="border col-12 col-sm-8 col-md-6 col-lg-3 px-0 me-4">
              <h5 className="fw-bold px-3 mb-0 py-2 border-bottom ">
                Order Details
              </h5>
              <div className="px-3 border border-bottom py-2 bg-darkGray text-white">
                <p className="mb-0">Status: {selectedOrder.paymentStatus}</p>
              </div>
              <div className=" border-bottom px-3 py-2">
                <p className="mb-0">
                  Total paid: ${selectedOrder.totalAmount / 100} CAD
                </p>
              </div>
              <div className=" border-bottom px-3 py-2">
                <p className="mb-0">
                  Promoter fees: $ {selectedOrder.promoterFees / 100} CAD
                </p>
              </div>
              <div className=" border-bottom px-3 py-2">
                <p className="mb-0">
                  Application fees: ${selectedOrder.serviceFees / 100} CAD
                </p>
              </div>
              <div className="px-3 py-2">
                <p className="mb-0">
                  Coupon code: {selectedOrder.couponCode || 'N/A'}
                </p>
              </div>
              {selectedOrder.couponDiscount && (
                <div className="px-3 py-2">
                  <p className="mb-0">
                    Coupon Discount: {selectedOrder.couponDiscount}
                  </p>
                </div>
              )}
            </div>

            {selectedOrder.refundStatus && (
              <div className="border col-12 col-sm-8 col-md-6 col-lg-3 px-0">
                <h5 className="fw-bold px-3 mb-0 py-2 border-bottom ">
                  Refund Details
                </h5>
                <div className="px-3 border border-bottom py-2 bg-darkGray text-white">
                  <p className="mb-0">Status: {selectedOrder.refundStatus}</p>
                </div>
                <div className=" border-bottom px-3 py-2">
                  <p className="mb-0">
                    Total paid: ${selectedOrder.refundAmount / 100} CAD
                  </p>
                </div>
                <div className=" border-bottom px-3 py-2">
                  <p className="mb-0">
                    Reason: {selectedOrder.refundReason || 'N/A'}
                  </p>
                </div>
                <div className=" border-bottom px-3 py-2">
                  <p className="mb-0">
                    Last Update:{' '}
                    {moment(selectedOrder.lastUpdate).format(
                      'MMM DD, YYYY hh:mm A'
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>

          <Button
            onClick={() => handleOpenCancelOrderModal()}
            disabled={
              selectedOrder.refundStatus ||
              selectedOrder.paymentStatus != 'CONFIRMED'
            }
            variant="orange"
            className="text-white mt-4 px-4"
          >
            Refund Order
          </Button>

          <Modal
            show={showCancelOrderModal}
            onHide={() => {
              setShowCancelOrderModal(false);
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="px-3">
                Are you sure you want to cancel this order?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 pb-5">
              {!loadingFeeDetails && (
                <>
                  <Row className="mb-2">
                    <Col>Order Amount</Col>
                    <Col>${selectedOrder.totalAmount / 100}</Col>
                  </Row>

                  <Row className="mb-2">
                    <Col>Restocking Fee</Col>
                    <Col>
                      - ${feeDetails.restockingFees % selectedOrder.totalAmount}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col>Application Fee</Col>
                    <Col>
                      - $
                      {feeDetails.applicationFees % selectedOrder.totalAmount}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col>Payment Gateway Fee</Col>
                    <Col>
                      - ${feeDetails.stripeFees % selectedOrder.totalAmount}
                    </Col>
                  </Row>

                  <hr />

                  <Row className="mb-2">
                    <Col>Refund Amount</Col>
                    <Col>${getRefundAmount(selectedOrder, feeDetails)}</Col>
                  </Row>

                  <Form.Group>
                    <Form.Check
                      label="Give Full Refund to Patron"
                      checked={fullRefund}
                      onChange={(e) => setFullRefund(e.target.checked)}
                    />
                  </Form.Group>

                  <Form.Group controlId="refundReason" className="mt-3">
                    <Form.Label>Reason (optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={refundReason}
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}

              {loadingFeeDetails && (
                <div className="p-5 d-flex align-items-center justify-content-center">
                  <h6 className="me-2">Loading...</h6>
                  <Spinner animation="border" variant="secondary" />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!orderRefundId && (
                <>
                  <Button
                    onClick={() => setShowCancelOrderModal(false)}
                    variant="outline-dark"
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleCancelOrder}
                    className="text-white"
                    variant="danger"
                  >
                    Yes, Cancel
                  </Button>
                </>
              )}

              {orderRefundId && (
                <Button
                  onClick={() => setShowCancelOrderModal(false)}
                  variant="outline-dark"
                >
                  Close
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default OrderManagement;
