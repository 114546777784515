// Last Step of the sequence
// To publish events for patron to buy tickets
import React, { useState, useLayoutEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  faUserCircle,
  faTicket,
  faTriangleExclamation,
  faChevronUp,
  faChevronDown,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Dropdown, Form, Modal, Card } from 'react-bootstrap';
import { getItem } from '../../../../utils/LocalStorageUtils';
import {
  EVENT_BANNER_IMG,
  EVENT_AVAILABILITY,
  EVENT_STEPS,
} from '../../../../constants';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { formatDate } from '../../../../utils/CommonUtils';
import validateEventForm from '../eventFormValidator';
import { createEvent } from '../../../../utils/APIUtils';
import { useEffect } from 'react';
import TermsAndConditions from '../../../../policies/TermsAndConditions';

// eventAvailability:PUBLIC
// publishDate:2022-12-02
// publishTime:1669789345833
// acceptTerms: boolean

// Component code
const Publish = () => {
  /**
   * Initializes the context for the Publish component.
   *
   * @return {void}
   */
  const [context, setContext] = useOutletContext();
  let { finalEventDetailsDto } = context;
  /**
   * Initializes the component on screen load. Setting some fields as soon as component renders on screen.
   *
   * @return {void}
   */
  useEffect(() => {
    // initialize event availability on page load
    if (!finalEventDetailsDto.eventAvailability)
      finalEventDetailsDto.eventAvailability = EVENT_AVAILABILITY.PUBLIC;
    if (!finalEventDetailsDto.publishDate)
      finalEventDetailsDto.publishDate = moment(new Date()).format(
        'YYYY-MM-DD'
      );
    if (!finalEventDetailsDto.publishTime)
      finalEventDetailsDto.publishTime = moment(new Date().getTime()).format(
        'hh:mm A'
      );
  }, []);

  /**
   * Initializes the state for the Publish component.
   *
   * @return {void}
   */
  const [formState, setFormState] = useState(finalEventDetailsDto);
  /**
   * Updates the state of the Publish component.
   *
   * @param {Object} state - The new state to set.
   * @return {void}
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      console.log({ ...prevState, ...state });
      return { ...prevState, ...state };
    });
  /**
   * The state for holding any errors that occur during form validation.
   *
   * @type {null|Object}
   */
  const [errors, setErrors] = useState(null);
  /**
   * Initializes the router.
   *
   * @type {function}
   */
  const navigate = useNavigate();
  /**
   * Initializes the state for public event.
   *
   * @type {boolean}
   */
  const [isPublic, setIsPublic] = useState(true);
  /**
   * Initializes the state for publish now.
   *
   * @type {boolean}
   */
  const [publishNow, setPublishNow] = useState(true);
  /**
   * State to show animation.
   *
   * @type {boolean}
   */
  const [isSaving, setisSaving] = useState(false);
  /**
   * State to show terms and conditions modal.
   *
   * @type {boolean}
   */
  const [showTermsModal, setShowTermsModal] = useState(false);

  /**
   * State to show or hide the description.
   *
   * @type {boolean}
   */
  const [showDescription, setShowDescription] = useState(false);

  /**
   * Layout effect that fires before the browser re-renders the DOM.
   *
   * @return {void}
   */
  useLayoutEffect(() => {
    // loadDefaultImage();
  });
  // Loading default image on screen
  // const loadDefaultImage = () => {
  //   document.getElementById('display-image').style.backgroundImage = `url(${getItem(EVENT_BANNER_IMG) || "https://picsum.photos/id/237/200/300"})` //TODO : added fallback image, remove after;
  // }
  // Legacy code
  const handleIsPublicRadioChange = (e) => {
    console.log(e);
    let eventAvailability =
      e.target.value == 'true'
        ? EVENT_AVAILABILITY.PUBLIC
        : EVENT_AVAILABILITY.PRIVATE;
    updateState({ eventAvailability });
    setIsPublic(e.target.value == 'true');
  };
  // Legacy code
  const handleIsPublishRadioChange = (e) => {
    console.log(e);
    let publishDate = e.target.value == 'true' ? formatDate(new Date()) : '',
      publishTime = e.target.value == 'true' ? new Date().now() : null;
    updateState({ publishDate, publishTime });
    setPublishNow(e.target.value == 'true');
  };

  // const handleChange = (e) => {
  //   let state = {};
  //   const {name, id, value, checked} = e.target;

  //   if(id){
  //       state[id] = value;
  //   }
  //   else{
  //       state[name] = checked;
  //   }

  //   updateState(state);
  // }
  // Validating form
  const validate = () => {
    let error = validateEventForm(formState, 'FINAL_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };
  /**
   * Handles form submission.
   * Validates the form data and creates an event if the data is valid.
   * @param {Event} e - The form submission event.
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      setisSaving(true);
      createEvent('FINAL_DETAILS', formState, context.eventId)
        .then((res) => {
          // Setting context..
          setContext({
            ...context,
            finalEventDetailsDto: {
              ...formState,
            },
          });
          // Routes to all events page, as this is final step for event creation
          // If everything goes well event is created..
          navigate('/promoter-panel/events');
          console.log(res);
          setisSaving(false);
        })
        .catch((err) => {
          console.log(err);
          setisSaving(false);
        });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} lg={10}>
            <h2 className="fw-bold mb-0">Publish Your Event</h2>

            <Card className="bg-dark text-white mt-4">
              <Card.Body className="p-4">
                <div className="row">
                  <div className="col-md-3 d-flex align-items-center">
                    <Card.Img
                      className="img-fluid"
                      style={{ maxHeight: '170px' }}
                      src={'https://picsum.photos/id/237/200/300'}
                    />
                  </div>
                  <div className="col-md-9 d-flex align-items-center">
                    <div className="d-flex flex-column h-100 text-default">
                      <Card.Title as="h3" className="fw-bold text-primary">
                        Vincent Dark
                      </Card.Title>
                      <Card.Text as="h4">25 Dec</Card.Text>
                      <Card.Text as="h5">
                        National Event Venue and Banquet Hall
                      </Card.Text>
                    </div>

                    <div className="ms-auto">
                      <div>
                        <Button
                          style={{ width: '225px' }}
                          variant="primary"
                          className="rounded-pill d-flex align-items-center px-4 py-3"
                        >
                          <FontAwesomeIcon icon={faShoppingCart} />
                          <div className="text-white text-center flex-grow-1">
                            <h5 className="mb-0"> View Tickets </h5>
                            <div className="fs-12">Start from $20</div>
                          </div>
                        </Button>
                        <p className="text-default mt-2 text-center">
                          120 People shared this event
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {showDescription && (
                    <>
                      {/* event description */}
                      <div className="text-default mt-4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        ele ctronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsu
                      </div>
                    </>
                  )}

                  <div className="d-flex justify-content-center">
                    <Button
                      variant="link"
                      onClick={(e) => setShowDescription(!showDescription)}
                    >
                      <FontAwesomeIcon
                        size="2x"
                        className="text-default"
                        title="event Information"
                        icon={showDescription ? faChevronUp : faChevronDown}
                      />
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>

            {/* <div className='d-flex align-items-center my-4 bg-light p-3'>
              <div>
                <h4 className='mb-0 fw-bold'>Rock Your Soul</h4>
                <p className='mb-0'>Tue, Oct 11, 2022 7:00 PM</p>
                <p className='mb-0'>Live stream event</p>
              </div>
              <div className='ms-auto'>
                <FontAwesomeIcon icon={faTicket} className="me-2" size='xl'></FontAwesomeIcon>
                <span>CA $20</span>
              </div>
              <div className='mx-5'>
                <FontAwesomeIcon icon={faUserCircle} className="me-2" size='xl'></FontAwesomeIcon>
                <span>300</span>
              </div>

            </div> */}
            {/* <div id="display-image" className="event-banner-image w-100"></div> */}

            <h5 className="mt-5 mb-3 fw-bold">Who can see your event?</h5>
            <Form.Group onChange={(e) => handleIsPublicRadioChange(e)}>
              <Form.Check type="radio" id="radio-public">
                <Form.Check.Input
                  type="radio"
                  name="who-can-see-radio"
                  checked={isPublic}
                  value={true}
                />
                <Form.Check.Label>Public</Form.Check.Label>
                <Form.Text>
                  <div>Shared on Eventlinx website</div>
                </Form.Text>
              </Form.Check>

              <Form.Check type="radio" id="radio-private">
                <Form.Check.Input
                  type="radio"
                  name="who-can-see-radio"
                  checked={isPublic}
                  value={false}
                />
                <Form.Check.Label>Private</Form.Check.Label>
                <Form.Text>
                  <div>Available Only for selected Audience</div>
                </Form.Text>
              </Form.Check>
            </Form.Group>

            <hr className="my-4" />

            <h5 className="mb-3 fw-bold">Date for publishing your event</h5>
            <Form.Group onChange={(e) => handleIsPublishRadioChange(e)}>
              <Form.Check type="radio" id="radio-now" className="mb-2">
                <Form.Check.Input
                  type="radio"
                  name="publish-date-radio"
                  checked={publishNow}
                  value={true}
                />
                <Form.Check.Label>Publish Now</Form.Check.Label>
              </Form.Check>

              <Form.Check type="radio" id="radio-later">
                <Form.Check.Input
                  type="radio"
                  name="publish-date-radio"
                  checked={!publishNow}
                  value={false}
                />
                <Form.Check.Label>Schedule for later</Form.Check.Label>
              </Form.Check>
            </Form.Group>

            {!publishNow && (
              <div className="d-flex align-items-center my-4">
                <Form.Group className="flex-grow-1 me-3 d-flex flex-column">
                  <Form.Label className="required">Start Date:</Form.Label>
                  <DatePicker
                    minDate={new Date()}
                    maxDate={moment(formState.eventStartDate).toDate() || null} // publish date cannot be later than event start date
                    className="form-control w-20"
                    selected={
                      formState.publishDate &&
                      moment(formState.publishDate).toDate()
                    }
                    onChange={(date) =>
                      updateState({ publishDate: formatDate(date) })
                    }
                  />
                  {errors && errors.publishDate && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.publishDate}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="flex-grow-1 d-flex flex-column">
                  <Form.Label className="required">Start Time:</Form.Label>

                  <DatePicker
                    className="form-control w-20"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    selected={
                      formState.publishTime && new Date(formState.publishTime)
                    }
                    onChange={(date) =>
                      updateState({ publishTime: date.getTime() })
                    }
                  />
                  {errors && errors.publishTime && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.publishTime}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
            )}

            <Form.Group className="mt-5">
              <div className="d-flex">
                <Form.Check
                  type="checkbox"
                  name="acceptTerms"
                  id="acceptTerms"
                  onChange={(e) =>
                    updateState({ acceptTerms: e.target.checked })
                  }
                  checked={formState.acceptTerms}
                />
                <Form.Label
                  htmlFor="acceptTerms"
                  className="d-flex align-items-center ms-2"
                >
                  By checking this box, I state that I have read and understood
                  the
                  <Button
                    onClick={() => setShowTermsModal(true)}
                    variant="link"
                    className="p-0 text-secondary ms-2"
                  >
                    Terms and Conditions
                  </Button>
                </Form.Label>
              </div>
              {/* This can be seen in almost code of all steps.. shows errors if anything shows up in validation step.. */}
              {errors && errors.acceptTerms && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors.acceptTerms}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex my-4 pt-5">
          {/* This is crucial to handle in-person and streaming pages.. So if there is DTO for in-person in cotext then render in-person tickets page else render normal tickets page.. */}
          {/* <Button as={Link} to="/promoter-panel/events/create-event/tickets"
                variant="orange" className="text-white ms-auto px-5">Publish</Button> */}
          <Button
            as={Link}
            to={
              context && context.IN_PERSON_TICKET_DETAILS
                ? `/promoter-panel/events/create-event/${EVENT_STEPS.TICKET_DETAILS}/${context.eventId}`
                : `/promoter-panel/events/create-event/${EVENT_STEPS.IN_PERSON_TICKET_DETAILS}/${context.eventId}`
            }
            variant="outlined"
            className="ms-auto px-3 border border-dark btn-create-event"
          >
            Back
          </Button>
          <Button
            disabled={!formState.acceptTerms}
            type="submit"
            variant="primary"
            className="text-white ms-3 px-5 btn-create-event"
          >
            {isSaving && (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Publishing...</span>
              </>
            )}
            {!isSaving && <span>Publish</span>}
          </Button>
        </div>
      </Form>

      <Modal
        size="xl"
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="px-4 border-0" closeButton></Modal.Header>
        <Modal.Body className="py-0">
          <TermsAndConditions />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Publish;
