// One of the steps in Create event sequence.
import React, { useState } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import {
  faPlus,
  faMinus,
  faTriangleExclamation,
  faChain,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DragDropFile from '../../../../shared/DragDropFile';
import { EVENT_STEPS, MAX_EVENT_IMG_COUNT } from '../../../../constants';
import validateEventForm from '../eventFormValidator';
import { createEvent } from '../../../../utils/APIUtils';
import ToastMessage from '../../../../shared/ToastMessage';

/**
 * Configuration object for the word count plugin.
 * @type {Object}
 */
const wordCountConfig = {
  displayCharacters: true,
};

/**
 * Maximum number of characters allowed for the event description.
 * @type {number}
 */
const MAX_DESC_CHAR_COUNT = 1500;

/**
 * Maximum number of characters allowed for the event summary.
 * @type {number}
 */
const MAX_SUMMARY_CHAR_COUNT = 150;

/**
 * Configuration object for the CKEditor text editor.
 * @type {Object}
 * @property {Array} toolbar - The toolbar buttons for the editor.
 */
const editorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'link',
    'undo',
    'redo',
    'numberedList',
    'bulletedList',
  ],
};

/**
 * Exported component for the Details step in the create event sequence.
 *
 * @return {JSX.Element} The rendered Details component.
 */
const Details = () => {
  /**
   * Initiating context to access state of all forms across steps.
   * @type {Object}
   */
  const [context, setContext] = useOutletContext();

  /**
   * Main event details DTO.
   * @type {Object}
   */
  let { mainEventDetailsDto } = context;

  /**
   * Initializing DTO for this step, if not present in context.
   */
  if (!mainEventDetailsDto) {
    mainEventDetailsDto = {};
  }

  /**
   * Initializing formState.
   * @type {Object}
   */
  const [formState, setFormState] = useState(mainEventDetailsDto);

  /**
   * Initializing errors state.
   * @type {Object}
   */
  const [errors, setErrors] = useState({});

  /**
   * Navigate hook.
   * @type {Function}
   */
  const navigate = useNavigate();

  /**
   * Initialize add video state.
   * @type {boolean}
   */
  const [addVideo, setAddVideo] = useState(
    mainEventDetailsDto.externalVideoURL || false
  );

  /**
   * Initialize isSaving state.
   * @type {boolean}
   */
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Initialize toast for notifications.
   * @type {Object}
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * Initialize summary char length state.
   * @type {number}
   */
  const [summaryCharLength, setSummaryCharLength] = useState(0);

  /**
   * Initialize desc char length state.
   * @type {number}
   */
  const [descCharLength, setDescCharLength] = useState(
    formState.description ? formState.description.length : 0
  );

  /**
   * Add images for events.
   * @returns {void}
   */
  const addImage = () => {
    let images = [],
      imageNameList = [];
    if (formState.eventImages && formState.eventImages.length) {
      images = [...formState.eventImages];
      imageNameList = [...formState.eventImagesNameList];
    }
    images.push(null);
    updateState({ eventImages: images, eventImagesNameList: imageNameList });
  };

  /**
   * If images are changed before submitting the form, this function is called
   * to modify state.
   * @param {string} imgName - Name of the image.
   * @returns {void}
   */
  const updateModifiedImagesArray = (imgName) => {
    if (imgName) {
      let currentState = { ...formState };
      currentState.deleteEventImageNames =
        currentState.deleteEventImageNames || [];
      if (currentState.deleteEventImageNames.indexOf(imgName) === -1) {
        currentState.deleteEventImageNames.push(imgName);
        updateState({
          deleteEventImageNames: currentState.deleteEventImageNames,
        });
      }
    }
  };

  /**
   * To remove image from state when promoter clicks on remove image button.
   * @param {number} i - Index of the image to be removed.
   * @returns {void}
   */
  const removeImage = (i) => {
    let images = [...formState.eventImages];
    images.splice(i, 1);
    updateState({ eventImages: images });
  };

  /**
   * This function is called to upload images to state.
   * @param {File} file - Image file to be uploaded.
   * @param {number} index - Index of the image in the state.
   * @returns {void}
   */
  const handleUploadedImage = (file, index) => {
    let images = [...formState.eventImages];
    images[index] = file;
    updateState({ eventImages: images });
  };

  /**
   * Uploads a banner image for the event.
   * Functions are different to add banner and to add other images because other images are uploaded in an array list and banner is a single image.
   * @param {File} file - The file to be uploaded.
   * @returns {void}
   */
  const handleBannerUpload = (file) => {
    if (file && errors && errors.bannerImage) {
      errors.bannerImage = '';
    }
    updateState({ bannerImage: file });
  };

  /**
   * Updates the entire form state.
   * @param {Object} state - The new state object.
   * @returns {void}
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      return { ...prevState, ...state };
    });

  /**
   * Handles the change event of form fields.
   * @param {Event} event - The event object.
   * @returns {void}
   */
  const handleChange = (event) => {
    let state = {};
    const { name, id, value, checked } = event.target;
    if (id) {
      state[id] = value;
    }
    if (checked) {
      state[name] = checked;
    }
    updateState(state);
  };

  /**
   * Validates all the fields in the form. If invalid, shows errors and prevents form submission.
   * @returns {boolean} True if all fields are valid, false otherwise.
   */
  const validate = () => {
    let error = validateEventForm(formState, 'MAIN_DETAILS');
    setErrors(error);
    if (error) {
      return false;
    }
    return true;
  };

  /**
   * Handles the form submission.
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      setIsSaving(true);
      let dto = { ...formState };

      if (typeof dto.bannerImage.name !== 'string') {
        delete dto.bannerImage;
      }

      if (dto.eventImages && dto.eventImages.length) {
        dto.eventImages = dto.eventImages.filter(
          (img) => typeof img.name === 'string'
        );
        if (!dto.eventImages.length) {
          delete dto.eventImages;
        }
      }

      createEvent('MAIN_DETAILS', dto, context.eventId)
        .then((res) => {
          setContext({
            ...context,
            mainEventDetailsDto: {
              ...formState,
            },
          });

          setToast({ show: true, msg: 'Saved Successfully', success: true });
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.EVENT_CHOICE}/${context.eventId}`
          );
          setIsSaving(false);
        })
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          setIsSaving(false);
        });
    }
  };

  /**
   * Main Details component
   *
   * @return {JSX.Element} The rendered JSX element
   */
  return (
    <Row>
      <Col xs={12} lg={9}>
        <Form onSubmit={handleSubmit}>
          <h2 className="fw-bold mb-0 required">Main Event Image</h2>
          <p className="mb-3">
            This image will be the header of your event page.
            <Button variant="link" className="text-dark fw-bold">
              Tips to select a great picture
            </Button>
          </p>
          <div className="my-5">
            <DragDropFile
              file={formState.bannerImage}
              onFileDrop={handleBannerUpload}
              banner={true}
            />
            {errors && errors.bannerImage && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.bannerImage}
              </Form.Text>
            )}
          </div>

          <hr className="my-5" />

          <h2 className="fw-bold mb-0 required">Description </h2>
          <p>
            Add more details to your event like sponsors, important dates or
            featured guests.
          </p>
          <Form.Group controlId="summary" className="mb-3">
            <Form.Label>Summary</Form.Label>
            <Form.Control
              onChange={handleChange}
              as="textarea"
              maxLength={200}
              value={formState.summary}
            />
            <Form.Text
              className={`${
                formState.summary.length > MAX_SUMMARY_CHAR_COUNT
                  ? 'text-danger'
                  : ''
              }`}
            >
              {formState.summary.length +
                ` / ${MAX_SUMMARY_CHAR_COUNT} characters`}
            </Form.Text>
            {errors && errors.summary && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.summary}
              </Form.Text>
            )}
          </Form.Group>
          <div
            className={`${
              formState.description.length > MAX_DESC_CHAR_COUNT
                ? 'editor-error'
                : ''
            }`}
          >
            <CKEditor
              id="description"
              className={`${
                formState.description.length > MAX_DESC_CHAR_COUNT
                  ? 'editor-error'
                  : ''
              }`}
              editor={ClassicEditor}
              data={formState.description}
              config={editorConfiguration}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                updateState({ description: data });
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </div>

          <Form.Text
            className={`${
              formState.description.length > MAX_DESC_CHAR_COUNT
                ? 'text-danger'
                : ''
            }`}
          >
            {formState.description.length +
              ` / ${MAX_DESC_CHAR_COUNT} characters`}
          </Form.Text>

          {errors && errors.description && (
            <Form.Text className="text-danger">
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              {errors.description}
            </Form.Text>
          )}

          {formState.eventImages &&
            formState.eventImages.map((image, i) => {
              return (
                <div key={i} className="my-4">
                  <DragDropFile
                    file={image}
                    onFileDrop={(file) => {
                      handleUploadedImage(file, i);
                      updateModifiedImagesArray(
                        formState.eventImagesNameList[i]
                      );
                    }}
                  />
                  {!image && errors && errors.eventImages && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.eventImages}
                    </Form.Text>
                  )}
                  <div className="d-flex align-items-center ms-auto mt-3 mb-5">
                    <span className="ms-auto me-2 fw-bold">Remove </span>
                    <Button
                      variant="orange"
                      onClick={() => {
                        removeImage(i);
                        updateModifiedImagesArray(
                          formState.eventImagesNameList[i]
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="ms-auto text-white"
                        size="xl"
                      />
                    </Button>
                  </div>
                </div>
              );
            })}

          {addVideo && (
            <div>
              <InputGroup className="my-3 border border-dark rounded">
                <InputGroup.Text className="fw-bold">
                  Video Link:{' '}
                </InputGroup.Text>
                <Form.Control
                  className="bg-white border-left-0 py-2"
                  value={formState.externalVideoURL}
                  onChange={handleChange}
                  placeholder=""
                  aria-label="video url"
                  id="externalVideoURL"
                  aria-describedby="basic-video-url"
                />
                <InputGroup.Text className="fw-bold bg-orange">
                  <FontAwesomeIcon icon={faChain} className="me-2 text-white" />
                </InputGroup.Text>
              </InputGroup>
              {errors && errors.externalVideoURL && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors.externalVideoURL}
                </Form.Text>
              )}
            </div>
          )}

          <div className="d-flex mt-3">
            <div className="d-flex align-items-center ms-auto">
              <span className="ms-auto me-2 fw-bold">Add Image(s)</span>
              <Button
                variant="orange"
                disabled={
                  formState.eventImages &&
                  formState.eventImages.length === MAX_EVENT_IMG_COUNT
                }
                onClick={addImage}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="ms-auto text-white"
                  size="xl"
                />
              </Button>
            </div>

            <div className="d-flex align-items-center ms-3">
              {!addVideo && (
                <>
                  <span className="ms-auto me-2 fw-bold">Add Video</span>
                  <Button variant="orange" onClick={() => setAddVideo(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto text-white"
                      size="xl"
                    />
                  </Button>
                </>
              )}
              {addVideo && (
                <>
                  <span className="ms-auto me-2 fw-bold">Remove Video</span>
                  <Button variant="orange" onClick={() => setAddVideo(false)}>
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto text-white"
                      size="xl"
                    />
                  </Button>
                </>
              )}
            </div>
          </div>

          <hr className="my-5" />

          <Row>
            <Col xs={12} md={9}>
              <h2 className="fw-bold mb-3">Social Media</h2>
              <p>Add your social media accounts </p>

              {formState.twitterUrlAdded && (
                <Tooltip title={<p className="mb-0">{formState.twitterUrl}</p>}>
                  <Button
                    variant="link"
                    className="bg-primary border-primary rounded-media-button me-3 mb-3"
                  >
                    <img
                      height="25"
                      src="/twitter-x.svg"
                      alt="twitter-x logo"
                    />
                  </Button>
                </Tooltip>
              )}

              {formState.facebookAdded && (
                <Tooltip title={<p className="mb-0">{formState.facebook}</p>}>
                  <Button
                    variant="link"
                    className="bg-primary border-primary rounded-media-button  me-3 mb-3"
                  >
                    <img src="/facebook.svg" alt="facebook logo" />
                  </Button>
                </Tooltip>
              )}

              {formState.instagramAdded && (
                <Tooltip title={<p className="mb-0">{formState.instagram}</p>}>
                  <Button
                    variant="link"
                    className="bg-primary border-primary rounded-media-button  me-3 mb-3"
                  >
                    <img src="/instagram.svg" alt="instagram logo" />
                  </Button>
                </Tooltip>
              )}

              {formState.tiktokAdded && (
                <Tooltip title={<p className="mb-0">{formState.Tiktok}</p>}>
                  <Button
                    variant="link"
                    className="bg-primary border-primary rounded-media-button  me-3 mb-3"
                  >
                    <img src="/tiktok.svg" alt="tiktok logo" />
                  </Button>
                </Tooltip>
              )}

              {formState.youtubeUrlAdded && (
                <Tooltip title={<p className="mb-0">{formState.youtubeUrl}</p>}>
                  <Button
                    variant="link"
                    className="bg-primary border-primary rounded-media-button  me-3 mb-3"
                  >
                    <img height="25" src="/youtube.svg" alt="youtube logo" />
                  </Button>
                </Tooltip>
              )}

              {formState.twitterUrl && !formState.twitterUrlAdded && (
                <Form.Group controlId="twitterUrl" className="mb-3 d-flex">
                  <InputGroup className="me-3">
                    <InputGroup.Text className="w-25 ">
                      <img
                        height="25"
                        src="/twitter-x.svg"
                        alt="facebook logo"
                      />{' '}
                      &nbsp; X:
                    </InputGroup.Text>
                    <Form.Control
                      className="h-40"
                      value={formState.twitterUrl}
                      onChange={handleChange}
                      aria-describedby="twitter"
                    />
                  </InputGroup>
                  <Button
                    onClick={() => updateState({ twitterUrlAdded: true })}
                    variant="outlined"
                    className="border border-dark"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>

                  <Button
                    onClick={() =>
                      updateState({ twitterUrl: '', twitterUrlAdded: false })
                    }
                    variant="outlined"
                    className="border border-dark ms-2"
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                </Form.Group>
              )}

              {formState.facebook && !formState.facebookAdded && (
                <Form.Group controlId="facebook" className="mb-3 d-flex">
                  <InputGroup className="me-3">
                    <InputGroup.Text className="w-25">
                      {' '}
                      <img src="/facebook.svg" alt="facebook logo" /> &nbsp;
                      Facebook:{' '}
                    </InputGroup.Text>
                    <Form.Control
                      className="h-40"
                      value={formState.facebook}
                      onChange={handleChange}
                      aria-describedby="facebook"
                    />
                  </InputGroup>
                  <Button
                    onClick={() => updateState({ facebookAdded: true })}
                    variant="outlined"
                    className="border border-dark"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      updateState({ facebook: '', facebookAdded: false })
                    }
                    variant="outlined"
                    className="border border-dark ms-2"
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                </Form.Group>
              )}

              {formState.instagram && !formState.instagramAdded && (
                <Form.Group controlId="instagram" className="mb-3 d-flex">
                  <InputGroup className="me-3">
                    <InputGroup.Text className="w-25">
                      <img src="/instagram.svg" alt="instagram logo" /> &nbsp;
                      Instagram:
                    </InputGroup.Text>
                    <Form.Control
                      className="h-40"
                      value={formState.instagram}
                      onChange={handleChange}
                      aria-describedby="Instagram"
                    />
                  </InputGroup>
                  <Button
                    onClick={() => updateState({ instagramAdded: true })}
                    variant="outlined"
                    className="border border-dark"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      updateState({ instagram: '', instagramAdded: false })
                    }
                    variant="outlined"
                    className="border border-dark ms-2"
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                </Form.Group>
              )}

              {formState.Tiktok && !formState.tiktokAdded && (
                <Form.Group controlId="Tiktok" className="mb-3 d-flex">
                  <InputGroup className="me-3">
                    <InputGroup.Text className="w-25">
                      {' '}
                      <img src="/tiktok.svg" alt="youtube logo" /> &nbsp; Tik
                      Tok:{' '}
                    </InputGroup.Text>
                    <Form.Control
                      className="h-40"
                      value={formState.Tiktok}
                      onChange={handleChange}
                      aria-describedby="TikTok"
                    />
                  </InputGroup>
                  <Button
                    onClick={() => updateState({ tiktokAdded: true })}
                    variant="outlined"
                    className="border border-dark"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      updateState({ Tiktok: '', tiktokAdded: false })
                    }
                    variant="outlined"
                    className="border border-dark ms-2"
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                </Form.Group>
              )}

              {formState.youtubeUrl && !formState.youtubeUrlAdded && (
                <Form.Group controlId="Youtube" className="mb-3 d-flex">
                  <InputGroup className="me-3">
                    <InputGroup.Text className="w-25">
                      {' '}
                      <img
                        height="25"
                        src="/youtube.svg"
                        alt="youtube logo"
                      />{' '}
                      &nbsp; Youtube:{' '}
                    </InputGroup.Text>
                    <Form.Control
                      className="h-40"
                      value={formState.youtubeUrl}
                      onChange={handleChange}
                      aria-describedby="TikTok"
                    />
                  </InputGroup>
                  <Button
                    onClick={() => updateState({ youtubeUrlAdded: true })}
                    variant="outlined"
                    className="border border-dark"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      updateState({ youtubeUrl: '', youtubeUrlAdded: false })
                    }
                    variant="outlined"
                    className="border border-dark ms-2"
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="ms-auto"
                      size="xl"
                    />
                  </Button>
                </Form.Group>
              )}

              <Form.Group>
                <Dropdown>
                  <DropdownToggle className="w-100 d-flex align-items-center no-caret">
                    <div>Add More Social Media</div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-auto" />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {!formState.twitterUrl && (
                      <Dropdown.Item>Twitter X</Dropdown.Item>
                    )}
                    {!formState.facebook && (
                      <Dropdown.Item>Facebook</Dropdown.Item>
                    )}
                    {!formState.instagram && (
                      <Dropdown.Item>Instagram</Dropdown.Item>
                    )}
                    {!formState.Tiktok && <Dropdown.Item>Tiktok</Dropdown.Item>}
                    {!formState.youtubeUrl && (
                      <Dropdown.Item>Youtube</Dropdown.Item>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex my-5">
            <Button
              as={Link}
              to={`/promoter-panel/events/create-event/${EVENT_STEPS.BASIC_DETAILS}/${context.eventId}`}
              variant="outlined"
              className="ms-auto me-3 border border-dark btn-create-event"
            >
              Back
            </Button>

            <Button
              type="submit"
              variant="success"
              className="text-white btn-create-event"
            >
              {isSaving && (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Saving...</span>
                </>
              )}
              {!isSaving && <span>Save and Continue</span>}
            </Button>
          </div>
        </Form>
      </Col>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </Row>
  );
};

export default Details;
