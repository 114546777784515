/**
 * Main application component that renders the entire application.
 *
 * @return {JSX.Element} The main application component.
 */
import './App.scss';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Header from './Header/Header';
import Main from './Main/Main';
import PreviewEvent from './PromoterPanel/Events/CreateEvent/PreviewEvent/PreviewEvent';
import { ACCESS_TOKEN, LOGGED_IN_USER } from './constants';
import Admin from './Admin/Admin';
import EventManagement from './Admin/EventManagement/EventManagement';
import OrderManagement from './Admin/OrderManagement/OrderManagement';
import PatronManagement from './Admin/PatronManagement/PatronManagement';
import UserManagement from './Admin/UserManagement/UserManagement';
import Error from './shared/Error';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import { ProvideAuth } from './hooks/useAuth';
// import InPerson from './PromoterPanel/Events/CreateEvent/InPerson/InPerson.jsx'

// Constant 'storageUtils' store the local storage utilities.
const storageUtils = require('./utils/LocalStorageUtils');

/**
 * Main application component.
 */
function App() {
  // // const [loading, setLoading] = useState(false);
  // const [authenticated, setauthenticated] = useState(false);
  // const [currentuser, setcurrentuser] = useState(null);

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  /**
   * Function to load the currently logged in user from the local storage.
   */
  const loadCurrentlyLoggedInUser = () => {
    let user = localStorage.getItem(LOGGED_IN_USER);
    if (user) {
      user = JSON.parse(user);
    }

    // setLoading(true);
    // let token = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
    // let user = JSON.parse(localStorage.getItem(LOGGED_IN_USER));
    // let token = storageUtils.getItem(ACCESS_TOKEN);
    // let user = storageUtils.getItem(LOGGED_IN_USER);

    // if(token){
    //   setcurrentuser(user);
    //   setauthenticated(true);
    // }
  };

  /**
   * Function to handle the logout event.
   */
  const handleLogout = () => {
    storageUtils.removeItem(ACCESS_TOKEN);
    storageUtils.removeItem(LOGGED_IN_USER);
    // setcurrentuser(null);
    // setauthenticated(false);
    console.log("You're safely logged out!");
  };

  return (
    <ProvideAuth>
      <div className="d-flex flex-column h-100 overflow-auto">
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <>
                  <Header handleLogout={handleLogout} />
                  <ErrorHandler>
                    <Routes>
                      <Route path="admin" element={<Admin />}>
                        <Route
                          exact
                          path=""
                          element={
                            <Navigate to="manage-users" replace={true} />
                          }
                        />
                        <Route
                          path="manage-users"
                          element={<UserManagement />}
                        />
                        <Route
                          path="manage-events"
                          element={<EventManagement />}
                        />
                        <Route
                          path="manage-orders"
                          element={<OrderManagement />}
                        />
                        <Route
                          path="manage-patrons"
                          element={<PatronManagement />}
                        />
                      </Route>
                      <Route path="/*" element={<Main />} />
                    </Routes>
                  </ErrorHandler>
                </>
              }
            />
            <Route
              path={`/preview-event/:eventId`}
              element={<PreviewEvent />}
            />
            {/* <Route path={`/test`} element={<InPerson/>} /> */}
          </Routes>
        </Router>
      </div>
    </ProvideAuth>
  );
}

export default App;
