// Promoter panel component just show promoter details like stripe account etc..
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Nav,
  Modal,
  Button,
  Spinner,
} from 'react-bootstrap';
import {
  faBook,
  faBullhorn,
  faCalendarDay,
  faClipboardList,
  faHome,
  faQuestionCircle,
  faStairs,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserDetails, updateDefaultStripe } from '../utils/APIUtils';
import { useAsync } from '../hooks/useAsync';
import { useNavigate, useLocation } from 'react-router-dom';
import ToastMessage from '../shared/ToastMessage';

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
// }

/**
 * @return {React.Element} The PromoterPanel component.
 */
const PromoterPanel = () => {
  // useLayoutEffect(() => {
  //     document.body.classList.add('theme-light')

  //     return () => {
  //         document.body.classList.remove('theme-light')
  //     }
  // }, [])

  const [showStripeModal, setShowStripeModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const { data, loading, error, run } = useAsync(null);

  const location = useLocation();
  const nestedPath =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  const [activeTab, setActiveTab] = useState(nestedPath);

  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  const navigate = useNavigate();

  /**
   * Handles create event button click. If user has not setup stripe and
   * has not opted for default setting, then show stripe modal else navigate
   * to create event page.
   */
  const createEventClickHandler = () => {
    const { stripeAccountActivated, enableStripeDefaultAccount } = userDetails;

    // if user has not setup stripe and not opted for default setting, then show modal
    if (!stripeAccountActivated && !enableStripeDefaultAccount) {
      setShowStripeModal(true);
    } else {
      navigate('/promoter-panel/events/create-event');
    }
  };

  /**
   * Handles click on enable default stripe account checkbox.
   */
  const defaultAccountClickHandler = () => {
    updateDefaultStripe(true)
      .then((res) => {
        console.log(res);
        setUserDetails({
          ...userDetails,
          enableStripeDefaultAccount: true,
        });
      })
      .catch((e) => {
        console.log(e);
        let msg = e.response.data.error.message || e.response.data.error;
        setToast({ show: true, msg: msg, success: false });
      });
  };

  const hideStripeModal = () => setShowStripeModal(false);

  /**
   * useEffect hook to fetch user details on component mount.
   *
   * @return {void}
   */

  useEffect(() => {
    run(() => getUserDetails()).then((res) => {
      const userDetails = res.data;
      setUserDetails(userDetails);
    });
  }, []);

  /**
   * If loading is true, render loading spinner.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="h-100">
      <Container fluid className="d-flex h-100">
        <Row className="flex-grow-1 flex-nowrap">
          <Col className="p-0 d-flex flex-grow-0 sidebar-menu-fixed">
            <Nav
              onSelect={(selectedKey) => setActiveTab(selectedKey)}
              activeKey={activeTab}
              className="flex-column vertical-nav-tabs flex-nowrap"
            >
              <Nav.Link
                as={Link}
                to="/promoter-panel/home"
                eventKey="home"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faHome} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">Home</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/promoter-panel/events"
                eventKey="events"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">Events</span>
              </Nav.Link>
              <Nav.Link eventKey="orders" className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">Orders</span>
              </Nav.Link>
              <Nav.Link
                eventKey="my-page"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faUser} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">My Page</span>
              </Nav.Link>
              <Nav.Link
                eventKey="marketing"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faBullhorn} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">Marketing</span>
              </Nav.Link>
              <Nav.Link
                eventKey="learning"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faBook} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">Learning</span>
              </Nav.Link>
              <Nav.Link
                eventKey="reports"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faStairs} className="me-1 fs-sm-1" />
                <span className="ms-2 d-none d-md-block">Reports</span>
              </Nav.Link>
              <Nav.Link
                eventKey="reports"
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">Dispute</span>
              </Nav.Link>
              <Nav.Link
                eventKey="faq"
                className="mt-auto d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="me-1 fs-sm-1"
                  size="lg"
                />
                <span className="ms-2 d-none d-md-block">FAQ</span>
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="flex-grow-1 sidebar-menu-content">
            <Outlet context={[userDetails, createEventClickHandler]} />
          </Col>
        </Row>
      </Container>
      <Modal
        size="sm"
        show={showStripeModal}
        onHide={() => {
          hideStripeModal(false);
        }}
        centered
      >
        <Modal.Body
          className="text-center bg-dark p-5"
          style={{ width: '400px' }}
        >
          <h5 className="text-primary">To create an event you need to</h5>
          <h4 className="text-primary">Log in to your Stripe account</h4>

          <Button
            target="_blank"
            href={userDetails.stripeAccountUrl}
            variant="link"
            className="ms-auto text-white px-4 btn-block my-4 btn-primary text-decoration-none border-primary"
          >
            <div className="d-flex">
              <h6 className="mt-1 me-1">Link to your </h6>
              <img src="/stripe_logo.png" alt="facebook logo" />
            </div>
          </Button>

          <h5 className="text-primary">I don't have A Stripe Account!</h5>
          <h6 className="text-white">That’s ok, EventLinx has you covered</h6>

          <Button
            variant="outlined"
            onClick={() => defaultAccountClickHandler()}
            className=" ms-auto me-3 border border-dark rounded bg-white rounded px-4 btn-block mt-4 mb-3"
          >
            <h6 className="mt-1">Use TicketWindow Stripe Account</h6>
          </Button>

          <div className="text-white text-start">
            <small>
              We will collect funds on your behalf and release any ticket sales
              after the event is over. It only takes 5 business days to
              reconcile.
            </small>
          </div>
        </Modal.Body>
      </Modal>

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default PromoterPanel;
