import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import Error from '../shared/Error';
import { useAuth } from '../hooks/useAuth';

// React functional component used to handle the errors.
/**
 * A React functional component used to handle the errors.
 * If the errorStatusCode property exists in the location.state object,
 * it will be used to handle the error.
 * If the errorStatusCode is 401, it will log out the user and navigate to /login.
 *
 * @param {Object} props The props object.
 * @param {JSX.Element} props.children The JSX element to render if no error occurred.
 *
 * @return {JSX.Element} The JSX element to render.
 */
const ErrorHandler = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  switch (get(location.state, 'errorStatusCode')) {
    case 401:
      auth.logout();
      navigate('/login');
      return children;

    case 404:
      return <Error />;

    case 403:
      return <Error code={403} message="Unauthorized Access" />;

    case 500:
      return <Error code={500} message="Internal Server Error" />;

    default:
      return children;
  }
};

export default ErrorHandler;
