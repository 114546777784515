import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

const Events = () => {
  const [userDetails, createEventClickHandler] = useOutletContext();

  return <Outlet context={[userDetails, createEventClickHandler]} />;
};

export default Events;
