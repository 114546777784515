import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

/**
 * Renders a section about the company Ticket Window Inc.
 * @returns {JSX.Element} The About component
 */
const About = () => {
  return (
    <div>
      <div className="p-5 col-12 col-lg-10 mx-auto mb-5">
        <h3 className="mb-5 text-center">About us</h3>
        <h5>Who is Ticketwindow Inc?</h5>
        <p className="mb-3">
          Ticketwindow founded in 2004 and operates its software support in
          London Ontario Canada as International Company providing cutting edge
          box office ticketing software and event support services to a variety
          of soft seat performing arts theaters, indoor concerts, festivals and
          big ticket events in Canada and the US.
        </p>
        <h5>A New Era in Streaming</h5>
        <p className="mb-3">
          Virtual event production is still in its infant stage but advancing
          quickly. Most streaming platforms provide basically no functionality
          when viewing a stream but does allows fans to enjoy a concert in the
          comfort of their home. The [UX] yoU eXperience module takes a few
          steps forward using the innovative technology with creative thinking
          to provide an interactive stream controller for fans to choose when
          they want any camera to view during a performance on their own PC, IOS
          or Android Device.
        </p>
        <p className="mb-3">
          For Creators, this technology opens many opportunities to help with
          audience/fan retention, and an ideal way to monetize an event and
          increase the bottom line long after a performance is over.
        </p>

        <Row className="mt-5 pt-5">
          <Col xs="12" md="7">
            <img className="img-fluid" src="/testimonial.jpeg" />
          </Col>
          <Col
            xs="12"
            md="5"
            className="d-flex flex-column justify-content-center"
          >
            <h3 className="p-5">
              {/** This is a space for a short testimonial of how TicketwindowUX
              help folks to start creating their own events */}
              "This is a space for a short testimonial of how TicketwindowUX
              help folks to start creating their own events"
            </h3>
            <div className="px-5">
              <Button
                as={Link}
                to="/"
                variant="primary"
                className="py-2 px-4 text-white mt-4 "
              >
                <span className="h5">Get Started</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default About;
