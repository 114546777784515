import React from 'react';

/**
 * React functional component renders and html elements for terms and conditions modal for ticket window Inc..
 *
 * @function
 * @returns {ReactElement} A React element representing the terms and conditions modal.
 */
const TermsAndConditions = () => {
  return (
    <div>
      <h3 className="mb-4 text-center">Terms and Conditions</h3>
      <p>
        Thank you for purchasing from Ticketwindow Inc. Please note the
        following Terms Of Use.
      </p>
      <p>
        1. All sales are final. No request for cancellations, changes, or
        refunds will be accepted, unless otherwise stated by a Ticketwindow Inc.
        representative.{' '}
      </p>
      <p>
        2. If your tickets are to be mailed, you should receive them within a
        reasonable amount of time, generally 7 to 10 days. If you do not, please
        contact Ticketwindow Inc. - no later than by 5pm on the day of for a
        weekday performance or 4pm on a Saturday for a Saturday or Sunday
        performance to ensure proper Ticketwindow Inc. attention.
      </p>
      <p>
        3. For in person events, the box office is usually open no later than 60
        minutes before performance time. It would be helpful if you can arrive
        early to allow for parking and to avoid the last minute rush.
      </p>
      <p>
        4. You must have the credit card you used for the purchase with you for
        identification. If someone else is picking up the tickets, either that
        same credit card or a photocopy of it with an authorization note from
        you must be presented. It would also be helpful to have with you a copy
        of your order confirmation and the confirmation number.{' '}
      </p>
      <p>
        5. In the event that a performance is either cancelled or postponed,
        Ticketwindow Inc. will make every effort to contact every patron via
        e-mail, time permitting.
      </p>
      <p>
        6. While Ticketwindow Inc. regrets the inconvenience caused by these
        circumstances, Ticketwindow Inc. cannot be held responsible for any cost
        or damage such postponement or cancellation may incur. Similarly, the
        conduct of events and performances for tickets purchased is the sole
        responsibility of the events’ organizers and venue operators,
        Ticketwindow Inc. cannot be held responsible or liable for injuries,
        loss or damages incurred by patrons while attending these events.{' '}
      </p>
      <p>
        7. All patrons purchasing from Ticketwindow Inc. must do so in the light
        of the above limitations.
      </p>
      <p>
        8. Refunds- In all instances when a ticket is refunded, only the cost of
        the ticket is refunded. All service fees paid are not refundable, as the
        services for those fees paid have been rendered.
      </p>
    </div>
  );
};

export default TermsAndConditions;
