import React, { useState, useEffect } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronDown,
  faExclamationCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Form,
  Row,
  Col,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import validateEventForm from '../eventFormValidator';
import { getLocationTypes, getEventDetails } from '../../../../utils/APIUtils';
import { formatDate } from '../../../../utils/CommonUtils';
import { setItem } from '../../../../utils/LocalStorageUtils';
import moment from 'moment';
import { EVENT_STEPS } from '../../../../constants';
import { useParams } from 'react-router-dom';
import InPerson from '../InPerson/InPerson';
import Streaming from '../Streaming/Streaming';

/**
 * Higher order component to make choice for streaming or in-person.
 * In future if another type of event just add there choice and you can route to that component.
 *
 * @function EventChoice
 * @returns {ReactElement} The component.
 */
const EventChoice = () => {
  /**
   * The id of the event to be created.
   * @type {string}
   */
  const { eventId } = useParams();
  /**
   * The type of event to be created (in-person or streaming).
   * @type {string}
   */
  const [eventType, setEventType] = useState('');
  /**
   * The event details from the API.
   * @type {Object}
   */
  const [eventDetails, setEventDetails] = useState(null);
  /**
   * The location types for the event.
   * @type {Array}
   */
  const [locationTypes, setLocationTypes] = useState(null);
  /**
   * If the component has been initialized.
   * @type {boolean}
   */
  const [initialized, setInitialized] = useState(false);
  /**
   * The navigate function to navigate through the app.
   * @type {function}
   */
  const navigate = useNavigate();
  /**
   * Effect to initialize the component.
   * @function useEffect
   * @param {function} effect - The effect function to run.
   * @param {Array} dependencies - The dependencies of the effect.
   */
  useEffect(() => {
    console.log('EventChoice called');
    if (!initialized) {
      /**
       * Fetches the location types and event details from the API.
       *
       * @return {Promise<void>} - A promise that resolves when both requests are complete.
       */
      Promise.all([getLocationTypes(), getEventDetails(eventId)])
        .then((values) => {
          console.log(eventId);
          const locationData = values[0].data;
          const eventDetailsData = values[1].data;
          /**
           * Sets the location types and event details from the API.
           * Not necessaraly needed, it was created for increased robustness
           *
           * @param {Array} locationData - The location types data from the API.
           * @param {Object} eventDetailsData - The event details data from the API.
           * @return {void}
           */
          setLocationTypes(locationData);
          setEventDetails(eventDetailsData);
          setInitialized(true);
          // console.log(eventDetailsData.locationTypeModel.id)
          /**
           * If the event is an in-person event, navigate to the in-person event step in the event creation sequence.
           * @param {number} eventId - The id of the event.
           */
          if (eventDetailsData.locationTypeModel.id == 1) {
            console.log('event is InPerson');
            navigate(
              `/promoter-panel/events/create-event/IN_PERSON_DETAILS/${eventId}`
            );
            /**
             * Set the event type to 'in-person'.
             * @type {string}
             */
            setEventType('in-person');
          } else {
          /**
           * If the event is not an in-person event, navigate to the streaming page.
           * @param {number} eventId - The id of the event.
           */
            console.log('evnent is other');
            navigate(
              `/promoter-panel/events/create-event/STREAMING_DETAILS/${eventId}`
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  // To test if the component is actually working or not, can be removed though
  return (
    <div>
      <h1>Test</h1>
    </div>
  );
};

export default EventChoice;
