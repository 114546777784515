// Streaming tickets sections
// Pretty much everything is similar to in-person ticket section
// only difference no sections and ticket types here.. One ticket for all..
import React, { useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  faCalendarDays,
  faCirclePlus,
  faDollarSign,
  faExternalLinkAlt,
  faPencil,
  faPercent,
  faTrashCan,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Button,
  InputGroup,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Badge,
} from 'react-bootstrap';
import {
  EVENT_STEPS,
  TICKET_LOCATION_TYPE,
  TICKET_TYPE,
} from '../../../../constants';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import moment from 'moment';
import { formatDate } from '../../../../utils/CommonUtils';
import validateEventForm from '../eventFormValidator';
import validateCouponDetails from './couponValidator';
import { createEvent } from '../../../../utils/APIUtils';
import Modal from 'react-bootstrap/Modal';
import ToastMessage from '../../../../shared/ToastMessage';

/**
 * Enumeration representing the frequency of billing.
 * @readonly
 * @enum {string}
 */
export const BILL_FREQUENCY = Object.freeze({
  /**
   * Billing is done once.
   */
  ONCE: 'ONCE',
  /**
   * Recurring billing is done.
   */
  RECURRING: 'RECURRING',
});

/**
 * Enumeration representing the period of billing.
 * @readonly
 * @enum {string}
 */
export const BILL_PERIOD = Object.freeze({
  /**
   * Billing is done per day.
   */
  DAY: 'DAY',
  /**
   * Billing is done per week.
   */
  WEEK: 'WEEK',
  /**
   * Billing is done per month.
   */
  MONTH: 'MONTH',
  /**
   * Billing is done per year.
   */
  YEAR: 'YEAR',
});

/**
 * Enumeration representing the type of coupon.
 * @readonly
 * @enum {string}
 */
export const COUPON_TYPE = Object.freeze({
  /**
   * Coupon is of fixed amount.
   */
  FIXED: 'FIXED',
  /**
   * Coupon is a percentage discount.
   */
  PERCENTAGE: 'PERCENTAGE',
});

const Tickets = () => {
  /**
   * Initialize context
   * @type {Object}
   */
  const [context, setContext] = useOutletContext();
  /**
   * Initialize DTO, we do it here so we can fetch the already present Data when the page re-rendered after moving back and forth from other steps in create event sequence..
   * @type {Object}
   */
  let { ticketDetailsDto } = context;

  // only one category for now, other is PKG_PRICE
  ticketDetailsDto.ticketCategory = 'STD_PRICE';
  ticketDetailsDto.eventTicketCouponsDtoList =
    ticketDetailsDto.eventTicketCouponsDtoList || [];

  // set initialized list as initial form state
  /**
   * Form state
   * @type {Object}
   */
  const [formState, setFormState] = useState(ticketDetailsDto);
  /**
   * Is saving
   * @type {boolean}
   */
  const [isSaving, setIsSaving] = useState(false);

  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  const [show, setShow] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [locationType, setLocationType] = useState(
    TICKET_LOCATION_TYPE.LIVESTREAM
  );

  const [currentCoupon, setCurrentCoupon] = useState(null);

  /**
   * Closes the modal
   * @returns {void}
   */
  const handleClose = () => setShow(false);

  /**
   * Shows the modal
   * @returns {void}
   */
  const handleShow = () => setShow(true);

  /**
   * Updates the form state with the provided state object
   * @param {Object} state - The state object to merge with the form state
   * @returns {void}
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      console.log({ ...prevState, ...state });
      // eslint-disable-next-line no-console
      return { ...prevState, ...state };
    });

  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  /**
   * Checks if two dates are the same day.
   *
   * @param {Date} d1 - The first date.
   * @param {Date} d2 - The second date.
   * @returns {boolean} true if the two dates are the same day, false otherwise.
   */
  const isDatesSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  /**
   * Sets the minimum time for the event end time to prevent the user from
   * selecting an end time before the start time.
   */
  const getMinTime = () => {
    let sDate = formState.salesStartDate,
      eDate = formState.salesEndDate,
      sTime = formState.salesStartTime;
    let date =
      sTime &&
      sDate &&
      sTime &&
      isDatesSameDay(new Date(sDate), new Date(eDate))
        ? new Date(sTime)
        : setHours(setMinutes(new Date(), 0), 0);
    return date;
  };

  /**
   * Returns the maximum time for the sales start time.
   *
   * Prevents the user from selecting a start time before the end time.
   *
   * @returns {Date} The maximum time for the sales start time.
   */
  const getMaxTime = () => {
    let date = formState.salesEndTime
      ? new Date(formState.salesEndTime)
      : setHours(setMinutes(new Date(), 45), 23);
    return date;
  };

  /**
   * Handles the change event for form input elements.
   *
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleChange = (e) => {
    let state = {};
    const { name, id, value, checked, type } = e.target;
    if (type == 'radio') {
      state[name] = value === 'true' ? true : false;
    } else {
      state[id] = value;
    }

    updateState(state);
  };

  /**
   * Validates the form state.
   *
   * @returns {boolean} True if the form state is valid, false otherwise.
   */
  const validate = () => {
    let error = validateEventForm(formState, 'TICKET_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };

  /**
   * Handles the form submission.
   *
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      setIsSaving(true);
      let data = JSON.parse(JSON.stringify(formState)); // clone obj and modify
      // modify time in format req by API i.e. hh:mm AM
      data.salesStartTime = moment(formState.salesStartTime).format('hh:mm A');
      data.salesEndTime = moment(formState.salesEndTime).format('hh:mm A');
      createEvent('TICKET_DETAILS', data, context.eventId)
        .then((res) => {
          setContext({
            ...context,
            ticketDetailsDto: {
              ...formState,
            },
          });
          setToast({ show: true, msg: 'Saved Successfully!', success: true });
          setIsSaving(false);
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.FINAL_DETAILS}/${context.eventId}`
          );
          console.log(res);
        })
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          console.log(err);
          setIsSaving(false);
        });
    }
  };

  /**
   * Generates a new coupon DTO with default values.
   * @returns {Object} - New coupon DTO.
   */
  const getNewCouponDTO = () => {
    return {
      couponType: '', //coupon type
      couponDiscount: null, //coupon discount
      couponName: '', //coupon name
      couponCode: '', //coupon code
      includeTaxInPrice: false, //include tax in price
      validTillDate: '', //valid till date
      limitToFirstTimeUsers: false, //limit to first time users
      limitToSpecificUser: false, //limit to specific user
      specificUserEmail: '', //specific user email
      limitUsageCount: false, //limit usage count
      usageCount: null, //usage count
      limitToOneUsePerUser: false, //limit to one use per user
      couponId: '', //coupon id
      isNewCoupon: true, //is new coupon
    };
  };

  /**
   * Generates a new date object from the given date and time strings.
   * @param {string} d - The date string in the format 'YYYY-MM-DD'.
   * @param {string} t - The time string in the format 'hh:mm A'.
   * @returns {Date} - The generated date object.
   */
  const getDateObject = (d, t) => {
    if (!d || !t) {
      return new Date();
    }
    return new Date(d + ' ' + t);
  };

  const getMinEndDate = () => {
    /**
     * If end time is before start time,
     * prevent user to select same day for start and end date.
     */
    if (
      formState.salesStartTime &&
      formState.salesEndTime &&
      formState.salesEndTime < formState.salesStartTime
    ) {
      return moment(formState.salesStartDate, 'YYYY-MM-DD')
        .add(1, 'd')
        .toDate();
    }
    return moment(formState.salesStartDate).toDate();
  };

  /**
   * Retrieves a coupon object from the form state based on the given index,
   * or generates a new coupon object if no index is provided.
   *
   * @param {number|undefined} index - The index of the coupon object in
   * the form state's `eventTicketCouponsDtoList` array.
   * @return {void} This function does not return anything.
   */
  const getCoupon = (index) => {
    let coupon; // variable to hold coupon object

    if (index) {
      coupon = formState.eventTicketCouponsDtoList[index];
    } else {
      coupon = getNewCouponDTO();
    }

    setCurrentCoupon(coupon);
    setShowCoupon(true);
  };

  /**
   * Validates the coupon and shows any errors if something is not correct.
   *
   * @param {Object} coupon - The coupon object to validate.
   * @param {Array} couponList - The list of coupons.
   * @return {boolean} Returns true if the coupon is valid, false otherwise.
   */
  const validateCoupon = (coupon, couponList) => {
    let error = validateCouponDetails(coupon, couponList);
    setErrors(error);
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  };

  /**
   * Creates a new coupon or updates an existing one.
   *
   * @param {Object} coupon - The coupon object to create or update.
   * @return {void} This function does not return anything.
   */
  const createCoupon = (coupon) => {
    console.log(coupon);
    let ticketDTO = { ...formState };
    let isCouponValid = validateCoupon(
      coupon,
      ticketDTO.eventTicketCouponsDtoList
    );

    if (isCouponValid) {
      if (coupon.isNewCoupon) {
        coupon.isNewCoupon = false;
        ticketDTO.eventTicketCouponsDtoList.push(coupon);
      } else {
        let index = ticketDTO.eventTicketCouponsDtoList.findIndex(
          (c) => c.couponCode == coupon.couponCode
        );
        ticketDTO.eventTicketCouponsDtoList[index] = coupon;
      }

      setFormState(ticketDTO);
      setShowCoupon(false);
    }
  };

  /**
   * Deletes a coupon.
   * @param {number} index - The index of the coupon to delete.
   * @return {void} This function does not return anything.
   */
  const deleteCoupon = (index) => {
    let couponList = [...formState.eventTicketCouponsDtoList];

    couponList.splice(index, 1);
    setFormState({ ...formState, eventTicketCouponsDtoList: couponList });
  };

  /**
   * Edits a coupon.
   * @param {number} index - The index of the coupon to edit.
   * @return {void} This function does not return anything.
   */
  const editCoupon = (index) => {
    let couponList = [...formState.eventTicketCouponsDtoList];

    let currentCoupon = couponList[index];
    setCurrentCoupon(currentCoupon);
    setShowCoupon(true);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <h2 className="fw-bold mb-4">Add tickets</h2>
            <h5>Select the type of admission</h5>
            <ButtonToolbar aria-label="Location Type Button Group">
              <ButtonGroup size="lg" aria-label="First group">
                <Button
                  onClick={() => updateState({ ticketType: TICKET_TYPE.PAID })}
                  className={`px-5 ${
                    formState.ticketType == TICKET_TYPE.PAID ? 'active' : ''
                  }`}
                >
                  Paid
                </Button>
              </ButtonGroup>
              <ButtonGroup size="lg" className="mx-3" aria-label="Second group">
                <Button
                  onClick={() => updateState({ ticketType: TICKET_TYPE.FREE })}
                  className={`px-5 ${
                    formState.ticketType == TICKET_TYPE.FREE ? 'active' : ''
                  }`}
                >
                  Free
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            {errors && errors.ticketType && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.ticketType}
              </Form.Text>
            )}

            <Form>
              <Row className="mb-4">
                <Col xs={12} lg={8}>
                  <h5 className="mt-4 fw-bold mb-3">Standard Price</h5>
                  {/* <Form.Group controlId="priceCategory" className="my-3"> 
                                            <Form.Label className="required">Price Category</Form.Label>
                                            <Form.Select 
                                                onChange={handleChange} 
                                                value={formState.priceCategory}
                                            >
                                                <option value="">Select</option>
                                                <option value="PACKAGE_PRICE">Package Price</option>
                                            </Form.Select>
                                        </Form.Group> */}

                  <Form.Group controlId="price" className="mb-3 col-8">
                    <Form.Label className="required">Price</Form.Label>
                    <div className="mb-3 d-flex">
                      <InputGroup.Text className="me-2 bg-gray fw-bold">
                        CA $
                      </InputGroup.Text>
                      <InputGroup>
                        <Form.Control
                          value={formState.price}
                          disabled={
                            formState.ticketType &&
                            formState.ticketType == TICKET_TYPE.FREE
                          }
                          onChange={handleChange}
                          aria-describedby="twitter"
                        />
                        <InputGroup.Text className="w-25 justify-content-center bg-white">
                          CAD
                        </InputGroup.Text>
                      </InputGroup>
                    </div>

                    {/* <div className='d-flex'>
                                                <InputGroup.Text className='me-2 bg-gray fw-bold px-3'>PER</InputGroup.Text>
                                                <InputGroup>
                                                    <Form.Control
                                                        value={formState.price}
                                                        disabled={formState.ticketType && formState.ticketType == TICKET_TYPE.FREE}
                                                        onChange={handleChange}  
                                                        aria-describedby="twitter" 
                                                    />
                                                    <InputGroup.Text className="w-25 justify-content-center bg-white"> 
                                                        Tickets 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </div> */}

                    {/* <Form.Control value={formState.price}
                                                disabled={formState.ticketType && formState.ticketType == TICKET_TYPE.FREE}
                                                onChange={handleChange}   type="text" placeholder="Search" />  */}
                    {/* <Form.Text>Disc price, ref 10%, 20%</Form.Text> */}
                    {errors && errors.price && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.price}
                      </Form.Text>
                    )}
                    <div className="d-flex">
                      <Button
                        onClick={handleShow}
                        variant="link"
                        className="ms-auto text-secondary text-decoration-none"
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          className="me-2"
                        />
                        How charges work?
                      </Button>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <span className="me-2">
                      Are you charging tax to this price?
                    </span>
                    <Form.Check
                      inline
                      label="Yes"
                      name="chargeTax"
                      type="radio"
                      id="taxRadioYes"
                      value={true}
                      checked={formState.chargeTax}
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="chargeTax"
                      type="radio"
                      id="taxRadioNo"
                      value={false}
                      onChange={handleChange}
                      checked={!formState.chargeTax}
                    />
                  </Form.Group>

                  <div className="d-flex">
                    {formState.chargeTax && (
                      <Form.Group
                        controlId="taxPercentage"
                        className="mb-3 me-4"
                      >
                        <Form.Label className="required">
                          Tax Percentage
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formState.taxPercentage}
                          onChange={handleChange}
                        />
                        {errors && errors.taxPercentage && (
                          <Form.Text className="text-danger">
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className="me-2"
                            />
                            {errors.taxPercentage}
                          </Form.Text>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group controlId="totalQuantity" className="mb-3">
                      <Form.Label className="required">
                        Available Quantity
                      </Form.Label>
                      <Form.Control
                        value={formState.totalQuantity}
                        onChange={handleChange}
                        type="text"
                      />
                      {errors && errors.totalQuantity && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors.totalQuantity}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                  <ButtonToolbar
                    className="my-4"
                    aria-label="Location Type Button Group"
                  >
                    <ButtonGroup size="lg" aria-label="First group">
                      <Button
                        onClick={() =>
                          setFormState({
                            ...formState,
                            billingFrequency: BILL_FREQUENCY.RECURRING,
                          })
                        }
                        className={`px-5 ${
                          formState.billingFrequency == BILL_FREQUENCY.RECURRING
                            ? 'active'
                            : ''
                        }`}
                        disabled={true}
                      >
                        Recurring
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup
                      size="lg"
                      className="mx-3"
                      aria-label="Second group"
                    >
                      <Button
                        onClick={() =>
                          setFormState({
                            ...formState,
                            billingFrequency: BILL_FREQUENCY.ONCE,
                          })
                        }
                        className={`px-5 ${
                          formState.billingFrequency == BILL_FREQUENCY.ONCE
                            ? 'active'
                            : ''
                        }`}
                        disabled={formState.ticketType == TICKET_TYPE.FREE}
                      >
                        One Time
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>

                  {formState.billingFrequency == BILL_FREQUENCY.RECURRING && (
                    <Form.Group controlId="billingPeriod" className="my-4">
                      <Form.Label className="required">
                        Billing Period
                      </Form.Label>
                      <Form.Select
                        onChange={handleChange}
                        value={formState.billingPeriod}
                      >
                        {Object.keys(BILL_PERIOD).map((bp, i) => {
                          return (
                            <option key={i} value={bp}>
                              {bp}
                            </option>
                          );
                        })}
                        {/* <option value="">Monthly</option>
                                                    <option value="YEARLY">Yearly</option>
                                                    <option value="BI_WEEKLY">Bi-Weekly</option>
                                                    <option value="WEEKLY">Weekly</option> */}
                      </Form.Select>
                    </Form.Group>
                  )}
                </Col>

                {/* <Col lg={1} xs={12} className="d-none d-lg-block border-end"></Col>
                                    <Col lg={1} xs={12} className="d-none d-lg-block"></Col>

                                    <Col xs={12} lg={5}>
                                        <h5 className="mt-4 fw-bold mb-3">Package Price</h5>

                                        <Form.Group controlId="price" className="mb-3"> 
                                            <Form.Label className="required">Price</Form.Label>
                                            <div className='mb-3 d-flex'>
                                                <InputGroup.Text className='me-2 bg-gray fw-bold'>CA $</InputGroup.Text>
                                                <InputGroup>
                                                    <Form.Control
                                                        value={formState.price}
                                                        disabled={formState.ticketType && formState.ticketType == TICKET_TYPE.FREE}
                                                        onChange={handleChange}  
                                                        aria-describedby="twitter" 
                                                    />
                                                    <InputGroup.Text className="w-25 justify-content-center bg-white"> 
                                                        CAD 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </div>

                                            <div className='d-flex'>
                                                <InputGroup.Text className='me-2 bg-gray fw-bold px-3'>PER</InputGroup.Text>
                                                <InputGroup>
                                                    <Form.Control
                                                        value={formState.price}
                                                        disabled={formState.ticketType && formState.ticketType == TICKET_TYPE.FREE}
                                                        onChange={handleChange}  
                                                        aria-describedby="twitter" 
                                                    />
                                                    <InputGroup.Text className="w-25 justify-content-center bg-white"> 
                                                        Tickets 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </div>
                                            
                                            {errors && errors.price &&
                                                <Form.Text className="text-danger">
                                                    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                    {errors.price}
                                                </Form.Text>
                                            }
                                            <div className="d-flex">
                                                <Button onClick={handleShow} variant="link" className="ms-auto text-secondary text-decoration-none">
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} className="me-2" />
                                                    How charges work?
                                                </Button>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <span className="me-2">Are you charging tax to this price?</span>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="chargeTax"
                                                type="radio"
                                                id="taxRadioYes"
                                                value={true}
                                                checked={formState.chargeTax}
                                                onChange={handleChange}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="chargeTax"
                                                type="radio"
                                                id="taxRadioNo"
                                                value={false}
                                                onChange={handleChange}
                                                checked={!formState.chargeTax}
                                            />
                                        </Form.Group>

                                        <div className="d-flex">
                                            <Form.Group controlId="taxPercentage" className="mb-3 me-4"> 
                                                <Form.Label className="required">Tax Percentage</Form.Label>
                                                <Form.Control type="text" value={formState.taxPercentage}
                                                    onChange={handleChange} /> 
                                                {errors && errors.taxPercentage &&
                                                    <Form.Text className="text-danger">
                                                        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                        {errors.taxPercentage}
                                                    </Form.Text>
                                                }
                                            </Form.Group>

                                            <Form.Group controlId="totalQuantity" className="mb-3"> 
                                                <Form.Label className="required">Available Quantity</Form.Label>
                                                <Form.Control value={formState.totalQuantity}
                                                    onChange={handleChange}  type="text"/> 
                                                {errors && errors.totalQuantity &&
                                                    <Form.Text className="text-danger">
                                                        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                        {errors.totalQuantity}
                                                    </Form.Text>
                                                }
                                            </Form.Group> 
                                        </div>

                                        <ButtonToolbar className="my-4" aria-label="Location Type Button Group">
                                            <ButtonGroup size="lg" aria-label="First group">
                                                <Button className={`px-5`}> Recurring </Button> 
                                            </ButtonGroup>
                                            <ButtonGroup size="lg" className="mx-3" aria-label="Second group">
                                                <Button className={`px-5`}> One Time </Button>
                                            </ButtonGroup> 
                                        </ButtonToolbar>

                                        <Form.Group controlId="billingPeriod" className="my-4"> 
                                            <Form.Label className="required">Billing Period</Form.Label>
                                            <Form.Select 
                                                onChange={handleChange} 
                                                value={formState.billingPeriod}
                                            >
                                                <option value="">Monthly</option>
                                                <option value="YEARLY">Yearly</option>
                                                <option value="BI_WEEKLY">Bi-Weekly</option>
                                                <option value="WEEKLY">Weekly</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                     */}
              </Row>

              <hr className="my-4" />

              <Row>
                <Col className="my-4">
                  {formState.eventTicketCouponsDtoList.map((coupon, i) => {
                    return (
                      <div
                        key={i}
                        className="border p-3 mb-3 me-3 border-dark d-inline-block"
                      >
                        <div className="d-flex">
                          <div className="me-5">
                            <h3>
                              {coupon.couponType == COUPON_TYPE.FIXED
                                ? `$${coupon.couponDiscount}`
                                : `${coupon.couponDiscount}%`}{' '}
                              OFF
                            </h3>
                            <h5>{coupon.couponCode}</h5>
                            <p className="mb-0">{coupon.couponName}</p>
                          </div>
                          <div className="d-flex flex-column ms-auto">
                            <Button
                              onClick={() => editCoupon(i)}
                              variant="link"
                              className="p-0 mb-2 text-secondary"
                            >
                              <FontAwesomeIcon
                                icon={faPencil}
                                className="me-2"
                              />
                            </Button>
                            <Button
                              onClick={() => deleteCoupon(i)}
                              variant="link"
                              className="p-0 text-danger"
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="me-2"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {!formState.eventTicketCouponsDtoList.length && (
                    <p>No Coupons added yet!</p>
                  )}

                  <Button
                    onClick={() => getCoupon()}
                    variant="primary"
                    disabled={formState.ticketType == TICKET_TYPE.FREE}
                    className="border-primaryDark text-white d-flex align-items-center"
                  >
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      className="me-2"
                      size="2x"
                    />
                    New Coupon
                  </Button>
                </Col>
              </Row>

              <hr className="my-4" />

              <Row>
                <Col xs={12} lg={5}>
                  <div className="d-flex align-items-center mb-3">
                    <Form.Group className="flex-grow-1 me-3">
                      <Form.Label className="required">
                        Sales Start Date:
                      </Form.Label>
                      {/* <Form.Control type="date" placeholder="Search" />  */}
                      <DatePicker
                        minDate={new Date()}
                        maxDate={
                          formState.salesEndDate &&
                          moment(formState.salesEndDate).toDate()
                        }
                        className="form-control w-20"
                        selected={
                          formState.salesStartDate &&
                          moment(formState.salesStartDate).toDate()
                        }
                        onChange={(date) =>
                          updateState({ salesStartDate: formatDate(date) })
                        }
                      />

                      {errors && errors.salesStartDate && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors.salesStartDate}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group className="flex-grow-1">
                      <Form.Label className="required">
                        Sales Start Time:
                      </Form.Label>
                      {/* <Form.Control type="time" placeholder="HH:MM:SS" />  */}

                      <DatePicker
                        className="form-control w-20"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minTime={setHours(setMinutes(new Date(), 0), 0)}
                        maxTime={getMaxTime()}
                        selected={
                          formState.salesStartTime &&
                          new Date(formState.salesStartTime)
                        }
                        onChange={(date) =>
                          updateState({ salesStartTime: date.getTime() })
                        }
                      />

                      {errors && errors.salesStartTime && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors.salesStartTime}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <Form.Group className="flex-grow-1 me-3">
                      <Form.Label className="required">
                        Sales End Date:
                      </Form.Label>
                      {/* <Form.Control type="date" placeholder="Search" />  */}
                      <DatePicker
                        minDate={getMinEndDate()}
                        className="form-control w-20"
                        selected={
                          formState.salesEndDate &&
                          moment(formState.salesEndDate).toDate()
                        }
                        onChange={(date) =>
                          updateState({ salesEndDate: formatDate(date) })
                        }
                      />

                      {errors && errors.salesEndDate && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors.salesEndDate}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group className="flex-grow-1">
                      <Form.Label className="required">
                        Sales End Time:
                      </Form.Label>
                      {/* <Form.Control type="time" placeholder="HH:MM:SS" />  */}

                      <DatePicker
                        className="form-control w-20"
                        minTime={getMinTime()}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={
                          formState.salesEndTime &&
                          new Date(parseInt(formState.salesEndTime))
                        }
                        onChange={(date) =>
                          updateState({ salesEndTime: date.getTime() })
                        }
                      />

                      {errors && errors.salesEndTime && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors.salesEndTime}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>

                  <div>
                    <Form.Group className="flex-grow-1 mb-3">
                      <Form.Label>Timezone:</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        value="(GMT-0400) Canada(Toronto) Time"
                      />
                      <Form.Text>
                        This is the timezone for the event set up in Step 1.
                      </Form.Text>
                    </Form.Group>
                  </div>

                  <Form.Check
                    type="checkbox"
                    name="showTicketDetailsAtCheckout"
                    label="Show ticket sale end dates and sate status at checkout"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        showTicketDetailsAtCheckout: e.target.checked,
                      })
                    }
                    checked={formState.showTicketDetailsAtCheckout}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <div className="d-flex my-4 pt-5">
            {/* <Button as={Link} to="/promoter-panel/events/create-event/publish"
                                variant="orange" className="text-white ms-auto px-4">Save and Continue</Button> */}
            <Button
              as={Link}
              to={`/promoter-panel/events/create-event/${EVENT_STEPS.STREAMING_DETAILS}/${context.eventId}`}
              variant="outlined"
              className="ms-auto px-3 border border-dark btn-create-event"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="orange"
              className="text-white ms-3 px-4 btn-create-event"
            >
              {isSaving && (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Saving...</span>
                </>
              )}
              {!isSaving && <span>Save and Continue</span>}
            </Button>
          </div>
        </Row>
      </Form>

      <Modal
        size="lg"
        show={showCoupon}
        onHide={() => {
          setShowCoupon(false);
          setErrors(null);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0 px-5 py-4" closeButton>
          <Modal.Title className="fw-bold">
            {currentCoupon && currentCoupon.isNewCoupon
              ? 'New Coupon'
              : 'Edit Coupon'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <p>Select the type of coupon you want to offer</p>
          {currentCoupon && (
            <>
              <div className="my-4">
                <ButtonToolbar
                  id="locationTypeId"
                  aria-label="Location Type Button Group"
                >
                  <ButtonGroup size="lg" aria-label="First group">
                    <Button
                      className={`p-4 me-3 ${
                        currentCoupon.couponType == COUPON_TYPE.FIXED
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          couponType: COUPON_TYPE.FIXED,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="me-2"
                        size="2x"
                      />
                      <p>Fixed amount discount</p>
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup size="lg" aria-label="First group">
                    <Button
                      className={`p-4 me-3 ${
                        currentCoupon.couponType == COUPON_TYPE.PERCENTAGE
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          couponType: COUPON_TYPE.PERCENTAGE,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPercent}
                        className="me-2"
                        size="2x"
                      />
                      <p>Percentage Discount</p>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                {errors && errors.couponType && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.couponType}
                  </Form.Text>
                )}
              </div>

              <div className="d-flex">
                <Form.Group controlId="couponName" className="mb-3 me-4">
                  <Form.Label className="required">Coupon Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCoupon.couponName}
                    onChange={(e) =>
                      setCurrentCoupon({
                        ...currentCoupon,
                        couponName: e.target.value,
                      })
                    }
                  />
                  {errors && errors.couponName && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.couponName}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="couponCode" className="mb-3">
                  <Form.Label className="required">Coupon code</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCoupon.couponCode}
                    onChange={(e) =>
                      setCurrentCoupon({
                        ...currentCoupon,
                        couponCode: e.target.value,
                      })
                    }
                  />
                  {errors && errors.couponCode && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.couponCode}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>

              <Form.Group controlId="couponDiscount" className="mb-3">
                <Form.Label className="required">Discount</Form.Label>

                {currentCoupon.couponType == COUPON_TYPE.FIXED && (
                  <div className="mb-3 d-flex w-25">
                    <InputGroup.Text className="me-2 bg-gray fw-bold">
                      CA $
                    </InputGroup.Text>
                    <InputGroup>
                      <Form.Control
                        value={currentCoupon.couponDiscount}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            couponDiscount: e.target.value,
                          })
                        }
                        aria-describedby="twitter"
                      />
                    </InputGroup>
                  </div>
                )}

                {currentCoupon.couponType == COUPON_TYPE.PERCENTAGE && (
                  <div className="mb-3 d-flex w-25">
                    <InputGroup>
                      <Form.Control
                        value={currentCoupon.couponDiscount}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            couponDiscount: e.target.value,
                          })
                        }
                        aria-describedby="twitter"
                      />
                      <InputGroup.Text className="me-2 bg-gray fw-bold">
                        %
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                )}

                {errors && errors.couponDiscount && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.couponDiscount}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="includeTaxInPrice" className="my-3">
                <Form.Check
                  checked={currentCoupon.includeTaxInPrice}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      includeTaxInPrice: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  name="couponTax"
                  label="Include Tax in price"
                />
              </Form.Group>

              <div className="d-flex align-items-center my-3 w-50">
                <Form.Group className="w-50 me-3">
                  <Form.Label>Valid Till:</Form.Label>
                  <div className="custom-calender-input">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <DatePicker
                      className="form-control w-20"
                      minDate={new Date()}
                      selected={
                        currentCoupon.validTillDate &&
                        moment(currentCoupon.validTillDate).toDate()
                      }
                      onChange={(date) =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          validTillDate: formatDate(date),
                        })
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <Form.Group>
                <Form.Label>Limit Uses</Form.Label>
                <Form.Check
                  checked={currentCoupon.limitToFirstTimeUsers}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToFirstTimeUsers: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  name="couponTax"
                  label="Eligible for first time users only"
                />

                <Form.Check
                  checked={currentCoupon.limitToSpecificUser}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToSpecificUser: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  label="Limit to specific customer"
                />

                {currentCoupon.limitToSpecificUser && (
                  <Form.Group className="w-50 ms-4 mb-4">
                    <span className="required d-flex">
                      <Form.Control
                        value={currentCoupon.specificUserEmail}
                        onChange={(e) =>
                          setCurrentCoupon({
                            ...currentCoupon,
                            specificUserEmail: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        aria-describedby="email id"
                        className="me-2"
                      />
                    </span>

                    {errors && errors.specificUserEmail && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.specificUserEmail}
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                <Form.Check
                  checked={currentCoupon.limitUsageCount}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitUsageCount: e.target.checked,
                    })
                  }
                  className="mb-3"
                  type="checkbox"
                  label="Limit the number of times this code can be redeemed"
                />

                {currentCoupon.limitUsageCount && (
                  <Form.Group className="w-25 ms-4 mb-4">
                    <Form.Control
                      value={currentCoupon.usageCount}
                      onChange={(e) =>
                        setCurrentCoupon({
                          ...currentCoupon,
                          usageCount: e.target.value,
                        })
                      }
                      placeholder="Enter Count"
                      aria-describedby="email id"
                    />
                    {errors && errors.usageCount && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.usageCount}
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                <Form.Check
                  className="mb-3"
                  type="checkbox"
                  checked={currentCoupon.limitToOneUsePerUser}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      limitToOneUsePerUser: e.target.checked,
                    })
                  }
                  label="Limit the one user per customer"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 p-5">
          <Button
            onClick={() => {
              setShowCoupon(false);
              setErrors(null);
            }}
            variant="outline-dark"
            className="px-4"
          >
            Cancel
          </Button>
          <Button
            onClick={() => createCoupon(currentCoupon)}
            variant="orange"
            className="text-white px-4"
          >
            Create Coupon
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          {/* <ButtonToolbar className="ps-4" id="locationTypeId" aria-label="Location Type Button Group">
                                <ButtonGroup size="lg" aria-label="First group">
                                    <Button onClick={() => setLocationType(TICKET_LOCATION_TYPE.LIVESTREAM)}
                                        className={`px-4 me-3 ${locationType == TICKET_LOCATION_TYPE.LIVESTREAM ? 'active' : ''}`}>
                                            Live Stream
                                    </Button> 
                                </ButtonGroup>
                                <ButtonGroup size="lg" aria-label="First group">
                                    <Button onClick={() => setLocationType(TICKET_LOCATION_TYPE.IN_PERSON_HYBRID)}
                                        className={`px-4 me-3 ${locationType == TICKET_LOCATION_TYPE.IN_PERSON_HYBRID ? 'active' : ''}`}>
                                            In-Person/Hybrid
                                    </Button> 
                                </ButtonGroup>
                                <ButtonGroup size="lg" aria-label="First group">
                                    <Button onClick={() => setLocationType(TICKET_LOCATION_TYPE.FREE)}
                                        className={`px-4 me-3 ${locationType == TICKET_LOCATION_TYPE.FREE ? 'active' : ''}`}>
                                            Free
                                    </Button> 
                                </ButtonGroup>
                        </ButtonToolbar> */}

          <div className="bg-light p-5">
            <Row className="mb-3">
              <Col>Ticket Price</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Tax</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Service Fee</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Stripe Fee</Col>
              <Col>$0</Col>
            </Row>

            <hr />

            <Row className="mb-3">
              <Col>Cost to Patron</Col>
              <Col>$0</Col>
            </Row>
            <Row className="mb-3">
              <Col>Your Payout</Col>
              <Col>$0</Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-5">
          <Button variant="orange" className="text-white">
            Got it!
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default Tickets;
