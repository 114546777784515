import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudArrowUp,
  faImage,
  faTrashCan,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { EVENT_BANNER_IMG, IMAGE_TYPES } from '../constants';
const storageUtils = require('../utils/LocalStorageUtils');

/**
 * Component to drag and drop a file for upload.
 *
 * @param {Object} props - Component props
 * @param {string} [props.file] - The file path to use for the initial image
 * @param {boolean} [props.banner] - Whether this is for a banner image or not
 * @param {Function} [props.onFileDrop] - The callback function to run when a file is dropped
 *
 * @returns {React.ReactElement} The component
 */
const DragDropFile = (props) => {
  /**
   * The drag state of the component.
   *
   * @type {boolean}
   */
  // drag state
  const [dragActive, setDragActive] = useState(false);

  /**
   * The ref to the input element.
   *
   * @type {React.RefObject}
   */
  const inputRef = React.useRef(null);

  /**
   * The ref to the image element.
   *
   * @type {React.RefObject}
   */
  const imageRef = React.useRef(null);

  const [isUploaded, setIsUploaded] = useState(props.file ? true : false);
  const [fileError, setFileError] = useState('');

  /**
   * Handles the effect of the component being rendered.
   * If there is a file passed in the props, it is used to preview the uploaded image.
   */
  useEffect(() => {
    console.log('drag drop rendered');
    console.log('uploaded =>', isUploaded);
    if (props.file) {
      previewUploadedImage(props.file);
    }
  }, [props.file]);

  /**
   * Handles drag events.
   *
   * @param {Event} e - The event object
   * @returns {void}
   */
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  /**
   * Triggers when file is dropped.
   *
   * @param {Event} e - The event object
   * @returns {void}
   */
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      let fileErr = '';
      const fsize = (file.size / 1024 / 1024).toFixed(2);

      if (fsize > 2) {
        fileErr = 'File size should be 2MB or less';
      } else {
        if (IMAGE_TYPES.indexOf(file.type) > -1) {
          previewUploadedImage(file);
          props.onFileDrop(file);
          fileErr = '';
        } else {
          fileErr = 'Please upload an image file only!';
        }
      }
      // handleFiles(e.dataTransfer.files);
      setFileError(fileErr);
    }
  };

  /**
   * Triggers when file is selected with click.
   *
   * @param {Event} e - The event object
   * @returns {void}
   */
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0],
        fsize = (file.size / 1024 / 1024).toFixed(2);
      let fileErr = '';

      if (fsize > 2) {
        fileErr = 'File size should be 2MB or less';
      } else {
        // handleFiles(e.target.files);
        // console.log(e.target.files[0]);
        fileErr = '';
        previewUploadedImage(file);
        props.onFileDrop(file);
      }

      setFileError(fileErr);
    }
  };

  /**
   * Preview the uploaded image.
   *
   * @param {File | string} file - The file object or base64 encoded string.
   * @return {void}
   */
  const previewUploadedImage = (file) => {
    setIsUploaded(true);
    if (typeof file.name == 'string') {
      // if file object convert into base64 encoded string
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const uploaded_image = reader.result;
        imageRef.current.style.backgroundImage = `url(${uploaded_image})`;
        // document.querySelector("#display-image").style.backgroundImage = `url(${uploaded_image})`;
        if (props.banner) {
          storageUtils.setItem(EVENT_BANNER_IMG, uploaded_image);
        }
      });
      reader.readAsDataURL(file);
    } else {
      // in file is base64 encoded string, set it directly in the url
      imageRef.current.style.backgroundImage = `url(${file})`;
    }
  };

  /**
   * Triggers the input when the button is clicked.
   *
   * @return {void}
   */
  const onButtonClick = () => {
    inputRef.current.click();
  };

  /**
   * Reset the state of the component.
   *
   * @return {void}
   */
  const resetState = () => {
    setIsUploaded(false);
    props.onFileDrop(null);
    setTimeout(() => {
      document.getElementById('form-file-upload').reset();
      if (props.banner) {
        storageUtils.removeItem(EVENT_BANNER_IMG);
      }
    });
  };

  return (
    <div>
      <div className="drag-drop-container">
        {isUploaded && (
          <div ref={imageRef} className="event-banner-image display-image">
            <div className="delete-btn-wrapper">
              <div className="bg-fade"></div>
              <FontAwesomeIcon
                onClick={() => resetState()}
                icon={faTrashCan}
                className="me-1 delete-btn text-white"
                size="xl"
              />
            </div>
          </div>
        )}
        {!isUploaded && (
          <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              accept="image/png, image/gif, image/jpeg"
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? 'drag-active' : ''}
            >
              <div>
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className="text-gray"
                  size="4x"
                />
                <h3>Drag and drop an image or</h3>
                <Button
                  variant="primary"
                  size="lg"
                  className="btn-create-event text-white mt-3"
                  onClick={onButtonClick}
                >
                  <h5 className="mb-0">Upload image</h5>
                </Button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
        )}
      </div>
      <div className="small mt-3">
        <strong>Recommended image size:</strong> 2200 x 1080px -
        <strong>Maximum file size:</strong> 2MB -
        <strong>Supported image files:</strong> JPEG or PNG
      </div>
      {fileError && (
        <Form.Text className="text-danger">
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
          {fileError}
        </Form.Text>
      )}
    </div>
  );
};

DragDropFile.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onFileDrop: PropTypes.func,
  banner: PropTypes.bool,
};

export default DragDropFile;
