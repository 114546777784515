// Streaming event Page
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import {
  faTriangleExclamation,
  faPlus,
  faMinus,
  faEdit,
  faCloudUpload,
  faTrash,
  faPencil,
  faCircleArrowUp,
  faTrashCan,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Dropdown, Form, Spinner } from 'react-bootstrap';
import { getItem } from '../../../../utils/LocalStorageUtils';
import {
  EVENT_BANNER_IMG,
  EVENT_STEPS,
  MAX_CAMERA_COUNT,
} from '../../../../constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import validateEventForm from '../eventFormValidator';
import { createEvent, getCameraDetails } from '../../../../utils/APIUtils';
import DragDropFile from '../../../../shared/DragDropFile';
import { useAsync } from '../../../../hooks/useAsync';
import ToastMessage from '../../../../shared/ToastMessage';

/**
 * Configuration object for the CKEditor text editor that is usef ti add description of events
 *
 * @typedef {Object} CKEditorConfiguration
 * @property {Array} toolbar - The toolbar buttons for the editor.
 * @property {string} placeholder - The placeholder text for the editor.
 */

/**
 * Configuration object for the CKEditor text editor.
 * @type {CKEditorConfiguration}
 * @property {Array} toolbar - The toolbar buttons for the editor.
 * @property {string} placeholder - The placeholder text for the editor.
 */
const editorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'link',
    'undo',
    'redo',
    'numberedList',
    'bulletedList',
  ],
  placeholder: 'Enter Description for primary camera here',
};

/**
 * Enumerates the actions that can be taken on images.
 * @readonly
 * @enum {string}
 */
const IMAGE_ACTION_STATUS = Object.freeze({
  /**
   * The action to add an image.
   */
  ADD: 'ADD',
  /**
   * The action to delete an image.
   */
  DELETE: 'DELETE',
});

/**
 * Returns a default thumbnail for placeholders.
 * @returns {string} A path to a default thumbnail.
 */
const getDefaultThumbnail = () => {
  let thumbnails = [
    'default_thumbnail_1.png',
    'default_thumbnail_2.png',
    'default_thumbnail_3.png',
  ];

  // get any random thumbnail from above list
  const thumbnail = thumbnails[Math.floor(Math.random() * thumbnails.length)];
  return `${process.env.PUBLIC_URL}/${thumbnail}`;
};

/**
 * Returns a new object representing a camera's details.
 *
 * @param {boolean} primary - Whether the camera is the primary camera or not.
 *
 * @returns {Object} An object with the following properties:
 *   - title: A string, the title of the camera.
 *   - artistName: A string, the artist's name.
 *   - cameraName: A string, the camera's name.
 *   - description: A string, the camera's description.
 *   - cameraImage: An object, the camera's image.
 *   - primary: A boolean, whether the camera is the primary camera or not.
 */
const getCameraDetailsDTO = (primary) => {
  return {
    // title: "",
    artistName: '',
    cameraName: '',
    description: '',
    cameraImage: null,
    primary: primary,
  };
};

/**
 * Streaming page component.
 *
 * @returns {React.ReactElement} The JSX element representing the streaming page.
 */
const Streaming = () => {
  /**
   * Hook to handle loading.. shows if api is getting called and until anything is recieved..
   */
  const { data, loading, error, run } = useAsync(null);
  /**
   * Initializing context..
   */
  const [context, setContext] = useOutletContext();
  /**
   * Streaming Page DTO to save this form's data and access accross all other steps..
   */
  let { streamingDetailsDto } = context;
  /**
   * Setting base values, that later get changed
   */
  if (!streamingDetailsDto.cameraDetailsDtoList) {
    streamingDetailsDto.cameraDetailsDtoList = [getCameraDetailsDTO(true)];
    streamingDetailsDto.totalCameras = 1;
  }
  /**
   * Initializing formState
   */
  const [formState, setFormState] = useState(streamingDetailsDto);
  /**
   * Initializing toast for notifications..
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * Renders as soon as page is loaded..
   *
   * @function
   * @param {Object} props The props of the component.
   * @param {Function} context The context of the component.
   */
  useEffect(() => {
    // To get Camera details, this ensures that saved cameras are accessed even if promoter goes to some other step and comes back
    if (context.step == EVENT_STEPS.STREAMING_DETAILS) {
      run(() => getCameraDetails(context.eventId)).then((res) => {
        let streamingDTO = res.data;
        streamingDTO.cameraDetailsDtoList.map((item) => {
          if (item.thumbnailUrl) {
            item.cameraImage = item.thumbnailUrl;
            // item.base64EncodedThumbnail = 'data:image/jpeg;base64,'+ item.base64EncodedThumbnail;
            item.uploaded = true;
          }
        });
        setFormState(streamingDTO);
        console.log(streamingDTO);
      });
    } else {
      run(() => Promise.resolve(true));
    }
  }, []);

  /**
   * Updates the state of the component.
   * @param {Object} state The new state of the component.
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      console.log({ ...prevState, ...state });
      return { ...prevState, ...state };
    });
  // Initialzing error state..
  /**
   * The error state of the component.
   * @type {Object}
   */
  const [errors, setErrors] = useState(null);
  // Legacy can be used to debug or can be removed in prod..
  /**
   * Whether the component has been initialized.
   * @type {boolean}
   */
  const [initialized, setInitialized] = useState(false);
  // state to show loading animation when request to server is made after submission..
  /**
   * Whether the form is being submitted.
   * @type {boolean}
   */
  const [isSaving, setisSaving] = useState(false);
  // Router initialzing
  /**
   * The navigate function to navigate through the app.
   * @type {function}
   */
  const navigate = useNavigate();

  // useEffect(() => {
  //         loadDefaultImage();
  //         // initialize form state
  //         // if(!initialized){
  //         //     let list = [];
  //         //     list.push(getCameraDetailsDTO(true));

  //         //     updateState({
  //         //         totalCameras: 1,
  //         //         cameraDetailsDtoList: list
  //         //     });
  //         //     setInitialized(true);
  //         // }
  // },[])

  // const loadDefaultImage = () => {
  //     document.getElementById('display-image').style.backgroundImage = `url(${getItem(EVENT_BANNER_IMG)})`;
  // }
  /**
   * To persist values in different renders
   * @type {React.MutableRefObject<HTMLInputElement | null>}
   */
  const inputRef = React.useRef(null);
  /**
   * To persist values in different renders
   * @type {React.MutableRefObject<HTMLInputElement | null>}
   */
  const imageRef = React.useRef(null);
  /**
   * State to store the cameras.
   * @type {Array<CameraDetailsDTO>}
   */
  const [cameras, setCameras] = useState([]);

  /**
   * Adding camera, function called when new camera is added by promoter.
   */
  const addCamera = () => {
    let list = [...formState.cameraDetailsDtoList];
    let cameraDTO = getCameraDetailsDTO(false);
    list.push(cameraDTO);
    // Updating state..
    updateState({
      totalCameras: formState.totalCameras + 1,
      cameraDetailsDtoList: list,
    });
  };

  /**
   * To remove camera, function called when promoter clicks on remove camera button.
   *
   * @param {number} index - The index of the camera to remove.
   */
  const removeCamera = (index) => {
    const list = [...formState.cameraDetailsDtoList];
    list.splice(index, 1);

    updateState({
      totalCameras: formState.totalCameras - 1,
      cameraDetailsDtoList: list,
    });

    /**
     * Removes any errors related to the camera at the specified index when the camera is removed.
     *
     * @param {number} index - The index of the camera to remove.
     */
    // remove any errors related when removing the camera

    if (errors && errors[index]) {
      let errList = errors.splice(index, 1);
      setErrors(errList);
    }
  };

  /**
   * Preview loaded image.
   * @param {File} file - The image file to be uploaded.
   * @param {number} i - The index of the camera in the list of cameras.
   */
  const previewUploadedImage = (file, i) => {
    // setIsUploaded(true);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const uploaded_image = reader.result;
      const cList = [...formState.cameraDetailsDtoList];
      cList[i].uploaded = true;
      cList[i].thumbnailUrl = uploaded_image;
      cList[i].cameraImage = file;
      // add this for api to check if image is added / updated / deleted
      cList[i].cameraImageAction = IMAGE_ACTION_STATUS.ADD;
      // setCameras(cList);
      updateState({ cameraDetailsDtoList: cList });
      // imageRef.current.style.backgroundImage = `url(${uploaded_image})`;
    });
    reader.readAsDataURL(file);
  };

  /**
   * Function called when values of any fields change.
   * @param {number} index - The index of the camera in the list of cameras.
   * @param {string} name - The name of the field that has changed.
   * @param {string} value - The new value of the field.
   */
  const handleChange = (index, name, value) => {
    let list = [...formState.cameraDetailsDtoList];

    list[index][name] = value;
    // updates state with new values
    updateState({ cameraDetailsDtoList: list });
  };

  /**
   * When image file is changed, this function is called..
   *
   * @param {Event} e - The event object.
   * @param {number} i - The index of the camera in the list of cameras.
   * @returns {void}
   */
  const handleFileChange = (e, i) => {
    e.preventDefault();
    let file = e.target.files && e.target.files[0];
    if (file) {
      let fsize = (file.size / 1024 / 1024).toFixed(2);
      // create a clone else react won't trigger rerender on state update
      let errList = errors ? [...errors] : [];
      let fileErr = '';
      if (!errList[i]) errList[i] = {};

      if (fsize > 2) {
        fileErr = 'File size should be 2MB or less';
      } else {
        previewUploadedImage(e.target.files[0], i);
      }
      errList[i].cameraImage = fileErr;
      setErrors(errList);
    }
  };

  /**
   * Called when primary camera image is changed..
   * @param {File} file - The new file uploaded.
   * @returns {void}
   */
  const handlePrimaryCameraImage = (file) => {
    let list = [...formState.cameraDetailsDtoList];
    list[0].cameraImage = file;
    list[0].cameraImageAction = IMAGE_ACTION_STATUS.DELETE;
    updateState({ cameraDetailsDtoList: list });
  };

  /**
   * Triggers the input when the button is clicked
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const onButtonClick = (e) => {
    e.stopPropagation();
    e.currentTarget.firstChild.click();
    // inputRef.current.click();
  };

  /**
   * Validation logic, to validate all fields are correctly populated..
   * @returns {boolean} - True if all fields are valid, false otherwise.
   */
  const validate = () => {
    let error = validateEventForm(formState, 'STREAMING_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };

  /**
   * Fetches the artist background image for camera.
   *
   * @param {number} index - The index of the camera in the formState.cameraDetailsDtoList.
   * @return {string} The URL of the artist background image.
   */
  const getArtistBackground = (index) => {
    let cameraList = [...formState.cameraDetailsDtoList];
    if (cameraList[index].uploaded) {
      return `url(${cameraList[index].thumbnailUrl})`;
    } else if (cameraList[index].defaultBg) {
      return cameraList[index].defaultBg;
      // return `url(${cameraList[index].defaultBg})`;
    } else {
      let defaultBg = `url(${getDefaultThumbnail()})`;
      cameraList[index].defaultBg = defaultBg;
      updateState({ cameraDetailsDtoList: cameraList });
      return defaultBg;
    }
  };

  /**
   * Called when submit button is clicked.
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    /**
     * Check the data in form before submitted, further shows errors if any..
     * @type {boolean}
     */
    const isValid = validate();

    /**
     * Handles form submission.
     *
     * @param {Event} e - The event object.
     * @return {void}
     */
    if (isValid) {
      // Setting saving true and showing little saving animate.
      setisSaving(true);
      formState.cameraDetailsDtoList.map((item) => {
        // Deleting thumbnailUrl property from each item.
        delete item['thumbnailUrl'];
      });
      // Making post request to api and saving data.
      createEvent('STREAMING_DETAILS', formState, context.eventId)
        .then((res) => {
          // Updating context with response data.
          setContext({
            ...context,
            streamingDetailsDto: {
              ...formState,
            },
          });
          // Notifying success message.
          setToast({ show: true, msg: 'Saved Successfully!', success: true });
          // Redirecting to next step.
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.TICKET_DETAILS}/${context.eventId}`
          );
          setisSaving(false);
          console.log(res);
        })
        // Catching any error and showing error message.
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          setisSaving(false);
          console.log(err);
        });
    }
  };

  // Shows animation when saving..
  /**
   * Renders a loading animation if loading is true.
   *
   * @return {JSX.Element} The loading animation component or null.
   */
  if (loading) {
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        {/* Loading message */}
        <h6 className="me-2">Loading...</h6>
        {/* Spinner animation */}
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  /**
   * Main function rendering component.
   *
   * @return {JSX.Element} The Streaming component.
   */
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} lg={9}>
          <h2 className="fw-bold mb-0">Streaming Page</h2>
          <p className="w-75 mt-3">
            This is how patrons will see the event, they will receive the access
            link 15 minutes before the streaming starts.{' '}
          </p>
          <h5 className="required">Primary Camera (Producer's Cut)</h5>
          <div>
            {/* <Form.Group className='mb-4' controlId='title'>
                                    <Form.Label className='required' >Title</Form.Label>
                                    <Form.Control type='text' placeholder='title...'
                                        value={formState.cameraDetailsDtoList[0].title}
                                        onChange={(e) => handleChange(0, 'title', e.target.value)}></Form.Control>
                                        {errors && errors[0].title &&
                                            <Form.Text className="text-danger">
                                                <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                {errors[0].title}
                                            </Form.Text>
                                        }
                                </Form.Group> */}

            {/* <div id="display-image" 
                                    className={`event-banner-image ${!context.mainEventDetailsDto.bannerImage ? 'd-none': ''}`}>
                                </div> */}

            <div className="my-5">
              <DragDropFile
                file={formState.cameraDetailsDtoList[0].cameraImage}
                onFileDrop={(file) => handlePrimaryCameraImage(file)}
              />

              {errors && errors[0] && errors[0].cameraImage && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors[0].cameraImage}
                </Form.Text>
              )}
            </div>

            <Form.Group className="mb-4" controlId="artistName">
              <Form.Label className="required">Artist Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="artist name..."
                value={formState.cameraDetailsDtoList[0].artistName}
                onChange={(e) => handleChange(0, 'artistName', e.target.value)}
              ></Form.Control>
              {errors && errors[0] && errors[0].artistName && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors[0].artistName}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-4" controlId="cameraName">
              <Form.Label className="required">Camera Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="camera name..."
                value={formState.cameraDetailsDtoList[0].cameraName}
                onChange={(e) => handleChange(0, 'cameraName', e.target.value)}
              ></Form.Control>
              {errors && errors[0] && errors[0].cameraName && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors[0].cameraName}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="my-4">
              <Form.Label className="required">Description</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  //console.log( 'Editor is ready to use!', editor );
                }}
                data={formState.cameraDetailsDtoList[0].description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange(0, 'description', data);
                  // console.log( { event, editor, data } );
                }}
                onBlur={(event, editor) => {
                  //console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  //console.log( 'Focus.', editor );
                }}
              />
              {errors && errors[0] && errors[0].description && (
                <Form.Text className="text-danger">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  {errors[0].description}
                </Form.Text>
              )}
            </Form.Group>

            {/* <p className="mt-3">An event description is copy that aims to tell your 
                                potential attendees what will be happening at the event, who will be speaking
                                , and what they will get out of attending. Good event descriptions can drive 
                                attendance to events and also lead to more media coverage.</p> */}
          </div>

          <hr className="my-4" />

          <h5 className="mb-4">Secondary Camera(s) (Optional)</h5>

          {formState.cameraDetailsDtoList &&
            formState.cameraDetailsDtoList.map((item, i) => {
              // render all cameras except primary camera
              if (i < 1) {
                return <div key={i}></div>;
              }
              return (
                <div className="mb-3" key={i}>
                  <div className="d-flex align-items-center justify-content-center p-4 bg-light">
                    <div className="col-12 col-sm-4 align-self-stretch p-3 me-3 artist-image-height">
                      <div
                        className="artist-thumbnail"
                        style={{ backgroundImage: `${getArtistBackground(i)}` }}
                      >
                        <button
                          onClick={onButtonClick}
                          type="button"
                          className="btn btn-camera"
                        >
                          <input
                            className="d-none"
                            ref={inputRef}
                            type="file"
                            id="input-file-upload"
                            multiple={true}
                            onChange={(e) => handleFileChange(e, i)}
                          />
                          <FontAwesomeIcon icon={faCamera} size="2x" />
                        </button>
                        {/* <h5 className={`${formState.cameraDetailsDtoList[i].uploaded ? 'd-none' : ''}`}>Thumbnail</h5> */}
                        {/* <div ref={imageRef} 
                                                        className={`${!formState.cameraDetailsDtoList[i].uploaded ? 'd-none' : 'w-100 h-100 bg-image'}`}   
                                                        style={{backgroundImage: `${getArtistBackground(formState.cameraDetailsDtoList[i])}`}}>
                                                    </div>  */}
                        {/* <div className="w-100 h-100 bg-image"   
                                                    > 
                                                    </div>
                                                    */}
                      </div>
                      {errors && errors[i] && errors[i].cameraImage && (
                        <Form.Text className="text-danger">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="me-2"
                          />
                          {errors[i].cameraImage}
                        </Form.Text>
                      )}
                    </div>
                    <div className="col-12 col-sm-8 d-flex">
                      <div className="flex-grow-1">
                        {/* <Form.Group controlId="title" className="mb-3 "> 
                                                        <Form.Label className='required'>Title</Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => handleChange(i, 'title', e.target.value)}
                                                            value={item.title}
                                                            type="text" placeholder="title" className="border-dark"/> 

                                                            {errors && errors[i] && errors[i].title &&
                                                                <Form.Text className="text-danger">
                                                                    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                                    {errors[i].title}
                                                                </Form.Text>
                                                            }
                                                    </Form.Group> */}
                        <Form.Group controlId="artistName" className="mb-3 ">
                          <Form.Label className="required">
                            Artist Name
                          </Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              handleChange(i, 'artistName', e.target.value)
                            }
                            value={item.artistName}
                            type="text"
                            placeholder="Artist Name"
                            className="border-dark"
                          />

                          {errors && errors[i] && errors[i].artistName && (
                            <Form.Text className="text-danger">
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="me-2"
                              />
                              {errors[i].artistName}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="cameraName" className="mb-3">
                          <Form.Label className="required">
                            Camera Name
                          </Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              handleChange(i, 'cameraName', e.target.value)
                            }
                            value={item.cameraName}
                            type="text"
                            placeholder="Camera Name"
                            className="border-dark"
                          />
                          {errors && errors[i] && errors[i].cameraName && (
                            <Form.Text className="text-danger">
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="me-2"
                              />
                              {errors[i].cameraName}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="description" className="mb-3">
                          <Form.Label className="required">
                            Description
                          </Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              handleChange(i, 'description', e.target.value)
                            }
                            value={item.description}
                            as="textarea"
                            maxLength={200}
                            placeholder="Description"
                            className="border-dark"
                          />
                          {errors && errors[i] && errors[i].description && (
                            <Form.Text className="text-danger">
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="me-2"
                              />
                              {errors[i].description}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </div>

                      <div className="d-flex flex-column align-items-center py-4 px-3 justify-content-around">
                        <Button variant="link" className="p-0 text-dark">
                          <FontAwesomeIcon icon={faPencil} size="xl" />
                        </Button>
                        <Button variant="link" className="p-0 text-dark">
                          <FontAwesomeIcon
                            onClick={onButtonClick}
                            icon={faCircleArrowUp}
                            size="xl"
                          />
                        </Button>
                        <Button variant="link" className="p-0 text-dark">
                          <FontAwesomeIcon
                            onClick={() => removeCamera(i)}
                            icon={faTrashCan}
                            size="xl"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="d-flex align-items-center">
            <span className="ms-auto me-3 fw-bold">Add Camera</span>
            <Button
              disabled={formState.totalCameras == MAX_CAMERA_COUNT}
              onClick={addCamera}
              variant="orange"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="ms-auto text-white"
                size="xl"
              />
            </Button>
          </div>

          {/* {cameras.length > 1 &&
                                <div className="d-flex align-items-center mt-3">
                                    <span className="ms-auto me-3">Remove Camera</span>    
                                    <Button onClick={removeCamera} variant="orange">
                                        <FontAwesomeIcon icon={faMinus} className="ms-auto text-white" size="xl" />
                                    </Button>
                                </div>
                            } */}

          <div className="d-flex my-4 pt-5">
            <Button
              as={Link}
              to={`/promoter-panel/events/create-event/${EVENT_STEPS.MAIN_DETAILS}/${context.eventId}`}
              variant="outlined"
              className="ms-auto px-3 me-3 border border-dark btn-create-event"
            >
              Back
            </Button>

            <Button
              type="submit"
              variant="orange"
              className="text-white px-4 btn-create-event"
            >
              {isSaving && (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Saving...</span>
                </>
              )}
              {!isSaving && <span>Save and Continue</span>}
            </Button>
          </div>
        </Col>
        <ToastMessage
          {...toast}
          onClose={() => setToast({ ...toast, show: false })}
        />
      </Row>
    </Form>
  );
};

export default Streaming;
