import React from 'react';
import {
  faChevronDown,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { formatDate } from '../../../utils/CommonUtils';
import moment from 'moment';

/**
 * A component to render the start and end date input fields for an event.
 *
 * @param {Object} props
 * @param {Object} props.formState - The form state object containing the event start date and time.
 * @param {Date} props.formState.eventStartDate - The start date of the event.
 * @param {Date} props.formState.eventEndDate - The end date of the event.
 * @param {Date} props.formState.eventStartTime - The start time of the event.
 * @param {Date} props.formState.eventEndTime - The end time of the event.
 * @param {Function} props.updateState - The function to update the form state.
 * @param {Object} props.errors - The errors object containing any error messages.
 * @param {string} props.errors.eventStartDate - The error message for the start date.
 * @param {string} props.errors.eventEndDate - The error message for the end date.
 * @param {string} props.errors.eventStartTime - The error message for the start time.
 * @param {string} props.errors.eventEndTime - The error message for the end time.
 * @param {string} props.errors.timezone - The error message for the timezone.
 * @param {boolean} props.showTimezonefield - Flag indicating whether to show the timezone field.
 */
const EventStartEndDate = ({
  formState,
  updateState,
  errors,
  showTimezonefield,
}) => {
  /**
   * Returns the maximum start date.
   *
   * @return {Date|null} The maximum start date.
   */
  const getMaxStartDate = () => {
    return formState.eventEndDate ? moment(formState.eventEndDate).toDate() : null;
  };

  /**
   * Returns the minimum end date for the event.
   *
   * @return {Date} The minimum end date.
   */
  const getMinEndDate = () => {
    if (
      formState.eventStartTime &&
      formState.eventEndTime &&
      formState.eventEndTime < formState.eventStartTime
    ) {
      return moment(formState.eventStartDate).add(1, 'd').toDate();
    }
    return moment(formState.eventStartDate).toDate();
  };

  /**
   * Returns the minimum start time for the event.
   *
   * @return {Date} The minimum start time.
   */
  const getMinTime = () => {
    const { eventStartDate, eventEndDate, eventStartTime } = formState;
    return eventStartTime &&
      eventStartDate &&
      eventEndDate &&
      isDatesSameDay(new Date(eventStartDate), new Date(eventEndDate))
      ? new Date(eventStartTime)
      : setHours(setMinutes(new Date(), 0), 0);
  };

  /**
   * Returns the maximum end time for the event.
   *
   * @return {Date} The maximum end time.
   */
  const getMaxTime = () => {
    const { eventStartDate, eventEndDate, eventEndTime } = formState;
    return eventEndTime &&
      eventStartDate &&
      eventEndDate &&
      isDatesSameDay(new Date(eventStartDate), new Date(eventEndDate))
      ? new Date(eventEndTime)
      : setHours(setMinutes(new Date(), 45), 23);
  };

  /**
   * Checks if two dates are the same day.
   *
   * @param {Date} d1 - The first date.
   * @param {Date} d2 - The second date.
   * @returns {boolean} true if the two dates are the same day, false otherwise.
   */
  const isDatesSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  return (
    <Row>
      <Col xs={12} lg={8}>
        <div className="d-flex align-items-start mb-4">
          <Form.Group controlId="eventStartDate" className="mb-3">
            <Form.Label className="required">Start Date</Form.Label>
            <DatePicker
              minDate={new Date()}
              maxDate={getMaxStartDate()}
              className="form-control w-20"
              selected={
                formState.eventStartDate &&
                moment(formState.eventStartDate).toDate()
              }
              onChange={(date) =>
                updateState({ eventStartDate: formatDate(date) })
              }
            />
            {errors && errors.eventStartDate && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.eventStartDate}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="eventEndDate" className="mb-3 ms-3">
            <Form.Label className="required">End Date</Form.Label>
            <DatePicker
              minDate={getMinEndDate()}
              className="form-control w-20"
              selected={
                formState.eventEndDate &&
                moment(formState.eventEndDate).toDate()
              }
              onChange={(date) =>
                updateState({ eventEndDate: formatDate(date) })
              }
            />
            {errors && errors.eventEndDate && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.eventEndDate}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="eventStartTime" className="mb-3 mx-3">
            <Form.Label className="required">Start Time</Form.Label>
            <DatePicker
              className="form-control w-20"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={getMaxTime()}
              selected={
                formState.eventStartTime && new Date(formState.eventStartTime)
              }
              onChange={(date) =>
                updateState({ eventStartTime: date.getTime() })
              }
            />
            {errors && errors.eventStartTime && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.eventStartTime}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="eventEndTime" className="mb-3">
            <Form.Label className="required">End Time</Form.Label>
            <DatePicker
              className="form-control w-20"
              minTime={getMinTime()}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              selected={
                formState.eventEndTime &&
                new Date(parseInt(formState.eventEndTime))
              }
              onChange={(date) => updateState({ eventEndTime: date.getTime() })}
            />
            {errors && errors.eventEndTime && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.eventEndTime}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {showTimezonefield && (
          <>
            <Dropdown id="timezone">
              <Dropdown.Toggle
                variant="outlined"
                className="border w-100 no-caret"
              >
                <div className="text-start d-flex align-items-center">
                  <span>
                    {formState.timezone
                      ? 'Eastern Standard Time (EST)'
                      : 'Select Timezone...'}
                    <br />
                  </span>
                  <FontAwesomeIcon icon={faChevronDown} className="ms-auto" />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                <Dropdown.Item
                  onClick={() => updateState({ timezone: 'EST5EDT' })}
                  eventKey="EST5EDT"
                >
                  Eastern Standard Time (EST)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {errors && errors.timezone && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.timezone}
              </Form.Text>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default EventStartEndDate;
