// Custom hook legacy
import { useEffect, useState } from 'react';
import { getPromoterEventDetails } from '../../../utils/APIUtils';
import { useAsync } from '../../../hooks/useAsync';
import { EVENT_STEPS } from '../../../constants';
import moment from 'moment';

/**
 * Generate a timestamp from the given date and time strings.
 * @param {string} d - The date string in the format 'YYYY-MM-DD'.
 * @param {string} t - The time string in the format 'hh:mm A'.
 * @returns {number} - The generated timestamp.
 * @example
 * getTsFromDateTimeStrings('2021-01-01', '12:00 AM')
 * // returns 1609459200000
 */
export const getTsFromDateTimeStrings = (d, t) => {
  if (!d || !t) {
    return new Date().getTime();
  }
  return new Date(d + ' ' + t).getTime();
};

/**
 * Returns the default state object.
 *
 * @return {Object} The default state object.
 * @property {number|null} eventId - The event ID.
 * @property {Object} basicEventDetailsDto - The basic event details DTO.
 * @property {Object} mainEventDetailsDto - The main event details DTO.
 * @property {Object} inPersonEventDetailsDto - The in-person event details DTO.
 * @property {Object} streamingDetailsDto - The streaming details DTO.
 * @property {Object} inPersonTicketDetailsDTO - The in-person ticket details DTO.
 * @property {Object} ticketDetailsDto - The ticket details DTO.
 * @property {Object} finalEventDetailsDto - The final event details DTO.
 */
const getDefaultState = () => {
  return {
    eventId: null,
    basicEventDetailsDto: {},
    mainEventDetailsDto: {},
    inPersonEventDetailsDto: {},
    streamingDetailsDto: {},
    inPersonTicketDetailsDTO: {},
    ticketDetailsDto: {},
    finalEventDetailsDto: {},
  };
};

// const getEventDTO = (data) => {
//     let state = getDefaultState();

//     if(data){

//         state.eventId = data.id;
//         state.step = data.step;
//         state.basicEventDetailsDto.eventTitle = data.eventTitle;
//         state.basicEventDetailsDto.eventTypeId = data.eventTypeModel.id;
//         state.basicEventDetailsDto.genreId = data.genreModel.id;
//         state.basicEventDetailsDto.locationTypeId = data.locationTypeModel.id ;
//         state.basicEventDetailsDto.eventStartDate = data.eventStartDate;
//         state.basicEventDetailsDto.eventStartTime = getTsFromDateTimeStrings(data.eventStartDate, data.eventStartTime);
//         state.basicEventDetailsDto.eventEndDate = data.eventEndDate;
//         state.basicEventDetailsDto.eventEndTime = getTsFromDateTimeStrings(data.eventEndDate, data.eventEndTime);
//         state.basicEventDetailsDto.timezone = data.timezone;
//         state.basicEventDetailsDto.organizer = data.customPromoterName;

//         if(data.step == EVENT_STEPS.BASIC_DETAILS){
//             return state;
//         }

//         state.mainEventDetailsDto.bannerImage = data.bannerThumbnailS3IdList[0].thumbnailUrl;
//         state.mainEventDetailsDto.summary = data.summary;
//         state.mainEventDetailsDto.description = data.description;
//         // get base64 encoded strings as array
//         state.mainEventDetailsDto.eventImages = data.eventThumbnailsList && data.eventThumbnailsList.map(dto => dto.thumbnailUrl);
//         state.mainEventDetailsDto.eventImagesNameList = data.eventThumbnailsList && data.eventThumbnailsList.map(dto => dto.thumbnailName);
//         state.mainEventDetailsDto.externalVideoURL = data.externalVideoUrl;
//         state.mainEventDetailsDto.Tiktok = data.socialMediaLinksModel.tiktok;
//         state.mainEventDetailsDto.facebook = data.socialMediaLinksModel.facebook;
//         state.mainEventDetailsDto.instagram = data.socialMediaLinksModel.instagram;
//         state.mainEventDetailsDto.twitterUrl = data.socialMediaLinksModel.twitter;

//         if(data.step == EVENT_STEPS.MAIN_DETAILS ||
//                 data.step == EVENT_STEPS.STREAMING_DETAILS){
//             return state;
//         }

//         state.inPersonEventDetails.addressLine1 = data.inPersonEventModel.addressLine1
//         state.inPersonEventDetails.addressLine2 = data.inPersonEventModel.addressLine2
//         state.inPersonEventDetails.city = data.inPersonEventModel.city
//         state.inPersonEventDetails.province = data.inPersonEventModel.province
//         state.inPersonEventDetails.postalCode = data.inPersonEventModel.postalCode
//         state.inPersonEventDetails.country = data.inPersonEventModel.country

//         state.inPersonTicketDetailsDto.price = data.eventTicketDetailsModel.price.toString(); // convert to string for validation to work
//         state.inPersonTicketDetailsDto.salesEndDate = data.eventTicketDetailsModel.salesEndDate;
//         state.inPersonTicketDetailsDto.salesEndTime = getTsFromDateTimeStrings(data.eventTicketDetailsModel.salesEndTime);
//         state.inPersonTicketDetailsDto.salesStartDate = data.eventTicketDetailsModel.salesStartDate;
//         state.inPersonTicketDetailsDto.salesStartTime = getTsFromDateTimeStrings(data.eventTicketDetailsModel.salesStartTime);
//         state.inPersonTicketDetailsDto.ticketName = data.eventTicketDetailsModel.ticketName;
//         state.inPersonTicketDetailsDto.ticketType = data.eventTicketDetailsModel.ticketType;
//         state.inPersonTicketDetailsDto.totalQuantity = data.eventTicketDetailsModel.totalQuantity.toString();// convert to string for validation to work;
//         state.inPersonTicketDetailsDto.chargeTax = data.eventTicketDetailsModel.chargeTax;
//         state.inPersonTicketDetailsDto.taxPercentage = data.eventTicketDetailsModel.taxPercentage;
//         state.inPersonTicketDetailsDto.billingFrequency = data.eventTicketDetailsModel.billingFrequency;
//         //
//         state.inPersonEventDetailsDto.sectionName = data.inPersonEventDetailsModel.sectionName
//         state.inPersonEventDetailsDto.quantity = data.inPersonEventDetailsModel.capacity
//         state.inPersonEventDetailsDto.ticketType = data.inPersonEventDetailsModel.ticketType.map(ticket => ({
//             type: ticket.type,
//             price: ticket.price.toString(), // Convert to string for validation if needed
//             quantity: ticket.quantity.toString(), // Convert to string for validation if needed
//             // Other properties as needed
//         }));
//         state.inPersonEventDetailsDto.venueImage = data.inPersonEventDetailsModel.venueImage

//         state.ticketDetailsDto.price = data.eventTicketDetailsModel.price.toString(); // convert to string for validation to work
//         state.ticketDetailsDto.salesEndDate = data.eventTicketDetailsModel.salesEndDate;
//         state.ticketDetailsDto.salesEndTime = getTsFromDateTimeStrings(data.eventTicketDetailsModel.salesEndTime);
//         state.ticketDetailsDto.salesStartDate = data.eventTicketDetailsModel.salesStartDate;
//         state.ticketDetailsDto.salesStartTime = getTsFromDateTimeStrings(data.eventTicketDetailsModel.salesStartTime);
//         state.ticketDetailsDto.ticketName = data.eventTicketDetailsModel.ticketName;
//         state.ticketDetailsDto.ticketType = data.eventTicketDetailsModel.ticketType;
//         state.ticketDetailsDto.totalQuantity = data.eventTicketDetailsModel.totalQuantity.toString();// convert to string for validation to work;
//         state.ticketDetailsDto.chargeTax = data.eventTicketDetailsModel.chargeTax;
//         state.ticketDetailsDto.taxPercentage = data.eventTicketDetailsModel.taxPercentage;
//         state.ticketDetailsDto.billingFrequency = data.eventTicketDetailsModel.billingFrequency;

//         if(data.step == EVENT_STEPS.TICKET_DETAILS){
//             return state;
//         }

//     }
// }

/**
 * Generates a dummy event DTO for testing purposes.
 *
 * @returns {Object} - A dummy event DTO.
 */
const getEventDummyDTO = () => {
  let state = getDefaultState();

  state.eventId = '21343242432423432422';
  state.step = EVENT_STEPS.BASIC_DETAILS;
  state.basicEventDetailsDto.eventTitle = 'Event Title XYZ';
  state.basicEventDetailsDto.eventTypeId = 'eventTypeId';
  state.basicEventDetailsDto.genreId = 'genreId';
  state.basicEventDetailsDto.locationTypeId = 1; // 1 for in person
  state.basicEventDetailsDto.eventStartDate = '05/06/2024';
  state.basicEventDetailsDto.eventStartTime = getTsFromDateTimeStrings();
  state.basicEventDetailsDto.eventEndDate = '05/06/2024';
  state.basicEventDetailsDto.eventEndTime = getTsFromDateTimeStrings();
  state.basicEventDetailsDto.timezone = 'EST';
  state.basicEventDetailsDto.organizer = 'Organizer Name';

  // if(data.step == EVENT_STEPS.BASIC_DETAILS){
  //     return state;
  // }

  state.mainEventDetailsDto.bannerImage =
    'https://img.freepik.com/free-vector/gradient-texture-music-festival-twitch-banner_23-2149201651.jpg';
  state.mainEventDetailsDto.summary =
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry.';
  state.mainEventDetailsDto.description =
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry.';
  // get base64 encoded strings as array
  state.mainEventDetailsDto.eventImages = [
    'https://img.freepik.com/free-vector/gradient-texture-music-festival-twitch-banner_23-2149201651.jpg',
    'https://img.freepik.com/free-vector/gradient-texture-music-festival-twitch-banner_23-2149201651.jpg',
    'https://img.freepik.com/free-vector/gradient-texture-music-festival-twitch-banner_23-2149201651.jpg',
  ];
  state.mainEventDetailsDto.eventImagesNameList = [
    'Image 1',
    'Image 2',
    'Image 3',
  ];
  state.mainEventDetailsDto.externalVideoURL =
    'https://www.youtube.com/embed/I5ksjGDnYUI';
  state.mainEventDetailsDto.Tiktok = 'https://www.tiktok.com/ticketwindow';
  state.mainEventDetailsDto.facebook = 'https://www.facebook.com/ticketwindow';
  state.mainEventDetailsDto.instagram =
    'https://www.instagram.com/ticketwindow';
  state.mainEventDetailsDto.twitterUrl = 'https://www.x.com/ticketwindow';
  state.mainEventDetailsDto.youtubeUrl = 'https://www.youtube.com/ticketwindow';

  // if(data.step == EVENT_STEPS.MAIN_DETAILS ||
  //         data.step == EVENT_STEPS.STREAMING_DETAILS){
  //     return state;
  // }

  state.inPersonEventDetailsDto.addressLine1 = '285 Taunton Rd E';
  state.inPersonEventDetailsDto.addressLine2 = '';
  state.inPersonEventDetailsDto.city = 'Oshawa';
  state.inPersonEventDetailsDto.province = 'Ontario';
  state.inPersonEventDetailsDto.postalCode = 'L1G 3V2';
  state.inPersonEventDetailsDto.country = 'Canada';

  state.inPersonTicketDetailsDTO.price = '50'; // convert to string for validation to work
  state.inPersonTicketDetailsDTO.salesEndDate = '05/06/2024';
  state.inPersonTicketDetailsDTO.salesEndTime = getTsFromDateTimeStrings();
  state.inPersonTicketDetailsDTO.salesStartDate = '05/06/2024';
  state.inPersonTicketDetailsDTO.salesStartTime = getTsFromDateTimeStrings();
  state.inPersonTicketDetailsDTO.ticketName = 'General';
  state.inPersonTicketDetailsDTO.ticketType = 'Student';
  state.inPersonTicketDetailsDTO.totalQuantity = '100'; // convert to string for validation to work;
  state.inPersonTicketDetailsDTO.chargeTax = true;
  state.inPersonTicketDetailsDTO.taxPercentage = 13;
  state.inPersonTicketDetailsDTO.billingFrequency = 'ONCE';
  //
  state.inPersonEventDetailsDto.sectionName = 'Balcony';
  state.inPersonEventDetailsDto.quantity = 50;
  state.inPersonEventDetailsDto.ticketType = [
    {
      type: 'Student',
      price: '50', // Convert to string for validation if needed
      quantity: '50', // Convert to string for validation if needed
    },
  ];
  state.inPersonEventDetailsDto.venueImage =
    'https://picsum.photos/seed/picsum/200/300';

  return state;

  // if(data.step == EVENT_STEPS.TICKET_DETAILS){
  //     return state;
  // }
};

// const [eventState, setEventState] = useState({
//         eventId: params.eventId,
//         basicEventDetailsDto: {},
//         mainEventDetailsDto: {},
//         streamingDetailsDto: {},
//         ticketDetailsDto: {},
//         finalEventDetailsDto:{}
// });

// useEffect(() => {
//     // set initial form state in case of update event
//     getFormState();

// }, [])

/**
 * Returns a stateful object and a function to update the state. The state
 * contains all the necessary data for creating an event.
 *
 * @param {string} id - The ID of the event to update. If not provided, a new
 * event will be created.
 * @returns {Object} - An object containing the event state and a function to
 * update the state.
 */
export const useCreateEvent = (id) => {
  const [eventState, setEventState] = useState(getDefaultState());
  const { data, loading, error, run } = useAsync(null);

  useEffect(() => {
    // TODO: remove this code, only to generate dummy data
    let dto = getEventDummyDTO();
    setEventState(dto);

    console.log('Event State', eventState);
    // if event id not found just return default event state
    if (id) {
      run(() => getPromoterEventDetails(id)).then((res) => {
        let { data } = res;
        // decode all strings getting from api response
        Object.keys(data).map((item) => {
          if (typeof data[item] == 'string') {
            data[item] = decodeURI(data[item]);
          }
        });
        // let dto = getEventDTO(data);
        setEventState(dto);
      });
    } else {
      run(() => Promise.resolve(true));
    }

    return () => {
      setEventState(getDefaultState());
    };
  }, []);

  return { eventState, setEventState, loading, error };
};
