import { URL_REGEX, INT_REGEX } from '../../constants';

/**
 * Function to validate the address field.
 * @param {Object} dto - The address object to be validated.
 * @returns {Object} - An error object containing validation errors.
 */
const validateAddress = (dto) => {
  let error = {};

  if (!dto.addressLine1 || dto.addressLine1.trim().length == 0) {
    error['addressLine1'] = 'This field is required';
  }

  if (!dto.city || dto.city.trim().length == 0) {
    error['city'] = 'Please enter your name';
  }

  if (!dto.postalCode || dto.postalCode.trim().length == 0) {
    error['postalCode'] = 'Please enter your name';
  }

  return error;
};

/**
 * Function to validate the contact information fields.
 * @param {Object} state - The state object containing contact information fields to be validated.
 * @returns {Object} - An error object containing validation errors.
 */
const validateContactInfo = (state) => {
  let error = {};

  if (!state.firstName || state.firstName.trim().length == 0) {
    error['firstName'] = 'Please enter your first name';
  }

  if (!state.lastName || state.lastName.trim().length == 0) {
    error['lastName'] = 'Please enter your last name';
  }

  if (!state.email || state.email.trim().length == 0) {
    error['email'] = 'Please enter contact email!';
  }

  if (!state.phoneNumber || state.phoneNumber?.trim().length == 0) {
    error['phoneNumber'] = 'This field is required!';
  } else if (
    isNaN(state.phoneNumber) ||
    INT_REGEX.test(state.phoneNumber) == false
  ) {
    error['phoneNumber'] = 'Please provide a valid number!';
  }

  for (let i = 0; i < state.addressDetailsList.length; i++) {
    const addrrDTO = state.addressDetailsList[i];
    let addErr = validateAddress(addrrDTO);

    if (Object.keys(addErr).length) {
      error[addrrDTO.addressType] = { ...addErr };
    }
  }

  return error;
};

export default validateContactInfo;
