import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
// import ReCAPTCHA from 'react-google-recaptcha';
import { login, login2, register, verifyemail } from '../utils/APIUtils';
import {
  ACCESS_TOKEN,
  LOGGED_IN_USER,
  GOOGLE_AUTH_URL,
  USER_TYPE,
  USER_ROLES,
} from '../constants';
// import Error from '../shared/Error';
import { validate } from './login.validation';
import { Link } from 'react-router-dom';
import useRecaptcha from '../hooks/useRecaptcha';
import { useAuth } from '../hooks/useAuth';
import ToastMessage from '../shared/ToastMessage';
const storageUtils = require('../utils/LocalStorageUtils');

/**
 * React functional component for the login page.
 * @returns {JSX.Element} JSX for the login page.
 */
export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [captchaResponse, setCaptchaResponsed] = useRecaptcha();
  const { state } = useLocation();
  const { newUser, userType } = state || { newUser: false };
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isNewUser, setNewUser] = useState(newUser);
  const [rememberUser, setRememberUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [iscodeRequested, setIsCodeRequested] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * React useEffect hook that runs when the component mounts.
   * It sets the initial state of the component based on the value of the `newUser` prop and the logged in user data.
   *
   * @return {void}
   */
  useEffect(() => {
    const loggedInUser = storageUtils.getItem('loginData');
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setEmail(foundUser.email);
      setPassword(foundUser.password);
    }
  }, []);

  /**
   * React useEffect hook that runs when the component mounts.
   * It sets the initial state of the component based on the value of the `newUser` prop and the logged in user data.
   *
   * @return {void}
   */

  useEffect(() => {
    setNewUser(newUser);
  }, [state]);

  /**
   * Handles the login success event.
   *
   * @param {Object} loginData - The login data object.
   * @param {Object} response - The response object from the API.
   *
   * @return {void}
   */
  const handleLoginSuccess = (loginData, response) => {
    let loggedInUser = response.data;
    console.log(loggedInUser);
    // store the user in localStorage
    if (rememberUser) {
      storageUtils.setItem('loginData', loginData);
    }

    storageUtils.setItem(LOGGED_IN_USER, loggedInUser);
    storageUtils.setItem(ACCESS_TOKEN, loggedInUser.idToken);
    auth.setUser(loggedInUser);

    setTimeout(() => {
      navigate('/promoter-panel/home');
    }, 100);
  };

  /**
   * Handles the login error event.
   *
   * @param {Object} err - The error object from the API.
   *
   * @return {void}
   */

  const handleLoginError = (err) => {
    if (!err.error) {
      err.error = 'Something went wrong please try again later';
    }
    setErrors({ auth: err.response.data.message });
    setToast({
      show: true,
      success: false,
      msg: err.response.data.message,
    });

    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * Handles the registration success event.
   *
   * @param {Object} res - The response object from the API.
   *
   * @return {void}
   */

  const handleRegisterSuccess = (res) => {
    setToast({
      show: true,
      success: true,
      msg: 'User Registered Successfully, Please check your email for verification code',
    });
    // setNewUser(false);
    // window.grecaptcha.reset();
    // resetAllFields();
    setIsCodeRequested(true);
    setEmailVerificationCode('');
    setPendingAuthToken(res.data.pending_authentication_token);
  };

  /**
   * Handles the verification code submission event.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   *
   * @return {void}
   */

  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const loginData = {
          email: email,
          password: password,
          // captchaResponse: captchaResponse
        };
        setNewUser(false);
        resetAllFields();
        handleLoginSuccess(loginData, res);
      })
      .catch((e) => {
        console.log(e);
        setEmailVerificationCode('');
      });
  };

  /**
   * Resets all the fields in the login form.
   *
   * @return {void}
   */

  const resetAllFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  /**
   * Submits the login form.
   *
   * @return {void}
   */

  const submitForm = () => {
    const loginData = {
      email: email,
      password: password,
      // captchaResponse: captchaResponse
    };

    if (isNewUser) {
      loginData.firstName = firstName;
      loginData.lastName = lastName;
      // loginData.fullName = fullName;
      loginData.userType = USER_TYPE.PATRON; // always promoter
      register(loginData, captchaResponse)
        .then((res) => {
          handleRegisterSuccess(res);
          // handleLoginSuccess(loginData, res);
        })
        .catch((err) => {
          handleLoginError(err);
        });
    } else {
      login2(loginData, captchaResponse)
        .then((res) => {
          if (res.data.pending_authentication_token) {
            setPendingAuthToken(res.data.pending_authentication_token);
            setIsCodeRequested(true);
            setToast({ show: true, success: true, msg: res.data.message });
          } else {
            handleLoginSuccess(loginData, res);
          }
        })
        .catch((err) => {
          handleLoginError(err);
        });
    }
  };

  /**
   * Handles the submit event.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   * @return {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(
      isNewUser,
      email,
      password,
      captchaResponse,
      confirmPassword,
      firstName,
      lastName
    );

    // if(process.env.NODE_ENV == "development") {
    //   delete errors.captcha;
    // }

    // TODO: remove this line of code once captcha is working on production
    delete errors.captcha;

    // if (
    //   window.location.hostname === "localhost" ||
    //   window.location.hostname === "54.156.62.160"
    // ) {
    //   delete errors.captcha;
    // }
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      submitForm();
    }

    setErrors(errors);
  };

  /**
   * Resets the form state.
   *
   * @return {void}
   */
  const resetFormState = () => {
    setErrors({});
    setEmail('');
    setPassword('');

    if (process.env.NODE_ENV != 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * Handles the login option selection.
   *
   * @param {boolean} isNewUser - Flag indicating if the user is new or not.
   * @return {void}
   */
  const handleChooseLoginOption = (isNewUser) => {
    setNewUser(!isNewUser);
    resetFormState();
    setEmailVerificationCode('');
    setIsCodeRequested(false);
    setPendingAuthToken('');
  };

  return (
    <div
      id="banner"
      className="h-100 d-flex align-items-center justify-content-center"
    >
      <Card className="login-box">
        <Card.Body className="p-4">
          <Card.Title className="text-primary text-uppercase mb-0 fw-bold">
            {!isNewUser ? 'Log in' : 'Sign up'}
          </Card.Title>
          <Card.Text className="fs-12 mb-2">
            {isNewUser
              ? 'Already have an account?'
              : 'Not registered with us yet?'}
            <button
              type="button"
              onClick={() => handleChooseLoginOption(isNewUser)}
              className="text-primary fw-bold  text-decoration-none btn btn-link fs-12 mb-1 ms-2 p-0"
            >
              {isNewUser ? 'Log in' : 'Sign up'}
            </button>
          </Card.Text>

          {/* login form */}
          {!iscodeRequested && (
            <Form onSubmit={handleSubmit}>
              {isNewUser && (
                <>
                  <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label
                      className="fw-bold mb-1"
                      aria-label="first name"
                    >
                      First name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name"
                      maxLength="150"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errors.firstName && (
                      <Form.Text className="text-danger">
                        {errors.firstName}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label className="fw-bold mb-1" aria-label="last name">
                      Last name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name"
                      maxLength="150"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastName && (
                      <Form.Text className="text-danger">
                        {errors.lastName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold mb-1" aria-label="username">
                  {isNewUser ? 'Email' : 'Username'}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="fw-bold mb-1" aria-label="password">
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  minLength="8"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password}
                  </Form.Text>
                )}
                {errors.auth && (
                  <Form.Text className="text-danger">{errors.auth}</Form.Text>
                )}
              </Form.Group>

              {isNewUser && (
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                  <Form.Label
                    className="fw-bold mb-1"
                    aria-label="password confirmation"
                  >
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    minLength="8"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && (
                    <Form.Text className="text-danger">
                      {errors.confirmPassword}
                    </Form.Text>
                  )}
                </Form.Group>
              )}

              <Form.Group className="d-flex mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  onChange={(e) => setRememberUser(e.target.value)}
                  className="fs-12"
                  type="checkbox"
                  label="Keep me signed in"
                  aria-label="checkbox for keep me signed in"
                />
                <Link
                  to="/forgot-password"
                  className="text-primary text-decoration-none fs-12 fw-bold ms-auto"
                >
                  Forgot Password?
                </Link>
              </Form.Group>

              {process.env.NODE_ENV !== 'development' && (
                <div id="captchaDiv"></div>
              )}
              {/* <ReCAPTCHA
                          sitekey="6Le7W5EgAAAAAFbXUO0Ur9oFlf_ic7YeQV-O9xyx" // demo site key added for testing => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                          onChange={onChange}
                      /> */}
              {process.env.NODE_ENV !== 'development' && errors.captcha && (
                <Form.Text className="text-danger">{errors.captcha}</Form.Text>
              )}

              <Card.Text className="fs-10 mt-3">
                By continuing past this page, you agree to the Terms of Use and
                understand that information will be used as described in our
                Privacy Policy.
              </Card.Text>

              <Button
                variant="primary"
                type="submit"
                className="w-100 fw-bold text-uppercase text-white"
              >
                {isNewUser ? 'Create your account' : 'Log In'}
              </Button>
            </Form>
          )}

          {iscodeRequested && (
            <Form onSubmit={handleSubmitVerificationCode}>
              <h6 className="my-4">
                We have sent a 6 digit verification code on your email. Please
                enter the 6 digit code to login.
              </h6>
              <Form.Group className="mb-3" controlId="verifyEmailCode">
                <Form.Label
                  className="fw-bold mb-1"
                  aria-label="Enter code to verify email"
                >
                  Enter Code
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter code here"
                  maxLength="6"
                  value={emailVerificationCode}
                  onChange={(e) => setEmailVerificationCode(e.target.value)}
                />
                {errors.emailVerificationCode && (
                  <Form.Text className="text-danger">
                    {errors.emailVerificationCode}
                  </Form.Text>
                )}
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="w-100 fw-bold text-uppercase text-white"
                disabled={
                  !emailVerificationCode || emailVerificationCode.length < 6
                }
              >
                Submit Verification Code
              </Button>
            </Form>
          )}

          {/* {!iscodeRequested && userType != USER_TYPE.PROMOTER && (
            <>
              <Card.Subtitle className="fw-bold mt-3">
                <div className="hr-text-wrapper">
                  <span className="hr-text">
                    OR {isNewUser ? "SIGN UP" : "LOG IN"} WITH
                  </span>
                </div>
              </Card.Subtitle>

              <div className="d-flex align-items-center justify-content-center mt-3">
                <a href={GOOGLE_AUTH_URL}>
                  <img
                    src="/google-square.png"
                    width="50"
                    height="30"
                    alt="google logo"
                  />
                </a>
              </div>
            </>
          )} */}
        </Card.Body>
      </Card>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />

      {/* <Error /> */}
    </div>
  );
}
