import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Dropdown,
  Spinner,
  Button,
  Modal,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
} from 'react-bootstrap';
import { EVENT_STATUS } from '../../constants';
import {
  getEventGenres,
  getEventTypes,
  getManageEvents,
  getLocationTypes,
  getEventDetails,
  updateEvent,
  updateEventStatus,
} from '../../utils/APIUtils';
import { useAsync } from '../../hooks/useAsync';
import Pagination from '../../shared/Pagination';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faChevronDown,
  faPencilSquare,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getTsFromDateTimeStrings } from '../../PromoterPanel/Events/CreateEvent/useCreateEvent';
import { formatDate } from '../../utils/CommonUtils';
import { validateAdminEventDetails } from '../../PromoterPanel/Events/CreateEvent/eventFormValidator';
import ToastMessage from '../../shared/ToastMessage';

/**
 * Configuration object for the editor component.
 * @type {Object}
 * @property {Array} toolbar - The toolbar buttons for the editor.
 */
const editorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'link',
    'undo',
    'redo',
    'numberedList',
    'bulletedList',
  ],
};

/**
 * React functional component to manage the events.
 * @returns {JSX.Element} The event management component.
 */

const EventManagement = () => {
  /**
   * Destructuring assignment to extract properties from the useAsync hook.
   * @type {Object}
   * @property {Object|null} data - The data returned from the API call.
   * @property {boolean} loading - Indicates if the API call is in progress.
   * @property {Error|null} error - The error object from the API call.
   * @property {Function} run - The function to execute the API call.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * State hook is used to set the event list.
   * @type {Array}
   */
  const [eventList, setEventList] = useState([]);

  /**
   * State hook is used to set the search query.
   * @type {string}
   */
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * State hook to set the current page.
   * @type {number}
   */
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * State hook to set the total pages.
   * @type {number}
   */
  const [totalPages, setTotalPages] = useState(0);

  /**
   * State hook to set the event status.
   * @type {string}
   */
  const [eventStatus, setEventStatus] = useState('');

  /**
   * State hook to set the event types.
   * @type {null|Object}
   */
  const [eventTypes, setEventTypes] = useState(null);

  /**
   * State hook to set the genre types.
   * @type {null|Object}
   */
  const [genreTypes, setGenreTypes] = useState(null);

  /**
   * State hook to set the locations types.
   * @type {null|Object}
   */
  const [locationTypes, setLocationTypes] = useState(null);

  /**
   * State hook to manage the errors.
   * @type {null|Object}
   */
  const [errors, setErrors] = useState(null);

  /**
   * State hook to manage the toast.
   * @type {Object}
   * @property {boolean} show - Indicates if toast should be shown.
   * @property {boolean} success - Indicates if toast is a success message.
   * @property {string} msg - The message to display in the toast.
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * State hook to manage show event modal.
   * @type {boolean}
   */
  const [showEventModal, setShowEventModal] = useState(false);

  /**
   * State hook to manage the show status modal.
   * @type {boolean}
   */
  const [showStatusModal, setShowStatusModal] = useState(false);

  /**
   * State hook is used to set the current event.
   * @type {null|Object}
   */
  const [currentEvent, setCurrentEvent] = useState(null);

  /**
   * State hook is used to set the event detail.
   * @type {null|Object}
   */
  const [eventDetails, setEventDetails] = useState(null);

  /**
   * This function is used to update the state of an event.
   * @param {Object} state - The state object to update.
   * @returns {void}
   */
  const updateEventState = (state) =>
    setEventDetails((prevState) => {
      return { ...prevState, ...state };
    });

  /**
   * Fetches the filtered list of events based on the search query, page number, and event status.
   * @returns {void}
   */
  const getFilteredEventList = () => {
    let query = searchQuery.trim(),
      pageNo = currentPage,
      status = eventStatus;

    getManageEvents(status, pageNo, query)
      .then((res) => {
        const { eventsList } = res.data;
        setEventList(eventsList);
        if (res.data.totalPages != totalPages) {
          setTotalPages(res.data.totalPages);
          setCurrentPage(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Handles the page changes and triggers the fetch of the filtered event list.
   * @param {number} page - The page number to fetch.
   * @returns {void}
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  /**
   * Debounces the search query and fetches the filtered event list.
   * @returns {void}
   */
  useEffect(() => {
    const getData = setTimeout(() => {
      getFilteredEventList();
    }, 300);

    return () => clearTimeout(getData);
  }, [searchQuery, currentPage, eventStatus]);

  /**
   * Fetches the initial data for the event management page, including the event list, event types,
   * event genres, and location types.
   * @returns {void}
   */
  useEffect(() => {
    run(() => getManageEvents())
      .then((res) => {
        setEventList(res.data.eventsList);
        setTotalPages(res.data.totalPages);
      })
      .catch((e) => {
        console.log(e);
      });

    getEventTypeList();
    getEventGenresList();
    getLocationTypeList();
  }, []);

  /**
   * Fetches the list of location types.
   * @returns {Promise<void>}
   */
  const getLocationTypeList = () => {
    getLocationTypes().then((res) => setLocationTypes(res.data));
  };

  /**
   * Fetches the list of event types.
   * @returns {Promise<void>}
   */
  const getEventTypeList = () => {
    getEventTypes().then((res) => setEventTypes(res.data));
  };

  /**
   * Fetches the list of event genres.
   * @returns {Promise<void>}
   */
  const getEventGenresList = () => {
    getEventGenres().then((res) => setGenreTypes(res.data));
  };

  /**
   * Filters the event list based on the status.
   * @param {string} status - The status to filter by.
   * @returns {void}
   */
  const filterByEventStatus = (status) => {
    if (status !== eventStatus) {
      setEventStatus(status);
      // getUserList();
    }
  };

  /**
   * Handles the event details display for the user.
   * @param {string} eventId - The ID of the event.
   * @returns {void}
   */
  const handleShowEventDetails = (eventId) => {
    setShowEventModal(true);
    getEventDetails(eventId)
      .then((res) => {
        console.log('event details ', res.data);
        res.data.eventStartTime = getTsFromDateTimeStrings(
          res.data.eventStartDate,
          res.data.eventStartTime
        );
        res.data.eventEndTime = getTsFromDateTimeStrings(
          res.data.eventEndDate,
          res.data.eventEndTime
        );
        setEventDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // This function is used to handle the search event.
  /**
   * Handles the search event.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object.
   * @returns {void}
   */
  const handleSearch = (e) => {
    let val = e.target.value;
    if (val.trim().length) {
      setSearchQuery(val);
    }
  };

  /**
   * Handles the submit event.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The event object.
   * @return {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = validateAdminEventDetails(eventDetails);

    if (Object.keys(error).length) {
      setErrors(error);
    } else {
      let payload = {
        eventTitle: eventDetails.eventTitle,
        customPromoterName: eventDetails.customPromoterName,
        eventTypeId: eventDetails.eventTypeModel.id,
        genreId: eventDetails.genreModel.genreId,
        eventStartDate: eventDetails.eventStartDate,
        eventStartTime: moment(eventDetails.eventStartTime).format('hh:mm A'),
        eventEndDate: eventDetails.eventEndDate,
        eventEndTime: moment(eventDetails.eventEndTime).format('hh:mm A'),
        timezone: eventDetails.timezone,
        summary: eventDetails.summary,
        description: eventDetails.description,
        twitterUrl: eventDetails.socialMediaLinksModel.twitter,
        facebook: eventDetails.socialMediaLinksModel.facebook,
        instagram: eventDetails.socialMediaLinksModel.instagram,
        tiktok: eventDetails.socialMediaLinksModel.tiktok,
      };

      updateEvent(eventDetails.id, payload)
        .then((res) => {
          setToast({
            show: true,
            msg: 'Event updated successfully',
            success: true,
          });
        })
        .catch((err) => {
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
        });
    }
  };

  /**
   * This function is used to save the updated status.
   *
   * @return {void} This function does not return anything.
   */
  const saveUpdatedStatus = () => {
    /** @type {Object} payload - The payload object. */
    let payload = {
      eventIds: [currentEvent.eventId],
      statusChangeMessage: currentEvent.statusChangeMessage,
    };
    updateEventStatus(currentEvent.approvalStatus, payload)
      .then((res) => {
        setToast({
          show: true,
          msg: 'Event updated successfully',
          success: true,
        });
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
  };

  /**
   * Renders a loading indicator if data is still being fetched.
   *
   * @return {JSX.Element|null} The loading indicator or null if data is already loaded.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="m-4 px-4">
      <h2 className="fw-bold">Event Management</h2>
      <p className="mb-0">Organize and approve events</p>
      <div className="d-flex my-4">
        <div className="d-flex align-items-center">
          <Form.Label className="mb-0 me-2">Filter By:</Form.Label>
          <Dropdown onSelect={filterByEventStatus}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="rounded text-capitalize"
            >
              {eventStatus
                ? EVENT_STATUS[eventStatus].toLowerCase()
                : 'All Events'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="">All Events</Dropdown.Item>
              {Object.keys(EVENT_STATUS).map((status, i) => {
                return (
                  <Dropdown.Item
                    key={i}
                    eventKey={status}
                    className="text-capitalize"
                  >
                    {EVENT_STATUS[status].toLowerCase()}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Form.Group className="ms-auto">
          <Form.Control
            type="text"
            placeholder="Search by event name"
            onChange={handleSearch}
          />
        </Form.Group>
      </div>
      <Row className="bg-primary text-white">
        <Col className="p-3">Event ID</Col>
        <Col className="p-3">Event name</Col>
        <Col className="p-3">Event Date</Col>
        <Col className="p-3">Type of event</Col>
        <Col className="p-3">Promoter/Company</Col>
        <Col className="p-3">Status</Col>
      </Row>

      {eventList.length == 0 && (
        <h5 className="text-center py-4">No Results Found!</h5>
      )}

      {eventList.map((event, i) => {
        return (
          <Row key={i} className="bg-header mb-2 border border-gray">
            <Col className="p-3">
              <Button
                onClick={() => handleShowEventDetails(event.eventId)}
                variant="link"
                className="text-secondary text-decoration-none"
              >
                {event.generatedEventId}
              </Button>
            </Col>
            <Col className="p-3">{event.eventTitle}</Col>
            <Col className="p-3">
              {event.eventDate} {event.eventTime}
            </Col>
            <Col className="p-3">Live Stream</Col>
            <Col className="p-3">{event.customPromoterName || '-'}</Col>
            <Col className="p-3 text-capitalize">
              {event.approvalStatus ? (
                <>
                  {EVENT_STATUS[event.approvalStatus].toLowerCase()}
                  <Button
                    onClick={() => {
                      setCurrentEvent(event);
                      setShowStatusModal(true);
                    }}
                    variant="link"
                  >
                    <FontAwesomeIcon
                      className="text-secondary py-0"
                      icon={faPencil}
                    />
                  </Button>
                </>
              ) : (
                '-'
              )}
            </Col>
          </Row>
        );
      })}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

      <Modal
        size="lg"
        show={showEventModal}
        backdrop="static"
        onHide={() => {
          setShowEventModal(false);
          setErrors(null);
        }}
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="px-3">Event Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 pb-5 modal-scroll">
            {eventDetails && (
              <>
                <h5 className="mb-3">Basic Info</h5>
                <Form.Group className="mb-3">
                  <Form.Label className="required">Event Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={eventDetails.eventTitle}
                    onChange={(e) =>
                      updateEventState({ eventTitle: e.target.value })
                    }
                  />
                  {errors && errors.eventTitle && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.eventTitle}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Promoter</Form.Label>
                  <Form.Control
                    type="text"
                    value={eventDetails.customPromoterName}
                    onChange={(e) =>
                      updateEventState({ customPromoterName: e.target.value })
                    }
                  />
                </Form.Group>

                <div className="d-flex align-items-center mb-4">
                  <Form.Group
                    controlId="eventTypeId"
                    className="flex-grow-1 me-3"
                  >
                    <Form.Label>Type:</Form.Label>
                    <Form.Select
                      value={eventDetails.eventTypeModel.id}
                      onChange={(e) =>
                        updateEventState({
                          eventTypeModel: {
                            ...eventDetails.eventTypeModel,
                            id: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="">Select</option>
                      {eventTypes &&
                        eventTypes.map((item, i) => {
                          return (
                            <option
                              key={i}
                              disabled={item.status == 'INACTIVE'}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group controlId="genreId" className="flex-grow-1">
                    <Form.Label>Genre:</Form.Label>
                    <Form.Select
                      value={eventDetails.genreModel.id}
                      onChange={(e) =>
                        updateEventState({
                          genreModel: {
                            ...eventDetails.genreModel,
                            id: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="">Select</option>
                      {genreTypes &&
                        genreTypes.map((item, i) => {
                          return (
                            <option
                              key={i}
                              disabled={item.status == 'INACTIVE'}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </div>
                <hr className="my-4" />

                <div className="my-4 col-12 col-md-6">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    readOnly
                    value={eventDetails.locationTypeModel.type}
                  />
                </div>

                <hr className="my-4" />

                <div className="d-flex align-items-start mb-3 w-75">
                  <Form.Group controlId="eventStartDate" className="w-50">
                    <Form.Label className="required">Start Date</Form.Label>
                    {/* <Form.Control type="date"  onChange={handleChange}/>  */}
                    <DatePicker
                      minDate={new Date()}
                      className="form-control w-20"
                      selected={moment(eventDetails.eventStartDate).toDate()}
                      onChange={(date) =>
                        updateEventState({ eventStartDate: formatDate(date) })
                      }
                    />

                    {errors && errors.eventStartDate && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.eventStartDate}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group controlId="eventStartTime" className="ms-3 w-50">
                    <Form.Label className="required">Start Time</Form.Label>
                    <DatePicker
                      className="form-control w-20"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      selected={new Date(eventDetails.eventStartTime)}
                      onChange={(date) =>
                        updateEventState({ eventStartTime: date.getTime() })
                      }
                    />

                    {errors && errors.eventStartTime && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.eventStartTime}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <div className="d-flex align-items-start mb-3 w-75">
                  <Form.Group controlId="eventEndDate" className="me-3 w-50">
                    <Form.Label className="required">End Date</Form.Label>
                    <DatePicker
                      minDate={new Date()}
                      className="form-control w-20"
                      selected={moment(eventDetails.eventEndDate).toDate()}
                      onChange={(date) =>
                        updateEventState({ eventEndDate: formatDate(date) })
                      }
                    />

                    {errors && errors.eventEndDate && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.eventEndDate}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group controlId="eventEndTime" className="w-50">
                    <Form.Label className="required">End Time</Form.Label>
                    <DatePicker
                      className="form-control w-20"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      selected={new Date(eventDetails.eventEndTime)}
                      onChange={(date) =>
                        updateEventState({ eventEndTime: date.getTime() })
                      }
                    />

                    {errors && errors.eventEndTime && (
                      <Form.Text className="text-danger">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="me-2"
                        />
                        {errors.eventEndTime}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6 col-md-8">
                  <Form.Label>Timezone</Form.Label>
                  <Dropdown id="timezone">
                    <Dropdown.Toggle
                      variant="outlined"
                      className="border w-100 no-caret"
                    >
                      <div className="text-start d-flex align-items-center">
                        <span>
                          {/* we have only one timezone for now so static value is provided */}
                          Eastern Standard Time(EST)
                          <br />
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="ms-auto"
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item eventKey="EST5EDT">
                        Eastern Standard Time(EST)
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="EST5EDT"> 
                                                        (GMT-0400) Canada(Toronto) Time    
                                                    </Dropdown.Item>  */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <hr className="my-4" />

                <h5 className="mb-3">Main Details</h5>

                <Form.Group className="mb-3">
                  <Form.Label className="required">Event Summary</Form.Label>
                  <Form.Control
                    type="text"
                    value={eventDetails.summary}
                    onChange={(e) =>
                      updateEventState({ summary: e.target.value })
                    }
                  />

                  {errors && errors.summary && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.summary}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="required">
                    Event Description
                  </Form.Label>
                  <CKEditor
                    id="description"
                    editor={ClassicEditor}
                    data={eventDetails.description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      updateEventState({ description: data });
                      // console.log( { event, editor, data } );
                    }}
                    onBlur={(event, editor) => {
                      //console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                      //console.log( 'Focus.', editor );
                    }}
                  />
                  {errors && errors.description && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.description}
                    </Form.Text>
                  )}
                </Form.Group>

                <hr className="my-4" />

                <h5 className="mb-3">Social Media</h5>

                <div className="col-6">
                  <Form.Group controlId="twitterUrl" className="mb-3">
                    <Form.Label className="fw-bold mb-1" aria-label="twitter">
                      Twitter:
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text> twitter.com/ </InputGroup.Text>
                      <Form.Control
                        value={eventDetails.socialMediaLinksModel.twitter}
                        onChange={(e) =>
                          updateEventState({
                            socialMediaLinksModel: {
                              ...eventDetails.socialMediaLinksModel,
                              twitter: e.target.value,
                            },
                          })
                        }
                        aria-describedby="twitter"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="facebook" className="mb-3">
                    <Form.Label className="fw-bold mb-1" aria-label="Facebook">
                      Facebook:
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text> facebook.com/ </InputGroup.Text>
                      <Form.Control
                        value={eventDetails.socialMediaLinksModel.facebook}
                        onChange={(e) =>
                          updateEventState({
                            socialMediaLinksModel: {
                              ...eventDetails.socialMediaLinksModel,
                              facebook: e.target.value,
                            },
                          })
                        }
                        aria-describedby="facebook"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="instagram" className="mb-3">
                    <Form.Label className="fw-bold mb-1" aria-label="Instagram">
                      Instagram:
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text> Instagram.com/ </InputGroup.Text>
                      <Form.Control
                        value={eventDetails.socialMediaLinksModel.instagram}
                        onChange={(e) =>
                          updateEventState({
                            socialMediaLinksModel: {
                              ...eventDetails.socialMediaLinksModel,
                              instagram: e.target.value,
                            },
                          })
                        }
                        aria-describedby="Instagram"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="Tiktok" className="mb-3">
                    <Form.Label className="fw-bold mb-1" aria-label="Instagram">
                      Tik Tok:
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text> TikTok.com/ </InputGroup.Text>
                      <Form.Control
                        value={eventDetails.socialMediaLinksModel.tiktok}
                        onChange={(e) =>
                          updateEventState({
                            socialMediaLinksModel: {
                              ...eventDetails.socialMediaLinksModel,
                              tiktok: e.target.value,
                            },
                          })
                        }
                        aria-describedby="TikTok"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setShowEventModal(false);
                setErrors(null);
              }}
              className="px-4 me-2"
              variant="outline-dark"
            >
              Cancel
            </Button>
            <Button type="submit" variant="orange" className="text-white px-4">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showStatusModal}
        backdrop="static"
        onHide={() => setShowStatusModal(false)}
        centered
      >
        {currentEvent && (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="px-3">
                {currentEvent.eventTitle}
                <h6 className="text-darkGray mb-0">
                  {currentEvent.eventDate} {currentEvent.eventTime}
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <div className="mb-3">
                <label className="fw-bold me-2">Promoter: </label>
                <span>{currentEvent.customPromoterName || '-'}</span>
              </div>
              <h5 className="mb-3">Update Status</h5>

              <Form>
                {Object.keys(EVENT_STATUS).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="w-50 text-capitalize d-flex justify-content-between"
                    >
                      <Form.Label
                        className="me-3 text-capitalize"
                        htmlFor={item}
                      >
                        {EVENT_STATUS[item].toLowerCase()}
                      </Form.Label>
                      <Form.Check
                        reverse
                        label=""
                        name="approvalStatus"
                        type="radio"
                        id={item}
                        checked={
                          currentEvent.approvalStatus.toLowerCase() ==
                          item.toLowerCase()
                        }
                        onChange={(e) =>
                          setCurrentEvent({
                            ...currentEvent,
                            approvalStatus: item,
                          })
                        }
                      />
                    </div>
                  );
                })}

                <Form.Control
                  value={currentEvent.statusChangeMessage}
                  onChange={(e) =>
                    setCurrentEvent({
                      ...currentEvent,
                      statusChangeMessage: e.target.value,
                    })
                  }
                  className="mt-3"
                  as="textarea"
                  rows="4"
                  placeholder="Why was rejected? write a short explanation to the promoter here or access the event info and send the message directly."
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setShowStatusModal(false);
                }}
                className="px-4 me-2"
                variant="outline-dark"
              >
                Cancel
              </Button>

              <Button
                onClick={() => saveUpdatedStatus()}
                variant="orange"
                className="text-white px-4"
              >
                Save
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default EventManagement;
