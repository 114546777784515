import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useMatch } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronDown,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';
import { EVENT_STEPS } from '../../../../constants';
import { capitalizeStr } from '../../../../utils/CommonUtils';
import moment from 'moment';

const STEP_NAME_MAP = new Map([
  [EVENT_STEPS.BASIC_DETAILS, 'Basic Event Information'],
  [EVENT_STEPS.MAIN_DETAILS, 'Main Event Setup Page'],
  [EVENT_STEPS.EVENT_CHOICE, 'Event Details Page'],
  [EVENT_STEPS.TICKET_DETAILS, 'Tickets'],
  [EVENT_STEPS.FINAL_DETAILS, 'Publish'],
]);

/**
 * Render the side panel of the create event sequence.
 * Tracks on which step currently promoter is in the journey.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.formState - The form state object.
 * @param {Object} props.formState.basicEventDetailsDto - The basic event details DTO.
 * @returns {JSX.Element} The rendered side panel component.
 */
const EventSidePanel = (props) => {
  // let location = useLocation();

  /**
   * The form state object.
   * @type {Object}
   * @prop {Object} basicEventDetailsDto - The basic event details DTO.
   */
  const { basicEventDetailsDto } = props.formState;
  /**
   * The function to navigate to a specific location.
   *
   * @function
   * @name useNavigate
   * @returns {Function} The navigate function.
   */
  const navigate = useNavigate();

  /**
   * The URL match object.
   * @type {Object}
   * @prop {String} path - The current path.
   * @prop {String} eventId - The event ID.
   */
  const { path, eventId } = useMatch(
    '/promoter-panel/events/create-event/:path/:eventId'
  ).params;
  /**
   * The step number in the event creation sequence.
   * @type {Number}
   */
  const [step, setStep] = useState(1);
  /**
   * Returns the current step number based on the given path.
   *
   * @param {string} path - The path to determine the step number.
   * @return {number} The current step number.
   */
  const getCurrentStep = (path) => {
    let stepNumber = 1;
    switch (path.toUpperCase()) {
      case EVENT_STEPS.MAIN_DETAILS:
        stepNumber = 2;
        break;
      case EVENT_STEPS.EVENT_CHOICE:
        stepNumber = 3;
        break;
      case EVENT_STEPS.TICKET_DETAILS:
        stepNumber = 4;
        break;
      case EVENT_STEPS.FINAL_DETAILS:
        stepNumber = 5;
        break;
      default:
        stepNumber = 1;
        break;
    }
    return stepNumber;
  };

  /**
   * A React effect hook that runs when the component mounts or the dependencies change.
   * Updates the step state based on the current path.
   *
   * @return {void}
   */
  useEffect(() => {
    const stepNumber = getCurrentStep(path);
    setStep(stepNumber);
  }, [path]);

  /**
   * Navigates to the given step.
   *
   * @param {number} step - The step number to navigate to.
   * @param {string} eventId - The id of the event to navigate to.
   * @return {void}
   */
  const goToStep = (step, eventId) => {
    navigate(`/promoter-panel/events/create-event/${step}/${eventId}`);
  };

  /**
   * Indicates whether the user can preview the event. User should have filled at least basic and main details to preview event.
   * @type {boolean}
   */
  let canPreview = true;

  const presentSteps = Object.keys(EVENT_STEPS).filter((step) =>
    STEP_NAME_MAP.has(step)
  );

  return (
    <div className="px-0 h-100 bg-dark text-white">
      <div className="d-flex border-bottom border-1 border-lightGray px-3">
        {/* desktop view */}
        <div className="d-none d-md-block my-4 mx-auto">
          <Button
            as={Link}
            to="/promoter-panel/events"
            variant="link"
            className="text-decoration-none p-0 text-white fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Go Back to Events
          </Button>
        </div>
        {/* desktop view */}

        {/* mobile/tablet view */}
        <div className="d-md-none my-2">
          <Button
            as={Link}
            to="/promoter-panel/events"
            variant="link"
            className="text-decoration-none p-0 text-white fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Events
          </Button>
          <h5 className="fw-bold mb-0 mt-3">
            {basicEventDetailsDto.eventTitle}
          </h5>
          <p>
            {basicEventDetailsDto.eventStartDate}{' '}
            {basicEventDetailsDto.eventStartTime}
          </p>
        </div>
        {/* mobile/tablet view */}
      </div>
      <div className="py-4 border-bottom border-1 border-lightGray d-none d-md-block">
        {/* <div className="d-flex justify-content-center">
                    <div className="border border-lightGray px-5 bg-white text-dark py-1">
                        <span>Draft</span>
                    </div>
                </div> */}
        <div className="text-center mt-4">
          <h5 className="fw-bold">{basicEventDetailsDto.eventTitle}</h5>
          <p>
            {moment(basicEventDetailsDto.eventStartDate).format('MMM DD, YYYY')}{' '}
            {moment(basicEventDetailsDto.eventStartTime).format('hh:mm A')}
          </p>

          {canPreview && (
            <Button
              to={`/preview-event/${eventId}`}
              as={Link}
              variant="light"
              className="text-dark text-decoration-none"
            >
              <FontAwesomeIcon icon={faUpRightFromSquare} className="me-2" />
              Preview
            </Button>
          )}
        </div>
      </div>
      <div className="mt-3 d-none d-md-block">
        <ul className="list-event-steps">
          {presentSteps.map((event_step, i) => (
            <li
              key={i}
              className={`text-capitalize ${i + 1 < step ? 'checked' : ''} ${
                i + 1 > step ? 'disabled' : ''
              }`}
            >
              <Button
                onClick={() => goToStep(event_step, eventId)}
                className={`p-0 text-decoration-none fw-bold ${
                  i + 1 < step ? 'text-primary' : 'text-white'
                }`}
                variant="link"
                disabled={i + 1 > step}
              >
                {STEP_NAME_MAP.get(event_step)}
              </Button>
            </li>
          ))}

          {/* <li className="checked">Basic Info </li>
                        <li className="checked">Details</li>
                        <li className="checked">Online Event Page </li>
                        <li className="checked">Streaming Page</li>
                        <li>Tickets </li>
                        <li>Publish</li> */}
        </ul>
      </div>
    </div>
  );
};

export default EventSidePanel;
