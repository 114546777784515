import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Col,
  Row,
  Nav,
  Navbar,
  Modal,
  ModalBody,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SOCIAL_MEDIA_URLS } from '../constants';
import PrivacyPolicy from '../policies/PrivacyPolicy';
import TermsAndConditions from '../policies/TermsAndConditions';

/**
 * React component renders an HTML elements of the footer section.
 *
 * @returns {React.Element} The rendered footer component.
 */
const Footer = () => {
  /**
   * State hook to manage the "show privacy" modal.
   *
   * @type {Array}
   * @property {boolean} 0 - Indicates if the privacy modal is shown.
   * @property {function} 1 - Function to set the privacy modal visibility.
   */
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  /**
   * State hook to manage the "show terms" modal.
   *
   * @type {Array}
   * @property {boolean} 0 - Indicates if the terms modal is shown.
   * @property {function} 1 - Function to set the terms modal visibility.
   */
  const [showTermsModal, setShowTermsModal] = useState(false);

  return (
    <footer className="main">
      <Row>
        <Col className="justify-content-center d-flex">
          <Nav className="flex-column">
            <Nav.Link className=" fw-bold" href="/home">
              Home
            </Nav.Link>
            <Nav.Link className="fw-bold" href="/about">
              About Us
            </Nav.Link>
            <Nav.Link className="fw-bold" href="/events">
              Events
            </Nav.Link>
            <Nav.Link
              onClick={() => setShowPrivacyModal(true)}
              className="fw-bold"
            >
              Privacy Policy
            </Nav.Link>
            <Nav.Link
              onClick={() => setShowTermsModal(true)}
              className=" fw-bold"
            >
              Terms of use
            </Nav.Link>
          </Nav>
        </Col>
        <Col className="justify-content-center d-flex">
          <Nav className="flex-column">
            <Nav.Link className="fw-bold" href="/home">
              Contact Us
            </Nav.Link>
            <Nav.Link className=" mt-5 fw-bold" href="tel:519-434-9700">
              519-434-9700
            </Nav.Link>
            <Nav.Link className=" fw-bold" href="tel:226-213-4811">
              226-213-4811
            </Nav.Link>
          </Nav>
        </Col>
        <Col className="justify-content-center d-flex d-none d-md-block">
          <Nav className="flex-row pt-4">
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.TWITTER}
              className="px-1 d-flex"
            >
              <span className="twitter-logo">
                <FontAwesomeIcon icon={faTwitter} className="text-white" />
              </span>
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.FACEBOOK}
              className="px-1"
            >
              <img src="/facebook.svg" alt="facebook logo" />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.YOUTUBE}
              className="px-1"
            >
              <img src="/youtube.svg" alt="youtube logo" />
            </Nav.Link>
          </Nav>
        </Col>
        <Col className="justify-content-center d-flex">
          <Navbar.Brand as={Link} className="m-0 logo" to="home">
            {/* <img className="img-fluid" src="/logo-light.svg" alt="website logo"/> */}
          </Navbar.Brand>
        </Col>
      </Row>
      <div className="text-center mt-4">
        <div className="justify-content-center d-flex d-md-none">
          <Nav className="flex-row pt-4 justify-content-center">
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.TWITTER}
              className="px-1 d-flex"
            >
              <span className="twitter-logo">
                <FontAwesomeIcon icon={faTwitter} className="text-white" />
              </span>
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.FACEBOOK}
              className="px-1"
            >
              <img src="/facebook.svg" alt="facebook logo" />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href={SOCIAL_MEDIA_URLS.YOUTUBE}
              className="px-1"
            >
              <img src="/youtube.svg" alt="youtube logo" />
            </Nav.Link>
          </Nav>
        </div>
        <small className="fw-bold mt-5">
          Copyright &copy; EventLinx Inc. 2023{' '}
        </small>{' '}
        <br />
        <small className="fw-bold mt-5">All Rights Reserved. </small>
        <br />
        <small className="fw-bold mt-5">Powered By EventLinx Inc. </small>
      </div>
      <Modal
        size="xl"
        show={showPrivacyModal || showTermsModal}
        onHide={() => {
          setShowPrivacyModal(false);
          setShowTermsModal(false);
        }}
      >
        <Modal.Header className="border-0" closeButton>
          {' '}
        </Modal.Header>
        <Modal.Body>
          {showPrivacyModal && <PrivacyPolicy />}
          {showTermsModal && <TermsAndConditions />}
        </Modal.Body>
      </Modal>
    </footer>
  );
};

export default Footer;
