import React from 'react';
import { Toast } from 'react-bootstrap';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

/**
 * React functional component display a toast message with a success or error based on the props.
 *
 * @param {Object} props - The props object
 * @param {boolean} props.show - Whether to show the toast message
 * @param {function} props.onClose - The function to call when the toast message is closed
 * @param {boolean} props.success - Whether the message is a success message
 * @param {string} props.msg - The message to display
 * @return {JSX.Element} The toast message component
 */
const ToastMessage = (props) => {
  // http error codes goes here

  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast
        show={props.show}
        onClose={props.onClose}
        autohide={true}
        delay={3000}
      >
        <Toast.Body
          className={`${
            props.success
              ? 'bg-success border-success'
              : 'bg-danger border-danger'
          } text-white`}
        >
          <FontAwesomeIcon
            icon={props.success ? faCheckCircle : faTriangleExclamation}
            className="me-2"
          />
          {props.msg}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastMessage;
