import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Form,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Modal,
} from 'react-bootstrap';
import {
  ACCESS_TOKEN,
  USER_TYPE,
  USER_ROLES,
  LOGGED_IN_USER,
} from '../constants';
import { useAuth } from '../hooks/useAuth';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCategoryDetails, getUserDetails } from '../utils/APIUtils';

import {
  getItem as getItemFromLocalStorage,
  setItem as setItemToLocalStorage,
} from '../utils/LocalStorageUtils';

// Import local storage utilities module.
// const storageUtils = require("../utils/LocalStorageUtils");
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

// React functional component export and renders and html elements for the header module.
/**
 * React functional component that exports and renders HTML elements for the header module.
 *
 * @param {Object} props - The props object containing the properties for the component.
 * @return {JSX.Element} - The rendered JSX element for the header module.
 */
export default function Header(props) {
  /**
   * Constant auth accessing the authentication context.
   * @type {Object}
   */
  const auth = useAuth();

  /**
   * Variable navigate accessing the navigate context.
   * @type {Function}
   */
  let navigate = useNavigate();

  /**
   * State hook to manage the show.
   * @type {Boolean}
   */
  const [show, setShow] = useState(false);

  /**
   * State hook to check whether the theme is light.
   * @type {Boolean}
   */
  const [islightTheme, setIsLightTheme] = useState(false);

  /**
   * Constant function is used to close a modal or dialog.
   */
  // const handleClose = () => setShow(false);

  /**
   * Constant function is used to show the modal or dialog.
   */
  const handleShow = () => setShow(true);

  /**
   * Constant function is used to check if the user is admin.
   * @function
   * @return {Boolean} Whether the user is an admin.
   */
  const isAdmin = React.useMemo(() => {
    return auth && auth.user
      ? auth.user.roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1
      : false;
  }, [auth.user]);

  // This function is used to determine the route for accessing events based on user role.
  // const getEventRoute  = () => {
  //     if(auth.user && auth.user.roles.indexOf(USER_ROLES.ROLE_PROMOTER) > -1)
  //         return '/promoter-panel/events';
  //     return '/events';
  // }

  // This function is used to handle the logout event.
  /**
   * Function to handle the logout event.
   * @function
   * @return {void}
   */
  const handleLogout = () => {
    props.handleLogout();
    auth.logout();

    setTimeout(() => {
      navigate('/login');
    }, 100);
  };

  /**
   * Function to handle layout effect.
   *
   * @return {void}
   */
  useLayoutEffect(() => {
    let theme = localStorage.getItem('theme') || 'dark';

    if (theme == 'light') {
      setIsLightTheme(true);
      if (!document.body.classList.contains('theme-light')) {
        document.body.classList.add('theme-light');
      }
    }

    localStorage.setItem('theme', theme);

    // if (document.body.classList.contains('theme-light')) {
    //     islightTheme(true);
    //     theme = 'light';
    // }
  }, []);

  /**
   * Function to handle the effect.
   *
   * @return {void}
   */
  useEffect(() => {
    getUserDetails()
      .then((res) => {
        let { firstName, lastName, profileImageUrl, roles } = res.data;
        const user = {
          firstName,
          lastName,
          profileImageUrl,
          roles,
        };
        setItemToLocalStorage(LOGGED_IN_USER, user);
      })
      .catch((e) => {
        const errStatus = e.response.status;
        if (errStatus == 401) {
          handleLogout();
        }
      });
  }, []);

  // This function is used to switch the theme.
  /**
   * Switches the theme.
   *
   * @return {void}
   */
  const switchTheme = () => {
    let theme = '';
    if (islightTheme) {
      document.body.classList.remove('theme-light');
      theme = 'dark';
    } else {
      document.body.classList.add('theme-light');
      theme = 'light';
    }

    setIsLightTheme(!islightTheme);
    localStorage.setItem('theme', theme);
  };

  // This function is used to navigate the login page with optional state.
  /**
   * Navigates to the login page with optional state.
   *
   * @param {boolean} newUser - Indicates if the user is new.
   * @param {string} userType - The type of user.
   * @return {void}
   */
  const navigateToLogin = (newUser, userType) => {
    // setShow(false);
    navigate('/login', {
      state: { newUser: newUser, userType: userType },
      replace: true,
    });
  };

  return (
    <header className="main sticky-top">
      <Navbar collapseOnSelect expand="lg">
        <Container fluid className="justify-content-start px-5 py-2">
          <Navbar.Brand as={Link} className="m-0 logo" to="promoter-panel/home">
            {/* <img className="img-fluid" src="/logo-light.svg" alt="website logo"/> */}
            {/* <h2 className='mb-0'><span className='text-primary'>EVENT</span>LINX</h2>
                        <h4 className='mb-0'>Make A Connection</h4> */}
          </Navbar.Brand>

          <Navbar.Toggle
            className="ms-auto"
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto d-lg-flex align-items-center">
              {/* <Nav.Item>
                                        <Nav.Link as={Link} to="home" className="fw-bold">Home</Nav.Link>
                                    </Nav.Item> */}

              {!isAdmin && (
                // <>
                //     <Nav.Item>
                //         <Nav.Link as={Link} to={'/promoter-panel/events'} className="fw-bold">Events</Nav.Link>
                //     </Nav.Item>
                //     {/* <Nav.Item>
                //         <Nav.Link className="text-primary fw-bold" eventKey="link-2">Support</Nav.Link>
                //     </Nav.Item> */}
                //     <Nav.Item>
                //         <Nav.Link as={Link} to="home" className="fw-bold">Pricing</Nav.Link>
                //     </Nav.Item>

                //     <Nav.Item>
                //         <Nav.Link as={Link}  to="/about" className="fw-bold">About Us</Nav.Link>
                //     </Nav.Item>
                // </>
                <></>
              )}

              {!auth.user && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigateToLogin(false)}
                      className="fw-bold text-primary btn btn-link"
                    >
                      Login
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigateToLogin(true)}
                      className="fw-bold border border-primary btn-primary text-white rounded-pill"
                    >
                      Sign Up
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Form.Check
                      className="ms-4"
                      type="switch"
                      id="custom-switch"
                      label="Dark Mode"
                      onChange={switchTheme}
                      checked={!islightTheme}
                    />
                  </Nav.Item>
                  {/* <Nav.Item>
                                                <FontAwesomeIcon title="No items in Cart yet" icon={faCartShopping} className="ms-4" />
                                            </Nav.Item> */}
                </>
              )}
              {auth.user && (
                <Nav.Item className="d-lg-block d-none">
                  <Dropdown className="user-menu">
                    <Dropdown.Toggle
                      variant="link"
                      className="user-toggle text-decoration-none"
                      id="dropdown-button-dark-example1"
                    >
                      <div className="user">
                        <div className="avatar">
                          {!auth.user.profileImageURL && (
                            <div className="h-100 d-flex align-items-center justify-content-center bg-darkGray">
                              <h4 className="mb-0 text-white">
                                {Array.from(auth.user.firstName)[0]}
                                {Array.from(auth.user.lastName)[0]}
                              </h4>
                            </div>
                          )}
                          {auth.user.profileImageURL && (
                            <img
                              src={auth.user.profileImageURL}
                              alt="profile pic"
                            />
                          )}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item eventKey="1">
                        <strong>
                          {auth.user.firstName} {auth.user.lastName}
                        </strong>
                        <p className="mb-0">{auth.user.email}</p>
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="2">Profile</Dropdown.Item> */}
                      <Dropdown.Divider />
                      <Dropdown.Item as="button">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Dark Mode"
                          onChange={switchTheme}
                          checked={!islightTheme}
                        />
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {/* <Dropdown.Item eventKey="4">Purchases</Dropdown.Item> */}
                      {!isAdmin && (
                        <Dropdown.Item as={Link} to={`/account-settings`}>
                          Account
                        </Dropdown.Item>
                      )}
                      {/* <Dropdown.Item eventKey="6">Support</Dropdown.Item> */}
                      <Dropdown.Item onClick={handleLogout}>
                        Log out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Modal show={show} onHide={handleClose} centered contentClassName="login-box"> 
                <Modal.Body className="text-center py-5">
                    <h2>SIGN UP</h2>
                    <h6 className="mb-3"> Welcome to TicketWindow UX</h6>
                    <div className="my-4 px-5">
                        <Button onClick={() => navigateToLogin(true, USER_TYPE.PATRON)} 
                            size="lg" variant="primary" 
                            className="text-white rounded-0 mb-4 fw-bold w-100">
                                I'm a Viewer
                        </Button>
                        <br/>
                        <Button onClick={() => navigateToLogin(true, USER_TYPE.PROMOTER)} 
                            size="lg" variant="primary" 
                            className="text-white rounded-0 w-100 fw-bold">
                                I'm a Producer
                        </Button>
                    </div>
                    <p>
                        Already have an account? 
                        <button onClick={() => navigateToLogin(false)} type="button" className="text-primary fw-bold  text-decoration-none ms-2 btn btn-link p-0 fs-12 mb-1">
                            Log In
                        </button> 
                    </p> 
                
                </Modal.Body>
            </Modal> */}
    </header>
  );
}
