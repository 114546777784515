import React, { Component } from 'react';
import { ACCESS_TOKEN, LOGGED_IN_USER, USER_ROLES } from '../constants';
import { Navigate } from 'react-router-dom';

/**
 * Module that contains a class for handling OAuth2 redirection.
 * @module src/oauth2/OAuth2RedirectHandler
 */
/**
 * Importing the local storage utilities module.
 * @requires ../utils/LocalStorageUtils
 */
const storageUtils = require('../utils/LocalStorageUtils');

/**
 * Class component for handling OAuth2 redirection.
 */
class OAuth2RedirectHandler extends Component {
  // Function to extract URL parameter.
  /**
   * Function to extract URL parameter.
   * @param {string} name - The name of the parameter.
   * @returns {string} The value of the parameter.
   */
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

    var results = regex.exec(window.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    /**
     * Extracts the URL parameter value for the given parameter name.
     * @param {string} name - The name of the parameter.
     * @returns {string} The value of the parameter.
     */
    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

      var results = regex.exec(window.location.search);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const firstName = getUrlParameter('firstName');
    const lastName = getUrlParameter('lastName');
    const profileImageURL = getUrlParameter('profileImageURL');
    const token = getUrlParameter('idToken');
    const roles = getUrlParameter('roles');
    const error = getUrlParameter('error');
    const location = window.location.pathname;

    /**
     * If token exists, process user authentication and redirect.
     *
     * @returns {void}
     */
    if (token) {
      /**
       * Logged in user detail.
       *
       * @type {Object}
       * @property {string} firstName - The first name of the user.
       * @property {string} lastName - The last name of the user.
       * @property {string} profileImageURL - The URL of the user's profile image.
       * @property {Array<string>} roles - The roles of the user.
       * @property {string} idToken - The ID token of the user.
       */
      const loggedInUser = {
        firstName: firstName,
        lastName: lastName,
        profileImageURL: profileImageURL,
        roles: [roles],
        idToken: token,
      };

      /**
       * Store the access token and logged in user detail in local storage.
       *
       * @return {void}
       */
      storageUtils.setItem(ACCESS_TOKEN, token);
      storageUtils.setItem(LOGGED_IN_USER, loggedInUser);

      /**
       * Redirect based on user roles.
       */
      if (loggedInUser.roles.indexOf(USER_ROLES.ROLE_USER) > -1) {
        return <Navigate to="/events" />;
      } else if (loggedInUser.roles.indexOf(USER_ROLES.ROLE_PROMOTER) > -1) {
        return <Navigate to="/promoter-panel/events" />;
      } else if (loggedInUser.roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1) {
        return <Navigate to="/admin/manage-users" />;
      } else {
        return <Navigate to="/events" />;
      }
    } else {
      /**
       * If token does not exist, redirect to login page.
       */
      return <Navigate to="/login" />;
    }
  }
}

export default OAuth2RedirectHandler;
