import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * A cache for storing data fetched from API endpoints.
 *
 * @type {Map<string, any>}
 */
const cache = new Map();

/**
 * Default options for the useAsync hook.
 *
 * @property {string} cacheKey - The cache key for the data fetched from the API endpoint. Defaults to an empty string.
 * @property {boolean} refetch - Indicates whether to refetch the data from the API endpoint. Defaults to false.
 * @type {{cacheKey: string, refetch: boolean}}
 */
const defaultOptions = {
  cacheKey: '',
  refetch: false,
};

/**
 * Custom hook that abstracts the logic for making API calls and handling the state of the data being fetched.
 *
 * @param {*} defaultData - The default value for the data state.
 * @returns {Object} An object containing the data, error, and loading state of the API call.
 */
export const useAsync = (defaultData) => {
  const [data, setData] = useState({
    data: defaultData ?? null,
    error: null,
    loading: true,
  });

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Custom hook that runs an asynchronous function and handles the state of the data being fetched.
   *
   * @param {Function} asyncFn - The asynchronous function to be run.
   * @param {Object} options - The options for running the asynchronous function.
   * @param {string} options.cacheKey - The cache key for the data fetched from the API endpoint. Defaults to an empty string.
   * @param {boolean} options.refetch - Indicates whether to refetch the data from the API endpoint. Defaults to false.
   * @returns {Promise} A promise that resolves to the result of the asynchronous function.
   */
  const run = useCallback(async (asyncFn, options = {}) => {
    try {
      // Merge the default options with the options passed in
      const { cacheKey, refetch } = { ...defaultOptions, ...options };

      const result = { data: null, error: null, loading: false };

      // If we have a cache key and not requesting a new data, then return the cached data
      if (!refetch && cacheKey && cache.has(cacheKey)) {
        const res = cache.get(cacheKey);
        result.data = res;
      } else {
        setData({ ...result, loading: true });
        const res = await asyncFn();
        // console.log("after api call", res);
        result.data = res.data;
        cacheKey && cache.set(cacheKey, res);
      }
      setData(result);
      return result;
    } catch (error) {
      if (error.response.status) {
        // check for api errors
        navigate(location.pathname, {
          replace: true,
          state: { errorStatusCode: error.response.status },
        });
      }
      const result = { data: null, error: error, loading: false };
      setData(result);
      return result;
    }
  }, []);

  return {
    ...data,
    run,
  };
};
