import { useState, useLayoutEffect } from 'react';
import { SITE_KEY } from '../constants';

/**
 * Custom hook for integrating reCAPTCHA functionality.
 * This hook initializes and manages the reCAPTCHA components.
 * @returns {Array} Contains the Captcha response and setter function
 */
const useRecaptcha = () => {
  // State hook to set the captcha response.

  /**
   * Custom hook for integrating reCAPTCHA functionality.
   * @returns {Array} Contains the Captcha response and setter function
   */

  const [captchaResponse, setCaptchaResponsed] = useState('');

  // Effect hook to initialize reCAPTCHA.
  /**
   * Effect hook to initialize reCAPTCHA.
   *
   * @return {void}
   */
  useLayoutEffect(() => {
    const initilizeRecaptcha = () => {
      setTimeout(() => {
        try {
          if (window.location.hostname === 'www.ticketwindowux.com') {
            let el = document.getElementById('captchaDiv');
            window.grecaptcha.render(el, {
              sitekey: SITE_KEY,
              callback: function (token) {
                console.log('token: ' + token);
                setCaptchaResponsed(token);
              },
            });
          }
        } catch (error) {
          console.log('captchaError:', error.message);
        }
      }, 100);
    };

    // Call the initialization function.
    initilizeRecaptcha();
  }, []);

  /**
   * @returns {Array} An array containing the captcha response and its setter function.
   * @property {string} captchaResponse - The captcha response string.
   * @property {function} setCaptchaResponsed - The function to set the captcha response.
   */
  return [captchaResponse, setCaptchaResponsed];
};

export default useRecaptcha;
