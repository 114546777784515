/**
 * Imports the configuration keys from the config/keys.js file.
 *
 * @module constants/index
 * @requires config/keys
 * @returns {Object} An object containing the imported keys.
 */
const keys = require('../config/keys');
// const storageUtils =  require("../utils/LocalStorageUtils");

// global const for google api
export const REACT_APP_GOOGLE_API_KEY = keys.reactAppGoogleApiKey;

// Export the global constant Google Access Token for the react component
export const REACT_APP_GOOGLE_ACCESS_TOKEN = keys.reactAppGoogleAccessToken;

// The following line export the Google Calender for the react app
export const REACT_APP_CALENDAR_ID = keys.reactAppGoogleCalendarId;

// Export the base URL for the API calls
export const API_BASE_URL = keys.apiBaseUrl;

// Export the Base URL for the Application
export const BASE_URL = keys.baseUrl;

// Export the Access Token used for the Autorization
export const ACCESS_TOKEN = 'idToken';

//Global constant is used to store the information of the logged in USER
export const LOGGED_IN_USER = 'loggedInUser';

//Export the OAuth 2.0 Redirect URI used for the autorization
export const OAUTH2_REDIRECT_URI = keys.redirectUri;

// Export the Google Authorization URL
export const GOOGLE_AUTH_URL =
  API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;

// Global constant for the Google Client ID
export const CLIENT_ID = keys.googleClientId;

// Global Constant for the google OAuth API key
export const API_KEY = keys.googleOAuthApiKey;

// Export the Google API Discovery Docs Array for the application
export const DISCOVERY_DOCS = keys.gapiDiscoverDocs;

// Export the Google API Scopes for the Application
export const SCOPES = keys.gapiScopes;

// Export the Stripe Publish Key used for the client-side interactions
export const STRIPE_PUBLISH_KEY = keys.stripePublishKey;

//  export const BASE_URL = 'http://44.212.37.237:8080/tw';
//export const BASE_URL = 'https://ticket-window-backend.herokuapp.com'; // for testing
// export const BASE_URL = 'http://localhost:8080';
// export const BASE_URL = 'https://ticket-window-backend.herokuapp.com'; // for testing

// export const BASE_URL = 'https://3e65-76-64-82-95.ngrok.io';
//export const BASE_URL = 'http://44.212.37.237:8080/tw';
// export const BASE_URL = 'https://349d-142-198-107-172.ngrok.io';

// Export Header that are commonly used to make JSON-based API request and the type of content is application/json
export const HEADERS = {
  'Content-Type': 'application/json',
};

// const getAuthHeaders = () => {
//     return {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${getItem(ACCESS_TOKEN)}`
//     }
// }

// export const AUTH_HEADERS = {
//     ...getAuthHeaders()
// }

// Global constant used for React Application Site Key
export const SITE_KEY = keys.reactAppSiteKey;
// local storage keys constants

// Global constent for the Event Banner Image
export const EVENT_BANNER_IMG = 'eventBannerImage';

// Global constant used to store the array of image types
export const IMAGE_TYPES = [
  'image/png',
  'image/gif',
  'image/bmp',
  'image/jpeg',
];

/**
 * Global constant used for the Maximun Event Image Count
 * @constant {number} MAX_EVENT_IMG_COUNT
 * @default 3
 */
export const MAX_EVENT_IMG_COUNT = 3;

/**
 * Global constant for Maximun Camera Count
 * @constant {number} MAX_CAMERA_COUNT
 * @default 5
 */
export const MAX_CAMERA_COUNT = 5;

/**
 * Global constant for the Tax Percentage
 * @constant {number} TAX_PERCENTAGE
 * @default 0.13
 */
export const TAX_PERCENTAGE = 0.13; // 13% added GST/HST for Ontario

/**
 * Global constant for Service Fee Percent
 * @constant {number} SERVICE_FEE_PERCENT
 * @default 0.05
 */
export const SERVICE_FEE_PERCENT = 0.05;

/**
 * Global constant for the Restocking Fee
 * @constant {number} RESTOCKING_FEE
 * @default 25
 */
export const RESTOCKING_FEE = 25;

/**
 * Global Constant for the Application Fee
 * @constant {number} APPLICATION_FEE
 * @default 7
 */
export const APPLICATION_FEE = 7;

/**
 * Global constant for the Gateway Fee
 * @constant {number} GATEWAY_FEE
 * @default 3.5
 */
export const GATEWAY_FEE = 3.5;

/**
 * Enumeration indicate the type of the user Promoter or Patron
 * @constant {Object} USER_TYPE
 * @property {string} PROMOTER - Promoter user type
 * @property {string} PATRON - Patron user type
 */
export const USER_TYPE = Object.freeze({
  PROMOTER: 'PROMOTER',
  PATRON: 'PATRON',
});

/**
 * Enumeration for the User Roles
 * @constant {Object} USER_ROLES
 * @property {string} ROLE_USER - User role
 * @property {string} ROLE_PROMOTER - Promoter role
 * @property {string} ROLE_SUPER_ADMIN - Super admin role
 */
export const USER_ROLES = Object.freeze({
  ROLE_USER: 'ROLE_USER',
  ROLE_PROMOTER: 'ROLE_PROMOTER',
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
});

/**
 * Enumeration for the Social Media URL's
 * @constant {Object} SOCIAL_MEDIA_URLS
 * @property {string} TWITTER - Twitter URL
 * @property {string} FACEBOOK - Facebook URL
 * @property {string} YOUTUBE - YouTube URL
 */
export const SOCIAL_MEDIA_URLS = Object.freeze({
  TWITTER: 'https://twitter.com/TicketWindowInc',
  FACEBOOK: 'https://www.facebook.com/ticketwindowinc/',
  YOUTUBE: 'https://www.youtube.com/user/TIcketWindowTV',
});

/**
 * Enumeration for the Event Steps
 * @constant {Object} EVENT_STEPS
 * @property {string} BASIC_DETAILS - Basic details step
 * @property {string} MAIN_DETAILS - Main details step
 * @property {string} IN_PERSON_DETAILS - In-person details step
 * @property {string} STREAMING_DETAILS - Streaming details step
 * @property {string} IN_PERSON_TICKET_DETAILS - In-person ticket details step
 * @property {string} EVENT_CHOICE - Event choice step
 * @property {string} TICKET_DETAILS - Ticket details step
 * @property {string} FINAL_DETAILS - Final details step
 */
export const EVENT_STEPS = Object.freeze({
  BASIC_DETAILS: 'BASIC_DETAILS', // we don't have any path for basic info step as it lies at index
  MAIN_DETAILS: 'MAIN_DETAILS',
  IN_PERSON_DETAILS: 'IN_PERSON_DETAILS',
  STREAMING_DETAILS: 'STREAMING_DETAILS',
  IN_PERSON_TICKET_DETAILS: 'IN_PERSON_TICKET_DETAILS',
  EVENT_CHOICE: 'EVENT_CHOICE',
  TICKET_DETAILS: 'TICKET_DETAILS',
  FINAL_DETAILS: 'FINAL_DETAILS',
});

/**
 * Enumeration for the Ticket Type
 * @constant {Object} TICKET_TYPE
 * @property {string} PAID - Paid ticket type
 * @property {string} FREE - Free ticket type
 */
export const TICKET_TYPE = Object.freeze({
  PAID: 'PAID',
  FREE: 'FREE',
});

// export const COUPON_TYPE = Object.freeze({
//     FIXED_AMOUNT: 'PAID',
//     PERCENT_DISCOUNT: 'FREE'
// })

/**
 * Enumeration for the Type of Ticket Location
 * @constant {Object} TICKET_LOCATION_TYPE
 * @property {string} LIVESTREAM - Livestream ticket location type
 * @property {string} IN_PERSON - In-person ticket location type
 * @property {string} HYBRID - Hybrid ticket location type
 */
export const TICKET_LOCATION_TYPE = Object.freeze({
  LIVESTREAM: 'LIVESTREAM',
  IN_PERSON: 'IN_PERSON',
  HYBRID: 'HYBRID',
});

/**
 * Enumeration for the Availability of the Event
 * @constant {Object} EVENT_AVAILABILITY
 * @property {string} PUBLIC - Public event availability type
 * @property {string} PRIVATE - Private event availability type
 */
export const EVENT_AVAILABILITY = Object.freeze({
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
});

/**
 * Enumeration for the Event Status
 * @constant {Object} EVENT_STATUS
 * @property {string} IN_PROGRESS - Event is in progress
 * @property {string} IN_REVIEW - Event is in review
 * @property {string} DECLINED - Event is declined
 * @property {string} APPROVED - Event is approved
 */
export const EVENT_STATUS = Object.freeze({
  IN_PROGRESS: 'IN PROGRESS',
  IN_REVIEW: 'IN REVIEW',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
});

/**
 * Enumeration for the User Status
 * @constant {Object} USER_STATUS
 * @property {string} REJECTED - User is rejected
 * @property {string} IN_REVIEW - User is in review
 * @property {string} APPROVED - User is approved
 */
export const USER_STATUS = Object.freeze({
  REJECTED: 'REJECTED',
  IN_REVIEW: 'IN REVIEW',
  APPROVED: 'APPROVED',
});

/**
 * Enumeration for the Channel Actions
 * @constant {Object} CHANNEL_ACTIONS
 * @property {string} START - Start channel action
 * @property {string} STARTING - Starting channel action
 * @property {string} RUNNING - Running channel action
 * @property {string} STOP - Stop channel action
 * @property {string} DELETE - Delete channel action
 */
export const CHANNEL_ACTIONS = Object.freeze({
  START: 'START',
  STARTING: 'STARTING',
  RUNNING: 'RUNNING',
  STOP: 'STOP',
  DELETE: 'DELETE',
});

/**
 * Global constant used to export the text for the Cancel Order ToolTip
 * @constant {string} CANCEL_ORDER_TOOLTIP
 * @default 'You can cancel your order 48 hours before event start time. Service and Restocking fees are non-refundable'
 */
export const CANCEL_ORDER_TOOLTIP =
  'You can cancel your order 48 hours before event start time. Service and Restocking fees are non-refundable';

// list all regex for consistent validations

/**
 * Global constant for the Email regular expression.
 *
 * This regular expression follows the RFC 5322 standard for validating email addresses.
 * @constant {RegExp} EMAIL_REGEX
 * @default /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Global constant for the URL regular expression.
 *
 * This regular expression follows the RFC 3986 standard for validating URLs.
 * @constant {RegExp} URL_REGEX
 * @default /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
 */
export const URL_REGEX =
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/; // eslint-disable-line max-len

/**
 * Global constant for the Integers regular expression.
 *
 * This regular expression matches positive and negative integers.
 * @constant {RegExp} INT_REGEX
 * @default /^-?[0-9]+$/
 */
export const INT_REGEX = /^-?[0-9]+$/;

/**
 * Global constant for the Coupon Code regular expression.
 *
 * This regular expression matches coupon codes that contain only letters and numbers.
 * @constant {RegExp} COUPON_CODE_REGEX
 * @default /^[A-Za-z0-9]*$/
 */
export const COUPON_CODE_REGEX = /^[A-Za-z0-9]*$/; // regex matching numbers and letters only
