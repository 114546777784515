// Validation logic of all the forms and there fields..
import { TICKET_TYPE, URL_REGEX, INT_REGEX } from '../../../constants';
// const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
// const regexInt = /^-?[0-9]+$/;

/**
 * Validation logic of all the forms and there fields..
 * @param {Object} formState - State of the form
 * @param {String} step - Current step of the event creation flow
 * @returns {Object} - Error object with keys having error messages
 */
const validateEventForm = (formState, step) => {
  let error;

  switch (step) {
    case 'BASIC_DETAILS':
      error = validateBasicInfo(formState);
      break;
    case 'MAIN_DETAILS':
      error = validateDetails(formState);
      break;
    case 'STREAMING_DETAILS':
      error = validateStreaming(formState);
      break;
    case 'IN_PERSON_DETAILS':
      error = validateInPersonDetails(formState);
      break;
    case 'IN_PERSON_TICKET_DETAILS':
      error = validateInPersonTicketDetails(formState);
      break;
    case 'TICKET_DETAILS':
      error = validateTicketDetails(formState);
      break;
    case 'FINAL_DETAILS':
      error = validateFinalDetails(formState);
    default:
      break;
  }

  if (Object.keys(error).length > 0) {
    return error;
  }
};

/**
 * Validate basic info form
 * @param {Object} state - State of the form
 * @returns {Object} - Error object with keys having error messages
 */
const validateBasicInfo = (state) => {
  let error = {};
  if (!state.eventTitle || state.eventTitle.trim().length == 0) {
    error['eventTitle'] = 'This field is required';
  }

  if (!state.eventStartDate || state.eventStartDate.trim().length == 0) {
    error['eventStartDate'] = 'Please select event start Date!';
  }

  if (!state.eventEndDate || state.eventEndDate.trim().length == 0) {
    error['eventEndDate'] = 'Please select event end Date!';
  }

  if (!state.eventStartTime) {
    error['eventStartTime'] = 'Please select event start time!';
  }

  if (!state.eventEndTime) {
    error['eventEndTime'] = 'Please select event end time!';
  }

  if (!state.locationTypeId) {
    error['locationTypeId'] = 'Please select location type for the event!';
  }

  if (!state.timezone) {
    error['timezone'] = 'Please select a timezone!';
  }

  return error;
};

// Legacy, this function is in each step component code now.. easier to handle there..
const validateFormData = (formData) => {
  let isValid = true;
  for (let value of formData.values()) {
    if (!value instanceof File) {
      isValid = false;
    }
  }
  return isValid;
};

/**
 * Validates admin event details
 * @param {Object} state - The state object containing admin event details
 * @returns {Object} An object with error messages
 */
export const validateAdminEventDetails = (state) => {
  let error = {};
  if (!state.eventTitle || state.eventTitle.trim().length == 0) {
    error['eventTitle'] = 'This field is required';
  }

  // start and end date format is dd-mm-yyyy
  if (!state.eventStartDate || state.eventStartDate.trim().length == 0) {
    error['eventStartDate'] = 'Please select event start Date!';
  }

  if (!state.eventEndDate || state.eventEndDate.trim().length == 0) {
    error['eventEndDate'] = 'Please select event end Date!';
  }

  if (!state.eventStartTime) {
    error['eventStartTime'] = 'Please select event start time!';
  }

  if (!state.eventEndTime) {
    error['eventEndTime'] = 'Please select event end time!';
  }

  if (!error['eventStartDate'] && !error['eventEndDate']) {
    if (
      new Date(state.eventStartDate).getTime() >
      new Date(state.eventEndDate).getTime()
    ) {
      error['eventStartDate'] =
        'Event start date cannot be after event end date';
    }

    if (!error['eventStartTime'] && !error['eventEndTime']) {
      if (
        state.eventStartDate == state.eventEndDate &&
        state.eventStartTime > state.eventEndTime
      ) {
        error['eventStartTime'] =
          'Event start time cannot be after event end time';
      }
    }
  }

  if (!state.summary || state.summary.trim().length == 0) {
    error['summary'] = 'This field is required!';
  }

  if (!state.description || state.description.trim().length == 0) {
    error['description'] = 'This field is required!';
  }

  return error;
};

/**
 * Validates details step form
 * @param {Object} state - The state to validate
 * @returns {Object} An object containing the validation errors
 */
const validateDetails = (state) => {
  let error = {};
  if (!state.summary || state.summary.trim().length == 0) {
    error['summary'] = 'This field is required!';
  }

  if (!state.description || state.description.trim().length == 0) {
    error['description'] = 'This field is required!';
  }

  // if(!state.bannerImage || !state.bannerImage instanceof File){
  //     error['bannerImage'] = 'Please upload a banner image!';
  // }

  if (
    state.externalVideoURL &&
    URL_REGEX.test(state.externalVideoURL) == false
  ) {
    error['externalVideoURL'] = 'Please enter a valid URL!';
  }

  if (
    state.eventImages &&
    state.eventImages.length &&
    state.eventImages.indexOf(null) > -1
  ) {
    error['eventImages'] = 'Please upload a valid image!';
  }

  return error;
};

/**
 * Validates Streaming step form
 * @param {Object} state - The state to validate
 * @returns {Array} An array containing the validation errors
 */
const validateStreaming = (state) => {
  let errors = [];

  for (let index = 0; index < state.cameraDetailsDtoList.length; index++) {
    const camera = state.cameraDetailsDtoList[index];
    let err = {};

    // if(!camera.title || camera.title.trim().length == 0){
    //     err['title'] = 'Please enter the title!';
    // }

    if (!camera.artistName || camera.artistName.trim().length == 0) {
      err['artistName'] = 'Please enter the Artist Name!';
    }

    if (!camera.cameraName || camera.cameraName.trim().length == 0) {
      err['cameraName'] = 'Please enter the Camera Name!';
    }

    if (!camera.description || camera.description.trim().length == 0) {
      err['description'] = 'Please enter the Description!';
    }

    // if(!camera.cameraImage){
    //     err['cameraImage'] = 'Please upload Camera Image!';
    // }

    // check if any error found, then set it in errors []
    if (Object.keys(err).length > 0) {
      errors[index] = err;
    }
  }

  return errors;
};

/**
 * Validates In-Person Details form
 * @param {Object} state - The state to validate
 * @returns {Object} An object containing the validation errors
 */
const validateInPersonDetails = (state) => {
  let error = {};

  console.log('Inside validator', state);
  if (!state.addressLine1) {
    error['addressLine1'] = 'Please enter Address';
  }

  if (!state.city) {
    error['city'] = 'Please enter city';
  }

  if (!state.postalCode) {
    error['postalCode'] = 'Please enter Postal Code';
  }

  if (!state.province) {
    error['province'] = 'Please enter Province';
  }

  if (!state.country) {
    error['addressLine'] = 'Please enter country';
  }

  return error;
};

// Validates ticket details form.. for streaming events
const validateTicketDetails = (state) => {
  let error = {};

  if (!state.ticketType || state.ticketType.trim().length == 0) {
    error['ticketType'] = 'Please select Ticket type!';
  }
  // if(!state.ticketName ||  state.ticketName.trim().length == 0){
  //     error['ticketName'] = 'This field is required!';
  // }

  if (!state.totalQuantity || state.totalQuantity.trim().length == 0) {
    error['totalQuantity'] = 'This field is required!';
  } else if (
    isNaN(state.totalQuantity) ||
    INT_REGEX.test(state.totalQuantity) == false
  ) {
    error['totalQuantity'] = 'Please provide a valid integer value!';
  }

  if (state.ticketType && state.ticketType == TICKET_TYPE.PAID) {
    if (!state.price || state.price.trim().length == 0) {
      error['price'] = 'This field is required!';
    } else if (isNaN(state.price)) {
      error['price'] = 'Please provide a valid number!';
    }
  }

  if (
    state.ticketType &&
    state.ticketType == TICKET_TYPE.PAID &&
    state.chargeTax
  ) {
    if (!state.taxPercentage || state.taxPercentage.trim().length == 0) {
      error['taxPercentage'] = 'This field is required!';
    } else if (isNaN(state.taxPercentage)) {
      error['taxPercentage'] = 'Please provide a valid number!';
    } else if (
      parseInt(state.taxPercentage) < 0 ||
      parseInt(state.taxPercentage) > 100
    ) {
      error['taxPercentage'] = 'Please provide a value between 0 to 100!';
    }
  }

  if (!state.salesStartDate || state.salesStartDate.trim().length == 0) {
    error['salesStartDate'] = 'Please provide Sales start date!';
  }

  if (!state.salesEndDate || state.salesEndDate.trim().length == 0) {
    error['salesEndDate'] = 'Please provide Sales start date!';
  }

  if (!state.salesStartTime) {
    error['salesStartTime'] = 'Please provide Sales start date!';
  }

  if (!state.salesEndTime) {
    error['salesEndTime'] = 'Please provide Sales start date!';
  }

  return error;
};

/**
 * Validates in-person event ticket details.
 *
 * @param {Object} state - The state object containing ticket details.
 * @return {Object} An object containing error messages for each field that failed validation.
 */
const validateInPersonTicketDetails = (state) => {
  let error = {};

  // console.log("Inside validator", state.inPersonTicketSectionDetailsDtoList[0].sectionName)

  // if (state.inPersonTicketSectionDetailsDtoList.length >= 0) {
  //     console.log('golu',state)
  //     state.inPersonTicketSectionDetailsDtoList.forEach((section, sectionIndex) => {
  //         console.log('goluSection',section.inPersonTicketSectionTypeDetailsDtoList)
  //         let sectionErrors = Array.from({ length:  state.inPersonTicketSectionDetailsDtoList.length });;
  //         let newErrorObject = {}

  //         // Check individual fields within each section
  //         if (!section.sectionName || section.sectionName.trim() === "") {
  //             newErrorObject['sectionName'] = 'Please Add Section Name'
  //         }
  //         if (!section.capacity || section.capacity.trim === '' || section.capacity === 0) {
  //             console.log('capacity debug', section.capacity)
  //             newErrorObject['capacity'] = 'Please Add Capacity Name'
  //         }
  //         // ticket type checks go here..
  //         // if (section.inPersonTicketSectionTypeDetailsDtoList.length >= 0) {
  //         //     console.log('popopopopopopop')
  //         //     section.inPersonTicketSectionTypeDetailsDtoList.forEach((sectionType, ticketTypeIndex) => {
  //         //         console.log('gurneesh', sectionType, ticketTypeIndex)
  //         //         let sectionTypeErrors = [];
  //         //         let newErrorObj = {}

  //         //         if (!sectionType.ticketType || sectionType.ticketType.trim() === "") {
  //         //             newErrorObj['ticketType'] = "Please Enter Ticket Type Name"
  //         //         }

  //         //         if (!sectionType.price || sectionType.price === 0) {
  //         //             newErrorObj['price'] = "Please Enter Ticket Type Price"
  //         //         }
  //         //         if (!sectionType.serviceFees || sectionType.serviceFees === 0) {
  //         //             newErrorObj['serviceFees'] = "Please Enter Ticket Type Service Fees"
  //         //         }
  //         //         sectionTypeErrors.splice(ticketTypeIndex, 0, newErrorObj)
  //         //         if (sectionTypeErrors.length > 0) {
  //         //             console.log('tttetstestsetsetsgv', sectionErrors[sectionIndex])
  //         //             sectionErrors[sectionIndex][ticketTypeIndex] = sectionTypeErrors;
  //         //             console.log('tttetstestsetsetsgv', sectionErrors)
  //         //         }
  //         //     })
  //         //     // Add more checks for other fields within the section if needed

  //         //     // If there are errors for the current section, add them to the errors array
  //         // }
  //         sectionErrors.splice(sectionIndex, 0, newErrorObject)
  //         sectionErrors.forEach((err, errIndex) => {
  //             const empObj = {}
  //             console.log('popop', Object.getOwnPropertyNames(err).length === 0)
  //             if (err === undefined || err === null || err === '' || Object.getOwnPropertyNames(err).length === 0){
  //                 console.log('kkjkjkjk', sectionErrors.splice(errIndex, 1))
  //             }
  //         })
  //         if (sectionErrors.length > 0 && Object.getOwnPropertyNames(sectionErrors).length === 0) {
  //             // checkObjEmpty(sectionErrors)
  //             error['sectionErrors'] = sectionErrors;
  //         }
  //     });

  //     console.log('indsihjdh', error)
  // } else {
  //     error['sectionError'] = 'Atleast add 1 section'
  // }

  if (!state.ticketType || state.ticketType.trim().length == 0) {
    error['ticketType'] = 'Please select Ticket type!';
  }
  // if(!state.ticketName ||  state.ticketName.trim().length == 0){
  //     error['ticketName'] = 'This field is required!';
  // }

  if (
    state.ticketType &&
    state.ticketType == TICKET_TYPE.PAID &&
    state.chargeTax
  ) {
    if (!state.taxPercentage || state.taxPercentage.trim().length == 0) {
      error['taxPercentage'] = 'This field is required!';
    } else if (isNaN(state.taxPercentage)) {
      error['taxPercentage'] = 'Please provide a valid number!';
    } else if (
      parseInt(state.taxPercentage) < 0 ||
      parseInt(state.taxPercentage) > 100
    ) {
      error['taxPercentage'] = 'Please provide a value between 0 to 100!';
    }
  }

  if (!state.salesStartDate || state.salesStartDate.trim().length == 0) {
    error['salesStartDate'] = 'Please provide Sales start date!';
  }

  if (!state.salesEndDate || state.salesEndDate.trim().length == 0) {
    error['salesEndDate'] = 'Please provide Sales start date!';
  }

  if (!state.salesStartTime) {
    error['salesStartTime'] = 'Please provide Sales start date!';
  }

  if (!state.salesEndTime) {
    error['salesEndTime'] = 'Please provide Sales start date!';
  }

  return error;
};

/**
 * Validates final step details
 * @param {Object} state - the state object with final details
 * @returns {Object} - an object with error messages if any
 */
const validateFinalDetails = (state) => {
  // eventAvailability:PUBLIC
  // publishDate:2022-12-02
  // publishTime:1669789345833
  // acceptTerms: boolean
  let error = {};

  if (!state.eventAvailability) {
    error['eventAvailability'] =
      'Please select when you want the event to be available!';
  }

  if (!state.publishDate) {
    error['publishDate'] = 'Please provide event publish date!';
  }

  if (!state.publishTime) {
    error['publishTime'] = 'Please provide event publish time!';
  }

  return error;
};

export default validateEventForm;
// Test object
// formStateJSON={
//     "eventTitle": "string",  // required
//     "organizer": "String",
//     "type": "String", // required
//     "genre": "String", // required
//     "location": "String | predefined values - VENUE, LIVESTREAM OR HYBRID ", // required
//     "eventDate": "String",  // required
//     "eventStartTime": 1667746789, // required
//     "eventEndTime": 1667746789, // required
//     "eventTimezone": "String", // required
//     "eventSummary": "String | Mandatory", // required
//     "eventDescription": "String | Mandatory", // required
//     "videoUrl": "String",
//     "twitterUrl": "String",
//     "facebookUrl": "String",
//     "InstagramUrl": "String",
//     "tikTokUrl": "String",
//   “eventBanner”: FormData {
//   eventBanner: File
//   },
//   “eventImages”: FormData {
//   eventImage1: File,
//   eventImage2: File,
//       eventImage3: File // max 3 images only
//   },
//     "cameras": [
//       {
//         "artistName": "String", // required if camera added
//         "cameraName": "String", // required if camera added
//         "description": "String", // required if camera added
//         "thumbnail":  "File" ,
//          “isPrimary”: true
//       },
//       {
//         "artistName": "String",
//         "cameraName": "String",
//         "description": "String",
//         "thumbnail":  "File",
//         “Primary”: false
//       }
//     ],
//     "ticketType": "String | Values will be 'PAID' | 'FREE'",
//     "ticketName": "String", // required
//     "ticketQuantity": 500, // required
//     "ticketPrice": 30, // required
//     "salesStartDate": 1667746789, // required
//     "salesEndDate": 1667746789, // required
//     "salesStartTime": 1667746789, // required
//     "salesEndTime": 1667746789, // required
//     "showSalesStatus": false,
//     "eventPublic": true, // required
//     "publishNow": true , // required
//     "publishStartDate": 1667746789, // required if publishNow is False
//     "publishEndDate": 1667746789, // required if publishNow is False
//     "termsAccepted": false // Mandatory
//   }
