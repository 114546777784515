import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Dropdown,
  Form,
  Button,
  Modal,
  Toast,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import {
  faCirclePlay,
  faClipboard,
  faClipboardCheck,
  faImage,
  faList,
  faPlay,
  faStop,
  faVideoCamera,
  faPencilAlt,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useOutletContext } from 'react-router-dom';
import {
  getPromoterEventList,
  modifyChannel,
  getCameraDetails,
  createChannel,
  getUserDetails,
  getCategoryDetails,
} from '../../../utils/APIUtils';
import moment from 'moment';
import { CHANNEL_ACTIONS, EVENT_STATUS, EVENT_STEPS } from '../../../constants';
import Pagination from '../../../shared/Pagination';
import { useAsync } from '../../../hooks/useAsync';
import { Tooltip } from '@mui/material';
import EventStartEndDate from '../Shared/EventStartEndDate';
import { getTsFromDateTimeStrings } from '../CreateEvent/useCreateEvent';

/**
 * Global constant used to export the text for the Stripe setup tooltip.
 *
 * @constant {string} STRIPE_SETUP_TOOLTIP
 * @default 'You need to setup your stripe account before you can start creating events. To setup strip account go to Account > Contact Information > Edit Stripe Settings'
 */
const STRIPE_SETUP_TOOLTIP =
  'You need to setup your stripe account before you can start creating events. To setup strip account go to Account > Contact Information > Edit Stripe Settings';

/**
 * Global constant used to export the text for the event closed tooltip.
 *
 * @constant {string} EVENT_CLOSED_TOOLTIP
 * @default 'You cannot view cameras as event is already closed.'
 */
const EVENT_CLOSED_TOOLTIP =
  'You cannot view cameras as event is already closed.';

// const TabPanel = (props) => {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// const a11yProps = (index) => {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

// const pageItems = (totalPages, active) => {
//     let items = [];
//     for (let number = 1; number <= totalPages; number++) {
//         items.push(
//             <Pagination.Item key={number}>
//                 {number}
//             </Pagination.Item>,
//         );
//     }

//     return items
// }

/**
 * Render the AllEvents component.
 *
 * @param {Object} props - The properties for the component.
 * @return {JSX.Element} The rendered AllEvents component.
 */
const AllEvents = (props) => {
  /**
   * Navigate to a different route.
   * @type {ReactRouterDOM.useNavigate}
   */
  const navigate = useNavigate();
  /**
   * The data and status of the last call to run.
   * @type {Object}
   * @property {any} data - The data returned from the API or null.
   * @property {boolean} loading - If the call to run is still loading.
   * @property {Error} error - The error from the API or null.
   * @property {Function} run - The function to call to make the API call.
   */
  const { data, loading, error, run } = useAsync(null);
  /**
   * The context passed from the parent component.
   * @type {Array}
   * @property {Object} userDetails - The user details.
   * @property {Function} createEventClickHandler - The function to call when the create event button is clicked.
   */
  const [userDetails, createEventClickHandler] = useOutletContext();
  /**
   * The currently selected tab.
   * @type {number}
   */
  const [value, setValue] = useState(0);
  /**
   * The list of all events for the user.
   * @type {Array}
   */
  const [allEvents, setAllEvents] = useState([]);
  /**
   * If the camera details are still loading.
   * @type {boolean}
   */
  const [loadingCameras, setLoadingCameras] = useState(false);
  /**
   * The camera details.
   * @type {Object}
   */
  const [cameraDetails, setCameraDetails] = useState(null);
  /**
   * If the add performance modal should be shown.
   * @type {boolean}
   */
  const [show, setShow] = useState(false);
  /**
   * If the add performance details modal should be shown.
   * @type {boolean}
   */
  const [showAddPerformanceModal, setShowAddPerformanceModal] = useState(false);
  /**
   * The name of the performance.
   * @type {string}
   */
  const [performanceName, setPerformanceName] = useState('');
  /**
   * If the user is selecting performance steps to modify.
   * @type {boolean}
   */
  const [selectPerformanceSteps, setSelectPerformanceSteps] = useState(false);
  /**
   * Any errors with the performance details.
   * @type {string}
   */
  const [performanceDetailsError, setPerformanceDetailsError] = useState('');
  /**
   * The steps that the user has selected to modify.
   * @type {Set<number>}
   */
  const [selectedStepsToModify, setSelectedStepsToModify] = useState(new Set());
  /**
   * The category types.
   * @type {Array}
   */
  const [categoryTypes, setCategoryTypes] = useState([]);

  // eventStartDate = data.eventStartDate;
  // eventStartTime = getTsFromDateTimeStrings(data.eventStartDate, data.eventStartTime);
  // eventEndDate = data.eventEndDate;
  // eventEndTime = getTsFromDateTimeStrings(data.eventEndDate, data.eventEndTime);
  // timezone = data.timezone;

  /**
   * The state for errors generated by the EventStartEndDate component.
   * @type {Object|null}
   */
  const [errors, setErrors] = useState(null);
  /**
   * The initial state of the event start and end dates.
   * @typedef {Object} InitialEventStartEndState
   * @property {string} eventStartDate - The start date of the event.
   * @property {string} eventStartTime - The start time of the event.
   * @property {string} eventEndDate - The end date of the event.
   * @property {string} eventEndTime - The end time of the event.
   * @property {string} timezone - The timezone of the event.
   */
  const initialEventStartEndState = {
    eventStartDate: '',
    eventStartTime: '',
    eventEndDate: '',
    eventEndTime: '',
    timezone: '',
  };
  /**
   * The state of the form containing the event start and end dates.
   * @typedef {InitialEventStartEndState} FormState
   */
  const [formState, setFormState] = useState(initialEventStartEndState);
  /**
   * Updates the form state with the provided state.
   *
   * @param {Object} state - The state to update the form state with.
   * @return {void}
   */
  const updateState = (state) =>
    setFormState((prevState) => {
      // Log the updated state to the console
      console.log({ ...prevState, ...state });
      // Return the updated state
      return { ...prevState, ...state };
    });

  /**
   * Closes the main modal.
   *
   * @return {void}
   */
  const handleClose = () => setShow(false);

  /**
   * Closes the performance modal.
   *
   * @return {void}
   */
  const handleClosePerformanceModal = () => {
    // reset all the states
    setCurrentEvent(null);
    setShowAddPerformanceModal(false);
    setSelectPerformanceSteps(false);
  };

  /**
   * Submits the performance name and validates the form state.
   *
   * @return {void}
   */
  const submitPerformanceName = () => {
    let errors = {};
    if (performanceName.trim() && performanceName.trim().length < 5) {
      setPerformanceDetailsError(
        'Performance Name should be atleast 5 characters long'
      );
    } else if (performanceName.trim() == currentEvent.eventTitle) {
      setPerformanceDetailsError(
        'Performance Name cannot be same as the Event Name'
      );
    }

    if (!formState.eventEndDate) {
      errors = { ...errors, eventEndDate: 'Please add performance end date!' };
    }
    if (!formState.eventStartDate) {
      errors = {
        ...errors,
        eventStartDate: 'Please add performance start date!',
      };
    }
    if (!formState.eventStartTime) {
      errors = {
        ...errors,
        eventStartTime: 'Please add performance start time!',
      };
    }
    if (!formState.eventEndTime) {
      errors = { ...errors, eventEndTime: 'Please add performance end time!' };
    }

    setErrors(errors);

    if (!Object.keys(errors).length) {
      setSelectPerformanceSteps(true);
    }
  };
  /**
   * Handles the selection of steps for adding a new performance.
   * @param {Object} e - The event object.
   * @param {string} stepName - The name of the step to be selected.
   * @return {void}
   */
  const handleSelectStepToAddPerformance = (e, stepName) => {
    const { checked } = e.target;
    let currSelection = new Set(selectedStepsToModify);
    if (checked) {
      currSelection.add(stepName);
    } else {
      currSelection.delete(stepName);
    }

    setSelectedStepsToModify(currSelection);
  };

  /**
   * Handles the navigation to the selected step for modifying an event.
   * @return {void}
   */
  const handleGoToStep = () => {
    if (selectedStepsToModify.has(EVENT_STEPS.BASIC_DETAILS)) {
      navigate(
        `/promoter-panel/events/create-event/${EVENT_STEPS.BASIC_DETAILS}/${currentEvent.id}`
      );
    } else if (selectedStepsToModify.has(EVENT_STEPS.MAIN_DETAILS)) {
      navigate(
        `/promoter-panel/events/create-event/${EVENT_STEPS.MAIN_DETAILS}/${currentEvent.id}`
      );
    } else if (selectedStepsToModify.has(EVENT_STEPS.IN_PERSON_DETAILS)) {
      navigate(
        `/promoter-panel/events/create-event/${EVENT_STEPS.IN_PERSON_DETAILS}/${currentEvent.id}`
      );
    } else if (selectedStepsToModify.has(EVENT_STEPS.TICKET_DETAILS)) {
      navigate(
        `/promoter-panel/events/create-event/${EVENT_STEPS.TICKET_DETAILS}/${currentEvent.id}`
      );
    }
  };

  /**
   * The ID of the current event.
   * @type {string|null}
   */
  const [currentEventId, setCurrentEventId] = useState(null);

  /**
   * The current event object.
   * @type {Object|null}
   */
  const [currentEvent, setCurrentEvent] = useState(null);

  /**
   * Determines whether to show the toast message.
   * @type {boolean}
   */
  const [showToast, setShowToast] = useState(false);

  /**
   * The total number of pages.
   * @type {number}
   */
  const [totalPages, setTotalPages] = useState(0);

  /**
   * The current page number.
   * @type {number}
   */
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * Determines whether Stripe is activated.
   * @type {boolean}
   */
  const [stripeActivated, setStripeActivated] = useState(false);

  /**
   * Determines whether editing is in progress.
   * @type {boolean}
   */
  const [isEditing, setIsEditing] = useState(false);

  /**
   * The edited text.
   * @type {string}
   */
  const [editedText, setEditedText] = useState('');

  /**
   * The original text.
   * @type {string|undefined}
   */
  const [originalText, setOriginalText] = useState();

  /**
   * The commission percentage.
   * @type {string}
   */
  const commissionPercent = '7';

  /**
   * Handles a text input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event.
   */
  const handleTextChange = (event) => {
    setEditedText(event.target.value);
  };

  /**
   * Toggles edit mode.
   */
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  /**
   * Handles a page change.
   * @param {number} page The new page number.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getEvents(page);
  };

  /**
   * Toggles the toast visibility.
   */
  const toggleToast = () => setShowToast(!showToast);

  /**
   * React useEffect hook that runs when the component mounts.
   *
   * @return {void} This function does not return anything.
   */

  useEffect(() => {
    // getEvents();
    run(() => getPromoterEventList())
      .then((res) => {
        setAllEvents(res.data.eventHomePageDtoList);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });

    // get logged in promoter details
    getUserDetails()
      .then((res) => {
        setStripeActivated(res.data.stripeAccountActivated);
      })
      .catch((err) => {
        console.log(err);
      });

    // get all category list , public api, does not require authentication
    getCategoryDetails()
      .then((res) => {
        setCategoryTypes(res.data);
      })
      .catch((err) => {
        console.log('Category list error');
      });
  }, []);

  /**
   * Fetches the list of events for the given page number.
   *
   * @param {number} page The page number to fetch.
   * @return {void} This function does not return anything.
   */
  const getEvents = (page) => {
    // setLoading(true);
    getPromoterEventList(page)
      .then((res) => {
        console.log(res);
        setAllEvents(res.data.eventHomePageDtoList);
        setTotalPages(res.data.totalPages);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  // const handleCamerasResponse = (res) => {
  //     res.data.cameraDetailsDtoList.forEach((camera) => {
  //         // if no status set STOP by default
  //         if (camera.channelStatus == undefined) {
  //             camera['channelStatus'] = CHANNEL_ACTIONS.STOP;
  //         }
  //     })
  //     setCameraDetails(res.data);
  //     setLoadingCameras(false);
  // }

  // const viewCameras = (event, eventIndex) => {
  //     setLoadingCameras(true);
  //     setShow(true);
  //     setCurrentEventId(event.eventId);
  //     if(!event.channelCreated){
  //         // if channel not created, call create channel first and then get channels
  //         createChannel(event.eventId).then((res) => {
  //             console.log(res);
  //             console.log("channels created successfully");

  //             let eventList = [...allEvents];
  //             eventList[eventIndex]['channelCreated'] = true;
  //             setCurrentEventId(event.eventId);

  //             getCameraDetails(event.eventId).then((res) => {
  //                 handleCamerasResponse(res);
  //             })
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setLoadingCameras(false);
  //         })
  //     }
  //     else{
  //         // get channel details directly if channel already created
  //         getCameraDetails(event.eventId).then((res) => {
  //             handleCamerasResponse(res);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setLoadingCameras(false);
  //         })
  //     }

  // };

  // const handleTabChange = (event, newValue) => {
  //     setValue(newValue);
  // };

  /**
   * Copies the given text to the clipboard.
   *
   * @param {string} text - The text to be copied.
   * @return {void} This function does not return anything.
   */
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowToast(true);
  };

  /**
   * Checks if some channel is streaming.
   *
   * @return {boolean} Whether some channel is streaming.
   */
  const isSomeChannelStreaming = () => {
    console.log(cameraDetails);
    return cameraDetails.cameraDetailsDtoList.some(
      (camera) =>
        camera.channelStatus == CHANNEL_ACTIONS.START ||
        camera.channelStatus == CHANNEL_ACTIONS.STARTING ||
        camera.channelStatus == CHANNEL_ACTIONS.RUNNING
    );
  };

  /**
   * Checks if the given camera is running.
   *
   * @param {Object} camera - The camera to check.
   * @return {boolean} Whether the camera is running.
   */
  const isChannelRunning = (camera) => {
    return (
      camera.channelStatus == CHANNEL_ACTIONS.START ||
      camera.channelStatus == CHANNEL_ACTIONS.STARTING ||
      camera.channelStatus == CHANNEL_ACTIONS.RUNNING
    );
  };

  /**
   * Starts or stops a channel based on the given action and camera setup ID.
   *
   * @param {string} action - The action to perform (start or stop).
   * @param {string} cameraSetupId - The ID of the camera setup.
   * @param {number} index - The index of the camera in the camera details list.
   * @return {void} This function does not return anything.
   */
  const startStopChannel = (action, cameraSetupId, index) => {
    let cameras = { ...cameraDetails };
    if (cameraSetupId) {
      cameras.cameraDetailsDtoList[index].actionLoading = true;
    } else {
      cameras.actionLoading = true;
    }
    setCameraDetails(cameraDetails);

    modifyChannel(action, currentEventId, cameraSetupId)
      .then((res) => {
        let events = [...allEvents],
          cameras = { ...cameraDetails };
        if (cameraSetupId) {
          cameras.cameraDetailsDtoList[index].channelStatus = action;
          cameraDetails.cameraDetailsDtoList[index].actionLoading = false;
        } else {
          cameras.actionLoading = false;
          cameras.cameraDetailsDtoList.forEach((camera) => {
            camera.channelStatus = action;
          });
        }
        setCameraDetails(cameras);

        for (let i = 0; i < events.length; i++) {
          const ev = events[i];
          if (ev.eventId == currentEventId) {
            ev.channelStreaming =
              action == CHANNEL_ACTIONS.START ? true : false;
            break;
          }
        }
        setAllEvents(events);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const goToEvent = (id) => {
  //     getEventDetails(id).then((res) => {
  //         console.log(res);
  //         navigate(`/promoter-panel/events/create-event/${res.data.step}/${res.data.id}`);

  //     })
  //     .catch((err) => {
  //         console.log(err);
  //     })
  // }

  /**
   * Checks if an event is closed based on the given date and time.
   *
   * @param {string} datestr - The date string in 'YYYY-MM-DD' format.
   * @param {string} timestr - The time string in 'HH:mm:ss' format.
   * @return {boolean} Returns true if the event is closed, false otherwise.
   */

  const isEventClosed = (datestr, timestr) => {
    return new Date(datestr + ' ' + timestr).getTime() < new Date().getTime();
  };

  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div>
      <div className="d-flex align-items-center pe-5">
        <h2 className="fw-bold ms-4 my-4 ps-4">Events</h2>
      </div>

      <Box sx={{ width: '100%' }} className="px-3 px-lg-5">
        <Row>
          <Col xs={12} md={6}>
            <Form className="d-flex">
              <Form.Group controlId="formBasicSearch">
                <Form.Control type="input" placeholder="Search" />
              </Form.Group>
              <Form.Group controlId="formBasicDate" className="mx-3">
                <Form.Control type="date" placeholder="Search" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          {!allEvents.length && (
            <Col xs="12">
              <Row>
                <Col xs="12" className="bg-primary text-white p-3">
                  Events
                </Col>
                <Col xs="12" className="bg-header col-12 p-5 text-center p-5">
                  <h3 className="mt-5 pt-5">
                    You haven't created any events yet,
                    <strong>
                      {' '}
                      {userDetails.firstName + ' ' + userDetails.lastName}!
                    </strong>
                  </h3>
                  <h3>
                    <strong>Get started now!</strong>
                  </h3>
                  <div className="mb-5 pb-5">
                    <Button
                      onClick={() => createEventClickHandler()}
                      variant="primary"
                      className="ms-auto text-white px-4 mt-2"
                    >
                      Create Event
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {!!allEvents.length && (
            <Col className="mt-4 ps-4">
              <Row className="bg-primary text-white p-3 mb-2">
                <Col xs={5}>Event</Col>
                <Col>Sold</Col>
                <Col>Income</Col>
                <Col>Status</Col>
                <Col>Sale Status</Col>
                <Col> Commission</Col>
                <Col xs="1" style={{ width: '50px' }}></Col>
              </Row>
              {allEvents.map((event, i) => {
                return (
                  <Row
                    key={i}
                    className="border-gray border mb-2 align-items-center"
                  >
                    <Col xs={5} className="d-flex align-items-center">
                      <div className="text-center me-3">
                        <h5 className="mb-0 fw-bold">
                          {moment(event.eventStartDate).format('MMM')}
                        </h5>
                        <h2 className="mb-0 fw-bold">
                          {moment(event.eventStartDate).format('DD')}
                        </h2>
                      </div>

                      {event.thumbnailUrl && (
                        <img
                          className="event-banner-thumbnail"
                          src={event.thumbnailUrl}
                          alt="event thumbnail"
                        />
                      )}

                      {!event.thumbnailUrl && (
                        <FontAwesomeIcon
                          className="text-darkGray event-banner-thumbnail"
                          icon={faImage}
                          size="4x"
                        />
                      )}

                      <div className="ms-3">
                        <Button
                          as={Link}
                          to={
                            event.eventStatus == 'PUBLISHED'
                              ? `/preview-event/${event.eventId}`
                              : `/promoter-panel/events/create-event/${event.eventStep}/${event.eventId}`
                          }
                          variant="link"
                          className="text-secondary text-decoration-none p-0"
                        >
                          <h4 className="mb-0 fw-bold text-start">
                            {event.eventTitle}
                          </h4>
                        </Button>
                        <br />
                        <span>
                          {' '}
                          {moment(event.eventStartDate).format(
                            'ddd MMM DD, YYYY'
                          )}{' '}
                          {event.eventStartTime}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      {event.totalTickets
                        ? event.ticketsSold
                          ? `${event.ticketsSold}/${event.totalTickets}`
                          : `0/${event.totalTickets}`
                        : '-'}
                    </Col>
                    <Col>
                      {event.eventStatus == 'PUBLISHED'
                        ? `CA $${event.income ? event.income : '0.00'}`
                        : '-'}
                    </Col>
                    <Col>{event.eventStatus}</Col>
                    <Col>
                      <Dropdown
                        onSelect={(eventkey) => {
                          let events = [...allEvents];
                          events[i].saleStatus = eventkey;
                          setAllEvents(events);
                        }}
                      >
                        <Dropdown.Toggle
                          style={{ width: '130px' }}
                          disabled={event.eventStatus != 'PUBLISHED'}
                          variant="primary"
                          id="dropdown-sale-status"
                          className="px-2 border-2 rounded"
                        >
                          <span className="me-2">
                            {event.saleStatus ? event.saleStatus : 'On Sale'}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="On Sale">
                            On Sale
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Postponed">
                            Postponed
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Available Soon">
                            Available Soon
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Closed">
                            Closed
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Tooltip
                        title={
                          <p className="fs-12 mb-0">
                            {isEventClosed(
                              event.eventEndDate,
                              event.eventEndTime
                            )
                              ? EVENT_CLOSED_TOOLTIP
                              : ''}
                          </p>
                        }
                      >
                        <div>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              disabled={!isEditing}
                              value={commissionPercent}
                              onChange={handleTextChange}
                              className="form-control mr-2"
                            />
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className="icon-pencil m-2"
                              onClick={toggleEditing}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </Col>
                    <Col xs="1" style={{ width: '50px' }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          className="no-caret bg-transparent border-0"
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item as={Button} className="fw-bold">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item as={Button} className="fw-bold">
                            Archive
                          </Dropdown.Item>
                          <Dropdown.Item as={Button} className="fw-bold">
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowAddPerformanceModal(true);
                              setCurrentEvent(event);
                            }}
                            as={Button}
                            className="fw-bold"
                          >
                            Add a performance
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          )}
        </Row>

        {/* <Pagination currentPage={currentPage} totalPages={totalPages}
                    handlePageChange={handlePageChange}
                /> */}

        <div className="d-flex mt-4">
          <Button
            onClick={() => createEventClickHandler()}
            variant="primary"
            className="mx-auto text-white px-4 mt-2 rounded btn-create-event"
          >
            Create Event
          </Button>
        </div>

        {/* <Row>
                    <Col className='d-flex justify-content-between align-items-center'>
                        <span className='invisible '>Showing Page {currentPage + 1} of {totalPages}</span>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() =>  handlePageChange(currentPage - 1)}
                                disabled={currentPage == 0} className='page-item-prev' />
                            {(() => {
                                let items = [];
                                for(let i=0; i<totalPages;i++){
                                    items.push(
                                        <Pagination.Item active={currentPage == i}
                                            onClick={() =>  handlePageChange(i)}>
                                            {i+1}
                                        </Pagination.Item>
                                    )
                                }
                                return items 
                            })()}
                            <Pagination.Next onClick={() =>  handlePageChange(currentPage + 1)} 
                                disabled={currentPage == totalPages - 1} className='page-item-next' />
                        </Pagination>
                        <span>Showing Page {currentPage + 1} of {totalPages}</span>
                    </Col>
                </Row> */}
      </Box>

      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setCurrentEventId(null);
        }}
        backdrop="static"
        keyboard={false}
        dialogClassName="w-50 mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-4">Manage Camera(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Toast
            style={{ width: 'unset' }}
            className="position-absolute"
            bg="secondary"
            show={showToast}
            onClose={toggleToast}
            autohide={true}
            delay={1000}
          >
            <Toast.Body className="text-white">
              Text Copied to Clipboard!
            </Toast.Body>
          </Toast>
          {loadingCameras && (
            <div className="p-5 text-center">
              <h6 className="me-2 mb-3">
                Please wait while we setup your camera(s)...
              </h6>
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          {!loadingCameras &&
            cameraDetails &&
            cameraDetails.cameraDetailsDtoList.length && (
              <div className="m-4">
                {isSomeChannelStreaming() && (
                  <div className="mb-3 text-right">
                    <a
                      href={`/stream/${currentEventId}`}
                      className="text-secondary"
                      target="_blank"
                    >
                      Click here to view your stream
                    </a>
                  </div>
                )}
                {cameraDetails.cameraDetailsDtoList.map((dto, i) => {
                  return (
                    <>
                      <Row key={i} className="mb-2">
                        <Col className="fw-bold" xs={3}>
                          Camera Name:
                        </Col>
                        <Col xs={9}>{dto.cameraName || '-'}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="fw-bold" xs={3}>
                          Artist Name:
                        </Col>
                        <Col xs={9}>{dto.artistName || '-'}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="fw-bold" xs={3}>
                          Description:
                        </Col>
                        <Col xs={9}>
                          <p
                            className="text-break"
                            dangerouslySetInnerHTML={{
                              __html: dto.description,
                            }}
                          ></p>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="fw-bold" xs={3}>
                          Input URL:
                        </Col>
                        <Col xs={9} className="d-flex">
                          {dto.inputUrl || '-'}
                          <Button
                            title="copy"
                            onClick={() => copyToClipboard(dto.inputUrl)}
                            variant="link"
                            className="text-decoration-none p-0 text-dark"
                          >
                            <FontAwesomeIcon
                              icon={faClipboard}
                              size="lg"
                              className="ms-2"
                            />
                          </Button>
                        </Col>
                      </Row>
                      {/* <Row className="mb-2">
                                            <Col className="fw-bold" xs={3}>Output URL:</Col>
                                            <Col xs={9} className="d-flex">
                                               <p className="text-break">{dto.outputUrl || '-'}</p> 
                                               <Button title='copy' onClick={() => copyToClipboard(dto.outputUrl)} variant="link"
                                                    className="text-decoration-none p-0 text-dark">
                                                    <FontAwesomeIcon  size="lg" icon={faClipboard} className='ms-2' />
                                               </Button>
                                            </Col>
                                        </Row> */}
                      <Row>
                        <Col>
                          {!isChannelRunning(dto) && (
                            <Button
                              onClick={() =>
                                startStopChannel(
                                  CHANNEL_ACTIONS.START,
                                  dto.cameraSetupId,
                                  i
                                )
                              }
                              disabled={dto.actionLoading}
                              variant="secondary"
                              className="d-flex align-items-center text-white ms-auto"
                            >
                              {dto.actionLoading && (
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {!dto.actionLoading && (
                                <FontAwesomeIcon
                                  icon={faPlay}
                                  size="2x"
                                  className="me-2"
                                />
                              )}
                              <h5 className="mb-0">Start Stream</h5>
                            </Button>
                          )}
                          {isChannelRunning(dto) && (
                            <Button
                              onClick={() =>
                                startStopChannel(
                                  CHANNEL_ACTIONS.STOP,
                                  dto.cameraSetupId,
                                  i
                                )
                              }
                              disabled={dto.actionLoading}
                              variant="danger"
                              className="d-flex align-items-center text-white ms-auto"
                            >
                              {dto.actionLoading && (
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {!dto.actionLoading && (
                                <FontAwesomeIcon
                                  icon={faStop}
                                  size="2x"
                                  className="me-2"
                                />
                              )}
                              <h5 className="mb-0">Stop Stream</h5>
                            </Button>
                          )}
                        </Col>
                      </Row>

                      <hr className="my-4" />
                    </>
                  );
                })}
                <Row>
                  <Col>
                    {!isSomeChannelStreaming() && (
                      <Button
                        onClick={() => startStopChannel(CHANNEL_ACTIONS.START)}
                        disabled={cameraDetails.actionLoading}
                        variant="secondary"
                        className="d-flex align-items-center text-white ms-auto"
                      >
                        {cameraDetails.actionLoading && (
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!cameraDetails.actionLoading && (
                          <FontAwesomeIcon
                            icon={faPlay}
                            size="2x"
                            className="me-2"
                          />
                        )}

                        <h5 className="mb-0">Start All Streams</h5>
                      </Button>
                    )}
                    {isSomeChannelStreaming() && (
                      <Button
                        onClick={() => startStopChannel(CHANNEL_ACTIONS.STOP)}
                        disabled={cameraDetails.actionLoading}
                        variant="danger"
                        className="d-flex align-items-center text-white ms-auto"
                      >
                        {cameraDetails.actionLoading && (
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!cameraDetails.actionLoading && (
                          <FontAwesomeIcon
                            icon={faStop}
                            size="2x"
                            className="me-2"
                          />
                        )}
                        <h5 className="mb-0">Stop All</h5>
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddPerformanceModal}
        onHide={() => {
          handleClosePerformanceModal();
        }}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="w-50 mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-4 d-flex align-items-center">
            <h6 className="mb-0 me-2">Add a performance for:</h6>
            {currentEvent && currentEvent.eventTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          {!selectPerformanceSteps && (
            <>
              <h6>Please enter the performance information:</h6>

              <Form.Group className="mb-3">
                <Form.Label>Performance Name</Form.Label>
                <Form.Control
                  value={performanceName}
                  onChange={(e) => setPerformanceName(e.target.value)}
                  type="text"
                  placeholder="Ex. Presented by John Smith"
                />
              </Form.Group>

              <div className="d-flex mb-3">
                <Form.Group className="flex-grow-1 pe-2">
                  <Form.Label>Category:</Form.Label>
                  <Form.Select>
                    <option>Concert</option>
                    <option value="1">Concert 1</option>
                    <option value="2">Concert 2</option>
                    <option value="3">Concert 3</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="flex-grow-1 ps-2">
                  <Form.Label>Type:</Form.Label>
                  <Form.Select>
                    <option>Rock</option>
                    <option value="1">Pop</option>
                    <option value="2">Jazz</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="mb-3">
                <EventStartEndDate
                  formState={formState}
                  updateState={updateState}
                  errors={errors}
                />
              </div>

              {performanceDetailsError && (
                <span className="text-danger">{performanceDetailsError}</span>
              )}

              <div className="mt-3">
                <Button
                  disabled={!performanceName}
                  variant="primary"
                  onClick={submitPerformanceName}
                  className="text-white mb-3 me-3"
                >
                  Continue
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleClosePerformanceModal()}
                  className="mb-3 text-dark border-dark bg-white"
                >
                  Discard
                </Button>
              </div>
            </>
          )}

          {selectPerformanceSteps && (
            <>
              <h6 className="mb-4">
                What do you want to modify in this performance?
              </h6>

              <div className="mb-3 px-4">
                {/* <Form.Check
                                    type="checkbox"
                                    label={<label for="basic-event-info-checkbox">Basic Event Information <br/> <small>SubTitle/Date/Time</small></label>}
                                    id={"basic-event-info-checkbox"}
                                    onChange={(e) => handleSelectStepToAddPerformance(e, EVENT_STEPS.BASIC_DETAILS)}
                                    className="mb-4"
                                />

                                <Form.Check
                                    type="checkbox"
                                    label={<label for="main-event-info-checkbox">Main Event Page Set Up <br/> <small>Images/Description</small></label>}
                                    id={"main-event-info-checkbox"}
                                    className="mb-4"
                                    onChange={(e) => handleSelectStepToAddPerformance(e, EVENT_STEPS.MAIN_DETAILS)}
                                /> */}

                <Form.Check
                  type="checkbox"
                  label={
                    <label for="venue-info-checkbox">
                      Location Setting <br /> <small>Venue Address</small>
                    </label>
                  }
                  id={'venue-info-checkbox'}
                  className="mb-4"
                  onChange={(e) =>
                    handleSelectStepToAddPerformance(
                      e,
                      EVENT_STEPS.IN_PERSON_DETAILS
                    )
                  }
                />

                <Form.Check
                  type="checkbox"
                  label={
                    <label for="price-info-checkbox">
                      Ticketing <br /> <small>Price sections and types</small>{' '}
                    </label>
                  }
                  id={'price-info-checkbox'}
                  className="mb-4"
                  onChange={(e) =>
                    handleSelectStepToAddPerformance(
                      e,
                      EVENT_STEPS.TICKET_DETAILS
                    )
                  }
                />
              </div>

              <Button
                onClick={handleGoToStep}
                disabled={selectedStepsToModify.size == 4}
                variant="primary"
                className="text-white mb-3 me-3"
              >
                Continue
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => setSelectPerformanceSteps(false)}
                className="mb-3 text-dark border-dark bg-white"
              >
                Back
              </Button>
            </>
          )}

          {selectedStepsToModify.size == 4 && (
            <>
              <p className="text-danger">
                If you want to change all the sections, you need to make a new
                event!
              </p>
              <Button
                onClick={() => createEventClickHandler()}
                variant="outline-secondary"
                className="mb-4 text-dark border-dark bg-white"
              >
                Create Event
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllEvents;
