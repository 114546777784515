import React, { useState, useEffect } from 'react';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAsync } from '../../hooks/useAsync';
import { cancelOrder, getOrderList, getFeeDetails } from '../../utils/APIUtils';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import {
  CANCEL_ORDER_TOOLTIP,
  SERVICE_FEE_PERCENT,
  TAX_PERCENTAGE,
  TICKET_TYPE,
  RESTOCKING_FEE,
  APPLICATION_FEE,
  GATEWAY_FEE,
} from '../../constants';
import Pagination from '../../shared/Pagination';

/**
 * Enumeration representing the steps for contact organizer.
 * @constant
 * @type {Object}
 * @property {string} SHOW_FAQ - Show FAQ.
 * @property {string} SHOW_FORM - Show form.
 * @property {string} SHOW_DETAILS - Show details.
 * @property {string} SHOW_MESSAGE - Show message.
 */
const CONTACT_ORGANIZER_STEPS = Object.freeze({
  SHOW_FAQ: 'SHOW_FAQ',
  SHOW_FORM: 'SHOW_FORM',
  SHOW_DETAILS: 'SHOW_DETAILS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
});

/**
 * This function is used to display the user orders.
 *
 */
const MyOrders = () => {
  const { data, loading, error, run } = useAsync(null);
  const [orderList, setOrderList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  /**
   * Use effect hook that runs when the component is mounted or when the currentPage state changes.
   * It fetches the order list from the server and updates the orderList and totalPages states.
   */
  useEffect(() => {
    run(() => getOrderList()).then((res) => {
      console.log(res.data);
      setOrderList(res.data.patronOrderDetailsDtoList);
      setTotalPages(res.data.totalPages);
    });
  }, [currentPage]);

  /**
   * State hook managing the visibility of the contact organizer modal.
   * @type {useState}
   */
  const [showContactOrganizerModal, setShowContactOrganizerModal] =
    useState(false);

  /**
   * State hook managing the form steps of the contact organizer form.
   * @type {useState}
   */
  const [contactOrganizerFormStep, setContactOrganizerFormStep] = useState(
    CONTACT_ORGANIZER_STEPS.SHOW_FAQ
  );

  /**
   * State hook managing the visibility of the cancel order modal.
   * @type {useState}
   */
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  /**
   * State hook managing the reason for refund.
   * @type {useState}
   */
  const [refundReason, setRefundReason] = useState('');

  /**
   * State hook to set the refund order ID.
   * @type {useState}
   */
  const [orderRefundId, setOrderRefundId] = useState('');

  /**
   * State hook to set the detail for fee.
   * @type {useState}
   */
  const [feeDetails, setFeeDetails] = useState(null);

  /**
   * Handle page change event.
   * @param {number} page - The new page number.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  /**
   * Reset the contact organizer form.
   */
  const resetContactOrganizerForm = () => {
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_FAQ);
  };

  /**
   * Handle the submission of the contact form.
   * @param {Event} e - The form submission event.
   */
  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_DETAILS);
  };

  // This function is used to handle the cancellation of the order
  /**
   * Handle the cancellation of the order
   * @return {void}
   */
  const handleCancelOrder = () => {
    let orderCancelDTO = {
      eventId: selectedOrder.eventId,
      orderId: selectedOrder.orderId,
      refundReason: refundReason,
      fullRefund: false,
    };

    cancelOrder(orderCancelDTO)
      .then((res) => {
        console.log(res.data);
        setOrderRefundId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(orderCancelDTO);
  };

  /**
   * Handle the open cancel order modal
   * @return {void}
   */
  const handleOpenCancelOrderModal = () => {
    setShowCancelOrderModal(true);

    if (!feeDetails) {
      getFeeDetails()
        .then((res) => {
          console.log(res.data);
          setFeeDetails(res.data);
        })
        .catch((err) => {
          console.log('ERR', err);
        });
    }
  };

  /**
   * Fetch the sub total price for the ticket
   * @param {Object} ticketDTO - The ticket details object
   * @return {number} The sub total price
   */
  const getSubTotalPrice = (ticketDTO) => {
    const { chargeTax, taxPercentage, ticketPrice, ticketType } = ticketDTO;

    return (
      ticketPrice +
      (chargeTax
        ? (ticketPrice * taxPercentage) / 100
        : ticketPrice * TAX_PERCENTAGE) +
      ticketPrice * SERVICE_FEE_PERCENT
    );
  };

  /**
   * Get the total price for the ticket
   * @return {number} The total price
   */
  const getTotalPrice = () => {
    let subtotal = getSubTotalPrice(selectedOrder.ticketDetails);
    let discount = selectedOrder.couponDiscount || 0;

    return subtotal - discount;
  };
  /**
   * This function is used to calculate the refund amount for the selected order.
   *
   * @returns {number} The refund amount.
   */
  const getRefundAmount = () => {
    /**
     * Calculate the refund amount by subtracting the restocking fee, gateway fee,
     * and application fee from the total price of the selected order.
     *
     * If the calculated refund amount is less than 0, set it to 0.
     *
     * @type {number}
     */
    let refund =
      getTotalPrice() - RESTOCKING_FEE - GATEWAY_FEE - APPLICATION_FEE;

    if (refund < 0) {
      refund = 0;
    }

    return refund;
  };

  /**
   * Render a loading spinner if the data is still loading.
   *
   * @returns {JSX.Element} The loading spinner component.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <>
      {!selectedOrder && (
        <div>
          <Button
            as={Link}
            to="/promoter-panel/events"
            variant="link"
            className="text-decoration-none px-0 mt-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Go Back to Dashboard
          </Button>
          <div className="mb-5">
            <h2 className="fw-bold mt-3 mb-0">Your Orders</h2>
            <span>Here is the list of your orders.</span>
          </div>

          <Row>
            <Col xs="12">
              <Row className="bg-primary text-white p-3">
                <Col xs="2" className="px-0">
                  Order #
                </Col>
                <Col xs="4">Event Name</Col>
                <Col className="d-none d-md-block">Organizer</Col>
                <Col>Date & Time</Col>
                <Col xs="1">Status</Col>
              </Row>
              {orderList.map((item, i) => {
                return (
                  <Row className="bg-light p-3 mb-1 align-items-center">
                    <Col xs="2" className="px-0">
                      <Button
                        onClick={() => setSelectedOrder(item)}
                        variant="link"
                        className="text-truncate w-100 text-secondary text-decoration-none px-0"
                      >
                        {item.uniqueGeneratedOrderId}
                      </Button>
                    </Col>
                    <Col
                      xs="4"
                      className="text-truncate"
                      title={item.eventName}
                    >
                      {item.eventName}
                    </Col>
                    <Col className="d-none d-md-block">
                      {item.customPromoterName || '-'}
                    </Col>
                    <Col
                      className="text-truncate"
                      title={moment(item.orderDate).format(
                        'ddd MMM DD, YYYY hh:mm A'
                      )}
                    >
                      {moment(item.orderDate).format(
                        'ddd MMM DD, YYYY hh:mm A'
                      )}
                    </Col>
                    <Col xs="1">{item.orderStatus}</Col>
                  </Row>
                );
              })}
            </Col>
          </Row>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}

      {selectedOrder && (
        <div>
          <Row>
            <Col xs="12" lg="9">
              <Button
                onClick={() => setSelectedOrder(null)}
                variant="link"
                className="text-decoration-none px-0 mt-2"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                Back to your orders
              </Button>
              <div className="mb-4">
                <h2 className="fw-bold mt-3 mb-0 border-bottom border-gray pb-3">
                  Order for {selectedOrder.eventName}
                  {selectedOrder.customPromoterName
                    ? 'by Vincent Dark' + selectedOrder.customPromoterName
                    : ''}
                </h2>
              </div>

              <Row>
                <Col xs="12" md="6" lg="8">
                  <h5 className="mb-3">
                    <strong>Order id: </strong>{' '}
                    {selectedOrder.uniqueGeneratedOrderId}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Order status: </strong> {selectedOrder.orderStatus}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Ordered on: </strong>{' '}
                    {moment(selectedOrder.orderDate).format(
                      'ddd MMM DD, YYYY hh:mm A'
                    )}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Order Type: </strong> LiveStream
                  </h5>{' '}
                  {/* hard coded */}
                  <h5 className="mb-3">
                    <strong>Card Number: </strong> XXXX XXXX XXXX{' '}
                    {selectedOrder.lastFourDigit}
                  </h5>
                  <h5 className="mb-3 text-capitalize">
                    <strong>Card Type: </strong> {selectedOrder.cardBrand}
                  </h5>
                </Col>

                <Col xs="12" md="6" lg="4" className="ms-auto">
                  <h5>Order Summary</h5>
                  <div className="d-flex">
                    <p className="mb-1 me-5">1 x General Admission</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketType ==
                      TICKET_TYPE.PAID
                        ? selectedOrder.ticketDetails.ticketPrice
                        : 0}
                    </span>
                  </div>

                  <div className="d-flex">
                    <p className="mb-1">Fee</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketPrice *
                        SERVICE_FEE_PERCENT}
                    </span>
                  </div>

                  {/* tax hard coded */}
                  <div className="d-flex">
                    <p className="mb-1">Tax</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketPrice *
                        (selectedOrder.ticketDetails.chargeTax
                          ? selectedOrder.ticketDetails.taxPercentage / 100
                          : TAX_PERCENTAGE)}
                    </span>
                  </div>

                  <hr className="my-2" />

                  <div className="d-flex">
                    <p className="mb-1">Subtotal</p>
                    <span className="ms-auto">
                      CA $ {getSubTotalPrice(selectedOrder.ticketDetails)}
                      {/* {getTotalPrice()} */}
                    </span>
                  </div>

                  <div className="d-flex">
                    <p className="mb-1">
                      {' '}
                      Discount
                      {selectedOrder.couponDiscount && (
                        <small className="text-darkGray">
                          {' '}
                          (Coupon Applied)
                        </small>
                      )}
                    </p>
                    <span className="ms-auto">
                      - CA ${' '}
                      {selectedOrder.couponDiscount
                        ? selectedOrder.couponDiscount.couponDiscount
                        : 0}
                      {/* {appliedCoupon.couponType == 'FIXED' ? appliedCoupon.couponDiscount : 
                                                        eventDTO.eventTicketDetailsModel.price * (appliedCoupon.couponDiscount / 100)} */}
                    </span>
                  </div>

                  <hr className="my-2" />

                  <div className="d-flex">
                    <p className="mb-1">Grand Total</p>
                    <span className="ms-auto">
                      CA $ {getSubTotalPrice(selectedOrder.ticketDetails)}
                      {/* {getTotalPrice()} */}
                    </span>
                  </div>
                </Col>
              </Row>

              <hr className="my-4" />

              <h4 className="mb-3 fw-bold">Billing Details</h4>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.firstName}{' '}
                {selectedOrder.orderBillingInfoDto.lastName}
              </h5>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.email}
              </h5>

              <h5 className="mb-2">
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .addressLine1
                }
              </h5>

              <h5 className="mb-2">
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .addressLine2
                }
              </h5>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.orderBillingAddressDto.city},{' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .province
                }
                ,{' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .country
                }{' '}
                ({' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .postalCode
                }{' '}
                )
              </h5>

              {/* <div className="d-flex mb-3">
                                <Form.Group controlId="firstName" className="me-4">
                                    <Form.Label className="required">First Name</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.firstName} 
                                        readOnly />
                                </Form.Group>
                                <Form.Group controlId="lastName">
                                    <Form.Label className="required">Last Name</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.lastName}
                                        readOnly 
                                    />
                                </Form.Group>
                            </div>


                            <div className="d-flex mb-3">
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.email}
                                        readOnly />
                                </Form.Group>
                            </div>
                            
                            <div className="d-flex mb-3">
                                <Form.Group controlId="deliveryMethod">
                                    <Form.Label>Delivery method</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value="e-Ticket"
                                        readOnly
                                    />
                                </Form.Group>
                            </div> */}

              <div className="mt-4 pt-3">
                {/* <Button variant="primary" className="text-white">
                                    Print Ticket
                                </Button> */}

                <Tooltip title={<p className="mb-0">{CANCEL_ORDER_TOOLTIP}</p>}>
                  <Button
                    onClick={handleOpenCancelOrderModal}
                    disabled={selectedOrder.orderStatus != 'CONFIRMED'}
                    variant="outline-dark"
                    className="me-3"
                  >
                    Cancel Order Request
                  </Button>
                </Tooltip>

                <Button
                  onClick={() => setShowContactOrganizerModal(true)}
                  variant="orange"
                  className="text-white"
                >
                  Contact Organizer
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        show={showCancelOrderModal}
        onHide={() => {
          setShowCancelOrderModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3">
            Are you sure you want to cancel this order?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {selectedOrder && (
            <>
              <Row className="mb-2">
                <Col>Order Amount</Col>
                <Col>${getTotalPrice()}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Restocking Fee</Col>
                <Col>- ${RESTOCKING_FEE}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Application Fee</Col>
                <Col>- ${APPLICATION_FEE}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Payment Gateway Fee</Col>
                <Col>- ${GATEWAY_FEE}</Col>
              </Row>

              <hr />

              <Row className="mb-2">
                <Col>Refund Amount</Col>
                <Col>${getRefundAmount()}</Col>
              </Row>

              <Form.Group controlId="refundReason" className="mt-3">
                <Form.Label>Reason (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  value={refundReason}
                  onChange={(e) => setRefundReason(e.target.value)}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!orderRefundId && (
            <>
              <Button
                onClick={() => setShowCancelOrderModal(false)}
                variant="outline-dark"
              >
                No
              </Button>
              <Button
                onClick={handleCancelOrder}
                className="text-white"
                variant="danger"
              >
                Yes, Cancel
              </Button>
            </>
          )}

          {orderRefundId && (
            <Button
              onClick={() => setShowCancelOrderModal(false)}
              variant="outline-dark"
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showContactOrganizerModal}
        onHide={() => {
          setShowContactOrganizerModal(false);
          resetContactOrganizerForm();
        }}
      >
        <Modal.Header
          closeButton
          className={`${
            contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE
              ? 'border-0'
              : ''
          }`}
        >
          {contactOrganizerFormStep != CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE && (
            <Modal.Title className="px-4">Contact Organizer</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_FAQ && (
            <>
              <p className="mb-2">FAQ</p>
              <Button
                variant="link"
                className="text-decoration-none text-secondary px-0"
              >
                Can I get a refund?
              </Button>
              <br />
              <Button
                variant="link"
                className="text-decoration-none text-secondary px-0"
              >
                How to update your ticket information?
              </Button>
              <br />
              <p className="mb-2 mt-4">Have a question for organizer?</p>
              <Button
                onClick={() =>
                  setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_FORM)
                }
                variant="orange"
                className="text-white mt-2"
              >
                Contact Organizer
              </Button>
            </>
          )}
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_FORM && (
            <Form>
              <div className="d-flex mb-3">
                <Form.Group controlId="yourName" className="me-3">
                  <Form.Label className="required">Your name</Form.Label>
                  <Form.Control id="yourName" type="text" />
                </Form.Group>

                <Form.Group controlId="yourEmail">
                  <Form.Label className="required">
                    Your email address
                  </Form.Label>
                  <Form.Control id="yourEmail" type="text" />
                </Form.Group>
              </div>

              <Form.Group controlId="subject" className="mb-3">
                <Form.Label className="required">Subject</Form.Label>
                <Form.Control id="subject" type="text" />
              </Form.Group>

              <Form.Group controlId="message" className="mb-4">
                <Form.Label className="required">Message</Form.Label>
                <Form.Control as="textarea" id="message" />
              </Form.Group>
              <div className="d-flex mt-5">
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_FAQ
                    )
                  }
                  variant="outline-dark"
                  className="ms-auto me-2"
                >
                  Back
                </Button>
                <Button
                  onClick={handleContactFormSubmit}
                  variant="orange"
                  type="submit"
                  className="text-white px-4"
                >
                  Contact Organizer
                </Button>
              </div>
            </Form>
          )}
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_DETAILS && (
            <div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="mb-2">Contact Information</p>
                  <span>Maria R</span>
                </div>

                <div className="flex-grow-1">
                  <p className="mb-2">Your email address</p>
                  <span>Maria@gmail.com</span>
                </div>
              </div>

              <div className="mb-3">
                <p className="mb-2">Subject</p>
                <span>Refund ticket</span>
              </div>

              <div className="mb-3">
                <p className="mb-2">Message</p>
                <span>I need to get some information about this event</span>
              </div>

              <div className="d-flex mt-5">
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_FORM
                    )
                  }
                  variant="outline-dark"
                  className="ms-auto me-2"
                >
                  Back
                </Button>
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE
                    )
                  }
                  variant="orange"
                  className="text-white px-4"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}

          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE && (
            <div className="p-5 text-center">
              <h3>Thank you.</h3>
              <p>Your message was sent. The organizer will get back you soon</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyOrders;
