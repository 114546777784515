// In-Person event form, latest addition.
import React, { useState, useEffect } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import validateEventForm from '../eventFormValidator';
import { createEvent } from '../../../../utils/APIUtils';
import { setItem } from '../../../../utils/LocalStorageUtils';
import { EVENT_STEPS } from '../../../../constants';
import './styles.css';

/**
 * In-Person event form, latest addition.
 * @returns {JSX.Element} JSX element representing In-Person event form.
 */
const InPerson = () => {
  const [context, setContext] = useOutletContext();
  const { inPersonEventDetailsDto } = context;
  const [showAnotherForm, setShowAnotherForm] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [location, setLocation] = useState({ latitude: 43.6529, longitude: -79.3849 });
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [venueSections, setVenueSections] = useState([{}]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [formState, setFormState] = useState(inPersonEventDetailsDto);
  const [errors, setErrors] = useState(null);

  const updateState = (state) =>
    setFormState((prevState) => ({ ...prevState, ...state }));

  useEffect(() => {}, [formState]);

  const handleChange = (event) => {
    const { name, id, value, checked } = event.target;
    const state = id ? { [id]: value } : { [name]: checked };
    updateState(state);
  };

  const validate = () => {
    const error = validateEventForm(formState, 'IN_PERSON_DETAILS');
    if (error) {
      setErrors(error);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSaving(true);
      const data = JSON.parse(JSON.stringify(formState)); // Clone obj and modify
      if (!data.postalCode) {
        data.postalCode = data.zip;
      }
      createEvent('IN_PERSON_LOCATION_DETAILS', data, context.eventId)
        .then((res) => {
          setContext({
            ...context,
            inPersonEventDetailsDto: { ...formState },
            eventId: res.data.eventId,
          });
          setItem('eventId', res.data.eventId);
          setIsSaving(false);
          navigate(
            `/promoter-panel/events/create-event/${EVENT_STEPS.IN_PERSON_TICKET_DETAILS}/${res.data.eventId}`
          );
        })
        .catch((err) => {
          console.error(err);
          setIsSaving(false);
        });
    }
  };

  const handleShowAnotherForm = () => {
    setShowAnotherForm(!showAnotherForm);
  };

  const handleMarkerDragEnd = async (e) => {
    const { lat, lng } = e.target.getLatLng();
    setLocation([lat, lng]);
    setMarkerPosition([lat, lng]);

    const res = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`
    );
    const data = await res.json();
    updateState({
      addressLine1: data.address.amenity || `${data.address.house_number} ${data.address.road}`,
      venueName: data.display_name,
      addressLine2: data.address.road || '',
      city: data.address.city,
      postalCode: data.address.postcode,
      country: data.address.country,
      province: data.address.state,
    });
  };

  const handleSearchAddress = async (e) => {
    handleChange(e);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${
          formState.addressLine1 ||
          `${formState.venue} ${formState.city} ${formState.province} ${formState.country}`
        }`
      );
      const data = await response.json();
      setSearchResults(data);

      if (data.length > 0) {
        const { lat, lon } = data[0];
        setLocation([parseFloat(lat), parseFloat(lon)]);
        setAddressConfirmed(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSetAddress = async (address) => {
    setShowAnotherForm(true);
    const { lat, lon } = address;

    const res = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1`
    );
    const data = await res.json();
    updateState({
      addressLine1: data.address.amenity || `${data.address.house_number} ${data.address.road}`,
      venueName: data.display_name,
      addressLine2: data.address.road || '',
      city: data.address.city,
      postalCode: data.address.postcode,
      country: data.address.country,
      province: data.address.state,
    });
  };

  const Centerer = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);
    return null;
  };

  const customIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
    iconSize: [38, 38],
  });

  const [markerPosition, setMarkerPosition] = useState([43.6529, -79.3849]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs="5">
          <h2 className="fw-bold my-3">Location</h2>
          <Form.Group controlId="addressLine1" className="mb-3">
            <Form.Label className="required">Address</Form.Label>
            <Form.Control
              value={formState.addressLine1}
              onChange={handleChange}
              type="text"
              placeholder="Address"
            />
            {errors && errors.addressLine1 && (
              <Form.Text className="text-danger">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-2"
                />
                {errors.addressLine1}
              </Form.Text>
            )}
          </Form.Group>
          {!showAnotherForm && !searchResults.length && (
            <p>Please Select Card With Correct Address</p>
          )}

          {!showAnotherForm &&
            searchResults.map((result, index) => (
              <Card
                key={index}
                className="m-3 selectable-card"
                onClick={() => handleSetAddress(result)}
              >
                <Card.Body>
                  <Card.Text>{result.display_name}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          <p>
            <span className="hover-green" onClick={handleShowAnotherForm}>
              Add an address manually &nbsp;&gt;
            </span>
          </p>
          {!showAnotherForm && (
            <Button
              variant="orange"
              type="button"
              className="text-white px-3 border border-orange"
              onClick={handleSearchAddress}
            >
              Add Address
            </Button>
          )}
          {showAnotherForm && (
            <div>
              <Form.Group controlId="venueName" className="mb-3">
                <Form.Label>Venue Name</Form.Label>
                <Form.Control
                  value={formState.venueName}
                  onChange={handleChange}
                  type="text"
                  placeholder="State typing an address, eg 123 Main..."
                />
                {errors && errors.addressLine1 && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.addressLine1}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="addressLine2" className="mb-3">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  value={formState.addressLine2}
                  onChange={handleChange}
                  type="text"
                  placeholder="Apt/Suite/Bldg/Unit"
                />
              </Form.Group>
              <Form.Group controlId="city" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  value={formState.city}
                  onChange={handleChange}
                  type="text"
                  placeholder="City"
                />
                {errors && errors.city && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.city}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="postalCode" className="mb-3">
                <Form.Label>Zip/Postal Code</Form.Label>
                <Form.Control
                  value={formState.postalCode}
                  onChange={handleChange}
                  type="text"
                  placeholder="Zip"
                />
                {errors && errors.postalCode && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.postalCode}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="province" className="mb-3">
                <Form.Label>State/Province</Form.Label>
                <Form.Control
                  value={formState.province}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ontario"
                />
                {errors && errors.province && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.province}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="country" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  value={formState.country}
                  onChange={handleChange}
                  type="text"
                  placeholder="Canada"
                />
                {errors && errors.country && (
                  <Form.Text className="text-danger">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="me-2"
                    />
                    {errors.country}
                  </Form.Text>
                )}
              </Form.Group>
              {showAnotherForm && (
                <div
                  style={{ height: '400px', width: '800px' }}
                  className="my-3"
                >
                  <MapContainer
                    center={markerPosition}
                    zoom={markerPosition ? 14 : 2}
                    style={{ height: '400px', width: '800px' }}
                  >
                    <Centerer center={markerPosition} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                      position={markerPosition}
                      icon={customIcon}
                      draggable={true}
                      eventHandlers={{ dragend: handleMarkerDragEnd }}
                    >
                      <Popup>A marker indicating the location.</Popup>
                    </Marker>
                  </MapContainer>
                </div>
              )}
              <Row className="my-5">
                <Col className="d-flex mt-3">
                  <Button
                    className={`${
                      addressConfirmed ? 'disabled' : 'bg-orange border-orange'
                    } text-white px-3 border `}
                    onClick={handleSearchAddress}
                  >
                    {addressConfirmed ? 'Address Confirmed' : 'Confirm Address'}
                  </Button>
                  <Button
                    as={Link}
                    to={`/promoter-panel/events/create-event/${EVENT_STEPS.MAIN_DETAILS}/${context.eventId}`}
                    variant="outlined"
                    className="px-3 ms-3 border border-dark"
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
      <Row className="my-5">
        <Col className="d-flex mt-4">
          <Button
            variant="outlined"
            type="button"
            className="ms-auto me-3 border border-dark btn-create-event"
          >
            Discard
          </Button>
          <Button
            disabled={!addressConfirmed}
            type="submit"
            variant="primary"
            className="text-white px-3 border border-primary btn-create-event"
          >
            {isSaving && (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Saving...</span>
              </>
            )}
            {!isSaving && <span>Save and Continue</span>}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InPerson;
