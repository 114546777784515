import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Spinner,
  Dropdown,
  Modal,
  Button,
} from 'react-bootstrap';
import { useAsync } from '../../hooks/useAsync';
import Pagination from '../../shared/Pagination';
import { getManageUsers, updateUserStatus } from '../../utils/APIUtils';
import { USER_STATUS, USER_ROLES } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import ToastMessage from '../../shared/ToastMessage';

/**
 * UserManagement component is responsible for managing the user accounts.
 * It provides options to filter the users based on their type, search for users,
 * update the status of users, and view the details of users.
 *
 * @return {JSX.Element} The UserManagement component.
 */
const UserManagement = () => {
  /**
   * Destructuring assigment to extract the properties from the useAsync hook
   * @type {Object}
   * @property {Object|null} data - The data returned from the API call.
   * @property {boolean} loading - Indicates if the API call is in progress.
   * @property {Error|null} error - The error object from the API call.
   * @property {Function} run - The function to execute the API call.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * State hook to set the list of users
   * @type {React.State<Array>}
   */
  const [userList, setUserList] = useState([]);

  /**
   * State hook to load the filtered results.
   * @type {React.State<boolean>}
   */
  const [loadingFilteredResults, setLoadingFilteredResults] = useState(false);

  /**
   * State hook to set the active user type.
   * @type {React.State<string>}
   */
  const [activeUserType, setActiveUserType] = useState('');

  /**
   * State hook to manage the total pages.
   * @type {React.State<number>}
   */
  const [totalPages, setTotalPages] = useState(0);

  /**
   * State hook to manage the current page.
   * @type {React.State<number>}
   */
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * State hook to set the search query.
   * @type {React.State<string>}
   */
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * State hook to manage the show status modal.
   * @type {React.State<boolean>}
   */
  const [showStatusModal, setShowStatusModal] = useState(false);

  /**
   * State hook to manage the current user.
   * @type {React.State<Object|null>}
   */
  const [currentUser, setCurrentUser] = useState(null);

  /**
   * State hook to manage the toast.
   * @type {React.State<{ show: boolean, success: boolean, msg: string }>}
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * Function to handle the page changes
   * @param {number} page - The page number to navigate to.
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  /**
   * Fetches the list of users based on the active user type, current page, and search query.
   *
   * @return {Promise<void>} A Promise that resolves when the user list is fetched and the filtered results are updated.
   * @throws {Error} If there is an error fetching the user list.
   */
  const getUserList = () => {
    let role = activeUserType,
      pageNo = currentPage,
      query = searchQuery.trim();
    setLoadingFilteredResults(true);

    getManageUsers(role, pageNo, query)
      .then((res) => {
        setUserList(res.data.usersList);
        setLoadingFilteredResults(false);
        if (res.data.totalPages != totalPages) {
          setTotalPages(res.data.totalPages);
          setCurrentPage(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingFilteredResults(false);
      });
  };

  /**
   * Effect to fetch user list and total pages when the component mounts.
   */
  useEffect(() => {
    run(() => getManageUsers())
      .then((res) => {
        setUserList(res.data.usersList);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * Debounce effect for search
   * Fetches the list of users when the search query, current page, or active user type changes.
   */
  useEffect(() => {
    const getData = setTimeout(() => {
      getUserList();
    }, 500);

    return () => clearTimeout(getData);
  }, [searchQuery, currentPage, activeUserType]);

  // Function to perform the filteration according to the type of user.
  const filterByUserType = (role) => {
    if (role !== activeUserType) {
      setActiveUserType(role);
      // getUserList();
    }
  };

  /**
   * Function to fetch the address string.
   * @param {Object} addressDTO - The address data transfer object.
   * @returns {string} The formatted address string.
   */
  const getAddressString = (addressDTO) => {
    return ` ${addressDTO.addressLine1 || ''} ${
      addressDTO.addressLine2 || ''
    } ${addressDTO.addressLine1 || ''} ${addressDTO.city || ''} ${
      addressDTO.province || ''
    } ${addressDTO.country || ''} ${addressDTO.postalCode || ''}`;
  };

  /**
   * Function to save the updated status.
   * @return {Promise} A promise that resolves when the status is updated successfully.
   */
  const saveUpdatedStatus = () => {
    return updateUserStatus(currentUser.userId, currentUser.status)
      .then((res) => {
        setToast({
          show: true,
          msg: 'User updated successfully',
          success: true,
        });
        let updatedList = userList.map((user) => {
          if (user.userId == currentUser.userId) {
            user.status = currentUser.status;
          }
          return user;
        });

        setUserList(updatedList);
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
  };

  /**
   * Render the loading state if loading is true.
   * @returns {JSX.Element|null} The loading state component or null.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="m-4 px-4">
      <Row>
        <Col>
          <h2 className="fw-bold">User Management</h2>
          <p className="mb-0 pe-5">
            Manage Patron’s and Promoters’ accounts. Ability to approve and
            activate new promoters and make changes to existing user profiles.
            This includes Billing Information, Stripe Accounts etc.
          </p>
          <div className="d-flex my-4">
            <div className="d-flex align-items-center me-auto">
              <Form.Label className="mb-0 me-2">Filter By:</Form.Label>
              <Dropdown onSelect={filterByUserType}>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="rounded text-capitalize"
                >
                  {activeUserType
                    ? activeUserType.toLowerCase() + 's'
                    : 'All Users'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey={''}>All Users</Dropdown.Item>
                  <Dropdown.Item eventKey={'PROMOTER'}>Promoters</Dropdown.Item>
                  <Dropdown.Item eventKey={'USER'}>Patrons</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Search By Promoter"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Row className="bg-primary text-white">
        <Col className="p-3">Promoter/Company</Col>
        <Col className="p-3">Login Email</Col>
        <Col className="p-3">Address</Col>
        <Col className="p-3">Phone</Col>
        <Col className="p-3">Status</Col>
      </Row>

      {loadingFilteredResults && (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <h6 className="me-2">Loading...</h6>
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      {!loadingFilteredResults && (
        <>
          <>
            {!userList.length && (
              <h5 className="text-center py-4">No Results Found!</h5>
            )}
          </>
          {userList.map((user, i) => {
            return (
              <Row key={i} className="bg-header mb-2 border border-gray">
                <Col className="p-3">
                  {user.firstName} {user.lastName}
                </Col>
                <Col className="p-3">{user.email}</Col>
                <Col className="p-3">
                  {user.addressDetailsList
                    ? getAddressString(user.addressDetailsList[0])
                    : '-'}
                </Col>
                <Col className="p-3">{user.phoneNumber || '-'}</Col>
                <Col className="p-3">
                  {user.status}
                  {user.roles.indexOf(USER_ROLES.ROLE_PROMOTER) > -1 && (
                    <Button
                      onClick={() => {
                        setCurrentUser(user);
                        setShowStatusModal(true);
                      }}
                      variant="link"
                    >
                      <FontAwesomeIcon
                        className="text-secondary py-0"
                        icon={faPencil}
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            );
          })}
        </>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

      <Modal
        show={showStatusModal}
        backdrop="static"
        onHide={() => {
          setShowStatusModal(false);
          setCurrentUser(null);
        }}
        centered
      >
        {currentUser && (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="px-3">
                {currentUser.firstName} {currentUser.lastName}
                <h6 className="text-darkGray mb-0">{currentUser.email}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <h5 className="mb-3">Update Status</h5>

              <Form>
                {Object.keys(USER_STATUS).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="w-50 text-capitalize d-flex justify-content-between"
                    >
                      <Form.Label
                        className="me-3 text-capitalize"
                        htmlFor={item}
                      >
                        {USER_STATUS[item].toLowerCase()}
                      </Form.Label>
                      <Form.Check
                        reverse
                        label=""
                        name="eventStatus"
                        type="radio"
                        id={item}
                        checked={
                          currentUser.status.toLowerCase() == item.toLowerCase()
                        }
                        onChange={(e) =>
                          setCurrentUser({ ...currentUser, status: item })
                        }
                      />
                    </div>
                  );
                })}
                <Form.Control
                  className="mt-3"
                  as="textarea"
                  rows="4"
                  placeholder="Why was user rejected? write a short explanation to the user."
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => saveUpdatedStatus()}
                variant="orange"
                className="text-white px-4"
              >
                Save
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default UserManagement;
