/**
 * Utility functions that are used to format data throughout the code.
 */

/**
 * Utility func to capitalize first letter of every word in a string
 * Assumption: input string only contains alphabets
 * @param {string} str - The string to capitalize
 * @returns {string} - The capitalized string
 */
const capitalizeStr = (str) => {
  str = str.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

/**
 * Function specify the format for date.
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date in the format 'YYYY-MM-DD'.
 */
const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

// const getFormDataObj = (obj) => {
//     let formData = new FormData();
//     for ( let key in obj ) {
//         let kValue = obj[key];
//         if(kValue == undefined)
//             continue;
//         else if(Array.isArray(kValue)){
//             if(kValue.length){
//                 for(let i = 0; i < kValue.length; i++){
//                     if(kValue[i] instanceof File || typeof(kValue[i]) == "string"){
//                         formData.append(key, kValue[i]);
//                     }
//                     else {
//                         for(let k in kValue[i]){
//                             let fdKey = key + "[" + i + "]" + "." + k;
//                             if(kValue[i][k]){
//                                 formData.set(fdKey, kValue[i][k]);
//                             }

//                         }

//                     }
//                 }
//             }
//         }
//         else if(typeof(obj[key]) == "string"){
//             formData.append(key, obj[key]);
//         }
//         else{
//             formData.append(key, obj[key]);
//         }

//     }
//     return formData;
// }

/**
 * Converts form that includes fields such as images, etc. to a FormData object,
 * which is used to post a request to the server.
 *
 * @param {Object} obj - The object to convert to a FormData object.
 * @param {FormData} formData - The existing FormData object to append to.
 * @param {string} parentKey - The parent key to prefix the object keys with.
 * @return {FormData} The FormData object.
 */
const getFormDataObj = (obj, formData = null, parentKey = '') => {
  if (!formData) {
    formData = new FormData();
  }

  console.log('inform data', obj);

  /**
   * Loops through the object properties and appends the data to the formData
   * object.
   *
   * @param {Object} obj - The object to loop through.
   */
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey
        ? !isNaN(key)
          ? `${parentKey}[${key}]`
          : `${parentKey}.${key}`
        : key;

      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (typeof element === 'object') {
            Object.keys(element).forEach((nestedKey) => {
              const nestedValue = element[nestedKey];
              const nestedFormKey = `${formKey}[${index}].${nestedKey}`;
              if (
                nestedValue instanceof File ||
                typeof nestedValue === 'string'
              ) {
                formData.append(nestedFormKey, nestedValue);
              } else if (
                typeof nestedValue === 'boolean' ||
                typeof nestedValue === 'number'
              ) {
                formData.append(nestedFormKey, nestedValue.toString());
              } else if (
                typeof nestedValue === 'object' &&
                nestedValue !== null
              ) {
                getFormDataObj(nestedValue, formData, nestedFormKey);
              }
            });
          } else {
            formData.append(`${formKey}[[${index}]]`, element);
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        if (value instanceof File || typeof value === 'string') {
          formData.append(formKey, value);
        }
        getFormDataObj(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
};

// const formData = new FormData();

//     const getFormDataObj = (obj, parentKey = '') => {
//       for (const key in obj) {
//         if (obj.hasOwnProperty(key)) {
//           const keyName = parentKey ? `${parentKey}.${key}` : key;
//           if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//             getFormDataObj(obj[key], keyName);
//           } else if (Array.isArray(obj[key])) {
//             obj[key].forEach((item, index) => {
//               getFormDataObj(item, `${keyName}[${index}]`);
//             });
//           } else {
//             formData.append(keyName, obj[key]);
//           }
//         }
//       }
//       return formData
//     };

export { capitalizeStr, formatDate, getFormDataObj };
