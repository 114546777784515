import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

/*
 * CloseAccount component renders a form to get the reason to close an account.
 *
 * @returns {JSX.Element} The CloseAccount component.
 */
const CloseAccount = () => {
  /*
   * Function to handle form submission.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    // Still need to add the logic to close the account
  };

  return (
    <>
      <h2 className="fw-bold mt-3 mb-0">Close your account</h2>
      <span>Please take a moment to let us know why you are leaving.</span>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="10">
            <Row>
              <Col xs="7">
                <Form.Group controlId="summary" className="mt-4 mb-5">
                  <Form.Control
                    as="textarea"
                    placeholder="Type here..."
                    rows={5}
                  />
                </Form.Group>

                <span>
                  Please enter your password to confirm that you wish to close
                  your account.
                </span>
                <Form.Group controlId="password" className="mb-3 mt-3">
                  <Form.Label className="required">Password</Form.Label>
                  <Form.Control type="password" placeholder="email..." />
                </Form.Group>
              </Col>
            </Row>

            <hr className="my-4" />

            <Row>
              <Col className="d-flex">
                <Button
                  type="submit"
                  variant="orange"
                  className="text-white px-5 ms-auto border border-orange"
                >
                  Close Account
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CloseAccount;
