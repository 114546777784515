/**
 * Sets an item in the local storage.
 *
 * @param {string} k - The key for the item to be stored.
 * @param {*} v - The value to be stored in local storage.
 */
const setItem = (k, v) => {
  const jsonData = JSON.stringify(v);
  localStorage.setItem(k, jsonData);
};

/**
 * Gets an item from local storage.
 *
 * @param {string} key - The key for the item to be retrieved.
 * @returns {*} - The value stored in local storage. If the key does not exist, this method will return null.
 */
const getItem = (key) => {
  let value = localStorage.getItem(key);
  if (value) {
    value = JSON.parse(value);
  }
  return value;
};

/**
 * Removes an item from local storage.
 *
 * @param {string} key - The key for the item to be removed.
 */
const removeItem = (key) => {
  localStorage.removeItem(key);
};

/**
 * Clears the local storage.
 *
 * This function will remove all items from the local storage.
 */
const clearStorage = () => {
  localStorage.clear();
};

export { setItem, getItem, removeItem, clearStorage };
