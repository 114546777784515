import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Card,
  Form,
  Modal,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useAsync } from '../hooks/useAsync';
import Footer from '../Footer/Footer';
import {
  getEventList,
  getCategoryDetails,
  getLocationTypes,
  getEventListCustomized,
  eventListFilter,
} from '../utils/APIUtils';
import { Slide } from 'react-slideshow-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import {
  faChevronLeft,
  faChevronRight,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';
import { colors } from '@mui/material';
import { Co2Sharp } from '@mui/icons-material';
import { type } from '@testing-library/user-event/dist/type';
import { set } from 'lodash';
import { useAuth } from '../hooks/useAuth';

const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="ms-5">
      <FontAwesomeIcon
        icon={faChevronLeft}
        size="4x"
        className="text-darkGray"
      />
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="me-5">
      <FontAwesomeIcon
        icon={faChevronRight}
        size="4x"
        className="text-darkGray"
      />
    </button>
  ),
  indicators: true,
};
/**
 * React functional component for the event dashboard.
 *
 * @return {JSX.Element} The event dashboard component.
 */
const EventDashboard = () => {
  const { data, error, run } = useAsync(null);
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const defaultThumbnail =
    'https://via.placeholder.com/150/FF5733/FFFFFF/?text=Thumbnail';

  const [locationTypes, setLocationTypes] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const [categoryName, setCategoryName] = useState('');

  const [format, setFormat] = useState(null);
  const [typesSelected, setTypesSelected] = useState([]);

  const [showModal, setShowModal] = useState();

  const [dateType, setDateType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [result, setResults] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  let FilterEventListRequestDto = {};

  /**
   * UseEffect hook which is run once when the component mounts.
   * Calls the API to get the list of published events, categories and location types.
   * Sets the state variables allEvents, locationTypes and categoryDetails.
   * Shows the loading spinner while the API call is in progress.
   */
  useEffect(() => {
    setLoading(true);
    let isloggedin = auth && auth.user;
    Promise.all([
      getEventList(0, isloggedin),
      getCategoryDetails(isloggedin),
      getLocationTypes(),
    ])
      .then((values) => {
        const eventListRes = values[0];
        const categoryDetailsRes = values[1];
        const locationTypeRes = values[2];
        console.log('test', categoryDetailsRes.data);
        const publishedEvents = eventListRes.data;
        setAllEvents(publishedEvents);
        setLocationTypes(locationTypeRes.data);
        setCategoryDetails(categoryDetailsRes.data);
        setResults(publishedEvents.length);
        setLoading(false);
        // For pagination
        // const tp = Math.ceil(publishedEvents.length / 10) // 10 is total number of items
        // setTotalPages(tp)

        //For Customized List

        getEventListCustomized(auth.user).then((res) => {
          console.log('Customized List', res.data);
          setUpcomingEvents(eventListRes.data.upcomingEvents);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  /**
   * If the loading flag is true, shows a loading spinner.
   */
  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  /**
   * Checks if the length of allEvents is zero.
   *
   * @return {boolean} The result of the comparison.
   */
  if (allEvents.length == 0)
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <h5 className="me-2">
          Sorry, We don't have any events going on at this time.
        </h5>
      </div>
    );

  /**
   * Handles the change event of the category select input.
   *
   * @param {Event} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleCategoryChange = (event) => {
    console.log('Category clicked', event.target.value);
    if (typesSelected.length > 0) {
      setTypesSelected([]);
    } // handling if User changes Category after selected any types from previous category
    setCategoryName(event.target.value);
  };

  /**
   * Handles the change event of the type select input.
   *
   * @param {Event} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleTypeChange = (event) => {
    const { value, checked } = event.target;

    if (value === 'all' && checked) {
      setTypesSelected(['all']);
    } else if (value !== 'all' && checked) {
      setTypesSelected((prevArray) => [...prevArray, value]);
    } else {
      setTypesSelected((prevArray) =>
        prevArray.filter((item) => item !== value)
      );
    }

    console.log('Type clicked', value);
    console.log('Types selected', typesSelected);
  };

  /**
   * Serializes an object into a URL-encoded string.
   *
   * @param {Object} obj - The object to be serialized.
   * @param {string} [prefix] - The prefix for the keys in the serialized string.
   * @return {string} The serialized string.
   */
  const serialize = function (obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  };

  /**
   * Applies the filters based on the selected options.
   *
   * @return {void} This function does not return anything.
   */
  const applyFilters = () => {
    console.log('Apply filters clicked');
    const allLocationTypes = format === 'all' ? true : false;
    const allCategories = categoryName === 'all' ? true : false;
    const allSubCategories =
      typesSelected[0] === 'all' || typesSelected.length === 0 ? true : false;
    const allDatesCovered =
      startDate === null && endDate === null && dateType === 'All'
        ? true
        : false;
    console.log(dateType === 'All');
    const eventsOnWeekends = dateType === 'weekends' ? true : false;
    const eventsOnWeekdays = dateType === 'weekdays' ? true : false;
    const eventsWithinDateRange = dateType === 'dateRange' ? true : false;
    const eventsOnThisWeekend = dateType === 'thisWeekend' ? true : false;
    const locationTypeFilterList =
      format === 'all'
        ? []
        : [locationTypes.find((type) => type.type === format).id];
    const categoriesFilterList =
      categoryName === 'all' || categoryName === null
        ? []
        : [
            categoryDetails.find((category) => category.name === categoryName)
              .id,
          ];

    const mc = categoryDetails.findIndex(
      (category) => category.name === categoryName
    );
    // console.log("abcd", categoryDetails[mc].eventSubCategoriesModelList)
    let subCategoriesFilterList;
    if (mc) {
      subCategoriesFilterList = typesSelected.map((type) => {
        return categoryDetails[mc].eventSubCategoriesModelList.find(
          (subCategory) => subCategory.name === type
        ).id;
      });
    } else {
      console.log('No category');
    }

    let formattedStartDate;

    if (startDate) {
      const year = startDate.getFullYear();
      let month = startDate.getMonth() + 1; // Adding 1 because January is 0
      month = month < 10 ? '0' + month : month; // Add leading zero if month is less than 10
      const day = String(startDate.getDate()).padStart(2, '0');

      formattedStartDate = `${year}-${month}-${day}`;
    }

    let formattedEndDate;

    if (endDate) {
      const year = endDate.getFullYear();
      let month = endDate.getMonth() + 1; // Adding 1 because January is 0
      month = month < 10 ? '0' + month : month; // Add leading zero if month is less than 10
      const day = String(endDate.getDate()).padStart(2, '0');
      formattedEndDate = `${year}-${month}-${day}`;
    }

    FilterEventListRequestDto = {
      allLocationTypes: allLocationTypes,
      allCategories: allCategories,
      allSubCategories: allSubCategories,
      allDatesCovered: allDatesCovered,
      eventsOnWeekends: eventsOnWeekends,
      eventsOnWeekdays: eventsOnWeekdays,
      eventsWithinDateRange: eventsWithinDateRange,
      eventsOnThisWeekend: eventsOnThisWeekend,
      locationTypeFilterList: locationTypeFilterList,
      categoriesFilterList: categoriesFilterList,
      subCategoriesFilterList: subCategoriesFilterList,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    console.log(FilterEventListRequestDto);

    console.log('Filtered events: ');
    eventListFilter(FilterEventListRequestDto)
      .then((res) => {
        console.log(res.data);
        setAllEvents(res.data);
        // Total pages logic
        console.log(res.data.length);
        const tp = Math.ceil(res.data.length / 10); // 10 is items per page
        setTotalPages(tp);
        setResults(res.data.length);
        console.log('Total pages', tp);
      })
      .catch((error) => {
        console.error('Error applying filters:', error);
      });
  };

  /**
   * Resets all filters and updates the event list.
   *
   * @return {void}
   */
  const resetFilters = () => {
    console.log('Reset filters clicked');
    FilterEventListRequestDto = {};
    eventListFilter(FilterEventListRequestDto).then((res) => {
      setAllEvents(res.data);
      // Total pages logic
      console.log(res.data.length);
      const tp = Math.ceil(res.data.length / 10);
      setTotalPages(tp);
      setResults(res.data.length);
    });
  };

  /**
   * Opens the date range modal.
   *
   * @return {void}
   */
  const dateRangeModal = () => {
    setShowModal(true);
  };

  /**
   * Handles the format change event for the format dropdown.
   *
   * @param {object} event - The event object passed from the format dropdown.
   *
   * @return {void}
   */
  const handleFormatChange = (event) => {
    console.log('Format clicked', event.target.value);
    setFormat(event.target.value);
  };

  /**
   * Handles the apply button click in the date range modal.
   *
   * @return {void}
   */
  const handleModalApply = () => {
    // API Call thing here
    console.log('Start Date', startDate);
    console.log('End Date', endDate);
    setShowModal(false);
    console.log('Modal Apply clicked');
  };

  /**
   * Closes the date range modal.
   *
   * @return {void}
   */
  const handleModalClose = () => {
    setShowModal(false);
    console.log('Modal Close clicked');
  };

  /**
   * Handles the date range select event.
   *
   * @return {void}
   */
  const handleDateRangeSelect = () => {
    console.log('Date Range Selected');
    setDateType('dateRange');
    setShowModal(true);
  };

  /**
   * Handles the date change event.
   *
   * @param {object} event - The event object passed from the date input.
   *
   * @return {void}
   */
  const handleDateChange = (event) => {
    console.log('Date Change', event.target.value);
    setDateType(event.target.value);
  };

  /**
   * Handles the page change event.
   *
   * @param {number} page - The page number to fetch.
   *
   * @return {void}
   */
  const handlePageChange = (page) => {
    console.log('Page Change', page);
    // setPage(event.target.value);
    setCurrentPage(page);
    eventListFilter(FilterEventListRequestDto, page);
  };

  /**
   * Handles the sort click event.
   *
   * @param {object} option - The object containing the sort option.
   *
   * @return {void}
   */
  const handleSortClick = (option) => {
    console.log(handleSortClick);
    setSortOption(option);
    // handleSort(option);
  };

  return (
    <div className="h-100">
      <Container fluid className="bg-header">
        <Row>
          <Col
            key="events"
            md={3}
            className="d-flex flex-column align-items-start"
          >
            <h3 className="fw-bold mb-4">Filters</h3>
            <div style={{ width: '250px', marginRight: '20px' }}>
              {/* Add your sidebar filters here */}
              <h5 className="bold-text">FORMAT</h5>
              {/* Example filter radio buttons */}
              <ul style={{ listStyle: 'none' }}>
                <Form>
                  <Col key="radio">
                    <Form.Check
                      type="radio"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleFormatChange}
                    />
                    {Object.entries(locationTypes).map(([key, value]) => (
                      <Form.Check
                        key={key}
                        type="radio"
                        name="filter"
                        id={key}
                        label={value.name}
                        value={value.type}
                        onChange={handleFormatChange}
                      />
                    ))}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h5 className="bold-text">CATEGORY</h5>
              <ul style={{ listStyle: 'none' }}>
                <Form>
                  <Col>
                    <Form.Check
                      type="radio"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleCategoryChange}
                    />
                    {Object.entries(categoryDetails).map(([key, value]) => (
                      <Form.Check
                        key={key}
                        type="radio"
                        name="filter"
                        id={key}
                        label={value.name}
                        value={value.name}
                        onChange={handleCategoryChange}
                      />
                    ))}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h5 className="bold-text">TYPES</h5>
              <ul style={{ listStyle: 'none' }}>
                <Form>
                  <Col>
                    <Form.Check
                      disabled={
                        categoryName === 'all' || categoryName === ''
                          ? true
                          : false
                      }
                      type="checkbox"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleTypeChange}
                    />
                    {Object.entries(categoryDetails).map((key, value) => {
                      if (categoryDetails[value]['name'] === categoryName) {
                        return Object.entries(
                          categoryDetails[value]['eventSubCategoriesModelList']
                        ).map(([key, value]) => (
                          <Form.Check
                            disabled={
                              typesSelected.includes('all') ? true : false
                            }
                            key={key}
                            type="checkbox"
                            name="filter"
                            id={key}
                            label={value.name}
                            value={value.name}
                            onChange={handleTypeChange}
                          />
                        ));
                      }
                    })}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h5 className="bold-text">Date</h5>
              <ul style={{ listStyle: 'None' }}>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="thiWeekend"
                    label="This Weekend"
                    value="thisWeekend"
                    onChange={handleDateChange}
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Date Range"
                    label="Date Range"
                    value="Date Range"
                    onClick={handleDateRangeSelect}
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Weekdays"
                    label="Weekdays"
                    value="Weekdays"
                    onChange={handleDateChange}
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Weekends"
                    label="Weekends"
                    value="Weekends"
                    onChange={handleDateChange}
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="All"
                    label="All"
                    value="All"
                    onChange={handleDateChange}
                  />
                </li>
              </ul>
              <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Select Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Date range picker */}
                  <div>
                    <p>Start Date:</p>
                    <DatePicker
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div>
                    <p>End Date:</p>
                    <DatePicker
                      selected={endDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleModalApply}>
                    Apply
                  </Button>
                </Modal.Footer>
              </Modal>

              <Button className="m-4 rounded-pill" onClick={applyFilters}>
                Apply
              </Button>
              <Button className="m-4 rounded-pill" onClick={resetFilters}>
                Reset
              </Button>
            </div>
          </Col>
          <Col
            md={9}
            style={{ width: '50%', position: 'relative', left: '30px' }}
          >
            <Row className="align-items-center">
              <Col xs={6}>
                <h4 className="fw-bold mb-4">{result} items found</h4>
              </Col>
              <Col xs={6} className="text-end">
                <DropdownButton
                  title={`Sort by ${sortOption || '...'}`}
                  variant="secondary"
                >
                  <Dropdown.Item onClick={() => handleSortClick('date')}>
                    Sort by Date
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortClick('alphabet')}>
                    Sort Alphabetically
                  </Dropdown.Item>
                  {/* Add more sorting options as needed */}
                </DropdownButton>
              </Col>
            </Row>

            {allEvents.map((event) => {
              return (
                <ul style={{ listStyle: 'None' }}>
                  <li>
                    <Link to={`/events/${event.eventId}`}>
                      <Card
                        className="text-white bg-dark"
                        style={{ maxWidth: '100%', minWidth: '400px' }}
                      >
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center justify-content-center">
                              {event.thumbnailUrl && (
                                <Card.Img
                                  src={event.thumbnailUrl}
                                  alt={event.eventTitle}
                                  style={{ height: 'auto', maxHeight: '50vh' }}
                                />
                              )}
                              {!event.thumbnailUrl && (
                                <Card.Img
                                  src={defaultThumbnail}
                                  alt={event.eventTitle}
                                  style={{ height: 'auto', maxHeight: '50vh' }}
                                />
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex flex-column h-100 justify-content-between">
                                <div className="m-4">
                                  <Card.Title style={{ fontWeight: 'bold' }}>
                                    {event.eventTitle}
                                  </Card.Title>
                                  <Card.Text>
                                    {moment(event.eventStartDate).format(
                                      'MMM DD'
                                    )}
                                  </Card.Text>
                                  <Card.Text>{event.summary}</Card.Text>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 d-flex align-items-center justify-content-end">
                              {' '}
                              {/* Adjusted class */}
                              <Button
                                variant="primary"
                                className="m-4 rounded-pill"
                                style={{
                                  fontSize: '0.8em',
                                  fontFamily: 'Arial, sans-serif',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faShoppingCart}
                                  className="me-2"
                                />{' '}
                                View Tickets
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </li>
                </ul>
              );
            })}

            {upcomingEvents && !!upcomingEvents.length && (
              <Row>
                <Col className="my-4 px-5">
                  <h3 className="fw-bold mb-4">Upcoming</h3>
                  <Row>
                    {upcomingEvents.map((event) => {
                      return (
                        <Col xs={12} md={6} lg={4} className="mb-4">
                          <a
                            href={`/events/${event.eventId}`}
                            className="hoverable-card position-relative"
                          >
                            <div
                              className="d-flex justify-content-center event-card"
                              style={{
                                backgroundImage: `url(${event.thumbnailUrl})`,
                                height: '250px',
                              }}
                            >
                              <div className="hoverable-text-hidden d-flex flex-column flex-grow-1">
                                <div>
                                  <div className="d-inline-block m-3 bg-black px-3 py-1 text-white">
                                    <small>
                                      {moment(event.eventDate).format('MMM DD')}
                                    </small>
                                  </div>
                                </div>
                                {/* <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                                        <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2> */}
                                <h3 className="text-uppercase fw-bold mb-3 mt-auto text-center text-white">
                                  {event.eventTitle}
                                </h3>
                              </div>

                              <div className="hoverable-text-visible">
                                <div className="banner-overlay">
                                  <div className="p-3">
                                    <h3 className="text-uppercase fw-bold mb-3 mt-auto text-center text-white">
                                      {event.eventTitle}
                                    </h3>
                                    <h5 className="text-center text-white">
                                      {moment(event.eventDate).format(
                                        'ddd MMM DD, YYYY hh:mm A'
                                      )}
                                    </h5>
                                    <p className="event-card-text-ellipses">
                                      YEAT This is an ALL AGES show. HISTORY is
                                      proud to have a food menu brought to you
                                      by Food Dudes & Blondies each and every
                                      night. If you have tickets for the Premium
                                      Seating areas and arEAT This is an ALL
                                      AGES show. HISTORY is proud to have a food
                                      menu brought to you by Food Dudes &
                                      Blondies each and every night. If you have
                                      tickets for the Premium Seating areas and
                                      arEAT This is an ALL AGES show. HISTORY is
                                      proud to have a food menu brought to you
                                      by Food Dudes & Blondies each and every
                                      night. If you have tickets for the Premium
                                      Seating areas and ar
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {totalPages > 1 && (
          <Container>
            <Row className="justify-content-center">
              <Col xs="auto">
                <div>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <button key={page} onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}

        {/* <Row>
                        <Col className="px-0">
                            <div className='p-3'>
                                <h2>Your Events</h2>
                            </div>
                        {allEvents.map((event) => {
                            return (
                                <div className="homebanner mb-5" style={{backgroundImage: `url(${event.thumbnailUrl})`}}>
                                    <div id="bannertext" className="mt-auto p-5 mb-5 mx-4">
                                        <div>
                                            <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                            <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2>
                                        </div>
                                        <Button to={`/preview-event/${event.eventId}`} as={Link} className='text-white px-4' variant='primary'>
                                            Get Tickets
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                        </Col>
                    </Row> */}

        {/* {upcomingEvents.length &&
                    <Row>
                        <Col xs={12} className="p-5 text-center">
                            <h2 className="fw-bold">Upcoming Events</h2>
                        </Col>
                        <Col xs={12} className="my-4">
                            <ul className="artists-carousel">
                                {upcomingEvents.map((event) => {
                                    return(
                                        <li>
                                            <picture>
                                                <source srcSet={event.thumbnailUrl} media="(min-width: 768px)" />
                                                <img className='h-100' src={event.thumbnailUrl} alt="artist 1" />
                                            </picture>
                                            <h5 className='title'>{event.eventTitle}</h5>
                                        </li>
                                    )
                                })}

                            </ul>
                        </Col>
                    </Row>
                    } */}

        {/* <Row>
                        <Col className="px-0">
                            <div className='p-3'>
                                <h2>Featured Events</h2>
                            </div>
                        {allEvents.map((event) => {
                            return (
                                <div className="homebanner mb-5" style={{backgroundImage: `url(${event.thumbnailUrl})`}}>
                                    <div id="bannertext" className="mt-auto p-5 mb-5 mx-4">
                                        <div>
                                            <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                            <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2>
                                        </div>
                                        <Button to={`/preview-event/${event.eventId}`} as={Link} className='text-white px-4' variant='primary'>
                                            Get Tickets
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}

                        </Col>
                    </Row> */}

        <Row>
          <Col>
            <Footer></Footer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventDashboard;
