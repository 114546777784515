import React, { useState, useEffect } from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { useAsync } from '../../hooks/useAsync';
import { getManageUsers, getPatronOrderList } from '../../utils/APIUtils';
import { USER_ROLES, USER_TYPE } from '../../constants';
import Pagination from '../../shared/Pagination';

/**
 * The PatronManagement component is a functional component that renders a
 * HTML element for patron management. It fetches the list of users on component
 * mount and allows the user to search for patrons and view their orders.
 *
 * @returns {JSX.Element} The rendered PatronManagement component.
 */
const PatronManagement = () => {
  /**
   * State hook to manage the "show order" modal.
   * @type {React.State<boolean>}
   */
  const [showOrderModal, setShowOrderModal] = useState(false);

  /**
   * Destructuring assigment to extract properties from the useAsync hook.
   * @type {Object}
   * @property {Object|null} data - The data returned from the API call.
   * @property {boolean} loading - Indicates if the API call is in progress.
   * @property {Error|null} error - The error object from the API call.
   * @property {Function} run - The function to execute the API call.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * State hook to manage the user list.
   * @type {React.State<Array>}
   */
  const [userList, setUserList] = useState([]);

  /**
   * State hook to manage the order list.
   * @type {React.State<Array>}
   */
  const [orderList, setOrderList] = useState([]);

  /**
   * State hook to manage the search query.
   * @type {React.State<string>}
   */
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * State hook to manage the current page.
   * @type {React.State<number>}
   */
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * State hook to manage the total pages.
   * @type {React.State<number>}
   */
  const [totalPages, setTotalPages] = useState(0);

  /**
   * Debounce effect for search.
   * Fetches the filtered patron list after a delay of 500ms.
   * @returns {void}
   */
  useEffect(() => {
    const getData = setTimeout(() => {
      getFilteredPatronList();
    }, 500);

    return () => clearTimeout(getData);
  }, [searchQuery, currentPage]);

  /**
   * Fetches the list of users on component mount.
   * @returns {void}
   */
  useEffect(() => {
    run(() => getManageUsers('USER')).then((res) => {
      setUserList(res.data.usersList);
      setTotalPages(res.data.totalPages);
    });
  }, []);

  /**
   * Function to get the address string.
   * @param {Object} addressDTO - The address data transfer object.
   * @returns {string} The formatted address string.
   */
  const getAddressString = (addressDTO) => {
    return ` ${addressDTO.addressLine1 || ''} ${
      addressDTO.addressLine2 || ''
    } ${addressDTO.addressLine1 || ''} ${addressDTO.city || ''} ${
      addressDTO.province || ''
    } ${addressDTO.country || ''} ${addressDTO.postalCode || ''}`;
  };

  /**
   * Function to get the filtered patron list.
   * @returns {void}
   */
  const getFilteredPatronList = () => {
    let query = searchQuery.trim(),
      pageNo = currentPage;

    getManageUsers('USER', pageNo, query)
      .then((res) => {
        setUserList(res.data.usersList);
        if (res.data.totalPages != totalPages) {
          setTotalPages(res.data.totalPages);
          setCurrentPage(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Function to view the patron orders.
   * @param {string} userId - The ID of the user.
   * @returns {void}
   */
  const viewPatronOrders = (userId) => {
    setShowOrderModal(true);
    getPatronOrderList(userId)
      .then((res) => {
        setOrderList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Function to handle page changes.
   * @param {number} page - The page number.
   * @returns {void}
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  /**
   * Renders a loading spinner if loading is true.
   *
   * @return {JSX.Element|null} The loading spinner or null if not loading.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="m-4 px-4">
      <h2 className="fw-bold">Patron Management</h2>
      <p className="mb-0">View and manage patron details.</p>

      <div className="d-flex my-4">
        <Form.Group className="ms-auto">
          <Form.Control
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
      </div>

      <Row className="bg-primary text-white">
        <Col className="p-3">Patron ID</Col>
        <Col className="p-3">Patron name</Col>
        <Col className="p-3">Email Id</Col>
        <Col className="p-3">Phone</Col>
        <Col className="p-3">Address</Col>
        <Col xs="2" className="p-3">
          Orders
        </Col>
      </Row>

      {!userList.length && (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <h5 className="me-2">No Results Found!</h5>
        </div>
      )}
      {userList.length &&
        userList.map((user, i) => {
          return (
            <Row key={i} className="bg-header mb-2 border border-gray">
              <Col className="p-3">{user.userId}</Col>
              <Col className="p-3">
                {user.firstName} {user.lastName}
              </Col>
              <Col className="p-3">{user.email}</Col>
              <Col className="p-3">{user.phoneNumber || '-'}</Col>
              <Col className="p-3">
                {user.addressDetailsList
                  ? getAddressString(user.addressDetailsList[0])
                  : '-'}
              </Col>
              <Col xs="2" className="p-3">
                <Button
                  onClick={() => viewPatronOrders(user.userId)}
                  variant="link"
                  className="p-0 text-secondary text-decoration-none"
                >
                  View Orders{' '}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Button>
              </Col>
            </Row>
          );
        })}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

      <Modal
        show={showOrderModal}
        onHide={() => setShowOrderModal(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3">
            <h3 className="mb-0 fw-bold">Order History</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {!orderList.length && (
            <div className="d-flex h-100 align-items-center justify-content-center">
              <h5>No Orders Found for this patron.</h5>
            </div>
          )}

          {!!orderList.length && (
            <div>
              <Row className="bg-primary text-white mt-3">
                <Col xs={1} className="p-2">
                  Order ID
                </Col>
                <Col className="p-2">Event Name</Col>
                <Col className="p-2">Promoter Name / Email</Col>
                <Col className="p-2">Patron Name / Email</Col>
                <Col className="p-2 text-center">Service Fees</Col>
                <Col className="p-2 text-center">Promoter Fees</Col>
                <Col className="p-2 text-center">Total Amount</Col>
                <Col className="p-2 text-center">Last Update</Col>
                <Col className="p-2">Payment Status</Col>
              </Row>

              <Row className="bg-header mb-2 border border-gray">
                <Col xs={1} className="p-2">
                  001
                </Col>
                <Col className="p-2">Rock your soul</Col>
                <Col className="p-2">Vincent Dark / vinDark@gmail.com</Col>
                <Col className="p-2">Robert Steeve / rb.stv@gmail.com</Col>
                <Col className="p-2 text-center">$2.5</Col>
                <Col className="p-2 text-center">$3.5</Col>
                <Col className="p-2 text-center">$10.0</Col>
                <Col className="p-2 text-center">12/05/2022</Col>
                <Col className="p-2">Paid</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatronManagement;
